import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { commonType } from '../../../constant/actionTypes';
import service from '../../../service';
import './index.scss';
import { newsUrl } from '../../../constant/endpoint';

const NewsDetail = (props) => {
    const {
        router: {
            location: {
                query: { storyId, id },
            },
        },
        dispatch,
        locale,
    } = props;

    const [detail, setDetail] = useState(null);

    useEffect(() => {
        fetchNewsDetail();
    }, []); // eslint-disable-line

    const fetchNewsDetail = async () => {
        if (
            (typeof storyId !== 'string' || !storyId.trim()) &&
            (typeof id !== 'string' || !id.trim())
        ) {
            return;
        }
        try {
            toggleGlobalLoading(true);
            let res = null;
            if (typeof storyId === 'string' && storyId.trim()) {
                res = await service.get(newsUrl.NEWS_CONTENT, {
                    storyId,
                });
            } else if (typeof id === 'string' && id.trim()) {
                res = await service.get(newsUrl.ESG_SCORE_NEWS_CONTENT(id));
            }
            const {
                text = '',
                body = '',
                title = '',
                abstract = '',
            } = res || {};
            setDetail({
                title: title || text,
                content: abstract || body,
            });
        } catch (err) {
            console.log(err);
        }
        toggleGlobalLoading(false);
    };

    const toggleGlobalLoading = (globalLoading) => {
        dispatch({
            type: commonType.APPLY_SET_COMMON_STATE,
            payload: {
                globalLoading,
            },
        });
    };
    return (
        <div className="page news_detail_page">
            {(typeof storyId !== 'string' || !storyId.trim()) &&
                (typeof id !== 'string' || !id.trim()) && (
                    <Redirect to="/news" />
                )}
            <Helmet title={locale.news_detail} />
            {detail && (
                <div className="news_detail-wrap esgai_scrollbar">
                    <h4 className="news_detail-title">{detail.title}</h4>
                    <div
                        className="news_detail-content"
                        dangerouslySetInnerHTML={{ __html: detail.content }}
                    />
                </div>
            )}
        </div>
    );
};

export default withRouter(
    connect(({ persist: { locale }, router }) => ({
        router,
        locale,
    }))(NewsDetail)
);
