import React from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { esgScoreUrl, profileUrl } from '../../constant/endpoint';
import service from '../../service';
import { commonType, esgType } from '../../constant/actionTypes';
import EsgScoreHeader from './component/EsgScoreHeader';
import EsgScoreMain from './component/EsgScoreMain';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';
import './index.scss';

class EsgScore extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            competitorEditable: false,
            listData: null,
            competitorArr: [],
        };
    }

    componentDidMount() {
        this.addPageListener();
        this.fetchListData();
    }

    componentWillUnmount() {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    }

    addPageListener = () => {
        this.resizeObserver = new ResizeObserver(() => {
            const page = document.getElementsByClassName('esg_score_page')[0];
            const { width, height } = page.getBoundingClientRect();
            const hGap = width - page.clientWidth;
            const vGap = height - page.clientHeight;
            document.documentElement.style.setProperty(
                '--page_scroll_bar-v',
                `${hGap}px`
            );
            document.documentElement.style.setProperty(
                '--page_scroll_bar-h',
                `${vGap}px`
            );
        });
        this.resizeObserver.observe(
            document.getElementsByClassName('esg_score-content')[0]
        );
    };

    isIndustryScore = () => {
        const {
            router: {
                location: { pathname },
            },
        } = this.props;
        return pathname.indexOf('/esg-score/industry') === 0;
    };

    updateCompetitors = async (organizationIds, cb) => {
        this.toggleGlobalLoading(true);
        try {
            await service.put(esgScoreUrl.UPDATE_COMPETITORS, {
                organizationIds,
            });
        } catch (err) {
            console.log(err);
        }
        if (typeof cb === 'function') {
            await cb();
        }
        this.toggleGlobalLoading(false);
    };

    fetchListData = async (forceUpdate = false, competitorIds = [], cb) => {
        const { competitorArr, listData } = this.state;
        if (
            !forceUpdate &&
            listData &&
            !(!this.isIndustryScore() && competitorArr.length === 0)
        ) {
            return;
        }
        this.toggleGlobalLoading(true);
        try {
            if (!listData || forceUpdate) {
                const { esg, dispatch } = this.props;
                let dataPoint = esg && esg.name ? esg.name : '';
                let api = this.isIndustryScore()
                    ? esgScoreUrl.SCORE_CARD_INDUSTRY
                    : esgScoreUrl.SCORE_CARD_COMPETITORS;
                if (!listData && dataPoint) {
                    api += `?name=${dataPoint}`;
                    dispatch({
                        type: esgType.APPLY_SET_ESG_STATE,
                        payload: {
                            name: null,
                        },
                    });
                }
                if (Array.isArray(competitorIds) && competitorIds.length > 0) {
                    api = `${api}${!listData && dataPoint ? '&' : '?'
                        }${competitorIds
                            .map(
                                (i) =>
                                    `${i.isNewCompetitor
                                        ? 'newCompetitorIds'
                                        : 'competitorIds'
                                    }=${i.id}`
                            )
                            .join('&')}`;
                }

                const res = await service.get(api);
                this.processListData(res);
            }
            if (
                !this.isIndustryScore() &&
                (competitorArr.length === 0 ||
                    (Array.isArray(competitorIds) &&
                        competitorIds.find((i) => i.isNewCompetitor)))
            ) {
                const res = await service.get(
                    profileUrl.MANAGE_COMPANY.COMPETITORS.ITEMS
                );
                if (Array.isArray(res)) {
                    this.setState({
                        competitorArr: res,
                    });
                }
            }
        } catch (err) {
            console.log(err);
        }
        if (typeof cb === 'function') {
            cb();
        }
        this.toggleGlobalLoading(false);
    };

    toggleGlobalLoading = (globalLoading) => {
        this.props.dispatch({
            type: commonType.APPLY_SET_COMMON_STATE,
            payload: {
                globalLoading,
            },
        });
    };

    processListData = (data) => {
        const { locale } = this.props;
        const isIndustryScore = this.isIndustryScore();
        let list = null;
        let rank = null;
        this.setState({
            hasCompetitor: data.hasCompetitor,
        });
        if (data && Array.isArray(data.score)) {
            const {
                score,
                scoreRank = {},
                strongest = [],
                weakest = [],
            } = data;
            list = score;
            rank = { scoreRank, strongest, weakest };
        }
        if (list) {
            let self = null;
            let avg = null;
            let noAvg = false;
            let noCompetitors = false;
            const competitors = [];
            list.forEach((i) => {
                if (i.myself) {
                    self = i;
                } else if (
                    i.name === 'Competitors avg' ||
                    i.name === 'Industry avg'
                ) {
                    avg = i;
                } else {
                    competitors.push(i);
                }
            });
            if (!avg) {
                avg = self;
                noAvg = true;
            }
            avg = { ...avg, id: 'avg' };
            if (competitors.length === 0) {
                if (!isIndustryScore) {
                    competitors.push({ ...self, isPlaceholder: true });
                }
                noCompetitors = true;
            }
            let columnArr = [self, avg, ...competitors];
            const firstLevelRow = [
                { key: 'ESGScore', name: locale.overall_score.toUpperCase() },
                {
                    key: 'EnvironmentPillarScore',
                    name: locale.environmental.toUpperCase(),
                },
                { key: 'SocialPillarScore', name: locale.social.toUpperCase() },
                {
                    key: 'GovernancePillarScore',
                    name: locale.governance.toUpperCase(),
                },
            ];
            columnArr = columnArr.map((i) => {
                const dataMap = {};
                firstLevelRow.forEach((m) => {
                    dataMap[m.key] = i.pillars.find((n) => n.name === m.key);
                });
                return { ...i, dataMap };
            });
            this.setState({
                listData: {
                    noAvg,
                    noCompetitors,
                    avg: columnArr[1],
                    self: columnArr[0],
                    competitors: columnArr.slice(2),
                    columnArr,
                    firstLevelRow,
                    rank,
                    breadCrumbs: data.breadCrumbs,
                },
            });
        }
    };

    renderLegend = () => {
        const { locale } = this.props;
        return (
            <div className="esg_score-table-legend">
                <p>{locale.less_than_yours}</p>
                <p>{locale.more_than_yours}</p>
            </div>
        );
    };

    exportCSV = () => {
        const zip = new JSZip();
        const time = dayjs().format('YYYY-MM-DD_HHmm');
        const isIndustryScore = this.isIndustryScore();

        if (isIndustryScore) {
            const industrySummary = this.getIndustrySummary();
            const industryData = this.getIndustryData();

            zip.file(`esg-score-industry-summary-${time}.csv`, industrySummary);
            zip.file(`esg-score-industry-data-${time}.csv`, industryData);
            zip.generateAsync({type:"blob"})
            .then((content) => {
                saveAs(content, `esg-score-industry-${time}.zip`);
            });
        } else {
            const competitorsSummary = this.getCompetitorsSummary();
            const competitorsData = this.getCompetitorsData();

            zip.file(`esg-score-competitors-summary-${time}.csv`, competitorsSummary);
            zip.file(`esg-score-competitors-data-${time}.csv`, competitorsData);
            zip.generateAsync({type:"blob"})
            .then((content) => {
                saveAs(content, `esg-score-competitors-${time}.zip`);
            });
        }
    }

    getIndustrySummary = () => {
        const { listData } = this.state;
        const { self, rank } = listData || {};
        const firstDropDate = self?.firstDropDate ? dayjs(self.firstDropDate).format('LL') : '--';
        const eSGScoreNumeric = self?.dataMap?.ESGScore?.scoreValue?.displayValue || '--';
        const eSGLetterGrade = self?.dataMap?.ESGScore?.scoreValue?.grade || '--';
        const { title: groupName, rankValue, rankTotal, dateCreated } = rank?.scoreRank || {};
        const lastUpdateDate = dateCreated ? dayjs(dateCreated).format('LL') : '--';
        const strongestMetrics = ((rank?.strongest || []).map(item => item));
        const weakestMetrics = ((rank?.weakest || []).map(item => item));

        const summary = [
            ['Export Type', 'ESG Company Overview'],
            ['Company', `"${self?.name || '--'}"`],
            ['Data Export Date', `"${dayjs().format('LL')}"`],
            ['Last audit date by Refinitiv', `"${firstDropDate}"`],
            ['Overall ESG Score Numeric', `"${eSGScoreNumeric}  "`],
            ['Overall ESG Letter Grade', eSGLetterGrade],
            ['Industry Group Name', `"${groupName || '--'}"`],
            ['Industry Group Rank', rankValue && rankTotal ? `${rankValue}/${rankTotal}` : '--'],
            ['Industry Score Last Updated Date', `"${lastUpdateDate}"`],
            ['10 Strongest Performing ESG Metrics'],
            ...strongestMetrics.map((metric, num) => ['', `"${num + 1}. ${metric.displayName}"`, `"${metric.pillar}"`, `"${metric.scoreValue?.diffDisplayValue || '--'}"`]),
            ['10 Weakest Performing ESG Metrics'],
            ...weakestMetrics.map((metric, num) => ['', `"${num + 1}. ${metric.displayName}"`, `"${metric.pillar}"`, `"${metric.scoreValue?.diffDisplayValue || '--'}"`]),
        ]

        return summary.map(item => item.join(',')).join('\n');
    }

    getCompetitorsSummary = () => {
        const { listData } = this.state;
        const { self, rank } = listData || {};
        const firstDropDate = self?.firstDropDate ? dayjs(self.firstDropDate).format('LL') : '--';
        const eSGScoreNumeric = self?.dataMap?.ESGScore?.scoreValue?.displayValue || '--';
        const eSGLetterGrade = self?.dataMap?.ESGScore?.scoreValue?.grade || '--';
        const { title: groupName, rankValue, rankTotal } = rank?.scoreRank || {};

        const summary = [
            ['Export Type', 'ESG Company Overview'],
            ['Company', `"${self?.name || '--'}"`],
            ['Data Export Date', `"${dayjs().format('LL')}"`],
            ['Last audit date by Refinitiv', `"${firstDropDate}"`],
            ['Overall ESG Score Numeric', `"${eSGScoreNumeric}  "`],
            ['Overall ESG Letter Grade', eSGLetterGrade],
            ['Industry Group Name', `"${groupName || '--'}"`],
            ['Industry Group Rank', rankValue && rankTotal ? `${rankValue}/${rankTotal}` : '--']
        ]

        return summary.map(item => item.join(',')).join('\n');
    }

    getIndustryData = () => {
        const { listData } = this.state;
        const { avg: { dataMap: avgDataMap } } = listData;
        const allData = Object.entries(listData?.self?.dataMap || {})
            .filter(([_, item]) => item.name !== 'ESGScore')
            .reduce((ev, [key, item]) => {
                const avgItem = avgDataMap[key];
                const itemValue = item?.scoreValue?.numberValue;
                const avgValue = avgItem?.scoreValue?.numberValue;
                const between = (itemValue && avgValue) ? (itemValue / avgValue * 100 - 100).toFixed(2) + '%' : '--';
                ev.push([
                    'N/A',
                    `"${item?.displayName}"`,
                    `"${item?.scoreValue?.displayValue || '--'}  "`,
                    `"${item?.weight || '--'}  "`,
                    `"${(item?.displayName || '--').toUpperCase()} PILLAR SCORE"`,
                    `"${(item?.displayName || '--').toUpperCase()} PILLAR SCORE"`,
                    `"${(item?.displayName || '--').toUpperCase()} PILLAR SCORE"`,
                    `"${(item?.displayName || '--').toUpperCase()} PILLAR SCORE"`,
                    `"${(item?.displayName || '--').toUpperCase()} PILLAR SCORE"`,
                    `"${(item?.displayName || '--').toUpperCase()} PILLAR SCORE"`,
                    '--',
                    '--',
                    `"${between}  "`,
                    `"${avgItem?.scoreValue?.displayValue || '--'}  "`,
                    `"${avgItem?.scoreValue?.grade || '--'}  "`
                ]);

                item.categories.forEach((categorie, cateIndex) => {
                    const avgCate = avgDataMap[key]?.categories?.[cateIndex];
                    const cateValue = categorie?.scoreValue?.value;
                    const avgCateValue = avgCate?.scoreValue?.value;
                    const between = (cateValue && avgCateValue) ? (cateValue / avgCateValue * 100 - 100).toFixed(2) + '%' : '--';
                    ev.push([
                        'N/A',
                        `"${item?.displayName}"`,
                        `"${item?.scoreValue?.displayValue || '--'}  "`,
                        `"${item?.weight || '--'}  "`,
                        `"${(categorie?.displayName || '--').toUpperCase()} CATEGORY SCORE"`,
                        `"${categorie?.scoreValue?.displayValue || '--'}  "`,
                        `"${categorie?.weight || '--'}  "`,
                        `"${(categorie?.displayName || '--').toUpperCase()} CATEGORY SCORE"`,
                        `"${(categorie?.displayName || '--').toUpperCase()} CATEGORY SCORE"`,
                        `"${(categorie?.displayName || '--').toUpperCase()} CATEGORY SCORE"`,
                        '--',
                        '--',
                        `"${between}  "`,
                        `"${avgCate?.scoreValue?.displayValue || '--'}  "`,
                        `"${avgCate?.scoreValue?.grade || '--'}  "`
                    ]);
                    categorie.dataPoints.forEach((dataPoint, pointIndex) => {
                        const avgPoint = avgDataMap[key]?.categories?.[cateIndex]?.dataPoints?.[pointIndex];
                        const pointValue = dataPoint?.scoreValue?.value;
                        const avgPointValue = avgPoint?.scoreValue?.value;
                        const between = (pointValue && avgPointValue) ? (pointValue / avgPointValue * 100 - 100).toFixed(2) + '%' : '--';
                        const numeric = dataPoint?.rawValueType === 'NUMERIC' ? 'NUMERIC' : 'Non-Numeric';
                        ev.push([
                            'N/A',
                            `"${item?.displayName}"`,
                            `"${item?.scoreValue?.displayValue || '--'}  "`,
                            `"${item?.weight || '--'}  "`,
                            `"${categorie?.displayName}  "`,
                            `"${categorie?.scoreValue?.displayValue || '--'}  "`,
                            `"${categorie?.weight || '--'}  "`,
                            `"${dataPoint?.displayName}"`,
                            `"${dataPoint?.description.replace(/"/g, '""')}"`,
                            `"${numeric}  "`,
                            `"${dataPoint?.scoreValue?.displayValue || '--'}  "`,
                            `"${dataPoint?.scoreValue?.grade || '--'}  "`,
                            `"${between}  "`,
                            `"${avgPoint?.scoreValue?.displayValue || '--'}  "`,
                            `"${avgPoint?.scoreValue?.grade || '--'}  "`
                        ]);
                    })
                })
                return ev;
            }, []);

        const data = [
            [
                'XBRL Mapping',
                'ESG Pillar Name',
                'Pillar Score',
                'Pillar Weighting',
                'Category Name',
                'Category Score',
                'Category Weighting',
                'Metric Name',
                'Metric Description',
                'Numeric/Non-Numeric',
                'Metric Numeric Score',
                'Metric Letter Grade',
                '% between Industry',
                'Industry Avg Numeric Score',
                'Industry Avg Letter Grade'
            ],
            ...allData
        ]

        return data.map(item => item.join(',')).join('\n');
    }

    getCompetitorsData = () => {
        const { listData } = this.state;
        const { locale } = this.props;
        const { avg: { dataMap: avgDataMap }, competitors } = listData;
        const allData = Object.entries(listData?.self?.dataMap || {})
            .filter(([_, item]) => item.name !== 'ESGScore')
            .reduce((ev, [key, item]) => {
                const avgItem = avgDataMap[key];
                const itemValue = item?.scoreValue?.numberValue;
                const avgValue = avgItem?.scoreValue?.numberValue;
                const between = (itemValue && avgValue) ? (itemValue / avgValue * 100 - 100).toFixed(2) + '%' : '--';
                const itemScore0Num = competitors.filter(competitor => {
                    const competitorItem = competitor?.dataMap?.[key];

                    return competitorItem?.scoreValue?.numberValue === 0;
                }).length;
                const itemScoreNullNum = competitors.filter(competitor => {
                    const competitorItem = competitor?.dataMap?.[key];
                    const numberValue = competitorItem?.scoreValue?.numberValue;

                    return   !numberValue && numberValue !== 0;
                }).length;
                ev.push([
                    'N/A',
                    `"${item?.displayName}"`,
                    `"${item?.scoreValue?.displayValue || '--'}  "`,
                    `"${item?.weight || '--'}  "`,
                    `"${(item?.displayName || '--').toUpperCase()} PILLAR SCORE"`,
                    `"${(item?.displayName || '--').toUpperCase()} PILLAR SCORE"`,
                    `"${(item?.displayName || '--').toUpperCase()} PILLAR SCORE"`,
                    `"${(item?.displayName || '--').toUpperCase()} PILLAR SCORE"`,
                    `"${(item?.displayName || '--').toUpperCase()} PILLAR SCORE"`,
                    `"${(item?.displayName || '--').toUpperCase()} PILLAR SCORE"`,
                    '--',
                    '--',
                    `"${avgItem?.scoreValue?.displayValue || '--'}  "`,
                    `"${avgItem?.scoreValue?.grade || '--'}  "`,
                    `"${between}  "`,
                    `"${itemScore0Num}  "`,
                    `"${itemScoreNullNum}  "`,
                    ...competitors.reduce((ev, competitor) => {
                        const date = competitor?.firstDropDate ? dayjs(competitor.firstDropDate).format('LL') : '--';
                        ev.push(`"${date}"`);
                        ev.push(`"${(item?.displayName || '--').toUpperCase()} PILLAR SCORE"`,);
                        ev.push(`"${competitor?.dataMap?.[key]?.scoreValue?.displayValue || '--'}  "`);
                        ev.push(`"${(item?.displayName || '--').toUpperCase()} PILLAR SCORE"`,);
    
                        return ev;
                    }, [])
                ]);

                item.categories.forEach((categorie, cateIndex) => {
                    const avgCate = avgDataMap[key]?.categories?.[cateIndex];
                    const cateValue = categorie?.scoreValue?.numberValue;
                    const avgCateValue = avgCate?.scoreValue?.numberValue;
                    const between = (cateValue && avgCateValue) ? (cateValue / avgCateValue * 100 - 100).toFixed(2) + '%' : '--';
                    const cateScore0Num = competitors.filter(competitor => {
                        const competitorItem = competitor?.dataMap?.[key]?.categories?.[cateIndex];
    
                        return competitorItem?.scoreValue?.numberValue === 0;
                    }).length;
                    const cateScoreNullNum = competitors.filter(competitor => {
                        const competitorItem = competitor?.dataMap?.[key]?.categories?.[cateIndex];
                        const numberValue = competitorItem?.scoreValue?.numberValue;
    
                        return  !numberValue && numberValue !== 0;
                    }).length;

                    ev.push([
                        'N/A',
                        `"${item?.displayName}"`,
                        `"${item?.scoreValue?.displayValue || '--'}  "`,
                        `"${item?.weight || '--'}  "`,
                        `"${(categorie?.displayName || '--').toUpperCase()} CATEGORY SCORE"`,
                        `"${categorie?.scoreValue?.displayValue || '--'}  "`,
                        `"${categorie?.weight || '--'}  "`,
                        `"${(categorie?.displayName || '--').toUpperCase()} CATEGORY SCORE"`,
                        `"${(categorie?.displayName || '--').toUpperCase()} CATEGORY SCORE"`,
                        `"${(categorie?.displayName || '--').toUpperCase()} CATEGORY SCORE"`,
                        '--',
                        '--',
                        `"${avgCate?.scoreValue?.displayValue || '--'}  "`,
                        `"${avgCate?.scoreValue?.grade || '--'}  "`,
                        `"${between}  "`,
                        `"${cateScore0Num}  "`,
                        `"${cateScoreNullNum}  "`,
                        ...competitors.reduce((ev, competitor) => {
                            const date = competitor?.firstDropDate ? dayjs(competitor.firstDropDate).format('LL') : '--';
                            const comCateValue = competitor?.dataMap?.[key]?.categories?.[cateIndex]?.scoreValue?.displayValue || '--';
                            ev.push(`"${date}"`);
                            ev.push(`"${(categorie?.displayName || '--').toUpperCase()} CATEGORY SCORE"`,);
                            ev.push(`"${comCateValue}  "`);
                            ev.push(`"${(categorie?.displayName || '--').toUpperCase()} CATEGORY SCORE"`,);
        
                            return ev;
                        }, [])
                    ]);
                    categorie.dataPoints.forEach((dataPoint, pointIndex) => {
                        const avgPoint = avgDataMap[key]?.categories?.[cateIndex]?.dataPoints?.[pointIndex];
                        const pointValue = dataPoint?.scoreValue?.numberValue;
                        const avgPointValue = avgPoint?.scoreValue?.numberValue;
                        const between = (pointValue && avgPointValue) ? (pointValue / avgPointValue * 100 - 100).toFixed(2) + '%' : '--';
                        const numeric = dataPoint?.rawValueType === 'NUMERIC' ? 'NUMERIC' : 'Non-Numeric';
                        const point0Num = competitors.filter(competitor => {
                            const competitorItem = competitor?.dataMap?.[key]?.categories?.[cateIndex]?.dataPoints?.[pointIndex];
        
                            return competitorItem?.scoreValue?.numberValue === 0;
                        }).length;
                        const pointNullNum = competitors.filter(competitor => {
                            const competitorItem = competitor?.dataMap?.[key]?.categories?.[cateIndex]?.dataPoints?.[pointIndex];
                            const numberValue = competitorItem?.scoreValue?.numberValue;
        
                            return  !numberValue && numberValue !== 0;
                        }).length;
                        ev.push([
                            'N/A',
                            `"${item?.displayName}"`,
                            `"${item?.scoreValue?.displayValue || '--'}  "`,
                            `"${item?.weight || '--'}  "`,
                            `"${categorie?.displayName}  "`,
                            `"${categorie?.scoreValue?.displayValue || '--'}  "`,
                            `"${categorie?.weight || '--'}  "`,
                            `"${dataPoint?.displayName}"`,
                            `"${dataPoint?.description.replace(/"/g, '""')}"`,
                            `"${numeric}  "`,
                            `"${dataPoint?.scoreValue?.displayValue || '--'}  "`,
                            `"${dataPoint?.scoreValue?.grade || '--'}  "`,
                            `"${avgPoint?.scoreValue?.displayValue || '--'}  "`,
                            `"${avgPoint?.scoreValue?.grade || '--'}  "`,
                            `"${between}  "`,
                            `"${point0Num}  "`,
                            `"${pointNullNum}  "`,
                            ...competitors.reduce((ev, competitor) => {
                                const date = competitor?.firstDropDate ? dayjs(competitor.firstDropDate).format('LL') : '--';
                                const comPoint = competitor?.dataMap?.[key]?.categories?.[cateIndex]?.dataPoints?.[pointIndex];
                                const comPointValue = comPoint?.scoreValue?.displayValue || '--';
                                const comPointNumeric = comPoint?.rawValueType === 'NUMERIC' ? 'NUMERIC' : 'Non-Numeric';
                                const comPointReported = (!comPoint?.scoreValue?.numberValue && comPoint?.scoreValue?.numberValue !== 0) ? locale.not_reported : locale.reported;

                                ev.push(`"${date}"`);
                                ev.push(comPointNumeric);
                                ev.push(`"${comPointValue}  "`);
                                ev.push(comPointReported);
            
                                return ev;
                            }, [])
                        ]);
                    })
                })
                return ev;
            }, []);

        const data = [
            [
                'XBRL Mapping',
                'ESG Pillar',
                'Pillar Score',
                'Pillar Weighting',
                'Category Name',
                'Category Score',
                'Category Weighting',
                'Metric Name',
                'Metric Description',
                'Numeric/Non-Numeric',
                'Metric Score',
                'Metric Letter Grade',
                'Competitors Avg',
                'Competitors Avg letter grade',
                '% between Competitors',
                '# of competitors with a Score of 0',
                '# of competitors with a Score of \'null\'',
                ...competitors.reduce((ev, competitor, index) => {
                    ev.push(`${competitor.name} Most Recent Data`);
                    ev.push(`${competitor.name} Data Type`);
                    ev.push(`${competitor.name} metric score`);
                    ev.push(`${competitor.name} Reported / Not Reported`);

                    return ev;
                }, [])
            ],
            ...allData
        ]

        return data.map(item => item.join(',')).join('\n');
    }

    render() {
        const { competitorEditable, listData, competitorArr, hasCompetitor } = this.state;
        const { locale } = this.props;
        const isIndustryScore = this.isIndustryScore();
        return (
            <div className="page esg_score_page">
                <Helmet
                    title={`${isIndustryScore ? locale.industry : locale.competitors
                        } ${locale.esg_score}`}
                />
                <div className="esg_score-content">
                    <EsgScoreHeader
                        isIndustryScore={isIndustryScore}
                        data={listData}
                        hasCompetitor={hasCompetitor}
                        competitorEditable={competitorEditable}
                        exportCSV={this.exportCSV}
                        updateParentState={(newState) => {
                            this.setState(newState);
                        }}
                    />
                    <EsgScoreMain
                        competitorEditable={competitorEditable}
                        isIndustryScore={isIndustryScore}
                        data={listData}
                        hasCompetitor={hasCompetitor}
                        competitorArr={competitorArr}
                        fetchData={this.fetchListData}
                        updateParentState={(newState) => {
                            this.setState(newState);
                        }}
                        updateCompetitors={this.updateCompetitors}
                    />
                    {!isIndustryScore && listData && this.renderLegend()}
                </div>
            </div>
        );
    }
}

export default withRouter(
    connect(
        ({
            persist: { account, oneTimeNote, locale },
            router,
            common: { mobileView },
            esg,
        }) => ({
            account,
            locale,
            router,
            oneTimeNote,
            mobileView,
            esg,
        })
    )(EsgScore)
);
