import React from 'react';
import cns from 'classnames';
import { connect } from 'react-redux';
import { message } from 'antd';
import './index.scss';
import service from '../../service';
import { accountUrl } from '../../constant/endpoint';
import { Search, Loading, Close } from '../../component/Icon';

class SearchInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            loading: false,
            list: null,
            showReachMaxNote: false,
        };
        this.timer = null;
        this.inputRef = React.createRef();
    }

    componentDidMount() {
        window.addEventListener('click', this.tryHideDropdown);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.tryHideDropdown);
    }

    tryHideDropdown = (e) => {
        const { list } = this.state;
        const path = e.path || (e.composedPath && e.composedPath());
        if (path.indexOf(this.inputRef.current) < 0 && Array.isArray(list)) {
            this.setState({
                value: '',
                list: null,
            });
        }
    };

    onChange = (e) => {
        const {
            target: { value },
        } = e;
        this.setState(
            {
                value,
            },
            () => {
                this.searchList(value);
            }
        );
    };

    clearTimer = () => {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
    };

    searchList = (name) => {
        this.clearTimer();
        if (!name.trim()) {
            this.setState({
                list: null,
            });
            return;
        }
        this.timer = setTimeout(async () => {
            this.setState({ loading: true });
            try {
                const res = await service.get(accountUrl.ORGANIZATIONS, {
                    name,
                    size: 100,
                });
                if (res && Array.isArray(res.data) && this.state.value.trim()) {
                    this.setState({
                        list: res.data,
                    });
                }
            } catch (err) {
                console.log(err);
            }
            this.setState({ loading: false });
        }, 800);
    };

    handleUpdateTags = (newTags) => {
        const { showReachMaxNote } = this.state;
        if (showReachMaxNote) {
            this.setState({ showReachMaxNote: false });
        }
        const { updateTags } = this.props;
        updateTags(newTags);
    };

    toggleSelectItem = (item) => {
        const {
            tags = [],
            tagKey = 'id',
            maxTagsNum,
            reachMaxNote,
            otherSelected = [],
        } = this.props;
        let target = -1;
        [...tags, ...otherSelected].find((tag, index) => {
            if (tag[tagKey] === item[tagKey]) {
                target = index;
                return true;
            }
            return false;
        });
        const newTags = JSON.parse(JSON.stringify(tags));
        if (target < 0) {
            if (maxTagsNum && newTags.length === maxTagsNum) {
                if (reachMaxNote) {
                    this.setState({ showReachMaxNote: true });
                } else {
                    const { locale } = this.props;
                    message.warn(locale.max_select(maxTagsNum));
                }
            } else {
                newTags.push(item);
                this.handleUpdateTags(newTags);
            }
            this.setState({
                value: '',
                list: null,
            });
        }
    };

    removeTag = (item) => {
        const { tags = [], tagKey = 'id' } = this.props;
        let target = -1;
        tags.find((tag, index) => {
            if (tag[tagKey] === item[tagKey]) {
                target = index;
                return true;
            }
            return false;
        });
        const newTags = JSON.parse(JSON.stringify(tags));
        newTags.splice(target, 1);
        this.handleUpdateTags(newTags);
    };

    render() {
        const {
            locale,
            placeholder,
            maxLength = 60,
            tags = [],
            tagIcon: TagIcon,
            tagKey = 'id',
            tagDisplayKey,
            emptyTip,
            reachMaxNote,
            otherSelected = [],
        } = this.props;
        const { value, list, loading, showReachMaxNote } = this.state;
        return (
            <div className="esgai_form-search-input-wrap">
                <div
                    className="esgai_form-search-input-box"
                    ref={this.inputRef}
                >
                    <input
                        placeholder={placeholder}
                        maxLength={maxLength}
                        autoComplete="off"
                        type="text"
                        value={value}
                        onChange={this.onChange}
                    />
                    <span
                        className={cns({
                            'esgai_form-search-input-icon': true,
                            'esgai_form-search-input-icon-loading': loading,
                        })}
                    >
                        {loading ? (
                            <Loading style={{ fontSize: 16 }} color="233BC9" />
                        ) : (
                            <Search style={{ fontSize: 16 }} color="4D4D4D" />
                        )}
                    </span>
                    {value && !loading && (
                        <button
                            onClick={() => {
                                this.setState({
                                    value: '',
                                    list: null,
                                });
                            }}
                            className="esgai_form-search-input-clear"
                        >
                            <Close style={{ fontSize: 12 }} color="111111" />
                        </button>
                    )}
                    {Array.isArray(list) && (
                        <div className="esgai_form-search-list esgai_scrollbar">
                            {list.length > 0 ? (
                                list.map((i) => (
                                    <p
                                        className={cns({
                                            'esgai_form-search-item': true,
                                            'esgai_form-search-item-selected':
                                                !![
                                                    ...tags,
                                                    ...otherSelected,
                                                ].find(
                                                    (tag) =>
                                                        tag[tagKey] ===
                                                        i[tagKey]
                                                ),
                                        })}
                                        key={i[tagKey] || i[tagDisplayKey]}
                                        onClick={() => this.toggleSelectItem(i)}
                                    >
                                        {i[tagDisplayKey]}
                                    </p>
                                ))
                            ) : (
                                <p className="esgai_form-search-list-empty">
                                    {emptyTip || locale.setup_profile_no_result}
                                </p>
                            )}
                        </div>
                    )}
                </div>
                {showReachMaxNote && reachMaxNote && (
                    <p className="search-input-reach-max-note">
                        {reachMaxNote}
                    </p>
                )}
                {tags.length > 0 && (
                    <div className="esgai_form-tags scrollbar_hide">
                        {tags.map((i) => (
                            <div
                                className="esgai_form-tag-item"
                                key={i[tagKey] || i[tagDisplayKey]}
                            >
                                <TagIcon style={{ fontSize: 18 }} />
                                <span>{i[tagDisplayKey]}</span>
                                <button
                                    onClick={() => this.removeTag(i)}
                                    className="esgai_form-tag-item-delete"
                                >
                                    <Close
                                        style={{ fontSize: 12 }}
                                        color="4D4D4D"
                                    />
                                </button>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    }
}

export default connect(({ persist: { locale } }) => ({
    locale,
}))(SearchInput);
