import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Close } from '../Icon';
import './index.scss';

class BottomSheet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            translateY: '100%',
        };
        this.timer = null;
    }

    componentDidMount() {
        this.timer = setTimeout(() => {
            this.setState({ translateY: 0 }, () => {
                this.clearTimer();
            });
        }, 0);
    }

    componentWillUnmount() {
        this.clearTimer();
    }

    handleClose = (cb) => {
        this.setState({ translateY: '100%' }, () => {
            this.clearTimer();
            this.timer = setTimeout(() => {
                this.props.close();
                if (typeof cb === 'function') {
                    cb();
                }
            }, 180);
        });
    };

    clearTimer = () => {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
    };

    render() {
        const { translateY } = this.state;
        const {
            title,
            renderContent,
            showHeader = true,
            fullScreen = false,
        } = this.props;
        return (
            <div
                className={classNames({
                    'bottom-sheet': true,
                    'bottom-sheet-full-screen': fullScreen,
                })}
            >
                <div className="bottom-sheet-bg">
                    <div
                        className="bottom-sheet-wrap"
                        style={{ transform: `translateY(${translateY})` }}
                    >
                        <div className="bottom-sheet-main">
                            {showHeader && (
                                <div className="bottom-sheet-header">
                                    <h5>{title}</h5>
                                    <button onClick={this.handleClose}>
                                        <Close />
                                    </button>
                                </div>
                            )}
                            <div className="bottom-sheet-content">
                                {renderContent({ close: this.handleClose })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(({ persist: { locale }, common: { mobileView } }) => ({
    locale,
    mobileView,
}))(BottomSheet);
