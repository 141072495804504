import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { More, User, Team } from '../../../../component/Icon';
import './index.scss';
import { formatDate } from '../../../../utils';
import BottomSheet from '../../../../component/BottomSheet';

const BoardItem = (props) => {
    const dropdownButtonRef = useRef(null);
    const dropdownRef = useRef(null);
    const bottomSheetRef = useRef(null);
    const timer = useRef(null);
    const {
        type, // private, myPublic, otherPublic, trash
        item,
        options,
        locale,
        handleAction,
        mobileView,
        history,
    } = props;
    const { lastUpdate, name, score, trashedDate } = item;
    const {
        ESGScore = {},
        EnvironmentPillarScore = {},
        GovernancePillarScore = {},
        SocialPillarScore = {},
    } = score || {};
    const [showDropdown, setShowDropdown] = useState(false);

    useEffect(() => {
        if (!mobileView) {
            if (showDropdown) {
                window.addEventListener('click', tryHideDropdown);
                setAdditionalPadding();
            } else {
                window.removeEventListener('click', tryHideDropdown);
                removeAdditionalPadding();
            }
        }
        return () => {
            window.removeEventListener('click', tryHideDropdown);
            clearTimer();
        };
    }, [showDropdown]); // eslint-disable-line

    const clearTimer = () => {
        if (timer.current) {
            clearTimeout(timer.current);
            timer.current = null;
        }
    };

    const setAdditionalPadding = () => {
        const boardPageContainer = document.getElementsByClassName(
            'optimizer_board-content'
        )[0];
        const currentAdditionalPadding =
            parseFloat(boardPageContainer.style.paddingBottom) || 0;
        const boardPageContainerBottom =
            boardPageContainer.getBoundingClientRect().bottom -
            currentAdditionalPadding;
        const dropdownBottom =
            dropdownRef.current.getBoundingClientRect().bottom;
        const gap = boardPageContainerBottom - dropdownBottom;
        if (boardPageContainerBottom - dropdownBottom < 17) {
            boardPageContainer.setAttribute('additionalPadding', 17 - gap);
            clearTimer();
            timer.current = setTimeout(() => {
                const additionalPadding =
                    boardPageContainer.getAttribute('additionalPadding');
                boardPageContainer.style.paddingBottom = `${additionalPadding}px`;
                boardPageContainer.setAttribute('additionalPadding', '');
                clearTimer();
            }, 0);
        }
    };

    const removeAdditionalPadding = () => {
        const boardPageContainer = document.getElementsByClassName(
            'optimizer_board-content'
        )[0];
        const additionalPadding =
            boardPageContainer.getAttribute('additionalPadding');
        if (!timer.current && !additionalPadding) {
            boardPageContainer.style.paddingBottom = 0;
        }
    };

    const tryHideDropdown = (e) => {
        if (mobileView) {
            return;
        }
        const path = e.path || (e.composedPath && e.composedPath());
        if (path.indexOf(dropdownButtonRef.current) < 0 && showDropdown) {
            setShowDropdown(false);
        }
    };

    const processScore = (scoreObj) => {
        if (scoreObj && scoreObj.displayValue && scoreObj.grade) {
            return `${scoreObj.displayValue} ${scoreObj.grade}`;
        }
        return '--';
    };

    const handleClickItem = (e) => {
        const { nativeEvent } = e;
        const path =
            nativeEvent.path ||
            (nativeEvent.composedPath && nativeEvent.composedPath());
        if (
            path.indexOf(dropdownButtonRef.current) < 0 &&
            path.indexOf(dropdownRef.current) < 0 &&
            path.indexOf(bottomSheetRef.current) < 0 &&
            type !== 'trash' &&
            type !== 'archived'
        ) {
            history.push(`/optimizer/boards/${item.id}`);
        }
    };

    const isPublicBoard = type === 'myPublic' || type === 'otherPublic';

    const timestamp = type === 'trash' ? trashedDate : lastUpdate;

    const renderOptions = (data) => {
        return (
            <div
                className={classNames({
                    'option-list': !mobileView,
                })}
                ref={dropdownRef}
            >
                {options.map((i) => (
                    <span
                        className={classNames({
                            'option-item': true,
                            'option-item-danger': !!i.isDangerous,
                            'option-item-download': i.action === 'download'
                        })}
                        key={i.action}
                        onClick={() => {
                            if (data && typeof data.close === 'function') {
                                data.close(() => handleAction(item, i.action));
                            } else {
                                handleAction(item, i.action);
                            }
                        }}
                    >
                        {i[`title_${type}`] || i.title}
                    </span>
                ))}
            </div>
        );
    };

    return (
        <div
            className={classNames({
                'board-item-content': true,
                'board-item-content-trash': type === 'trash',
                'board-item-content-active': showDropdown,
                'board-item-content-public': isPublicBoard,
            })}
            onClick={handleClickItem}
        >
            {isPublicBoard && (
                <div className="board-icon-wrap">
                    {type === 'myPublic' ? <User /> : <Team />}
                </div>
            )}
            <div className="board-top">
                <h6>{locale.target_esg_score}</h6>
                <p className="board-esg-score">{processScore(ESGScore)}</p>
                <div className="board-pillars-score">
                    <div className="pillar-score">
                        <b>E</b>
                        <span>{processScore(EnvironmentPillarScore)}</span>
                    </div>
                    <div className="pillar-score">
                        <b>S</b>
                        <span>{processScore(SocialPillarScore)}</span>
                    </div>
                    <div className="pillar-score">
                        <b>G</b>
                        <span>{processScore(GovernancePillarScore)}</span>
                    </div>
                </div>
            </div>
            <div className="board-bottom">
                <div className="bottom-left">
                    <h4 title={name}>{name}</h4>
                    <p>{`${formatDate(timestamp, 'newsTime', {
                        hour12: true,
                    }).toLowerCase()} | ${formatDate(timestamp, 'mid')}`}</p>
                </div>
                <button
                    onClick={() => setShowDropdown(!showDropdown)}
                    ref={dropdownButtonRef}
                >
                    <More />
                </button>
            </div>
            {!mobileView && showDropdown && renderOptions()}
            {mobileView && showDropdown && (
                <div ref={bottomSheetRef}>
                    <BottomSheet
                        title={name}
                        renderContent={renderOptions}
                        close={() => setShowDropdown(false)}
                    />
                </div>
            )}
        </div>
    );
};

export default withRouter(
    connect(({ persist: { locale }, common: { mobileView } }) => ({
        locale,
        mobileView,
    }))(BoardItem)
);
