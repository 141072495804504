import axios from 'axios';
import { message } from 'antd';
import redux from '../redux';
import { persistType } from '../constant/actionTypes';
import env from '../constant/env';
import { routerGuide } from '../utils';

const defaultOptions = () => {
    const { store } = redux;
    const states = store.getState();
    const {
        persist: { account },
    } = states;
    const token = account.token || '';
    const options = {
        timeout: 0,
    };
    if (token) {
        options.headers = {
            'X-Auth-Token': token,
        };
    }
    return options;
};

// Add a request interceptor
axios.interceptors.request.use(
    (config) => {
        // Do something before request is sent
        // const { store } = redux;
        // const states = store.getState();
        // const {
        //   persist: { authorizedDate },
        // } = states;
        // if (
        //   authorizedDate &&
        //   (new Date().getTime() - authorizedDate) / 1000 / 60 / 60 > 8 // 8 hour
        // ) {
        //   const error = {
        //     response: { status: 403 },
        //     data: { message: 'Authorization Expired' },
        //   };
        //   return Promise.reject(error);
        // }
        return config;
    },
    (error) => {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
axios.interceptors.response.use(
    (response) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    },
    (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        const response = (error && error.response) || {};
        const config = (error && error.config) || {};
        const { handleErrorBySelf = false } = config;
        const { data, statusText, status } = response;
        const { store } = redux;
        const errorText =
            data && (data.error_description || data.error || data.message)
                ? data.error_description || data.error || data.message
                : typeof data === 'string'
                ? data
                : statusText;
        const {
            location: { pathname },
        } = window;
        const redirectUrl = routerGuide({}, pathname);
        switch (true) {
            case status === 401:
                if (redirectUrl) {
                    store.dispatch({
                        type: persistType.LOGOUT,
                    });
                    message.error(`${status}: Authorization Denied`);
                }
                break;
            case status === 403:
                if (redirectUrl) {
                    // this action should remove those expired login data
                    store.dispatch({
                        type: persistType.LOGOUT,
                    });
                    message.error(`${status}: Authorization Expired`);
                }
                break;
            case status === 400:
                message.error(errorText);
                break;
            default:
                if ((status || errorText) && !handleErrorBySelf) {
                    message.error(`${status ? status + ': ' : ''}${errorText}`);
                }
        }
        return Promise.reject(error);
    }
);

const request = (url, options = {}) =>
    new Promise((resolve, reject) => {
        let apiUrl = url;
        if (url.indexOf('http') !== 0) {
            apiUrl = `${env.apiBaseUrl}${url}`;
        }
        const params = { ...options, url: apiUrl };
        axios(params)
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error);
            })
            .finally((res) => {
                resolve(res);
            });
    });

const service = {
    /**
     * GET request
     */
    get: (url, params) =>
        request(url, {
            ...defaultOptions(),
            method: 'get',
            params,
        }),

    /**
     * POST request
     */
    post: (url, data) =>
        request(url, {
            ...defaultOptions(),
            method: 'post',
            data,
        }),

    /**
     * PUT request
     */
    put: (url, data) =>
        request(url, {
            ...defaultOptions(),
            method: 'put',
            data,
        }),

    /**
     * DELETE request
     */
    delete: (url, params) =>
        request(url, {
            ...defaultOptions(),
            method: 'delete',
            params,
        }),

    /**
     * Call request method using the given options
     */
    fetch: (url, options) =>
        request(url, {
            ...defaultOptions(),
            ...options,
        }),

    /**
     * Mock http request function
     */
    mock: (data = {}, timeout = 600) => {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(data);
            }, timeout);
        });
    },
};

export default service;
