import React, { useEffect, useState, useCallback } from 'react';
import { Checkbox } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import cns from 'classnames';
import { Filter } from '../Icon';

import './index.scss';

const standardOptions = [
    { label: 'All Standards', value: 'all' },
    { label: 'GRI', value: 'gri' },
    { label: 'SASB', value: 'sasb' },
    { label: 'TCFD', value: 'tcfd' },
    { label: 'CDP', value: 'cdp' },
    { label: 'GRESB', value: 'gresb' },
    { label: 'WEF', value: 'wef' },
    { label: 'DJSI', value: 'djsi' },
  ];


function Filters({ onChange, value }) {
    const [showFilters, setshowFilters] = useState(false);
    const [inToggle, setInToggle] = useState(false);
    const [top, setTop] = useState(0);
    const dropdownRef = React.createRef();

    function toggle() {
        setInToggle(true);
        setshowFilters(!showFilters);

        setTimeout(() => {
            setInToggle(false);
        }, 300);
    }

    const hide = useCallback((e) => {
        if (inToggle) {
            return;
        }

        const path = e.path || (e.composedPath && e.composedPath());

        if (path.indexOf(dropdownRef.current) < 0 && showFilters ) {
            setshowFilters(false);
        }
    }, [dropdownRef, setshowFilters, showFilters, inToggle]);

    const setFiltersTop = useCallback(() => {
        setTop((dropdownRef.current?.getBoundingClientRect?.()?.top || 0) + 44);
    }, [setTop, dropdownRef]);

    function filtersChange(options) {
        let selectedOptions = [];

        if (options.indexOf('all') > -1 && value.indexOf('all') === -1) {
            selectedOptions = standardOptions.map(item => item.value);
        } else if (options.indexOf('all') === -1 && value.indexOf('all') > -1) {
            selectedOptions = [];
        } else if (options.indexOf('all') > -1 && options.length < 8) {
            selectedOptions = options.filter(option => option !== 'all');
        } else if (options.indexOf('all') === -1 && options.length === 7) {
            selectedOptions = standardOptions.map(item => item.value);
        } else {
            selectedOptions = options;
        }
        onChange(selectedOptions);
    }

    useEffect(() => {
        window.addEventListener('click', hide);
        window.addEventListener('scroll', setFiltersTop);
        setTop((dropdownRef.current?.getBoundingClientRect?.()?.top || 0) + 44);
        

        return function() {
            window.removeEventListener('click', hide);
            window.removeEventListener('scroll', setFiltersTop);
        }
    }, [hide, dropdownRef, setFiltersTop]);

    return (
        <div ref={dropdownRef} className="esgai_header-filters-wrap">
            <div
                className={cns({
                    'filters-wrap': true,
                    'filters-wrap-active': showFilters,
                })}
                onClick={toggle}
            >
                <div className='filters-label'>
                    <Filter /> Filter Standards
                </div>
            </div>
            {showFilters && (
                <div className="esgai-dropdown-wrap-filters" style={{ top: `${top}px` }}>
                    <h2>Standards</h2>
                    <Checkbox.Group
                        options={standardOptions}
                        value={value}
                        onChange={filtersChange}
                    />
                </div>
            )}
        </div>
    )
}

export default withRouter(
    connect(({ persist: { locale } }) => ({ locale }))(Filters)
);
