
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

import './mobile.scss';

const standardOptions = [
    { label: 'All Standards', value: 'all' },
    { label: 'GRI', value: 'gri' },
    { label: 'SASB', value: 'sasb' },
    { label: 'TCFD', value: 'tcfd' },
    { label: 'CDP', value: 'cdp' },
    { label: 'GRESB', value: 'gresb' },
    { label: 'WEF', value: 'wef' },
    { label: 'DJSI', value: 'djsi' },
  ];


function MobileFilters({ onChange, value }) {
    const [ selected, setSelected ] = useState(value);

    function handleClickOption(item) {
        let selOptions = [];
        if (item === 'all') {
            if (selected.indexOf(item) === -1) {
                selOptions = standardOptions.map(o => o.value);
            } else {
                selOptions = [];
            }
        } else {
            if (selected.indexOf(item) > -1) {
                selOptions = selected.filter(s => s !== item && s !== 'all');
            } else {
                selOptions = [...selected, item];
            }

            if (selOptions.indexOf('all') === -1 && selOptions.length === 7) {
                selOptions = standardOptions.map(o => o.value);
            }
        }
        setSelected(selOptions);
        onChange(selOptions)
    }

    return (
        <div className="search-group-standards">
            <h6>Standards</h6>
            <div className='option-group-list'>
                <div className="option-group">
                    {standardOptions.map((o) => (
                        <span
                            className={classNames({
                                option: true,
                                'option-selected': (selected || []).indexOf(o.value) > -1,
                            })}
                            key={o.value}
                            onClick={() =>
                                handleClickOption(o.value)
                            }
                        >
                            {o.label}
                        </span>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default withRouter(
    connect(({ persist: { locale } }) => ({ locale }))(MobileFilters)
);
