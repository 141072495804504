import Immutable from 'seamless-immutable';
import { esgType } from '../../constant/actionTypes';

const initialState = Immutable({
    openIndex: null,
    name: null,
    scrollY: -1,
    isNameChanged: false,
});

const esg = (state = initialState, action) => {
    switch (action.type) {
        case esgType.APPLY_SET_ESG_STATE:
            return state.merge({ ...action.payload });
        default:
            return state;
    }
};

export default esg;
