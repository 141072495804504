import { formatYLabel, getYScope } from '../../utils';

const getCompetitorChartOptions = (XArr, EArr, SArr, GArr) => {
    const eLength = EArr.filter((i) => typeof i === 'number').length;
    const sLength = SArr.filter((i) => typeof i === 'number').length;
    const gLength = GArr.filter((i) => typeof i === 'number').length;
    let scoreYLabelSettings = {
        min: 0,
        max: 100,
        interval: 25,
    };
    if (eLength + sLength + gLength > 0) {
        const { max, min } = getYScope([...EArr, ...SArr, ...GArr]);
        scoreYLabelSettings = {
            min,
            max,
            interval: (max - min) / 4,
        };
        if (max === min) {
            scoreYLabelSettings = {
                min: 0,
                max: 100,
                interval: 25,
            };
        }
    }
    return {
        title: { show: false },
        legend: { show: false },
        grid: {
            show: false,
            top: 15,
            bottom: 50,
            left: 45,
            right: 20,
        },
        xAxis: {
            type: 'category',
            boundaryGap: true,
            triggerEvent: true,
            axisLine: {
                lineStyle: {
                    color: 'transparent',
                    type: [5, 10],
                    dashOffset: 5,
                },
            },
            axisTick: {
                show: false,
            },
            axisLabel: {
                interval:
                    XArr.length <= 4
                        ? 0
                        : (index) => {
                              let gap = Math.ceil(XArr.length / 4);
                              if (gap === 1) {
                                  gap = 2;
                              }
                              return (XArr.length - 1 - index) % gap === 0;
                          },
                margin: 12,
                formatter: (value) => {
                    return '{date|' + value + '}';
                },
                rich: {
                    date: {
                        color: '#7a7a7a',
                        fontWeight: 'bold',
                        padding: [8, 0],
                        borderRadius: 5,
                        backgroundColor: 'transparent',
                        fontFamily: 'Mulish',
                    },
                    dateActive: {
                        color: '#233BC9',
                        fontWeight: 'bold',
                        padding: [8, 0],
                        borderRadius: 5,
                        backgroundColor: '#f3f3f3',
                        fontFamily: 'Mulish',
                    },
                },
            },
            axisPointer: {
                label: {
                    show: false,
                    formatter: () => '',
                },
                lineStyle: {
                    color: '#9C42CC',
                },
            },
            data: XArr,
        },
        yAxis: {
            type: 'value',
            boundaryGap: false,
            axisLine: {
                lineStyle: {
                    color: 'transparent',
                    type: [5, 10],
                    dashOffset: 5,
                },
            },
            axisTick: {
                show: false,
            },
            axisLabel: {
                formatter: (value) => {
                    return `${formatYLabel(value, scoreYLabelSettings)}`;
                },
                color: '#7A7A7A',
                fontFamily: 'Mulish',
                fontWeight: 700,
            },
            splitLine: {
                show: true,
                lineStyle: {
                    color: '#ebebeb',
                    type: [3, 6],
                    dashOffset: 3,
                },
            },
            axisPointer: {
                show: false,
            },
            ...scoreYLabelSettings,
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                snap: true,
            },
            formatter: (arr) => {
                let e = {};
                let s = {};
                let g = {};
                let xValue = '';
                if (Array.isArray(arr) && arr.length > 0) {
                    xValue = arr[0].axisValue;
                    arr.forEach((i) => {
                        const value =
                            i && typeof i.value === 'number' ? i.value : '--';
                        if (i.seriesIndex === 0) {
                            e = value;
                        } else if (i.seriesIndex === 1) {
                            s = value;
                        } else if (i.seriesIndex === 2) {
                            g = value;
                        }
                    });
                }
                return `<div style="font-size: 12px; line-height: 20px; font-family: Mulish; color: #4D4D4D">
                <h5 style="font-family: Montserrat; margin-bottom: ${2}px">${xValue}</h5>
                <p><span>E: </span><span style="font-family: Montserrat; font-weight: 700; color: #838D72">${e}</span></p>
                <p><span>S: </span><span style="font-family: Montserrat; font-weight: 700; color: #799EB2">${s}</span></p>
                <p><span>G: </span><span style="font-family: Montserrat; font-weight: 700; color: #BA782C">${g}</span></p>
            </div>`;
            },
            backgroundColor: '#ffffff',
            padding: [8, 12],
        },
        toolbox: {
            show: false,
        },
        series: [
            {
                type: 'line',
                lineStyle: {
                    color: '#838D72',
                    shadowColor: 'rgba(116, 134, 86, 0.2)',
                    shadowBlur: 4,
                    shadowOffsetY: 7,
                    width: 1.5,
                },
                showSymbol: true,
                itemStyle: {
                    color: '#838D72',
                    borderColor: 'rgba(255, 255, 255, 0.9)',
                    borderWidth: 1.5,
                },
                emphasis: {
                    lineStyle: {
                        width: 1.5,
                    },
                },
                symbol: 'circle',
                symbolSize: 6,
                data: EArr,
            },
            {
                type: 'line',
                lineStyle: {
                    color: '#799EB2',
                    shadowColor: 'rgba(80, 116, 136, 0.2)',
                    shadowBlur: 4,
                    shadowOffsetY: 7,
                    width: 1.5,
                },
                showSymbol: true,
                itemStyle: {
                    color: '#799EB2',
                    borderColor: 'rgba(255, 255, 255, 0.9)',
                    borderWidth: 1.5,
                },
                emphasis: {
                    lineStyle: {
                        width: 1.5,
                    },
                },
                symbol: 'circle',
                symbolSize: 6,
                data: SArr,
            },
            {
                type: 'line',
                lineStyle: {
                    color: '#BA782C',
                    shadowColor: 'rgba(149, 100, 44, 0.2)',
                    shadowBlur: 4,
                    shadowOffsetY: 7,
                    width: 1.5,
                },
                showSymbol: true,
                itemStyle: {
                    color: '#BA782C',
                    borderColor: 'rgba(255, 255, 255, 0.9)',
                    borderWidth: 1.5,
                },
                emphasis: {
                    lineStyle: {
                        width: 1.5,
                    },
                },
                symbol: 'circle',
                symbolSize: 6,
                data: GArr,
            },
        ],
    };
};

export default getCompetitorChartOptions;
