import React from 'react';
import { connect } from 'react-redux';
import Modal from '../../../../component/Modal';
import Button from '../../../../component/Button';
import Input from '../../../../component/Input';
import Popover from '../../../../component/Popover';
import Switch from '../../../../component/Switch';
import './index.scss';
import service from '../../../../service';
import { optimizerUrl } from '../../../../constant/endpoint';

class EditBoardModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            bePublic: false,
            saving: false,
        };
    }

    componentDidMount() {
        this.initState();
    }

    componentWillUnmount() {
        if (this.props.selectedItem) {
            const body = document.getElementsByTagName('body')[0];
            body.setAttribute('class', '');
        }
    }

    handleSave = async () => {
        this.setState({ saving: true });
        try {
            const { name, bePublic } = this.state;
            const { createType, selectedItem, close } = this.props;
            if (selectedItem) {
                await service.put(
                    optimizerUrl.SPECIFIC_BOARD(selectedItem.id),
                    { name, bePublic }
                );
            } else {
                await service.post(optimizerUrl.BOARDS, {
                    name,
                    bePublic: createType === 'public',
                });
            }
            this.setState({ saving: false }, () => close(true));
        } catch (err) {
            this.setState({ saving: false });
            console.log(err);
        }
    };

    initState = () => {
        const { selectedItem } = this.props;
        if (selectedItem) {
            const body = document.getElementsByTagName('body')[0];
            body.setAttribute('class', 'body-with-high-zindex-popover');
            const { name, bePublic } = selectedItem;
            this.setState({ name, bePublic });
        }
    };

    render() {
        const {
            locale,
            createType, // private, public, undefined
            selectedItem,
            close,
        } = this.props;
        const { bePublic, name, saving } = this.state;

        return (
            <Modal>
                <div className="optimizer-board-edit-modal">
                    <h4>
                        {createType === 'private'
                            ? locale.create_private_board_title
                            : createType === 'public'
                            ? locale.create_public_board_title
                            : locale.edit_name_privacy}
                    </h4>
                    <div className="optimizer-board-edit-modal-content">
                        <div className="field-wrap">
                            <div className="field-label">
                                {locale.file_name}
                            </div>
                            <Input
                                value={name}
                                onChange={(e) =>
                                    this.setState({ name: e.target.value })
                                }
                                onBlur={() => this.setState({ name: name.trim() })}
                                maxLength={40}
                            />
                        </div>
                        {!!selectedItem && (
                            <div className="field-wrap">
                                <div className="field-label">
                                    <span>{locale.privacy}</span>
                                    <Popover
                                        tooltip={locale.privacy_note}
                                        infoIconSize={14}
                                        mobileMaxWidth={380}
                                    />
                                </div>
                                <Switch
                                    options={[
                                        {
                                            key: false,
                                            name: locale.private,
                                        },
                                        {
                                            key: true,
                                            name: locale.public,
                                        },
                                    ]}
                                    type="radio"
                                    value={bePublic}
                                    onChange={(value) =>
                                        this.setState({ bePublic: value })
                                    }
                                />
                            </div>
                        )}
                    </div>
                    <div className="optimizer-board-edit-modal-actions">
                        <Button
                            loading={saving}
                            label={locale.save}
                            onClick={this.handleSave}
                            disabled={!name.trim()}
                        />
                        <Button
                            type="link"
                            label={locale.cancel}
                            onClick={() => close()}
                        />
                    </div>
                </div>
            </Modal>
        );
    }
}

export default connect(({ persist: { locale } }) => ({
    locale,
}))(EditBoardModal);
