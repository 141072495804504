import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { message } from 'antd';
import { pattern } from '../../utils';
import { EmailSend } from '../../component/Icon';
import Input from '../../component/Input';
import Button from '../../component/Button';
import PasswordManage from '../../component/layouts/PasswordManage';
import service from '../../service';
import { accountUrl } from '../../constant/endpoint';

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                email: '',
            },
            errors: {
                email: '',
            },
            loading: false,
            passwordResetEmailSend: false,
        };
    }

    handleInputChange = (e) => {
        this.setState(
            {
                data: {
                    email: e.target.value.trim(),
                },
            },
            () => {
                this.validation();
            }
        );
    };

    handleSubmit = async () => {
        const {
            data: { email },
            loading,
            passwordResetEmailSend,
        } = this.state;
        if (!loading && !passwordResetEmailSend && this.validation()) {
            this.setState({ loading: true });
            try {
                await service.post(accountUrl.PASSWORD_RESET, {
                    email,
                });
                this.setState({ passwordResetEmailSend: true });
            } catch (err) {
                message.error(err.message);
            }
            this.setState({ loading: false });
        }
    };

    validation = (field) => {
        const {
            data: { email },
            errors,
        } = this.state;
        const { locale } = this.props;
        if (!pattern.email.test(email)) {
            errors.email = locale.invalid_email;
        } else if (errors.email) {
            errors.email = '';
        }
        this.setState({ errors });
        return !errors.email;
    };

    renderForm = () => {
        const {
            data: { email },
            errors,
            loading,
        } = this.state;
        const { locale } = this.props;
        return (
            <>
                <h3>{locale.forgotPSW_title}</h3>
                <p className="password_manage-tip">
                    {locale.forgotPSW_subtitle}
                </p>
                <div
                    className="password_manage-form"
                    style={{ paddingTop: 38 }}
                >
                    <Input
                        id="login-email"
                        label={locale.email_address}
                        value={email}
                        onChange={this.handleInputChange}
                        error={errors.email}
                    />
                    <Button
                        loading={loading}
                        style={{ marginTop: 15, width: '100%' }}
                        label={locale.send}
                        onClick={this.handleSubmit}
                        onClickEnter={this.handleSubmit}
                    />
                </div>
            </>
        );
    };

    renderResetEmailSend = () => {
        const { history, locale } = this.props;
        return (
            <>
                <h3>{locale.forgotPSW_check_email}</h3>
                <p className="password_manage-tip">
                    {locale.forgotPSW_send_email_note}
                </p>
                <div
                    className="password_manage-icon"
                    style={{ margin: '48px auto' }}
                >
                    <EmailSend style={{ fontSize: 98 }} color="9C42CC" />
                </div>
                <Button
                    style={{ width: '100%' }}
                    label={locale.done}
                    onClick={() => history.push('/login')}
                />
            </>
        );
    };

    render() {
        const { passwordResetEmailSend } = this.state;
        const { locale } = this.props;
        return (
            <PasswordManage>
                <Helmet title={locale.forgot_password} />
                {passwordResetEmailSend
                    ? this.renderResetEmailSend()
                    : this.renderForm()}
            </PasswordManage>
        );
    }
}

export default withRouter(
    connect(({ router, persist: { locale } }) => ({
        router,
        locale,
    }))(ForgotPassword)
);
