import React, { useCallback, useEffect, useRef, useState } from 'react';
import './index.scss';

const MiniBarChart = (props) => {
    const enlarge = 4;
    const { data, competitors, metric } = props;
    const chartWrapRef = useRef();
    const [targetScore, setTargetScore] = useState(null);
    const [url, setUrl] = useState(null);

    useEffect(() => {
        drawCanvas();

        document.addEventListener('min_bar_chart_change', chartChange);
        return () => {
            document.removeEventListener('min_bar_chart_change', chartChange);
        }
    }, []); // eslint-disable-line

    const includeCompetitors = useCallback((index) => {
        if (!Array.isArray(competitors) || competitors.length === 0) {
            return false;
        }
        const min = 0.02 * index;
        const max = min + 0.02;
        return !!competitors.find(
            (c) =>
                typeof c.score === 'number' &&
                c.score >= min &&
                (c.score < max || (max === 1 && c.score === 1))
        );
    }, [competitors]);

    const drawCanvas = useCallback(() => {
        if (typeof url === 'string' || !chartWrapRef.current) {
            return;
        }

        let canvas = document.createElement('canvas');
        const { width: w, height: h } =
            chartWrapRef.current.getBoundingClientRect();
        const width = (w - 10) * enlarge;
        const height = h * enlarge;
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        const arr = data.data.map((i) => i.count);

        // bar chart
        const maxCompanyNum = Math.max(...arr);
        let perHeight = 0;
        if (isFinite(maxCompanyNum) && maxCompanyNum > 0) {
            perHeight = (height * 0.66) / maxCompanyNum;
        }
        const barW = width / 50;
        arr.forEach((i, index) => {
            if (i === 0) {
                return;
            }
            const barH = perHeight * i;
            ctx.fillStyle = includeCompetitors(index) ? '#9C42CC' : '#CDD2D4';
            ctx.fillRect(index * barW, height - barH, barW, barH);
        });
        // your company
        if (typeof metric.score === 'number') {
            ctx.beginPath();
            const targetX = (width / 1) * metric.score - 0.5 * enlarge;
            ctx.lineWidth = enlarge;
            ctx.setLineDash([enlarge * 4, enlarge * 2]);
            ctx.moveTo(targetX, 0);
            ctx.lineTo(targetX, height);
            ctx.strokeStyle = '#999';
            ctx.stroke();
            ctx.closePath();
        }

        if (typeof targetScore === 'number' || typeof metric.targetScore?.score === 'number') {
            ctx.beginPath();
            const targetX = Math.max((width / 1) * (targetScore || metric.targetScore.score)  - 0.5 * enlarge, 0).toFixed(2);
            ctx.lineWidth = enlarge;
            ctx.setLineDash([enlarge * 4, enlarge * 2]);
            ctx.moveTo(targetX, 0);
            ctx.lineTo(targetX, height);
            ctx.strokeStyle = '#233BC9';
            ctx.stroke();
            ctx.closePath();
        }

        setUrl(canvas.toDataURL());
        canvas = null;
    }, [url, targetScore, data, metric, includeCompetitors]);

    useEffect(() => {
        drawCanvas();
    }, [url, targetScore, data, metric, includeCompetitors, drawCanvas]);

    const chartChange = (event) => {
        if (event.detail.id === metric.id && event.detail.targetScore) {
            setTargetScore(() => event.detail.targetScore);
            setUrl(() => null);
        }
    }

    return (
        <div className="mini-bar-chart-wrap" ref={chartWrapRef}>
            {url && <img src={url} alt="Mini Chart" />}
        </div>
    );
};

export default MiniBarChart;
