import { getYScope, formatYLabel } from '../../utils';

const getMainChartOptions = (XArr, scoreArr, locale, mobileView) => {
    const scoreLength = scoreArr.filter((i) => typeof i === 'number').length;

    let scoreYLabelSettings = {
        min: 0,
        max: 100,
        interval: 25,
    };
    if (scoreLength > 0) {
        const { max, min } = getYScope(scoreArr);
        scoreYLabelSettings = {
            min,
            max,
            interval: (max - min) / 4,
        };
        if (max === min) {
            scoreYLabelSettings = {
                min: 0,
                max: 100,
                interval: 25,
            };
        }
    }

    let left = 52;
    let right = 30;
    let bottom = 70;
    let labelPadding = [8, 0];
    let tooltipWrapPadding = [8, 12];
    let axisLabelFontSize = 12;
    let tooltipFontSize = 16;
    let tooltipLineHeight = 20;
    let tooltipPadding = 10;
    if (mobileView) {
        left = 35;
        right = 20;
        bottom = 45;
        labelPadding = [6, 0];
        tooltipWrapPadding = [6, 6];
        axisLabelFontSize = 10;
        tooltipFontSize = 12;
        tooltipLineHeight = 16;
        tooltipPadding = 5;
    }

    return {
        title: { show: false },
        legend: { show: false },
        grid: {
            show: false,
            top: 30,
            bottom,
            left,
            right,
        },
        xAxis: {
            type: 'category',
            boundaryGap: true,
            triggerEvent: true,
            axisLine: {
                lineStyle: {
                    color: 'transparent',
                    type: [5, 10],
                    dashOffset: 5,
                },
            },
            axisTick: {
                show: false,
            },
            axisLabel: {
                interval:
                    XArr.length <= 6
                        ? 0
                        : (index) => {
                              if (XArr.length <= 8) {
                                  return true;
                              }
                              let gap = Math.ceil(XArr.length / 6);
                              if (gap === 1) {
                                  gap = 2;
                              }
                              return (XArr.length - 1 - index) % gap === 0;
                          },
                margin: 12,
                formatter: (value) => {
                    return '{date|' + value + '}';
                },
                rich: {
                    date: {
                        color: '#7a7a7a',
                        fontWeight: 'bold',
                        padding: labelPadding,
                        borderRadius: 5,
                        backgroundColor: 'transparent',
                        fontFamily: 'Mulish',
                        fontSize: axisLabelFontSize,
                    },
                    dateActive: {
                        color: '#233BC9',
                        fontWeight: 'bold',
                        padding: labelPadding,
                        borderRadius: 5,
                        backgroundColor: '#f3f3f3',
                        fontFamily: 'Mulish',
                        fontSize: axisLabelFontSize,
                    },
                },
            },
            axisPointer: {
                label: {
                    show: false,
                    formatter: () => '',
                },
                lineStyle: {
                    color: '#9C42CC',
                },
            },
            data: XArr,
        },
        yAxis: [
            {
                type: 'value',
                boundaryGap: false,
                axisLine: {
                    lineStyle: {
                        color: 'transparent',
                        type: [5, 10],
                        dashOffset: 5,
                    },
                },
                axisTick: {
                    show: false,
                },
                axisLabel: {
                    formatter: (value) => {
                        return `${formatYLabel(value, scoreYLabelSettings)}`;
                    },
                    color: '#233BC9',
                    fontFamily: 'Mulish',
                    fontWeight: 700,
                    fontSize: axisLabelFontSize,
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#ebebeb',
                        type: [3, 6],
                        dashOffset: 3,
                    },
                },
                axisPointer: {
                    show: false,
                },
                ...scoreYLabelSettings,
                position: 'left',
            },
        ],
        tooltip: {
            show: true,
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                snap: true,
            },
            formatter: (arr) => {
                let scoreItem = {};
                let xValue = '';
                if (Array.isArray(arr) && arr.length > 0) {
                    arr.forEach((i) => {
                        if (i.seriesIndex === 0) {
                            scoreItem = i;
                        }
                        if (i.axisValue) {
                            xValue = i.axisValue;
                        }
                    });
                }
                const score =
                    typeof scoreItem.value === 'number'
                        ? scoreItem.value
                        : '--';
                return `<div style="font-size: ${tooltipFontSize}px; line-height: ${tooltipLineHeight}px; font-family: Mulish; color: #4D4D4D">
                    <h5 style="font-family: Montserrat; margin-bottom: ${tooltipPadding}px">${xValue}</h5>
                    <p><span>${locale.esg}: </span><span style="font-family: Montserrat; font-weight: 700; color: #233BC9">${score}</span></p>
                </div>`;
            },
            backgroundColor: '#ffffff',
            padding: tooltipWrapPadding,
        },
        toolbox: {
            show: false,
        },
        series: [
            {
                type: 'line',
                name: 'ESG',
                lineStyle: {
                    color: '#233BC9',
                    shadowColor: 'rgba(35, 59, 201, 0.2)',
                    shadowBlur: 4,
                    shadowOffsetY: 7,
                    width: 1.5,
                },
                itemStyle: {
                    color: '#233BC9',
                    borderColor: 'rgba(255, 255, 255, 0.9)',
                    borderWidth: 1.5,
                },
                emphasis: {
                    lineStyle: {
                        width: 1.5,
                    },
                },
                showSymbol: true,
                symbol: 'circle',
                symbolSize: 6,
                data: scoreArr,
            },
        ],
    };
};

export default getMainChartOptions;
