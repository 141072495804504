import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import './index.scss';
import { ArrowDownFull } from '../../../../component/Icon';

const Selector = (props) => {
    const dropdownRef = useRef(null);
    const listRef = useRef(null);
    const {
        type, // priority, ease
        row,
        listenToScroll = false,
        disabled,
        locale,
    } = props;

    const { priority = null, ease = null } = row.metricNote || {};

    const isPriority = type === 'priority';

    const [showDropdown, setShowDropdown] = useState(false);
    const [listStyle, setListStyle] = useState({});

    const setListPosition = () => {
        const clientH =
            parseFloat(
                document.documentElement.style.getPropertyValue('--vh')
            ) * 100;
        const listInfo = listRef.current.getBoundingClientRect();
        const dropdownInfo = dropdownRef.current.getBoundingClientRect();
        const headerInfo = document
            .getElementsByClassName('esgai_header')[0]
            .getBoundingClientRect();
        if (listInfo.bottom > clientH) {
            if (dropdownInfo.top - headerInfo.height - 4 > listInfo.height) {
                setListStyle({ top: -(listInfo.height + 4) });
            } else {
                setListStyle({
                    top: clientH - dropdownInfo.top - listInfo.height - 4,
                    left: dropdownInfo.width + 4,
                });
            }
        }
    };

    useEffect(() => {
        if (showDropdown) {
            setListPosition();
            window.addEventListener('click', tryHideDropdown);
            if (listenToScroll) {
                window.addEventListener('scroll', handleScrollBySelector);
            }
        } else {
            setListStyle({});
            window.removeEventListener('click', tryHideDropdown);
            if (listenToScroll) {
                window.removeEventListener('scroll', handleScrollBySelector);
            }
        }
        return () => {
            window.removeEventListener('click', tryHideDropdown);
            if (listenToScroll) {
                window.removeEventListener('scroll', handleScrollBySelector);
            }
        };
    }, [showDropdown]); // eslint-disable-line

    const tryHideDropdown = (e) => {
        const path = e.path || (e.composedPath && e.composedPath());
        if (path.indexOf(dropdownRef.current) < 0 && showDropdown) {
            setShowDropdown(false);
        }
    };

    const handleScrollBySelector = () => {
        if (!showDropdown) {
            return;
        }
        setShowDropdown(false);
    };

    const getPriorityList = () => {
        return [
            {
                key: 'HIGH',
                title: locale.high,
            },
            {
                key: 'MEDIUM',
                title: locale.mid,
            },
            {
                key: 'LOW',
                title: locale.low,
            },
            {
                key: null,
                title: locale.na,
            },
        ];
    };

    const getEaseList = () => {
        return [...new Array(10).keys()].map((i) => {
            return i + 1;
        });
    };

    const list = isPriority ? getPriorityList() : getEaseList();

    const renderPriority = () => {
        const target = list.find((i) => i.key === priority) || list[3];
        return (
            <div
                className={classNames({
                    priority: true,
                    [`priority-${target.key || 'NA'}`]: true,
                })}
            >
                <span>{target.title}</span>
                <ArrowDownFull />
            </div>
        );
    };

    const renderEase = () => {
        return (
            <div className="ease">
                <span>{ease || '--'}</span>
                <ArrowDownFull />
            </div>
        );
    };

    const handleSelected = (data) => {
        const {
            metricNote: { priority, ease },
        } = row;
        if (data) {
            props.handleSelected({ priority, ease, ...data, name: row.name });
        }
    };

    const renderPriorityList = () => {
        return list.map((i) => {
            const selected = (!priority && !i.key) || priority === i.key;
            return (
                <div
                    className={classNames({
                        'option priority': true,
                        [`priority-${i.key || 'NA'}`]: true,
                        'option-selected': selected,
                    })}
                    key={i.key || 'NA'}
                    onClick={() =>
                        handleSelected(selected ? null : { priority: i.key })
                    }
                >
                    <span>{i.title}</span>
                </div>
            );
        });
    };

    const renderEaseList = () => {
        return list.map((i) => {
            const selected = Number(ease) === i;
            return (
                <div
                    className={classNames({
                        'ease option': true,
                        'option-selected': selected,
                    })}
                    key={i}
                    onClick={() =>
                        handleSelected(selected ? null : { ease: i })
                    }
                >
                    <span>{i}</span>
                </div>
            );
        });
    };

    return (
        <div
            className={classNames({
                optimizer_dropdown_selector: true,
                'optimizer_dropdown_selector-show': showDropdown,
                'optimizer_dropdown_selector-disable': disabled,
            })}
            ref={dropdownRef}
            onClick={() => {
                if (!disabled) {
                    setShowDropdown(!showDropdown);
                }
            }}
        >
            {isPriority ? renderPriority() : renderEase()}
            {showDropdown && (
                <div
                    className="optimizer_dropdown_selector-list-wrap"
                    ref={listRef}
                    style={listStyle}
                >
                    <div className="optimizer_dropdown_selector-list">
                        {isPriority ? renderPriorityList() : renderEaseList()}
                    </div>
                </div>
            )}
        </div>
    );
};

export default connect(({ persist: { locale } }) => ({
    locale,
}))(Selector);
