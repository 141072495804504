import { all, takeLatest, put } from 'redux-saga/effects';
import { commonType } from '../../constant/actionTypes';

export function* setCommonState(action) {
    yield put({
        type: commonType.APPLY_SET_COMMON_STATE,
        payload: action.payload,
    });
}

export default function* commonSaga() {
    yield all([takeLatest(commonType.SET_COMMON_STATE, setCommonState)]);
}
