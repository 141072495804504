import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import cns from 'classnames';
import { ArrowDown, Plus } from '../../../../component/Icon';
import './index.scss';

class RegionOfOperation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            displayName: '',
            activeIndex: -1,
            options: null,
        };
        this.inputsRef = React.createRef();
    }

    componentDidMount() {
        window.addEventListener('click', this.tryHideDropdown);
    }

    componentDidUpdate(prevProps, prevState) {
        const { data } = this.props;
        const { data: prevData } = prevProps;
        if (data !== prevData) {
            this.syncListWithProps();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.tryHideDropdown);
    }

    tryHideDropdown = (e) => {
        const { activeIndex } = this.state;
        const { editable } = this.props;
        const path = e.path || (e.composedPath && e.composedPath());
        if (editable && activeIndex > -1) {
            const inputWrapEls = this.inputsRef.current.getElementsByClassName(
                'company-info-search-input-wrap'
            );
            if (!path.find((i) => Array.from(inputWrapEls).indexOf(i) > -1)) {
                this.setState({
                    activeIndex: -1,
                    displayName: '',
                    options: null,
                });
            }
        }
    };

    syncListWithProps = () => {
        const { data = [] } = this.props;
        this.setState({
            list: data,
        });
    };

    onDescChange = (e) => {
        const {
            target: { value },
        } = e;
        this.setState({
            displayName: value,
        });
    };

    activeInput = (e, i) => {
        const { editable, displayKey, options: propsOptions } = this.props;
        const { activeIndex, list } = this.state;
        if (editable) {
            const currentInputEl = this.inputsRef.current.children[i];
            const displayEl = currentInputEl.getElementsByClassName(
                'company-info-search-input-display'
            )[0];
            const searchIconEl =
                currentInputEl.getElementsByClassName('esgai-icon')[0];
            const { nativeEvent } = e;
            const path =
                nativeEvent.path ||
                (nativeEvent.composedPath && nativeEvent.composedPath());
            if (
                (path.indexOf(displayEl) > -1 ||
                    path.indexOf(searchIconEl) > -1) &&
                activeIndex !== i
            ) {
                const displayName = list[i] ? list[i][displayKey] : '';
                this.setState({
                    activeIndex: i,
                    displayName,
                    options: propsOptions,
                });
            }
        }
    };

    updateList = (index, data, type = 'SELECT') => {
        const { list } = this.state;
        const { updateCompanyInfoList, idKey, updateReset, locale } =
            this.props;
        if (list[index] && data && list[index][idKey] === data[idKey]) {
            this.setState({
                activeIndex: -1,
                displayName: '',
                options: null,
            });
            return;
        }
        let newList = JSON.parse(JSON.stringify(list));
        if (type === 'SELECT') {
            newList.splice(index, 1, data);
        } else {
            if (list.length > 1) {
                newList.splice(index, 1);
            } else {
                newList = this.props.defaultValue;
                updateReset(
                    locale.companyFieldReset(locale.region_of_operation)
                );
            }
        }
        updateCompanyInfoList(newList);
        this.setState({
            activeIndex: -1,
            displayName: '',
            options: null,
        });
    };

    render() {
        const { editable, key, displayKey, idKey, locale } = this.props;
        const { list, displayName, activeIndex, options } = this.state;
        const filteredOptions = (options || []).filter(
            (o) =>
                o[displayKey].toUpperCase().indexOf(displayName.toUpperCase()) >
                -1
        );
        const processedList = editable
            ? [
                  ...list,
                  {
                      [idKey]: '',
                      [displayKey]: '',
                  },
              ]
            : list;
        return (
            <div className="region_of_operation" key={key}>
                <div
                    className="company-info-search-inputs"
                    ref={this.inputsRef}
                >
                    {processedList.length > 0 ? (
                        processedList.map((i, index) => (
                            <div
                                className={cns({
                                    'company-info-search-input-wrap': true,
                                    'company-info-search-input-wrap-editable':
                                        editable,
                                    'company-info-search-input-wrap-active':
                                        activeIndex === index,
                                })}
                                key={i[idKey]}
                                onClick={(e) => this.activeInput(e, index)}
                            >
                                <span
                                    title={i[displayKey]}
                                    className={cns({
                                        'company-info-search-input-display': true,
                                        'company-info-search-input-display-placeholder':
                                            !i[displayKey],
                                    })}
                                >
                                    {i[displayKey]}
                                    {!i[displayKey] && (
                                        <Plus style={{ fontSize: 16 }} />
                                    )}
                                </span>
                                {editable && (
                                    <>
                                        {i[displayKey] && (
                                            <>
                                                <ArrowDown
                                                    style={{
                                                        fontSize: 16,
                                                        transform: `rotate(${
                                                            activeIndex ===
                                                            index
                                                                ? 180
                                                                : 0
                                                        }deg)`,
                                                    }}
                                                />
                                                <button
                                                    onClick={() =>
                                                        this.updateList(
                                                            index,
                                                            null,
                                                            'REMOVE'
                                                        )
                                                    }
                                                    className="company-info-search-input-remove"
                                                />
                                            </>
                                        )}
                                        {activeIndex === index && (
                                            <>
                                                <input
                                                    className="company-info-search-input"
                                                    value={displayName}
                                                    placeholder={
                                                        locale.select_region
                                                    }
                                                    autoFocus
                                                    onChange={this.onDescChange}
                                                    maxLength={60}
                                                    autoComplete="off"
                                                />
                                                {typeof displayName ===
                                                    'string' &&
                                                    Array.isArray(options) && (
                                                        <div className="company-info-search-options esgai_scrollbar">
                                                            {filteredOptions.map(
                                                                (j) => {
                                                                    const disableOption =
                                                                        !!list.find(
                                                                            (
                                                                                m,
                                                                                n
                                                                            ) =>
                                                                                m[
                                                                                    idKey
                                                                                ] ===
                                                                                    j[
                                                                                        idKey
                                                                                    ] &&
                                                                                n !==
                                                                                    index
                                                                        );
                                                                    return (
                                                                        <div
                                                                            onClick={() => {
                                                                                if (
                                                                                    !disableOption
                                                                                ) {
                                                                                    this.updateList(
                                                                                        index,
                                                                                        j
                                                                                    );
                                                                                }
                                                                            }}
                                                                            className={cns(
                                                                                {
                                                                                    'company-info-search-option': true,
                                                                                    'company-info-search-option-active':
                                                                                        i[
                                                                                            idKey
                                                                                        ] ===
                                                                                        j[
                                                                                            idKey
                                                                                        ],
                                                                                    'company-info-search-option-disabled':
                                                                                        disableOption,
                                                                                }
                                                                            )}
                                                                            key={
                                                                                j[
                                                                                    idKey
                                                                                ]
                                                                            }
                                                                        >
                                                                            {
                                                                                j[
                                                                                    displayKey
                                                                                ]
                                                                            }
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                            {filteredOptions.length ===
                                                                0 && (
                                                                <div
                                                                    className="company-info-search-option company-info-search-option-empty"
                                                                    key="No results found"
                                                                >
                                                                    {
                                                                        locale.no_result
                                                                    }
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        ))
                    ) : (
                        <p>--</p>
                    )}
                </div>
            </div>
        );
    }
}

export default withRouter(
    connect(({ persist: { account, locale }, router }) => ({
        account,
        router,
        locale,
    }))(RegionOfOperation)
);
