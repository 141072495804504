import { all, takeLatest, put, select } from 'redux-saga/effects';
import firebase from 'firebase/app';
import { persistType } from '../../constant/actionTypes';
import { accountUrl } from '../../constant/endpoint';
import service from '../../service';
import { firebaseUserRx } from '../select';

export function* login(action) {
    const { idToken, callback, success } = action;
    try {
        const res = yield service.get(accountUrl.LOGIN, {
            idToken,
        });
        if (res.token) {
            yield put({
                type: persistType.APPLY_LOGIN,
                payload: res,
            });
            if (typeof success === 'function') {
                success();
            }
        }
    } catch (err) {
        console.log(err);
        if (typeof callback === 'function') {
            callback();
        }
    }
}

export function* getProfile() {
    try {
        const res = yield service.fetch(accountUrl.PROFILE);
        yield put({
            type: persistType.APPLY_UPDATE_PROFILE,
            payload: res,
        });
    } catch (err) {
        console.log(err);
    }
}

export function* logout() {
    try {
        const firebaseUser = yield select(firebaseUserRx);
        if (firebaseUser) {
            yield firebase.auth().signOut();
        }
        yield put({
            type: persistType.APPLY_LOGOUT,
            payload: {},
        });
    } catch (err) {
        console.log(err);
    }
}

export default function* persistSaga() {
    yield all([
        takeLatest(persistType.LOGIN, login),
        takeLatest(persistType.GET_PROFILE, getProfile),
        takeLatest(persistType.LOGOUT, logout),
    ]);
}
