import React from 'react';
import { connect } from 'react-redux';
import './index.scss';
import { Minus, ArrowDownFull } from '../../../../component/Icon';
import classNames from 'classnames';

const CompareData = (props) => {
    const { data, showPercent = true, competitorData } = props;
    let hasData = false;
    if (competitorData) {
        hasData =
            data &&
            data.scoreValue &&
            typeof data.scoreValue.numberValue === 'number' &&
            competitorData.scoreValue &&
            typeof competitorData.scoreValue.numberValue === 'number';
    } else {
        hasData =
            data &&
            data.scoreValue &&
            typeof data.scoreValue.compareAvg === 'number';
    }
    let diffValue = null;
    if (showPercent) {
        if (
            data.scoreValue &&
            typeof data.scoreValue.diffNumberValue === 'number'
        ) {
            diffValue = data.scoreValue.diffNumberValue;
        }
        if (typeof diffValue === 'number') {
            diffValue =
                data.scoreValue.compareAvg === 0
                    ? '+0%'
                    : `${data.scoreValue.compareAvg > 0 ? '+' : '-'}${(
                          Math.abs(diffValue) * 100
                      ).toFixed(2)}%`;
        }
    }

    return (
        <div
            className={classNames({
                'compare-data': true,
                'compare-data-up':
                    hasData &&
                    (competitorData
                        ? competitorData.scoreValue.numberValue <
                          data.scoreValue.numberValue
                        : data.scoreValue.compareAvg > 0),
                'compare-data-down':
                    hasData &&
                    (competitorData
                        ? competitorData.scoreValue.numberValue >
                          data.scoreValue.numberValue
                        : data.scoreValue.compareAvg < 0),
            })}
        >
            {hasData && (
                <>
                    {(
                        competitorData
                            ? competitorData.scoreValue.numberValue ===
                              data.scoreValue.numberValue
                            : data.scoreValue.compareAvg === 0
                    ) ? (
                        <Minus />
                    ) : (
                        <ArrowDownFull />
                    )}
                </>
            )}
            {showPercent && (
                <span className="compare-data-value no-wrap-text">
                    {diffValue || '--'}
                </span>
            )}
        </div>
    );
};

export default connect(({ persist: { locale }, common: { mobileView } }) => ({
    locale,
    mobileView,
}))(CompareData);
