import React from 'react';
import { connect } from 'react-redux';
import Modal from '../../../../component/Modal';
import Button from '../../../../component/Button';
import './index.scss';
import service from '../../../../service';
import { optimizerUrl } from '../../../../constant/endpoint';

class ArchiveModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            archiving: false,
        };
    }

    handleArchive = async () => {
        this.setState({ archiving: true });
        try {
            const { selectedItem, close } = this.props;
            await service.put(optimizerUrl.ARCHIVE_BOARD(selectedItem.id));
            this.setState({ archiving: false }, () => close(true));
        } catch (err) {
            this.setState({ archiving: false });
            console.log(err);
        }
    };

    render() {
        const { locale, close, selectedItem } = this.props;
        const { archiving } = this.state;
        return (
            <Modal>
                <div className="optimizer-board-archive-modal">
                    <h4
                        dangerouslySetInnerHTML={{
                            __html: locale.archive_item_title(
                                selectedItem.name
                            ),
                        }}
                    />
                    <p
                        dangerouslySetInnerHTML={{
                            __html: locale.archive_item_note,
                        }}
                    />
                    <div className="optimizer-board-archive-modal-actions">
                        <Button
                            loading={archiving}
                            label={locale.archive}
                            onClick={this.handleArchive}
                        />
                        <Button
                            type="link"
                            label={locale.cancel}
                            onClick={() => close()}
                        />
                    </div>
                </div>
            </Modal>
        );
    }
}

export default connect(({ persist: { locale } }) => ({
    locale,
}))(ArchiveModal);
