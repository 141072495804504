import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';
import './index.scss';
import { User, Trash, Team, Plus, File, Archive } from '../../component/Icon';
import SideMenuLayoutPage from '../../component/layouts/SideMenuLayoutPage';
import Button from '../../component/Button';
import Popover from '../../component/Popover';
import { optimizerUrl, esgScoreUrl } from '../../constant/endpoint';
import service from '../../service';
import { commonType } from '../../constant/actionTypes';
import ListHeader from './components/ListHeader';
import EditBoardModal from './components/EditBoardModal';
import BoardItem from './components/BoardItem';
import TrashModal from './components/TrashModal';
import ArchiveModal from './components/ArchiveModal';

class OptimizerBoard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mainFileList: [],
            bottomFileList: [],
            mainSortKey: '', // lastUpdate, name, trashedDate
            mainSortOrder: 'desc', // asc
            bottomSortKey: '',
            bottomSortOrder: 'desc',
            showEditBoardModal: false,
            selectedItem: null,
            createType: '',
            tooltipInfo: null,
            itemToTrash: null,
        };
        this.timer = null;
    }

    componentDidMount() {
        this.initSortKey();
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            mainSortKey,
            mainSortOrder,
            bottomSortKey,
            bottomSortOrder,
            tooltipInfo,
        } = this.state;
        const {
            mainSortKey: prevMainSortKey,
            mainSortOrder: prevMainSortOrder,
            bottomSortKey: prevBottomSortKey,
            bottomSortOrder: prevBottomSortOrder,
            tooltipInfo: prevTooltipInfo,
        } = prevState;
        const isMainChanged =
            mainSortKey !== prevMainSortKey ||
            mainSortOrder !== prevMainSortOrder;
        const isBottomChanged =
            bottomSortKey !== prevBottomSortKey ||
            bottomSortOrder !== prevBottomSortOrder;
        if (isMainChanged && isBottomChanged) {
            this.fetchBoardList('both');
        } else if (isMainChanged) {
            this.fetchBoardList('main');
        } else if (isBottomChanged) {
            this.fetchBoardList('bottom');
        }
        if (
            tooltipInfo &&
            !tooltipInfo.pause &&
            tooltipInfo !== prevTooltipInfo
        ) {
            this.handleTooltipChange();
        }
    }

    componentWillUnmount() {
        this.clearTimer();
    }

    clearTimer = () => {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
    };

    initSortKey = () => {
        const page = this.getPageKey();
        let newState = {
            mainSortKey: 'trashedDate',
        };
        if (page !== 'trash') {
            newState = {
                mainSortKey: 'lastUpdate',
            };
            if (page === 'teamBoard') {
                newState.bottomSortKey = 'lastUpdate';
            }
        }
        this.setState(newState);
    };

    fetchBoardList = async (updateType) => {
        this.toggleGlobalLoading(true);
        try {
            const {
                mainSortKey,
                mainSortOrder,
                bottomSortKey,
                bottomSortOrder,
            } = this.state;
            const page = this.getPageKey();
            const params = {
                sort:
                    updateType === 'bottom'
                        ? `${bottomSortKey},${bottomSortOrder}`
                        : `${mainSortKey},${mainSortOrder}`,
            };
            if (page === 'teamBoard' && updateType !== 'both') {
                params.type = updateType === 'main' ? 'MY' : 'OTHER';
            }
            const res = await service.get(this.getPageData().listApi, params);
            if (updateType === 'both') {
                if (res && res.my && res.other) {
                    this.setState({
                        mainFileList: res.my.data,
                        bottomFileList: res.other.data,
                    });
                }
            } else {
                let listData = [];
                if (page === 'teamBoard') {
                    const typeKey = params.type.toLowerCase();
                    if (res && res[typeKey]) {
                        listData = res[typeKey].data;
                    }
                } else {
                    if (res && Array.isArray(res.data)) {
                        listData = res.data;
                    }
                }
                this.setState({
                    [`${updateType}FileList`]: listData,
                });
            }
        } catch (err) {
            console.log(err);
        }
        this.toggleGlobalLoading(false);
    };

    toggleGlobalLoading = (globalLoading) => {
        this.props.dispatch({
            type: commonType.APPLY_SET_COMMON_STATE,
            payload: {
                globalLoading,
            },
        });
    };

    handleSortChange = (type, key, value) => {
        this.setState({
            [`${type}${key}`]: value,
        });
    };

    renderEmptyList = () => {
        const pageData = this.getPageData();
        const pageKey = this.getPageKey();
        const isTrash = pageKey === 'trash';
        return (
            <div className="empty-board-list">
                <ListHeader
                    title={pageData.emptyHeadTitle}
                    isTrash={isTrash}
                    isEmpty
                />
                <div className="empty-main">
                    <div className="empty-icon-wrap">
                        {isTrash ? <Trash /> : <File />}
                    </div>
                    <p className="empty-note">{pageData.emptyNote}</p>
                </div>
            </div>
        );
    };

    handleBoardAction = (item, action) => {
        if (action === 'edit') {
            this.setState({
                selectedItem: item,
                showEditBoardModal: true,
            });
        } else if (action === 'copy') {
            this.handleCopy({ ...item, actionType: 'copy' });
        } else if (action === 'download') {
            this.handleDownload({ ...item, actionType: 'download' });
        } else if (action === 'trash') {
            this.setState({ itemToTrash: item });
        } else if (action === 'archive') {
            this.setState({ itemToArchive: item });
        } else if (action === 'unarchive') {
            this.handleUnarchive(item);
        }else if (action === 'restore') {
            this.handleRestore(item);
        } else if (action === 'permanentlyDelete') {
            this.handleDelete(item);
        }
    };

    handleTooltipChange = () => {
        const { tooltipInfo } = this.state;
        this.clearTimer();
        this.timer = setTimeout(() => {
            this.setState({ tooltipInfo: null });
            this.clearTimer();
        }, tooltipInfo.remain);
    };

    pauseTimer = () => {
        const { tooltipInfo } = this.state;
        this.clearTimer();
        const remain = tooltipInfo.remain - (Date.now() - tooltipInfo.start);
        this.setState({
            tooltipInfo: { ...tooltipInfo, remain, pause: true },
        });
    };

    reStartTimer = () => {
        const { tooltipInfo } = this.state;
        this.clearTimer();
        this.setState({
            tooltipInfo: { ...tooltipInfo, pause: false, start: Date.now() },
        });
    };

    handleRestore = async (item) => {
        this.toggleGlobalLoading(true);
        try {
            await service.put(optimizerUrl.RESTORE_BOARD(item.id));
            await this.fetchBoardList('main');
        } catch (err) {
            console.log(err);
        }
        this.toggleGlobalLoading(false);
    };

    handleDelete = async (item) => {
        this.toggleGlobalLoading(true);
        try {
            await service.delete(optimizerUrl.SPECIFIC_BOARD(item.id));
            await this.fetchBoardList('main');
        } catch (err) {
            console.log(err);
        }
        this.toggleGlobalLoading(false);
    };

    handleUnarchive = async (item) => {
        this.toggleGlobalLoading(true);
        try {
            await service.put(optimizerUrl.UNARCHIVE_BOARD(item.id));
            await this.fetchBoardList('main');
        } catch (err) {
            console.log(err);
        }
        this.toggleGlobalLoading(false);
    };

    handleCopy = async (info) => {
        this.toggleGlobalLoading(true);
        try {
            await service.post(optimizerUrl.COPY_BOARD(info.id));
            const page = this.getPageKey();
            if (page === 'myBoard') {
                await this.fetchBoardList('main');
            } else {
                this.clearTimer();
                const start = Date.now();
                const remain = 5000;
                this.setState({ tooltipInfo: { ...info, start, remain } });
            }
        } catch (err) {
            console.log(err);
        }
        this.toggleGlobalLoading(false);
    };

    handleDownload = async (info) => {
        this.toggleGlobalLoading(true);
        try {
            const boardInfo = await service.get(optimizerUrl.BOARD_DATA(info.id));
            const competitorsData = await service.get(esgScoreUrl.SCORE_CARD_COMPETITORS);
            const self = competitorsData.score.find(item => item.myself);
            // const competitors = competitorsData.score.filter(item => (!item.myself && item.name !== 'Competitors avg'));

            const zip = new JSZip();
            const time = dayjs().format('YYYY-MM-DD_HHmm');
            const summary = this.getCsvSummary(boardInfo.board, boardInfo.pillars);
            const data = this.getCsvData(self, boardInfo.pillars, boardInfo.metrics, boardInfo.competitorMetrics);

            zip.file(`optimizer-board-summary-${time}.csv`, summary);
            zip.file(`optimizer-board-data-${time}.csv`, data);
            zip.generateAsync({type:"blob"})
            .then((content) => {
                saveAs(content, `optimizer-board-${time}.zip`);
            });
        } catch (err) {
            console.log(err);
        }
        this.toggleGlobalLoading(false);
    }

    getCsvSummary = (board, pillars) => {
        const { account } = this.props;
        const ranks = pillars?.ESGScore?.ranks;
        const targetRanks = pillars?.ESGScore?.targetRanks;
        const groupRank = ranks?.find?.(rank => !rank.geographical);
        const groupTargetRank = targetRanks?.find?.(rank => !rank.geographical);
        const geoRank = ranks?.find?.(rank => rank.geographical);
        const geoTargetRank = targetRanks?.find?.(rank => rank.geographical);

        const summary = [
            ['Export Type', 'Optimizer Board'],
            ['Company', `"${account?.organization?.name || '--'} "`],
            ['Board Name', `"${board?.name} "`],
            ['Data Export Date', `"${dayjs().format('LL')}"`],
            ['Overall ESG Score', `"${pillars?.ESGScore?.displayValue || '--'} "`],
            ['E Pillar Numeric Score', `"${pillars?.EnvironmentPillarScore?.displayValue || '--'} "`],
            ['E Pillar Letter Grade', `"${pillars?.EnvironmentPillarScore?.grade || '--'} "`],
            ['S Pillar Numeric Score', `"${pillars?.SocialPillarScore?.displayValue || '--'} "`],
            ['S Pillar Letter Grade', `"${pillars?.SocialPillarScore?.grade || '--'} "`],
            ['G Pillar Numeric Score', `"${pillars?.GovernancePillarScore?.displayValue || '--'} "`],
            ['G Pillar Letter Grade', `"${pillars?.GovernancePillarScore?.grade || '--'} "`],
            ['Target ESG Overall Score (Numeric)', `"${board?.score?.ESGScore?.displayValue || '--'} "`],
            ['Target ESG Overall Score (Letter)', `"${board?.score?.ESGScore?.grade || '--'} "`],
            ['Target E Pillar Numeric Score', `"${board?.score?.EnvironmentPillarScore?.displayValue || '--'} "`],
            ['Target E Pillar Letter Grade', `"${board?.score?.EnvironmentPillarScore?.grade || '--'} "`],
            ['Target S Pillar Numeric Score', `"${board?.score?.SocialPillarScore?.displayValue || '--'} "`],
            ['Target S Pillar Letter Grade', `"${board?.score?.SocialPillarScore?.grade || '--'} "`],
            ['Target G Pillar Numeric Score', `"${board?.score?.GovernancePillarScore?.displayValue || '--'} "`],
            ['Target G Pillar Letter Grade', `"${board?.score?.GovernancePillarScore?.grade || '--'} "`],
            ['Industry Group Rank', `"${groupRank?.rankValue} -> ${groupTargetRank?.rankValue || groupRank?.rankValue} / ${groupRank?.rankTotal}"`],
            ['Geographical Rank', `"${geoRank?.rankValue} -> ${geoTargetRank?.rankValue || groupRank?.rankValue} / ${geoRank?.rankTotal}"`],
        ]

        return summary.map(item => item.join(',')).join('\n');
    }

    getCsvData = (self, pillars, metrics, competitorMetrics) => {
        const { locale } = this.props;
        const maxCompetitors = (Object.values(competitorMetrics || {}).reduce((ev, m) => {
            if (ev.len < m.length) {
                ev.len = m.length;
                ev.competitors = m;
            }

            return ev;
        }, {len: 0, competitors: []}).competitors || [])
        .map(({name, organizationId: id, name: displayName }) => ({name, id, displayName }));
        const allData = Object.entries(self?.pillars || {})
            .filter(([_, item]) => item.name !== 'ESGScore' && item.displayName)
            .reduce((ev, [key, item]) => {
                ev.push([
                    'N/A',
                    `"${item?.displayName}"`,
                    `"${item?.weight || '--'}  "`,
                    `"${item?.scoreValue?.displayValue || '--'}  "`,
                    `"${(item?.displayName || '--').toUpperCase()} PILLAR SCORE"`,
                    `"${(item?.displayName || '--').toUpperCase()} PILLAR SCORE"`,
                    `"${(item?.displayName || '--').toUpperCase()} PILLAR SCORE"`,
                    `"${(item?.displayName || '--').toUpperCase()} PILLAR SCORE"`,
                    `"${(item?.displayName || '--').toUpperCase()} PILLAR SCORE"`,
                    `"${(item?.displayName || '--').toUpperCase()} PILLAR SCORE"`,
                    `"${(item?.displayName || '--').toUpperCase()} PILLAR SCORE"`,
                    `"${(item?.displayName || '--').toUpperCase()} PILLAR SCORE"`,
                    '--',
                    '--',
                    `"${(item?.displayName || '--').toUpperCase()} PILLAR SCORE"`,
                    `"${(item?.displayName || '--').toUpperCase()} PILLAR SCORE"`,
                    `"${pillars[item.name]?.targetScore?.displayValue || '--'}" `,
                    `"${pillars[item.name]?.targetRanks?.[0]?.rankValue || '--'}" `,
                    '--',
                    ...maxCompetitors.reduce((ev, competitor) => {
                        ev.push('--');
                        ev.push('--');
                        ev.push('--');

                        return ev;
                    }, []),
                ]);

                item.categories.forEach((categorie, cateIndex) => {
                    ev.push([
                        'N/A',
                        `"${item?.displayName}"`,
                        `"${item?.weight || '--'}  "`,
                        `"${item?.scoreValue?.displayValue || '--'}  "`,
                        `"${(categorie?.displayName || '--').toUpperCase()} CATEGORY SCORE"`,
                        `"${categorie?.weight || '--'}  "`,
                        `"${categorie?.scoreValue?.displayValue || '--'}  "`,
                        `"${(categorie?.displayName || '--').toUpperCase()} CATEGORY SCORE"`,
                        `"${(categorie?.displayName || '--').toUpperCase()} CATEGORY SCORE"`,
                        `"${(categorie?.displayName || '--').toUpperCase()} CATEGORY SCORE"`,
                        `"${(categorie?.displayName || '--').toUpperCase()} CATEGORY SCORE"`,
                        `"${(categorie?.displayName || '--').toUpperCase()} CATEGORY SCORE"`,
                        '--',
                        '--',
                        `"${(categorie?.displayName || '--').toUpperCase()} CATEGORY SCORE"`,
                        `"${(categorie?.displayName || '--').toUpperCase()} CATEGORY SCORE"`,
                        '--',
                        '--',
                        '--',
                        ...maxCompetitors.reduce((ev, competitor) => {
                            ev.push('--');
                            ev.push('--');
                            ev.push('--');

                            return ev;
                        }, []),
                    ]);
                    metrics
                    .filter(metric => metric.rootPillar === item.name && metric.parent === categorie.name)
                    .forEach((metric) => {
                        const numeric = metric?.rawValueType === 'NUMERIC' ? 'NUMERIC' : 'Non-Numeric';
                        const reported = (!metric?.score && metric?.score !== 0) ? locale.not_reported : locale.reported;
                        ev.push([
                            'N/A',
                            `"${item?.displayName}"`,
                            `"${item?.weight || '--'}  "`,
                            `"${item?.scoreValue?.displayValue || '--'}  "`,
                            `"${categorie?.displayName}  "`,
                            `"${categorie?.weight || '--'}  "`,
                            `"${categorie?.scoreValue?.displayValue || '--'}  "`,
                            `"${metric?.displayName}"`,
                            `"${metric?.description.replace(/"/g, '""')} "`,
                            `"${metric?.metricNote?.priority || '--'}"`,
                            `"${metric?.metricNote?.ease || '--'}"`,
                            `"${numeric}  "`,
                            `"${metric?.displayValue || '--'}  "`,
                            `"${metric?.grade || '--'}  "`,
                            `"${metric?.mmiDisplayValue || '--'}  "`,
                            `"${reported}"`,
                            `"${metric?.targetScore?.displayValue || '--'}  "`,
                            `"${metric?.targetRanks?.[0]?.rankValue || '--'}  "`,
                            `"${metric?.metricNote?.note || '--'}"`,
                            ...maxCompetitors.reduce((ev, competitor) => {
                                const comMetric = (competitorMetrics?.[metric?.name] || []).find(com => com.organizationId === competitor.id);
                                ev.push(`"${comMetric?.displayValue || '--'}"`);
                                ev.push(`"${comMetric?.grade || '--'}"`);
                                ev.push(`"${comMetric?.ranks?.[0]?.rankValue || '--'}"`);

                                return ev;
                            }, []),
                        ]);
                    })
                })
                return ev;
            }, []);

        const data = [
            [
                'XBRL Mapping',
                'ESG Pillar Name',
                'Pillar Weighting',
                'ESG Pillar Total Score',
                'Category Name',
                'Category Weighting',
                'Category Total Score',
                'Metric Name',
                'Metric Description',
                'Priority',
                'Level of Difficulty',
                'Numeric/Non-Numeric',
                'Metric Numeric Score',
                'Metric Letter Grade',
                'Metric Impact (MI)',
                'Position',
                'Target Score',
                'Target Rank',
                'User Notes',
                ...maxCompetitors.reduce((ev, competitor) => {
                    ev.push(`${competitor.name} Numeric Score`);
                    ev.push(`${competitor.name} Letter Grade`);
                    ev.push(`${competitor.name} Rank`);

                    return ev;
                }, []),
            ],
            ...allData
        ]

        return data.map(item => item.join(',')).join('\n');
    }

    renderBoards = () => {
        const { mainFileList, bottomFileList, tooltipInfo } = this.state;
        const { locale } = this.props;
        if (mainFileList.length === 0 && bottomFileList.length === 0) {
            return this.renderEmptyList();
        }
        const pageData = this.getPageData();
        const pageKey = this.getPageKey();
        const isTrash = pageKey === 'trash';
        const parts = ['main', 'bottom'];
        return (
            <div
                className={classNames({
                    'optimizer-boards': true,
                    'optimizer-boards-with-tooltip': !!tooltipInfo,
                })}
            >
                {parts.map((p) => {
                    if (this.state[`${p}FileList`].length === 0) {
                        return null;
                    }
                    let cardType = '';
                    if (isTrash) {
                        cardType = 'trash';
                    } else if (pageKey === 'myBoard') {
                        cardType = 'private';
                    } else if (pageKey === 'archivedBoard') {
                        cardType = 'archived';
                    } else {
                        if (p === 'main') {
                            cardType = 'myPublic';
                        } else {
                            cardType = 'otherPublic';
                        }
                    }
                    const options = [
                        {
                            title: locale.edit_name_privacy,
                            action: 'edit',
                            applyBoards: ['private', 'myPublic'],
                        },
                        {
                            title: locale.create_copy,
                            title_myPublic: locale.create_private_copy,
                            title_otherPublic: locale.create_private_copy,
                            action: 'copy',
                            applyBoards: ['private', 'myPublic', 'otherPublic'],
                        },
                        {
                            title: locale.export_CSV,
                            title_myPublic: locale.export_CSV,
                            title_otherPublic: locale.export_CSV,
                            action: 'download',
                            applyBoards: ['private', 'myPublic', 'otherPublic'],
                        },
                        {
                            title: locale.archive,
                            action: 'archive',
                            applyBoards: ['private', 'myPublic']
                        },
                        {
                            title: locale.unarchive,
                            action: 'unarchive',
                            applyBoards: ['archived']
                        },
                        {
                            title: locale.bin,
                            action: 'trash',
                            applyBoards: ['private', 'myPublic', 'archived'],
                            isDangerous: true,
                        },
                        {
                            title: locale.restore,
                            action: 'restore',
                            applyBoards: ['trash'],
                        },
                        {
                            title: locale.permanently_delete,
                            action: 'permanentlyDelete',
                            applyBoards: ['trash'],
                            isDangerous: true,
                        },
                    ];
                    const appliedOptions = options.filter(
                        (i) => i.applyBoards.indexOf(cardType) > -1
                    );
                    return (
                        <div className="board-list-group" key={p}>
                            <ListHeader
                                title={pageData[`${p}ListHeadTitle`]}
                                isTrash={isTrash}
                                sortKey={this.state[`${p}SortKey`]}
                                sortOrder={this.state[`${p}SortOrder`]}
                                handleChange={(key, value) =>
                                    this.setState({ [`${p}${key}`]: value })
                                }
                            />
                            <div className="board-list">
                                {this.state[`${p}FileList`].map((i) => {
                                    return (
                                        <div key={i.id} className="board-item">
                                            <BoardItem
                                                item={i}
                                                type={cardType}
                                                options={appliedOptions}
                                                handleAction={
                                                    this.handleBoardAction
                                                }
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    getPageKey = () => {
        const {
            router: {
                location: { pathname },
            },
        } = this.props;
        if (pathname === '/optimizer/my-board') {
            return 'myBoard';
        } else if (pathname === '/optimizer/team-board') {
            return 'teamBoard';
        } else if (pathname === '/optimizer/archived-board') {
            return 'archivedBoard';
        }
        return 'trash';
    };

    getPageData = () => {
        const { locale } = this.props;
        const textMap = {
            myBoard: {
                title: locale.my_board,
                titleNote: locale.my_board_note,
                listApi: optimizerUrl.MY_BOARD,
                emptyHeadTitle: locale.files,
                emptyNote: locale.my_board_empty_note,
                mainListHeadTitle: locale.files,
            },
            teamBoard: {
                title: locale.team_board,
                titleNote: locale.team_board_note,
                listApi: optimizerUrl.TEAM_BOARD,
                emptyHeadTitle: locale.shared_files,
                emptyNote: locale.team_board_empty_note,
                mainListHeadTitle: locale.shared_with_others,
                bottomListHeadTitle: locale.shared_with_me,
            },
            archivedBoard: {
                title: locale.archived_boards,
                titleNote: locale.archived_board_note,
                listApi: optimizerUrl.ARCHIVED,
                emptyNote: locale.archived_empty_note,
            },
            trash: {
                title: locale.bin,
                titleNote: locale.bin_title_note,
                listApi: optimizerUrl.TRASH,
                emptyNote: locale.bin_empty_note,
            }
        };
        const key = this.getPageKey();
        return textMap[key];
    };

    closeEditBoardModal = (needRefetchMainFileList = false) => {
        this.setState(
            {
                showEditBoardModal: false,
                selectedItem: null,
                createType: '',
            },
            () => {
                if (needRefetchMainFileList === true) {
                    this.fetchBoardList('main');
                }
            }
        );
    };

    closeTrashBoardModal = (needRefetchMainFileList = false) => {
        const {
            itemToTrash: { id, name },
        } = this.state;
        this.setState(
            {
                itemToTrash: null,
            },
            async () => {
                if (needRefetchMainFileList === true) {
                    await this.fetchBoardList('main');
                    this.clearTimer();
                    const start = Date.now();
                    const remain = 5000;
                    this.setState({
                        tooltipInfo: {
                            id,
                            name,
                            start,
                            remain,
                            actionType: 'trash',
                        },
                    });
                }
            }
        );
    };

    closeArchiveBoardModal = (needRefetchMainFileList = false) => {
        const {
            itemToArchive: { id, name },
        } = this.state;
        this.setState(
            {
                itemToArchive: null,
            },
            async () => {
                if (needRefetchMainFileList === true) {
                    await this.fetchBoardList('main');
                    this.clearTimer();
                    const start = Date.now();
                    const remain = 5000;
                    this.setState({
                        tooltipInfo: {
                            id,
                            name,
                            start,
                            remain,
                            actionType: 'archive',
                        },
                    });
                }
            }
        );
    };

    handleShowCreateBoardModal = () => {
        const page = this.getPageKey();
        this.setState({
            showEditBoardModal: true,
            createType: page === 'myBoard' ? 'private' : 'public',
        });
    };

    handleUndo = async () => {
        const { tooltipInfo } = this.state;
        await this.handleRestore(tooltipInfo);
        this.clearTimer();
        this.setState({ tooltipInfo: null });
    };

    goToMyBoard = () => {
        this.props.history.push('/optimizer/my-board');
    };

    goToArchivedBoard = () => {
        this.props.history.push('/optimizer/archived-board');
    };

    render() {
        const text = this.getPageData();
        const page = this.getPageKey();
        const {
            tooltipInfo,
            showEditBoardModal,
            selectedItem,
            createType,
            itemToTrash,
            itemToArchive,
        } = this.state;
        const { locale, mobileView } = this.props;
        const hasHeader = !mobileView || page !== 'trash';
        const actionLocale = {
            trash: locale.undo,
            archive: locale.go_to_archived_board
        };

        return (
            <SideMenuLayoutPage
                settingsMenu={[
                    {
                        title: locale.my_board,
                        icon: User,
                        path: '/optimizer/my-board',
                    },
                    {
                        title: locale.team_board,
                        icon: Team,
                        path: '/optimizer/team-board',
                    },
                    {
                        title: locale.archived_boards,
                        icon: Archive,
                        path: '/optimizer/archived-board',
                    },
                    {
                        title: locale.bin,
                        icon: Trash,
                        path: '/optimizer/trash',
                    },
                ]}
                displayVersion={false}
                style={{ backgroundColor: '#f9f9f9' }}
            >
                <Helmet title={`${text.title} | ${locale.optimizer}`} />
                <div className="optimizer_board-wrap">
                    {hasHeader && (
                        <div className="optimizer_board-header">
                            <h4 className="optimizer_board-title">
                                <span className="optimizer_board-title-text">
                                    {text.title}
                                </span>
                                <Popover tooltip={text.titleNote} />
                            </h4>
                            {page !== 'trash' && page !== 'archivedBoard' && (
                                <Button
                                    label={
                                        <>
                                            <Plus />
                                            <span>{locale.create_new}</span>
                                        </>
                                    }
                                    onClick={this.handleShowCreateBoardModal}
                                />
                            )}
                        </div>
                    )}
                    <div className="optimizer_board-content">
                        {this.renderBoards()}
                    </div>
                    {tooltipInfo && (
                        <div
                            className="bottom-tooltip"
                            onMouseEnter={!isMobile ? this.pauseTimer : null}
                            onMouseLeave={!isMobile ? this.reStartTimer : null}
                        >
                            <div className="bottom-tooltip-left">
                                {tooltipInfo.actionType === 'trash' && (
                                    <Trash
                                        style={{ fontSize: 24 }}
                                        color="E65C47"
                                    />
                                )}
                                <p
                                    className="bottom-tooltip-text"
                                    dangerouslySetInnerHTML={{
                                        __html: locale[
                                            `${tooltipInfo.actionType}_board_note`
                                        ](tooltipInfo.name),
                                    }}
                                />
                            </div>
                            <button
                                className="bottom-tooltip-btn"
                                onClick={() => {
                                    if ( tooltipInfo.actionType === 'trash') {
                                        this.handleUndo();

                                        return;
                                    }

                                    if ( tooltipInfo.actionType === 'archive') {
                                        this.goToArchivedBoard();

                                        return;
                                    }

                                    this.goToMyBoard();
                                }}
                            > 
                                {
                                    actionLocale[tooltipInfo.actionType] || locale.go_to_my_board
                                }
                            </button>
                        </div>
                    )}
                </div>
                {showEditBoardModal && (
                    <EditBoardModal
                        createType={createType}
                        selectedItem={selectedItem}
                        close={this.closeEditBoardModal}
                    />
                )}
                {!!itemToArchive && (
                    <ArchiveModal
                        selectedItem={itemToArchive}
                        close={this.closeArchiveBoardModal}
                    />
                )}
                {!!itemToTrash && (
                    <TrashModal
                        selectedItem={itemToTrash}
                        close={this.closeTrashBoardModal}
                    />
                )}
            </SideMenuLayoutPage>
        );
    }
}

export default withRouter(
    connect(
        ({ persist: { account, locale }, common: { mobileView }, router }) => ({
            account,
            router,
            locale,
            mobileView,
        })
    )(OptimizerBoard)
);
