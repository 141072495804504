import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import cns from 'classnames';
import './index.scss';
import { User, Competitor, Partner, Bell } from '../../../component/Icon';
import { commonType } from '../../../constant/actionTypes';

const SideMenuLayoutPage = (props) => {
    const {
        router: {
            location: { pathname },
        },
        children,
        locale,
        history,
        mobileSettingsMenuScrollLeft,
        mobileView,
        dispatch,
        settingsMenu = [
            {
                title: locale.profile,
                icon: User,
                path: '/settings/profile-info',
            },
            {
                title: locale.competitors,
                icon: Competitor,
                path: '/settings/competitor',
            },
            {
                title: locale.business_partners,
                icon: Partner,
                path: '/settings/partner',
            },
            {
                title: locale.notifications,
                icon: Bell,
                path: '/settings/notification',
            },
        ],
        displayVersion = true,
        style = {},
    } = props;
    const menuRef = useRef(null);
    useEffect(() => {
        if (mobileView && mobileSettingsMenuScrollLeft && menuRef.current) {
            menuRef.current.scrollTo({
                left: mobileSettingsMenuScrollLeft,
            });
        }
    }, []); // eslint-disable-line
    const version = process.env.REACT_APP_BUILD_VERSION;
    return (
        <div className="page side_menu_layout_page" style={style}>
            <div className="side_menu_layout_page-container">
                <div className="side_menu_layout_page-menu-wrap">
                    <div
                        className="side_menu_layout_page-menu-scroll-box"
                        ref={menuRef}
                    >
                        <div className="side_menu_layout_page-menu">
                            {settingsMenu.map((i) => {
                                const { title, path, icon: ItemIcon } = i;
                                return (
                                    <p
                                        key={path}
                                        onClick={() => {
                                            if (
                                                mobileView &&
                                                menuRef.current.scrollLeft !==
                                                    mobileSettingsMenuScrollLeft
                                            ) {
                                                dispatch({
                                                    type: commonType.APPLY_SET_COMMON_STATE,
                                                    payload: {
                                                        mobileSettingsMenuScrollLeft:
                                                            menuRef.current
                                                                .scrollLeft,
                                                    },
                                                });
                                            }
                                            history.push(path);
                                        }}
                                        className={cns({
                                            'side_menu_layout_page-menu-item': true,
                                            'side_menu_layout_page-menu-item-active':
                                                path === pathname,
                                        })}
                                    >
                                        <ItemIcon
                                            style={
                                                path ===
                                                '/settings/notification'
                                                    ? {
                                                          fontSize: 20,
                                                          marginRight: 16,
                                                      }
                                                    : { fontSize: 24 }
                                            }
                                            color={
                                                path === pathname
                                                    ? '233BC9'
                                                    : '4D4D4D'
                                            }
                                        />
                                        <span className="side_menu_layout_page-menu-item-title">
                                            {title}
                                        </span>
                                    </p>
                                );
                            })}
                            {displayVersion && typeof version === 'string' && (
                                <span className="esg_version">
                                    {version.toUpperCase()}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
                <div className="side_menu_layout_page-content-wrap">
                    <div className="side_menu_layout_page-content">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(
    connect(
        ({
            persist: { account, locale },
            router,
            common: { mobileSettingsMenuScrollLeft, mobileView },
        }) => ({
            account,
            router,
            locale,
            mobileSettingsMenuScrollLeft,
            mobileView,
        })
    )(SideMenuLayoutPage)
);
