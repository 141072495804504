import React from 'react';
import { connect } from 'react-redux';
import Modal from '../../../../component/Modal';
import Button from '../../../../component/Button';
import './index.scss';
import service from '../../../../service';
import { optimizerUrl } from '../../../../constant/endpoint';

class TrashModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deleting: false,
        };
    }

    handleDelete = async () => {
        this.setState({ deleting: true });
        try {
            const { selectedItem, close } = this.props;
            await service.put(optimizerUrl.TRASH_BOARD(selectedItem.id));
            this.setState({ deleting: false }, () => close(true));
        } catch (err) {
            this.setState({ deleting: false });
            console.log(err);
        }
    };

    render() {
        const { locale, close, selectedItem } = this.props;
        const { deleting } = this.state;
        return (
            <Modal>
                <div className="optimizer-board-archive-modal">
                    <h4
                        dangerouslySetInnerHTML={{
                            __html: locale.trash_item_title(
                                selectedItem.name
                            ),
                        }}
                    />
                    <p
                        dangerouslySetInnerHTML={{
                            __html: locale.trash_item_note,
                        }}
                    />
                    <div className="optimizer-board-archive-modal-actions">
                        <Button
                            loading={deleting}
                            label={locale.delete}
                            onClick={this.handleDelete}
                            type="error"
                        />
                        <Button
                            type="link"
                            label={locale.cancel}
                            onClick={() => close()}
                        />
                    </div>
                </div>
            </Modal>
        );
    }
}

export default connect(({ persist: { locale } }) => ({
    locale,
}))(TrashModal);
