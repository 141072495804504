import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { commonType } from '../../../constant/actionTypes';
import service from '../../../service';
import './index.scss';
import { Newspaper } from '../../../component/Icon';
import NewsWithoutImg from '../../../component/NewsWithoutImg';
import NewsPageTitle from '../../../component/NewsPageTitle';
import NewsEmpty from '../../../component/NewsEmpty';
import { newsUrl } from '../../../constant/endpoint';
import { formatDate, processNewsApi, displaySelectedDate } from '../../../utils';
import Pagination from '../../../component/Pagination';
import NewsFilter from '../../../component/NewsFilter';
import { NewsContext } from '../index';

class NewsIndustry extends React.Component {
    static contextType = NewsContext;

    constructor(props) {
        super(props);
        this.state = {
            news: [],
            totalCount: 0,
            page: 0,
            size: 10,
            industries: [],
            industriesMap: {},
            selectedIndustries: [],
            period: [],
            nextIndustries: [],
            paginationCount: 0,
            newsTypes: [],
            nextNewsTypes: [],
            onlyControversial: false,
            nextOnlyControversial: false,
        };
    }

    componentDidMount() {
        const { period, onlyControversial, newsTypes } = this.context;

        this.setState({
            period, onlyControversial, newsTypes
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            selectedIndustries,
            page,
            period,
            paginationCount,
            newsTypes,
            onlyControversial,
        } = this.state;
        const {
            selectedIndustries: prevSelectedIndustries,
            page: prevPage,
            period: prevPeriod,
            newsTypes: prevNewsTypes,
            onlyControversial: prevOnlyControversial,
        } = prevState;
        const filterChange =
            selectedIndustries !== prevSelectedIndustries ||
            period !== prevPeriod ||
            newsTypes !== prevNewsTypes ||
            onlyControversial !== prevOnlyControversial;
        if (filterChange) {
            this.setState({ paginationCount: paginationCount + 1 });
        }
        if (filterChange || page !== prevPage) {
            this.fetchData();
        }
    }

    fetchData = async () => {
        const { industries } = this.state;
        try {
            this.toggleGlobalLoading(true);
            if (industries.length === 0) {
                const industriesRes = await service.get(
                    newsUrl.INDUSTRIES_TOPICS
                );
                if (Array.isArray(industriesRes)) {
                    let industriesMap = {};
                    industriesRes.forEach((i) => {
                        industriesMap[i.trbc] = i.title;
                    });
                    this.setState({ industries: industriesRes, industriesMap });
                }
            }
            const newsRes = await service.get(
                processNewsApi(
                    this.state,
                    newsUrl.INDUSTRIES_NEWS,
                    'selectedIndustries',
                    'industryTopics',
                    'trbc',
                    industries.length
                )
            );
            if (newsRes && Array.isArray(newsRes.data)) {
                this.setState({
                    news: newsRes.data,
                    totalCount: newsRes.totalCount,
                });
            }
        } catch (err) {
            console.log(err);
        }
        this.toggleGlobalLoading(false);
    };

    toggleGlobalLoading = (globalLoading) => {
        this.props.dispatch({
            type: commonType.APPLY_SET_COMMON_STATE,
            payload: {
                globalLoading,
            },
        });
    };

    renderNewsList = () => {
        const {
            news,
            page,
            size = 10,
            totalCount,
            industriesMap,
            paginationCount,
            selectedIndustries,
            period,
        } = this.state;
        const { mobileView } = this.props;
        let groupedNews = [];
        news.forEach((i) => {
            const date = formatDate(i.versionCreated, 'mid', { month: 'long' });
            if (
                groupedNews.length === 0 ||
                groupedNews[groupedNews.length - 1].date !== date
            ) {
                const group = [];
                i.topic.forEach((k) => {
                    if (
                        (selectedIndustries.length === 0 && industriesMap[k]) ||
                        selectedIndustries.find((s) => s.trbc === k)
                    ) {
                        group.push({
                            groupId: k,
                            groupName: industriesMap[k],
                            news: [i],
                        });
                    }
                });
                groupedNews.push({
                    date,
                    group,
                });
            } else {
                i.topic.forEach((l) => {
                    if (
                        (selectedIndustries.length === 0 && industriesMap[l]) ||
                        selectedIndustries.find((s) => s.trbc === l)
                    ) {
                        let targetIndex = -1;
                        const targetGroup = groupedNews[
                            groupedNews.length - 1
                        ].group.find((j, jIndex) => {
                            if (j.groupId === l) {
                                targetIndex = jIndex;
                                return true;
                            }
                            return false;
                        });
                        if (targetGroup) {
                            groupedNews[groupedNews.length - 1].group.splice(
                                targetIndex,
                                1,
                                {
                                    ...targetGroup,
                                    news: [...targetGroup.news, i],
                                }
                            );
                        } else {
                            groupedNews[groupedNews.length - 1].group.push({
                                groupId: l,
                                groupName: industriesMap[l],
                                news: [i],
                            });
                        }
                    }
                });
            }
        });

        return (
            <div className="news-list-wrap">
                <div className="news-list">
                    {groupedNews.length > 0 ? (
                        groupedNews.map((i) => (
                            <div key={i.date} className="date-group">
                                <p className="date-group-date">{i.date}</p>
                                {i.group.map((j) => (
                                    <div className="sub-group" key={j.groupId}>
                                        <div className="sub-group-header">
                                            <span className="sub-group-name">
                                                {j.groupName}
                                            </span>
                                            <div className="sub-group-num-wrap">
                                                <Newspaper
                                                    style={{ fontSize: 24 }}
                                                    color="7A7A7A"
                                                />
                                                <span className="sub-group-num">
                                                    {j.news.length}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="sub-group-list">
                                            {j.news.map((k) => (
                                                <NewsWithoutImg
                                                    key={k.storyId}
                                                    news={{
                                                        title: k.text,
                                                        brief: k.abstract,
                                                        logo: k.thumbnail,
                                                        date: k.versionCreated,
                                                        topic: k.topic,
                                                        storyId: k.storyId,
                                                    }}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))
                    ) : (
                        <>
                            <div className="group-header-empty">
                                <p className="date-group-date">
                                    {displaySelectedDate(period)}
                                </p>
                                <div className="group-num-wrap">
                                    <Newspaper
                                        style={{ fontSize: 24 }}
                                        color="7A7A7A"
                                    />
                                    <span className="group-num">0</span>
                                </div>
                            </div>
                            <NewsEmpty size={mobileView ? 'tiny' : 'default'} />
                        </>
                    )}
                </div>
                <Pagination
                    count={paginationCount}
                    page={page}
                    size={size}
                    totalCount={totalCount}
                    onChange={(value) => this.setState({ page: value })}
                />
            </div>
        );
    };

    render() {
        const { locale, mobileView } = this.props;
        const {
            period,
            newsTypes,
            onlyControversial,
            nextOnlyControversial,
            industries,
            selectedIndustries,
            nextIndustries,
            nextNewsTypes,
        } = this.state;
        const { setPeriod, setOnlyControversial, setNewsTypes } = this.context;

        return (
            <div className="page news_industry_page">
                <Helmet title={locale.industry_news} />
                <NewsPageTitle
                    title={locale.industry_news}
                    tooltip={locale.industry_news_note}
                />
                <div className="news_industry-main">
                    {this.renderNewsList()}
                    <NewsFilter
                        period={period}
                        newsTypes={mobileView ? nextNewsTypes : newsTypes}
                        onlyControversial={
                            mobileView
                                ? nextOnlyControversial
                                : onlyControversial
                        }
                        updateParentState={(newState, cb = () => {}) => {
                            const { newsTypes, onlyControversial, period } = newState;

                            newsTypes && setNewsTypes(newsTypes);
                            typeof onlyControversial === 'boolean' && setOnlyControversial(onlyControversial);
                            period && setPeriod(period);

                            this.setState(newState, cb)
                        }}
                        otherFilters={[
                            {
                                title: locale.industry,
                                list: industries,
                                itemAllLabel: locale.all_industries,
                                stateKey: mobileView
                                    ? 'nextIndustries'
                                    : 'selectedIndustries',
                                valueKey: 'trbc',
                                labelKey: 'title',
                                selected: mobileView
                                    ? nextIndustries
                                    : selectedIndustries,
                            },
                        ]}
                        handleShowMobileFilter={(cb) => {
                            this.setState(
                                {
                                    nextIndustries: selectedIndustries,
                                    nextNewsTypes: newsTypes,
                                    nextOnlyControversial: onlyControversial,
                                },
                                cb
                            );
                        }}
                        handleApplyMobileFilter={() => {
                            setNewsTypes(nextNewsTypes);
                            setOnlyControversial(nextOnlyControversial);
                            this.setState({
                                selectedIndustries: nextIndustries,
                                newsTypes: nextNewsTypes,
                                onlyControversial: nextOnlyControversial,
                                page: 0,
                            });
                        }}
                    />
                </div>
            </div>
        );
    }
}

export default withRouter(
    connect(
        ({ persist: { account, locale }, common: { mobileView }, router }) => ({
            account,
            router,
            locale,
            mobileView,
        })
    )(NewsIndustry)
);
