import { useState } from 'react';
import { Form, Input, Select } from 'antd';
import { connect } from 'react-redux';
import Button from '../Button'
import { commonType } from '../../constant/actionTypes';
import service from '../../service';
import { accountUrl } from '../../constant/endpoint';

import './index.scss';

const { Option } = Select;

function EmailSupport({ dispatch, locale, emailSupport }) {
    const [form] = Form.useForm();
    const [disabled, setDisabled] = useState(true);
    const [loading, setLoading] = useState(false)

    async function sendEmail() {
        setLoading(true);
        try {
            const fieldsValue = form.getFieldsValue();
    
            await service.post(accountUrl.SEND_MAIL, {
                ...fieldsValue,
                content: fieldsValue.content.replace(/\n/g,'<br/>'),
            });
            close();
        } catch (error) {
            close();
        }
        setLoading(false);
    }

    function valueChange() {
        setDisabled(!form.getFieldValue('subject') || !form.getFieldValue('content'));
    }

    function close() {
        form.resetFields();
        dispatch({
            type: commonType.APPLY_SET_COMMON_STATE,
            payload: {
                emailSupport: false
            },
        });
    }

    return (
        emailSupport ? <div className="eagai_email-support-wrapper">
            <div className="eagai_email-support-container">
                <h3>{locale.email_support_title}</h3>
                <Form layout="vertical" form={form} onValuesChange={valueChange}>
                    <Form.Item label={locale.email_support_subject} name="subject">
                        <Select
                            placeholder={locale.email_support_subject_placeholder}
                            getPopupContainer={triggerNode => triggerNode.parentNode}
                        >
                            <Option value='bug' key="bug">{locale.email_support_subject_bug}</Option>
                            <Option value='comment' key="comment">{locale.email_support_subject_comment}</Option>
                            <Option value='other' key="other">{locale.email_support_subject_other}</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="Body" name="content">
                        <Input.TextArea rows={4} maxLength={1000} showCount />
                    </Form.Item>
                    <Form.Item className='eagai_email-support-action-buttons'>
                        <Button type="link" onClick={close} label={locale.email_support_cancel} />
                        <Button
                            type="primary"
                            disabled={disabled}
                            onClick={sendEmail }
                            label={locale.email_support_send}
                            loading={loading}
                        />
                    </Form.Item>
                </Form>
            </div>
        </div> : ''
    )
}

export default connect(({ persist: { locale }, common: {emailSupport} }) => ({
    locale,
    emailSupport,
}))(EmailSupport);