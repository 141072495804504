import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Newspaper } from '../Icon';
import './index.scss';

const NewsEmpty = (props) => {
    const { locale, placeholder, size = 'default' } = props;
    return (
        <div
            className={classNames({
                'news-placeholder': true,
                'news-placeholder-tiny': size === 'tiny',
            })}
        >
            <div className="news-placeholder-logo">
                <Newspaper />
            </div>
            <span className="news-placeholder-text">
                {placeholder || locale.no_result}
            </span>
        </div>
    );
};

export default connect(({ persist: { locale } }) => ({
    locale,
}))(NewsEmpty);
