import React, { createContext, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { newsRoutes } from '../../routes/routesMap';

export const NewsContext = createContext({
    period: [],
    newsTypes: [],
    onlyControversial: false,
});

const Router = () => {
    const [period, setPeriod] = useState([]);
    const [newsTypes, setNewsTypes] = useState([]);
    const [onlyControversial, setOnlyControversial] = useState(false);

    return (
        <NewsContext.Provider value={{ period, newsTypes, onlyControversial, setPeriod, setNewsTypes, setOnlyControversial }}>
        <Switch>
            {newsRoutes.map((route) => (
                <Route
                    path={route.path}
                    component={route.component}
                    key={route.path}
                    exact={route.exact}
                    index={route.index}
                />
            ))}
            <Redirect from="/news" to="/news/company" />
        </Switch>
    </NewsContext.Provider>
    )
}

export default Router;