import React from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { AsYouType, getCountryCallingCode } from 'libphonenumber-js/mobile';
import SideMenuLayoutPage from '../../component/layouts/SideMenuLayoutPage';
import './index.scss';
import { profileUrl } from '../../constant/endpoint';
import service from '../../service';
import { commonType } from '../../constant/actionTypes';
import Modal from '../../component/Modal';
import Button from '../../component/Button';
import classNames from 'classnames';
import { ArrowDownFull, Check, Edit } from '../../component/Icon';
import AddContactModal from './component/AddContactModal';

class NotifySettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            settings: {
                scoreEmail: true,
                newsEmail: true,
                newsInApp: true,
                scoreInApp: true,
                scoreMessage: true,
                newsMessage: true,
                scoreNotificationContacts: [],
                newsNotificationContacts: [],
            },
            showModal: false,
            thresholdData: [],
            openIndex: [],
            changedThreshold: [],
            updatingThreshold: false,
            saveSuccess: false,
            addContactType: '', // score, news
        };
        this.timer = null;
    }

    componentDidMount() {
        this.getNotifySettings();
    }

    componentWillUnmount() {
        this.clearTimer();
    }

    formatPhoneNumber = (list) => {
        return list.map((i) => {
            const asYouType = new AsYouType(i.countryCode);
            const prefix = getCountryCallingCode(i.countryCode);
            return {
                ...i,
                phoneNumber: asYouType.input(
                    i.phoneNumber.slice(prefix.length)
                ),
            };
        });
    };

    getNotifySettings = async () => {
        this.toggleGlobalLoading(true);
        try {
            let res = await service.get(profileUrl.NOTIFY_SETTINGS);
            if (res && res.id) {
                if (res.scoreNotificationContacts.length > 0) {
                    res = {
                        ...res,
                        scoreNotificationContacts: this.formatPhoneNumber(
                            res.scoreNotificationContacts
                        ),
                    };
                }
                if (res.newsNotificationContacts.length > 0) {
                    res = {
                        ...res,
                        newsNotificationContacts: this.formatPhoneNumber(
                            res.newsNotificationContacts
                        ),
                    };
                }
                this.setState({
                    settings: res,
                });
            }
        } catch (err) {
            console.log(err);
        }
        this.toggleGlobalLoading(false);
    };

    updateNotifySettings = async (data, contacts, cb) => {
        if (contacts && cb && this.state.settings[Object.keys(data)[0]]) {
            this.setState(
                {
                    settings: {
                        ...this.state.settings,
                        ...contacts,
                    },
                },
                () => {
                    if (typeof cb === 'function') {
                        cb();
                    }
                }
            );
            return;
        }
        if (!contacts && !cb) {
            this.toggleGlobalLoading(true);
        }
        try {
            const res = await service.put(profileUrl.NOTIFY_SETTINGS, data);
            if (res && res.id) {
                this.setState(
                    {
                        settings: {
                            ...this.state.settings,
                            ...res,
                            ...contacts,
                        },
                    },
                    () => {
                        if (typeof cb === 'function') {
                            cb();
                        }
                    }
                );
            }
        } catch (err) {
            console.log(err);
        }
        if (!contacts && !cb) {
            this.toggleGlobalLoading(false);
        }
    };

    getNotifyThreshold = async () => {
        this.toggleGlobalLoading(true);
        try {
            const res = await service.get(profileUrl.NOTIFY_THRESHOLD);
            if (Array.isArray(res)) {
                this.setState(
                    {
                        thresholdData: res,
                    },
                    () => {
                        this.handleShowModal();
                    }
                );
            }
        } catch (err) {
            console.log(err);
        }
        this.toggleGlobalLoading(false);
    };

    updateNotifyThreshold = async () => {
        this.setState({ updatingThreshold: true });
        const { changedThreshold } = this.state;
        try {
            const res = await service.put(
                profileUrl.NOTIFY_THRESHOLD,
                changedThreshold
            );
            if (Array.isArray(res)) {
                this.setState(
                    { updatingThreshold: false, saveSuccess: true },
                    () => {
                        this.clearTimer();
                        this.timer = setTimeout(() => {
                            this.setState({ showModal: false });
                            this.clearTimer();
                        }, 500);
                    }
                );
            }
        } catch (err) {
            console.log(err);
            this.setState({ updatingThreshold: false });
        }
    };

    clearTimer = () => {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
    };

    toggleGlobalLoading = (globalLoading) => {
        this.props.dispatch({
            type: commonType.APPLY_SET_COMMON_STATE,
            payload: {
                globalLoading,
            },
        });
    };

    handleShowModal = () => {
        this.setState({
            showModal: true,
            openIndex: [],
            changedThreshold: [],
            saveSuccess: false,
        });
    };

    updateSettings = (stateKey) => {
        const { settings } = this.state;
        if (
            (stateKey === 'scoreMessage' &&
                (!Array.isArray(settings.scoreNotificationContacts) ||
                    settings.scoreNotificationContacts.length === 0)) ||
            (stateKey === 'newsMessage' &&
                (!Array.isArray(settings.newsNotificationContacts) ||
                    settings.newsNotificationContacts.length === 0))
        ) {
            this.setState({
                addContactType: stateKey === 'scoreMessage' ? 'score' : 'news',
            });
        } else {
            this.updateNotifySettings({
                [stateKey]: !settings[stateKey],
            });
        }
    };

    renderContent = () => {
        const { locale } = this.props;
        const dataArr = [
            {
                key: 'score',
                title: locale.esg_score,
                note: locale.esg_score_notify_settings_note,
                allowNotifications: locale.allow_notifications_weekly,
            },
            // {
            //     key: 'news',
            //     title: locale.controversial_news,
            //     note: locale.news_notify_settings_note,
            //     allowNotifications: locale.allow_notifications_daily,
            // },
        ];
        const settings = [
            {
                key: 'InApp',
                label: locale.in_app,
            },
            {
                key: 'Email',
                label: locale.email,
            },
            {
                key: 'Message',
                label: locale.sms,
            },
        ];
        return dataArr.map((i) => (
            <div key={i.key} className="settings-card">
                <div className="settings-card-main">
                    <h5>{i.title}</h5>
                    <div className="main-content">
                        <div className="main-content-left">
                            <h6>{i.allowNotifications}</h6>
                            <p>{i.note}</p>
                        </div>
                        <div className="main-content-right">
                            {settings.map((j) => {
                                const stateKey = `${i.key}${j.key}`;
                                return (
                                    <div className="setting-item" key={j.key}>
                                        <span className="setting-item-label">
                                            {j.label}
                                        </span>
                                        <span
                                            className={classNames({
                                                switch: true,
                                                'switch-active':
                                                    this.state.settings[
                                                        stateKey
                                                    ],
                                            })}
                                            onClick={() =>
                                                this.updateSettings(stateKey)
                                            }
                                        />
                                    </div>
                                );
                            })}
                            {this.state.settings[`${i.key}NotificationContacts`]
                                .length > 0 && (
                                <div
                                    className={classNames({
                                        'contact-list': true,
                                        'contact-list-disabled':
                                            !this.state.settings[
                                                `${i.key}Message`
                                            ],
                                    })}
                                >
                                    {this.state.settings[
                                        `${i.key}NotificationContacts`
                                    ].map((c, cIndex) => (
                                        <div
                                            key={c.phoneNumber}
                                            className="contact-item"
                                        >
                                            <span>{c.name || '--'}</span>
                                            <span>{c.phoneNumber}</span>
                                            {cIndex === 0 && (
                                                <button
                                                    onClick={() =>
                                                        this.setState({
                                                            addContactType:
                                                                i.key,
                                                        })
                                                    }
                                                >
                                                    <Edit />
                                                </button>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {i.key === 'score' && (
                    <div className="settings-card-main threshold-settings">
                        <div className="main-content">
                            <div className="main-content-left">
                                <h6>{locale.set_threshold}</h6>
                                <p>{locale.set_threshold_note}</p>
                            </div>
                            <div className="main-content-right">
                                <Button
                                    type="secondary"
                                    label={locale.advanced_settings}
                                    onClick={() => this.getNotifyThreshold()}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        ));
    };

    toggleOpen = (isOpen, index) => {
        const { openIndex } = this.state;
        if (isOpen) {
            this.setState({ openIndex: openIndex.filter((i) => i !== index) });
        } else {
            this.setState({
                openIndex: [...openIndex, index],
            });
        }
    };

    getValue = (keyName, threshold) => {
        const { changedThreshold } = this.state;
        const target = changedThreshold.find((i) => i.keyName === keyName);
        let valueStr = '';
        if (target) {
            valueStr = target.threshold;
        } else {
            valueStr = threshold || '0.05';
        }
        const value = Math.round(Number(valueStr) * 100);
        if (!target && !value) {
            return 5;
        }
        return value || '';
    };

    handleThresholdChange = (keyName, value) => {
        const reg = /^[1-9][0-9]*$/;
        if (value && (!reg.test(value) || Number(value) > 100)) {
            return;
        }
        const { changedThreshold } = this.state;
        this.setState({
            changedThreshold: [
                ...changedThreshold.filter((i) => i.keyName !== keyName),
                {
                    keyName,
                    threshold: value ? (Number(value) / 100).toFixed(2) : '',
                },
            ],
        });
    };

    renderThresholdSettings = () => {
        const { thresholdData, openIndex } = this.state;
        return thresholdData.map((i, index) => {
            const isOpen = openIndex.indexOf(index) > -1;
            return (
                <div className="threshold-settings-group" key={i.keyName}>
                    <div
                        className={classNames({
                            'threshold-settings-item threshold-settings-item-pillar': true,
                            'threshold-settings-item-open': isOpen,
                        })}
                    >
                        <input
                            value={this.getValue(
                                i.keyName,
                                i.threshold.threshold
                            )}
                            onChange={(e) =>
                                this.handleThresholdChange(
                                    i.keyName,
                                    e.target.value
                                )
                            }
                        />
                        <span>%</span>
                        <div onClick={() => this.toggleOpen(isOpen, index)}>
                            <ArrowDownFull />
                            <span className="point-title">{i.displayName}</span>
                        </div>
                    </div>
                    {isOpen &&
                        i.children.map((j) => (
                            <div
                                key={j.keyName}
                                className="threshold-settings-item"
                            >
                                <input
                                    value={this.getValue(
                                        j.keyName,
                                        j.threshold.threshold
                                    )}
                                    onChange={(e) =>
                                        this.handleThresholdChange(
                                            j.keyName,
                                            e.target.value
                                        )
                                    }
                                />
                                <span>%</span>
                                <div>
                                    <span className="point-title">
                                        {j.displayName}
                                    </span>
                                </div>
                            </div>
                        ))}
                </div>
            );
        });
    };

    render() {
        const {
            showModal,
            changedThreshold,
            updatingThreshold,
            saveSuccess,
            addContactType,
        } = this.state;
        const { locale } = this.props;
        return (
            <SideMenuLayoutPage>
                <Helmet title={locale.notification_settings} />
                <div className="notify_settings-wrap">
                    <h4 className="notify_settings-title">
                        <span>{locale.notification_settings}</span>
                    </h4>
                    <div className="notify_settings-content scrollbar_hide">
                        {this.renderContent()}
                    </div>
                    {showModal && (
                        <Modal>
                            <div className="notify_settings-settings-modal">
                                <h4>{locale.threshold_percentage}</h4>
                                <div className="pillar-settings">
                                    {this.renderThresholdSettings()}
                                </div>
                                <div
                                    className={classNames({
                                        'notify_settings-settings-modal-actions': true,
                                        'notify_settings-settings-modal-actions-success':
                                            saveSuccess,
                                    })}
                                >
                                    <Button
                                        loading={updatingThreshold}
                                        disabled={
                                            changedThreshold.length > 0 &&
                                            changedThreshold.find(
                                                (i) => !i.threshold
                                            )
                                        }
                                        label={
                                            saveSuccess ? (
                                                <Check
                                                    style={{ fontSize: 24 }}
                                                    color="FFFFFF"
                                                />
                                            ) : updatingThreshold ? (
                                                locale.saving
                                            ) : (
                                                locale.save
                                            )
                                        }
                                        onClick={this.updateNotifyThreshold}
                                    />
                                    <Button
                                        type="link"
                                        label={locale.cancel}
                                        onClick={() =>
                                            this.setState({
                                                showModal: false,
                                            })
                                        }
                                    />
                                </div>
                            </div>
                        </Modal>
                    )}
                </div>
                {addContactType && (
                    <AddContactModal
                        handleCancel={() =>
                            this.setState({ addContactType: '' })
                        }
                        currentContact={
                            this.state.settings[
                                `${addContactType}NotificationContacts`
                            ]
                        }
                        addContactType={addContactType}
                        updateNotifySettings={this.updateNotifySettings}
                        formatPhoneNumber={this.formatPhoneNumber}
                    />
                )}
            </SideMenuLayoutPage>
        );
    }
}

export default withRouter(
    connect(({ persist: { account, locale }, router }) => ({
        account,
        router,
        locale,
    }))(NotifySettings)
);
