import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import dayjs from 'dayjs';
import Modal from '../../../../component/Modal';
import Button from '../../../../component/Button';
import Switch from '../../../../component/Switch';
import './index.scss';
import service from '../../../../service';
import { optimizerUrl } from '../../../../constant/endpoint';
import {
    ArrowDown,
    Business,
    Lock,
    LockOpen,
} from '../../../../component/Icon';
import NumericChart from '../NumericChart';
import { processScore } from '../../../../utils';

class EditMetricModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            targetScore: null,
            targetRawValue: null,
            note: null,
            showDescription: false,
            showCompetitors: false,
            saving: false,
            firstUnit: null,
            secondUnit: null,
            lockedUnit: 'secondUnit',
            tempError: {
                firstUnit: false,
                secondUnit: false,
            },
        };
    }

    componentDidMount() {
        this.initState();
    }

    componentDidUpdate(prevProps, prevState) {
        const { selectedItem } = this.props;
        const { targetRawValue, firstUnit, secondUnit, tempError } = this.state;
        const { targetRawValue: prevTargetRawValue } = prevState;
        if (
            selectedItem.rawValueType === 'NUMERIC' &&
            targetRawValue !== prevTargetRawValue &&
            firstUnit &&
            secondUnit &&
            !Object.values(tempError).find((err) => !!err)
        ) {
            this.updateNumericChildren();
        }
    }

    componentWillUnmount() {
        if (this.props.selectedItem) {
            const body = document.getElementsByTagName('body')[0];
            body.setAttribute('class', '');
        }
    }

    isPercentage = () => {
        const {
            selectedItem: {
                numericUnit: { unit },
            },
        } = this.props;
        return unit === 'Percentage';
    };

    updateNumericChildren = () => {
        const { targetRawValue, lockedUnit } = this.state;
        if (!(parseFloat(targetRawValue) === 0)) {
            const anotherType = this.getAnotherType(lockedUnit);
            const valueN = parseFloat(this.state[lockedUnit]);
            let rawValueN = parseFloat(targetRawValue);
            if (this.isPercentage()) {
                rawValueN = rawValueN / 100;
            }
            const anotherValue =
                lockedUnit === 'firstUnit'
                    ? valueN / rawValueN
                    : valueN * rawValueN;
            if (!Number.isSafeInteger(parseInt(anotherValue))) {
                this.setState({
                    firstUnit: null,
                    secondUnit: null,
                });
            } else {
                this.setState({
                    [anotherType]: Math.round(anotherValue * 100) / 100,
                });
            }
        }
    };

    initTargetRawValue = () => {
        const {
            selectedItem: { targetScore, rawValueType, rawValue },
        } = this.props;
        let targetRawValue = targetScore ? targetScore.rawValue : null;
        if (!targetRawValue && rawValueType === 'NUMERIC') {
            targetRawValue = rawValue;
        }
        return targetRawValue;
    };

    initState = () => {
        const { selectedItem } = this.props;
        const { score, targetScore, metricNote } = selectedItem;
        const { note, firstUnit, secondUnit } = metricNote
            ? metricNote
            : { note: '' };
        const newTargetScore =
            targetScore && typeof targetScore.score === 'number'
                ? targetScore.score
                : typeof score === 'number'
                ? score
                : 0;
        const targetRawValue = this.initTargetRawValue();
        this.setState(
            {
                targetScore: newTargetScore,
                currentScore: score,
                targetRawValue,
                note,
                firstUnit,
                secondUnit,
            },
            () => {
                if (parseFloat(this.state.targetRawValue) === 0) {
                    this.setState({
                        firstUnit: '0',
                        secondUnit: '',
                        lockedUnit: 'firstUnit',
                    });
                }
            }
        );
        const body = document.getElementsByTagName('body')[0];
        body.setAttribute(
            'class',
            'body_disable_scroll body-with-high-zindex-popover'
        );
    };

    handleSave = async () => {
        const {
            boardId,
            selectedItem: { name, rawValueType, id },
            handleUpdateState,
            close,
        } = this.props;
        const {
            note: stateNote,
            targetRawValue: stateTargetRawValue,
            targetScore,
            firstUnit,
            secondUnit,
        } = this.state;
        this.setState({ saving: true });
        try {
            const data = {
                name,
                note: this.processNote(stateNote),
                newRawValue: stateTargetRawValue,
            };
            if (rawValueType !== 'BOOLEAN') {
                if (firstUnit) {
                    data.firstUnit = firstUnit;
                }
                if (secondUnit) {
                    data.secondUnit = secondUnit;
                }
            }
            const resource = await service.put(
                optimizerUrl.METRIC_TARGET_SCORE(boardId),
                data
            );
            handleUpdateState({ resource }, close);

            document.dispatchEvent(new CustomEvent('min_bar_chart_change', {
                detail: {
                  id,
                  targetScore
                }
            }));
        } catch (err) {
            this.setState({ saving: false });
            console.log(err);
        }
    };

    renderNotes = () => {
        const { locale } = this.props;
        const { note } = this.state;
        return (
            <div className="notes group">
                <h5>{locale.notes}</h5>
                <textarea
                    value={note || ''}
                    onChange={(e) => this.setState({ note: e.target.value })}
                />
            </div>
        );
    };

    renderCompetitors = () => {
        const { competitors, locale } = this.props;
        return competitors.map((c) => {
            const { displayScore, displayRawValue } = processScore(
                { ...c, rawValueType: 'BOOLEAN' },
                locale
            );
            return (
                <div key={c.organizationId} className="competitor">
                    <div className="competitor-header competitor-part">
                        <div className="competitor-logo">
                            {c.logo && c.logoUrl ? (
                                <img src={c.logoUrl} alt="Competitor Logo" />
                            ) : (
                                <Business
                                    color="C8C8C9"
                                    style={{ fontSize: 8 }}
                                />
                            )}
                        </div>
                        <span>{c.name}</span>
                    </div>
                    <div className="competitor-score competitor-part">
                        <span>{displayScore}</span>
                        <span>{displayRawValue}</span>
                    </div>
                </div>
            );
        });
    };

    renderBoolContent = () => {
        const { targetRawValue } = this.state;
        const { locale, selectedItem, competitors } = this.props;
        const { polarityType } = selectedItem;
        const { displayScore, displayRawValue } = processScore(
            selectedItem,
            locale
        );
        const hasCompetitors =
            Array.isArray(competitors) && competitors.length > 0;
        return (
            <div className="main-groups bool-group">
                <div className="group current-value-group">
                    <div className="current-value-item">
                        <span>{locale.current_score}</span>
                        <p>{displayScore}</p>
                    </div>
                    <div className="current-value-item">
                        <span>{locale.current_data}</span>
                        <p>{displayRawValue}</p>
                    </div>
                </div>
                <div className="group">
                    <h5>{locale.select_bool_value_title}</h5>
                    {polarityType !== 'POSITIVE' && (
                        <p className="negative-metric-note">
                            {locale.negative_metric_note}
                        </p>
                    )}
                    <Switch
                        options={[
                            {
                                key: 'false',
                                name: locale.no,
                            },
                            {
                                key: 'true',
                                name: locale.yes,
                            },
                        ]}
                        type="radio"
                        value={targetRawValue}
                        onChange={(value) =>
                            this.setState({ targetRawValue: value })
                        }
                    />
                </div>
                {this.renderNotes()}
                {hasCompetitors &&
                    this.renderToggleShowItem(
                        'showCompetitors',
                        locale.competitors,
                        this.renderCompetitors()
                    )}
            </div>
        );
    };

    getUnit = (selectedItem) => {
        const unit =
            selectedItem.numericUnit && selectedItem.numericUnit.unit
                ? selectedItem.numericUnit.unit
                : '';
        return unit ? ` (${unit === 'Percentage' ? '%' : unit})` : '';
    };

    updateModalState = (data, cb) => {
        this.setState(data, () => {
            if (typeof cb === 'function') {
                cb();
            }
        });
    };

    getAnotherType = (type) => {
        return type === 'firstUnit' ? 'secondUnit' : 'firstUnit';
    };

    checkValueValid(value) {
        return !isNaN(parseFloat(value)) &&
        !(
            value[value.length - 1] === '.' &&
            value[value.length - 2] === '.'
        );
    }

    handleNumericChange(value, type) {
        const isValueValid = this.checkValueValid(value);
    
        if (isValueValid && !Number.isSafeInteger(parseInt(value))) {
            return;
        }
        const anotherType = this.getAnotherType(type);
        const reg = /^(-)?\d+(\.\d{1,2})?$/;
        const isValidZero =
            type !== 'firstUnit' && isValueValid && parseFloat(value) === 0;
        const negativeStartWithZero =
            value[0] === '-' && value[1] === '0' && value[2] !== '.';
        const positiveStartWithZero = value[0] === '0' && value[1] !== '.';
        const isTempError =
            value === '-' ||
            (isValueValid && value[value.length - 1] === '.') ||
            isValidZero;
        if (value === '' || isTempError || reg.test(value)) {
            const newState = {
                [type]: !(negativeStartWithZero || positiveStartWithZero)
                    ? value
                    : negativeStartWithZero && parseFloat(value) === 0
                    ? '-0'
                    : parseFloat(value).toString(),
            };


            const { tempError } = this.state;
            if (isTempError) {
                newState.tempError = {
                    ...tempError,
                    [type]: true,
                };
                this.current = new Date().getTime();
                clearTimeout(this.timeout);
            } else {
                if (reg.test(value)) {
                    const valueN = parseFloat(value);
                    const anotherValue = this.state[anotherType];

                    if (((isValidZero && type === 'firstUnit') || isValueValid) && reg.test(anotherValue)) {
                         let targetRawValue = type === 'firstUnit' ? valueN / anotherValue : anotherValue / valueN;

                        if (this.isPercentage()) {
                           targetRawValue = (targetRawValue * 100).toFixed(2);
                        }

                        this.current = new Date().getTime();
                        this.setRowValue(targetRawValue, this.current)
                    }
                }
                if (tempError[type]) {
                    newState.tempError = {
                        ...tempError,
                        [type]: false,
                    };
                }
            }
            this.setState(newState);
        }
    }

    setRowValue(targetRawValue, rightTime) {
        clearTimeout(this.timeout);
        this.setState({ saving: true });
        this.timeout = setTimeout(async () => {
            const newValue = await this.getNearbyRowValue(targetRawValue);
            if (newValue && rightTime === this.current) {
                const { rawValue: newRawValue, score: newScore } = newValue;
                this.setState({
                    targetScore: parseFloat(newScore),
                    targetRawValue: newRawValue,
                }, () => {
                    this.updateNumericChildren();
                })
            }
        }, 1200);
    }

    getNearbyRowValue = async (rawValue) => {
        const {
            boardId,
            selectedItem: { name },
        } = this.props;

        this.setState({ saving: true });

        try {
            const res = await service.get(
                optimizerUrl.METRIC_TARGET_ROW_VALUE(boardId),
                {
                    name,
                    rawValue,
                }
            );
            this.setState({ saving: false });
            if (res) {
                return res;
            }
        } catch (err) {
            console.log(err);
            this.setState({ saving: false });
        }
        return null;
    };

    toggleLockUnit = () => {
        const { lockedUnit } = this.state;
        this.setState({
            lockedUnit: this.getAnotherType(lockedUnit),
        });
    };

    renderNumericChild = (type) => {
        const { lockedUnit, targetRawValue, tempError } = this.state;
        const iconProps = {
            color: '7A7A7A',
            style: { fontSize: 16 },
        };
        const locked = lockedUnit === type;
        const isTargetRawValueValid = !isNaN(parseFloat(targetRawValue));
        return (
            <div className="numeric-children-input-wrap">
                <input
                    disabled={!isTargetRawValueValid || locked}
                    placeholder="--"
                    value={this.state[type] || ''}
                    onChange={(e) =>
                        this.handleNumericChange(e.target.value, type)
                    }
                    className={classNames({
                        'input-temp-error': tempError[type],
                    })}
                />
                <button
                    className="toggle-lock-btn"
                    onClick={this.toggleLockUnit}
                >
                    {locked ? (
                        <Lock {...iconProps} />
                    ) : (
                        <LockOpen {...iconProps} />
                    )}
                </button>
            </div>
        );
    };

    renderNumericContent = () => {
        const {
            locale,
            selectedItem,
            stat,
            logo,
            competitors,
            handleUpdateAllCompetitorsMap,
            allCompetitorsScore,
            boardId,
        } = this.props;
        const { numericUnit } = selectedItem;
        const hasChildren =
            numericUnit && numericUnit.firstUnit && numericUnit.secondUnit;
        const { displayScore, displayRawValue } = processScore(
            selectedItem,
            locale,
            false
        );
        const { targetScore, targetRawValue, currentScore } = this.state;
        const isTargetRawValueValid = !isNaN(parseFloat(targetRawValue));
        return (
            <div className="main-groups numeric-groups">
                <div className="group">
                    <h5>{locale.current}</h5>
                    <div className="current-value-group numeric-current-value-group">
                        <div className="current-value-item">
                            <span>{locale.score}</span>
                            <p>{displayScore}</p>
                        </div>
                        <div className="current-value-item">
                            <span>{locale.raw_data}</span>
                            <p>
                                {displayRawValue}
                                {this.getUnit(selectedItem)}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="group">
                    <h5>{locale.target}</h5>
                    <NumericChart
                        {...{
                            stat,
                            logo,
                            selectedItem,
                            competitors,
                            getUnit: this.getUnit,
                            handleUpdateAllCompetitorsMap,
                            allCompetitorsScore,
                            boardId,
                            targetScore,
                            currentScore,
                            updateModalState: this.updateModalState,
                        }}
                    />
                </div>
                <div className="group numeric-children-group">
                    {hasChildren && (
                        <div className="numeric-children-wrap">
                            <div className="numeric-children-labels">
                                <span>{numericUnit.firstUnit}</span>
                                <span>{numericUnit.secondUnit}</span>
                            </div>
                            <div className="numeric-children-inputs">
                                {this.renderNumericChild('firstUnit')}
                                <span>/</span>
                                {this.renderNumericChild('secondUnit')}
                            </div>
                        </div>
                    )}
                    <div>
                        <div className="numeric-children-labels">
                            <span>{locale.raw_data}</span>
                        </div>
                        <div className="numeric-children-inputs">
                            <input
                                disabled
                                placeholder="--"
                                value={
                                    isTargetRawValueValid
                                        ? parseFloat(targetRawValue).toFixed(2)
                                        : ''
                                }
                            />
                        </div>
                    </div>
                </div>
                {this.renderNotes()}
            </div>
        );
    };

    renderToggleShowItem = (stateKey, title, content) => {
        const show = this.state[stateKey];
        return (
            <div
                className={classNames({
                    'metric-toggle-item': true,
                    'metric-toggle-item-show': show,
                })}
            >
                <div className="toggle-item-header">
                    <h6>{title}</h6>
                    <button
                        onClick={() => this.setState({ [stateKey]: !show })}
                    >
                        <ArrowDown
                            color="4D4D4D"
                            style={{
                                fontSize: 18,
                                transform: `rotate(${show ? 180 : 0}deg)`,
                            }}
                        />
                    </button>
                </div>
                {content}
            </div>
        );
    };

    processNote = (note) => {
        if (typeof note !== 'string') {
            return '';
        }
        return note.trim();
    };

    disableSave = () => {
        const {
            selectedItem: { metricNote, rawValueType },
            disabled,
        } = this.props;
        if (disabled) {
            return true;
        }
        const { note, firstUnit, secondUnit } = metricNote
            ? metricNote
            : { note: '' };
        const targetRawValue = this.initTargetRawValue();
        const {
            note: stateNote,
            targetRawValue: stateTargetRawValue,
            firstUnit: stateFirstUnit,
            secondUnit: stateSecondUnit,
            tempError,
        } = this.state;
        const isNumericChildrenChanges =
            rawValueType === 'NUMERIC' &&
            ((stateFirstUnit !== firstUnit && (stateFirstUnit || firstUnit)) ||
                (stateSecondUnit !== secondUnit &&
                    (stateSecondUnit || secondUnit)));
        if (
            (stateTargetRawValue !== targetRawValue ||
                this.processNote(stateNote) !== this.processNote(note) ||
                isNumericChildrenChanges) &&
            !Object.values(tempError).find((err) => !!err)
        ) {
            return false;
        }
        return true;
    };

    render() {
        const { locale, selectedItem, close } = this.props;
        const { displayName, rawValueType, description } = selectedItem;
        const { saving } = this.state;
        const disabled = this.disableSave();
        return (
            <Modal className="optimizer-metric-edit-modal">
                <h4>{displayName}</h4>
                {
                    selectedItem.firstDropDate && <p className="esgai_modal-content-subtitle">{locale.lastAuditedDate}{dayjs(selectedItem.firstDropDate).format('LL')}</p>
                }
                <div className="modal-main esgai_scrollbar">
                    {this.renderToggleShowItem(
                        'showDescription',
                        locale.description,
                        <p>{description}</p>
                    )}
                    {rawValueType === 'BOOLEAN'
                        ? this.renderBoolContent()
                        : this.renderNumericContent()}
                    {/* <p className="set-target-score-tip">
                        {locale.set_target_score_tip}
                    </p> */}
                </div>
                <div className="modal-actions">
                    <Button
                        loading={saving}
                        label={locale.save}
                        onClick={this.handleSave}
                        disabled={disabled}
                    />
                    <Button
                        type="link"
                        label={locale.cancel}
                        onClick={() => close()}
                    />
                </div>
            </Modal>
        );
    }
}

export default connect(({ persist: { locale } }) => ({
    locale,
}))(EditMetricModal);
