import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { ArrowLeft } from '../../../../component/Icon';
import Button from '../../../../component/Button';
import './index.scss';
import { persistType } from '../../../../constant/actionTypes';
import triangle from '../../../../assets/image/triangle.png';
import metric_main from '../../../../assets/image/metric_main.png';
import metric_target from '../../../../assets/image/metric_target.png';
import overview_current from '../../../../assets/image/overview_current.png';
import overview_target from '../../../../assets/image/overview_target.png';
import earth from '../../../../assets/image/optimizer_earth_bg.png';

class OneTimeTutorialMobile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 0,
        };
    }
    componentDidMount() {
        const body = document.getElementsByTagName('body')[0];
        body.setAttribute('class', 'body_disable_scroll');
    }

    componentWillUnmount() {
        const body = document.getElementsByTagName('body')[0];
        body.removeAttribute('class');
    }

    hideTutorial = () => {
        const { handleUpdateState } = this.props;
        handleUpdateState({
            showOneTimeTutorialMobile: false,
        });
    };

    goToNext = () => {
        const { step } = this.state;
        if (step === 4) {
            this.hideTutorial();
            return;
        } else if (step === 0) {
            const {
                oneTimeNote,
                account: { username },
                dispatch,
            } = this.props;
            const newItem = { ...oneTimeNote[username], optimizerTip: true };
            dispatch({
                type: persistType.APPLY_UPDATE_ONE_TIME_NOTE,
                payload: { ...oneTimeNote, [username]: newItem },
            });
        }
        this.setState({
            step: step + 1,
        });
    };

    goToPrev = () => {
        const { step } = this.state;
        this.setState({
            step: step - 1,
        });
    };

    renderSteps = () => {
        const { locale } = this.props;
        const { step } = this.state;
        const stepArr = [...new Array(5).keys()];
        const stepContent = stepArr.map((i) => ({
            title: i
                ? locale[`optimizer-tutorial-step-${i}-title`]
                : locale.optimizer_tutorial_modal_title,
            description: i
                ? locale[`optimizer-tutorial-step-${i}-description`]
                : locale.optimizer_tutorial_modal_content,
        }));
        const currentContent = stepContent[step];
        return (
            <div
                className={classNames({
                    'step-content': true,
                    [`step-${step}-content`]: true,
                })}
            >
                {this.renderStepImg()}
                <div className="step-text">
                    <h4 className="step-title">{currentContent.title}</h4>
                    <p className="step-description">
                        {currentContent.description}
                    </p>
                </div>
                <div className="step-actions">
                    {step !== 0 && (
                        <div className="step-dot-list">
                            {stepArr.slice(0, 4).map((i) => (
                                <span
                                    key={i}
                                    className={classNames({
                                        'dot-active': step - 1 === i,
                                    })}
                                />
                            ))}
                        </div>
                    )}
                    <Button
                        label={
                            step === 0
                                ? locale.start
                                : step === 4
                                ? locale.done
                                : locale.next
                        }
                        onClick={this.goToNext}
                    />
                </div>
            </div>
        );
    };

    renderStepImg = () => {
        const { step } = this.state;
        const imgs = [
            [earth, overview_target, overview_current],
            [metric_target],
            [overview_target],
            [metric_main],
            [overview_current],
        ];
        return (
            <>
                {imgs[step].map((i, index) => (
                    <img src={i} alt="" key={index} />
                ))}
            </>
        );
    };

    render() {
        const { locale } = this.props;
        const { step } = this.state;
        return (
            <div className="optimizer-one-time-tutorial-mobile">
                <div className="top">
                    <img
                        src={triangle}
                        style={{ width: '100%', opacity: step > 0 ? 1 : 0 }}
                        alt="Triangle background"
                    />
                    {step > 0 && (
                        <div className="tutorial-mobile-header">
                            <div className="header-content">
                                <button onClick={this.goToPrev}>
                                    <ArrowLeft
                                        color="7A7A7A"
                                        style={{ fontSize: 32 }}
                                    />
                                </button>
                                <button onClick={this.hideTutorial}>
                                    {locale.skip_all}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                <div className="bottom">{this.renderSteps()}</div>
            </div>
        );
    }
}

export default withRouter(
    connect(({ persist: { locale, oneTimeNote, account }, router }) => ({
        locale,
        router,
        oneTimeNote,
        account,
    }))(OneTimeTutorialMobile)
);
