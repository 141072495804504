import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import cns from 'classnames';
import { Question } from '../Icon';
import { commonType } from '../../constant/actionTypes';

import './index.scss';


function Help({ locale, dispatch }) {
    const [showHelp, setShowHelp] = useState(false);
    const dropdownRef = React.createRef();

    function toggle() {
        setShowHelp(!showHelp);
    }

    function hide(e) {
        const path = e.path || (e.composedPath && e.composedPath());

        if (path.indexOf(dropdownRef.current) < 0 && showHelp ) {
            setShowHelp(false);
        }
    }

    function openEmailSupport() {
        dispatch({
            type: commonType.APPLY_SET_COMMON_STATE,
            payload: {
                emailSupport: true
            },
        });
    }

    useEffect(() => {
        window.addEventListener('click', hide);

        return function() {
            window.removeEventListener('click', hide);
        }
    })

    return (
        <div ref={dropdownRef} className="esgai_header-help-wrap">
            <div
                className={cns({
                    'question-wrap': true,
                    'question-wrap-active': showHelp,
                })}
                onClick={toggle}
            >
                <Question />
            </div>
            {showHelp && (
                <div className="esgai-dropdown-wrap">
                    <ul>
                        <li><a href="https://docs.esgai.com/" target="_blank" rel="noreferrer">{locale.help_centre}</a></li>
                        <li onClick={openEmailSupport}>{locale.email_support}</li>
                    </ul>
                </div>
            )}
        </div>
    )
}

export default withRouter(
    connect(({ persist: { locale } }) => ({ locale }))(Help)
);
