import React from 'react';
import { connect } from 'react-redux';
import cns from 'classnames';
import './index.scss';
import { scoreChartGroupType } from '../../constant/enum';
import { Check } from '../Icon';

const Switch = (props) => {
    const { locale } = props;
    const {
        options = [
            {
                key: scoreChartGroupType.WEEK,
                name: locale.by_week,
            },
            {
                key: scoreChartGroupType.YEAR,
                name: locale.by_year,
            },
        ],
        valueKey = 'key',
        labelKey = 'name',
        value,
        onChange,
        theme = 'light',
        type = 'switch', // radio, checkbox
        shouldActive,
        additionalChange,
    } = props;

    const selected =
        type !== 'checkbox'
            ? [value]
            : value === null
            ? options.map((i) => i[valueKey])
            : value;

    const handleChange = (i) => {
        if (isActive(i) && type !== 'checkbox') {
            return;
        }
        const targetValue = i[valueKey];
        let newValue = null;
        if (type !== 'checkbox') {
            newValue = targetValue;
        } else {
            if (!!selected.find((s) => s === targetValue)) {
                newValue = selected.filter((s) => s !== targetValue);
            } else {
                newValue = [];
                options.forEach((o) => {
                    if (
                        o[valueKey] === targetValue ||
                        !!selected.find((s) => s === o[valueKey])
                    ) {
                        newValue.push(o[valueKey]);
                    }
                });
            }
        }
        onChange(newValue);
        if (typeof additionalChange === 'function') {
            additionalChange(i, newValue);
        }
    };

    const isActive = (item) => {
        return typeof shouldActive === 'function'
            ? shouldActive(item)
            : selected.find((j) => j === item[valueKey]) !== undefined;
    };

    return (
        <div
            className={cns({
                'esgai_selector-wrap': true,
                'esgai_switch-wrap': type === 'switch',
                'esgai_switch-wrap-dark': type === 'switch' && theme === 'dark',
                'esgai_radio-wrap': type === 'radio',
                'esgai_checkbox-wrap': type === 'checkbox',
            })}
        >
            {options.map((i) => (
                <button
                    key={i[valueKey]}
                    className={cns({
                        item: true,
                        'item-active': isActive(i),
                    })}
                    onClick={() => handleChange(i)}
                    disabled={i.disabled}
                >
                    {type !== 'switch' && (
                        <div
                            className="item-icon"
                            style={
                                type === 'checkbox' && i.activeColor
                                    ? {
                                          border: `1px solid ${
                                              i.disabled
                                                  ? 'rgba(122, 122, 122, 0.5)'
                                                  : i.activeColor
                                          }`,
                                          backgroundColor: isActive(i)
                                              ? i.activeColor
                                              : 'transparent',
                                      }
                                    : {}
                            }
                        >
                            {type === 'checkbox' && isActive(i) && <Check />}
                        </div>
                    )}
                    <span>{i[labelKey]}</span>
                </button>
            ))}
        </div>
    );
};

export default connect(
    ({ common: { globalLoading }, persist: { locale } }) => ({
        globalLoading,
        locale,
    })
)(Switch);
