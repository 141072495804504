import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import persist from './persistReducer';
import common from './commonReducer';
import esg from './esgScoreReducer';

const createRootReducer = (history) =>
    combineReducers({
        router: connectRouter(history),
        persist: persistReducer(
            {
                key: 'persist',
                storage,
                whitelist: [
                    'account',
                    'firebaseUser',
                    'log',
                    'setupProfileLog',
                    'oneTimeNote',
                    'lang',
                ],
            },
            persist
        ),
        common,
        esg,
    });

export default createRootReducer;
