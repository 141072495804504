import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { ArrowDown } from '../Icon';
import './index.scss';

class Pagination extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageGroupIndex: 0,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const { count } = this.props;
        const { count: prevCount } = prevProps;
        if (prevCount !== count) {
            this.setState({
                pageGroupIndex: 0,
            });
        }
    }

    changePageGroup = (gap) => {
        const { pageGroupIndex } = this.state;
        this.setState({
            pageGroupIndex: pageGroupIndex + gap,
        });
    };

    render() {
        const {
            page,
            size = 10,
            totalCount,
            onChange,
            mobileView,
        } = this.props;
        const paginationBase = mobileView ? 5 : 10;
        const { pageGroupIndex } = this.state;
        const totalPage = Math.ceil(totalCount / size);
        const totalPageGroup = Math.ceil(totalPage / paginationBase);
        return totalPage > 1 ? (
            <div className="esgai-pagination-wrap">
                {totalPage > paginationBase && (
                    <button
                        className="page-prev"
                        disabled={pageGroupIndex === 0}
                        onClick={() => this.changePageGroup(-1)}
                    >
                        <ArrowDown />
                    </button>
                )}
                <div className="esgai-pagination-list-container">
                    <div
                        className="esgai-pagination-list"
                        style={{
                            left: (mobileView ? -190 : -480) * pageGroupIndex,
                        }}
                    >
                        {[...new Array(totalPage).keys()].map((i) => (
                            <span
                                key={i}
                                className={classNames({
                                    'pagination-item': true,
                                    'pagination-item-active': page === i,
                                })}
                                onClick={() => onChange(i)}
                            >
                                {i + 1}
                            </span>
                        ))}
                    </div>
                </div>
                {totalPage > paginationBase && (
                    <button
                        className="page-after"
                        disabled={pageGroupIndex === totalPageGroup - 1}
                        onClick={() => this.changePageGroup(1)}
                    >
                        <ArrowDown />
                    </button>
                )}
            </div>
        ) : null;
    }
}

export default connect(({ persist: { locale }, common: { mobileView } }) => ({
    locale,
    mobileView,
}))(Pagination);
