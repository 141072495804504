import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import cns from 'classnames';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import './index.scss';
import { commonType, esgType } from '../../../../constant/actionTypes';
import IndustrySummary from '../IndustrySummary';
import {
    ArrowDownFull,
    ArrowLeft,
    Business,
    Check,
    Plus,
    Reset,
    Drag
} from '../../../../component/Icon';
import CompareData from '../CompareData';
import Popover from '../../../../component/Popover';
import Selector from '../../../../component/Selector';
import AddCompetitorModal from '../AddCompetitorModal';
import SectorRadio from '../../../../component/SectorRatio';
import { pillarColors } from '../../../../constant/enum';

dayjs.locale('en');
dayjs.extend(localizedFormat)

class EsgScoreMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openIndex: [],
            changedCompetitor: null,
            tooltip: {
                // type: 'update', // reset
                // content: '',
            },
            mobileSelectedCompetitor: 'avg',
            showAddCompetitorModal: false,
            tableOverflow: false,
        };
        this.timer = null;
    }

    componentDidMount() {
        const { isIndustryScore } = this.state;
        if (!isIndustryScore) {
            this.tableListener();
            window.addEventListener('resize', this.checkTableSize);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { changedCompetitor, tooltip } = this.state;
        const {
            changedCompetitor: prevChangedCompetitor,
            tooltip: prevTooltip,
        } = prevState;
        if (!prevChangedCompetitor && changedCompetitor) {
            this.updateColumns();
        }
        if (prevTooltip !== tooltip && tooltip.content) {
            this.handleShowTooltip();
        }
        const { data } = this.props;
        const { data: prevData } = prevProps;
        if (!prevData && data) {
            this.initOpenIndex();
        }
    }

    componentWillUnmount() {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }

        this.saveOpenIndex();
        window.removeEventListener('resize', this.checkTableSize);
    }

    saveOpenIndex() {
        const { isIndustryScore, dispatch } = this.props;
        const { openIndex } = this.state;
        const page = document.getElementsByClassName('esg_score_page')[0];

        dispatch({
            type: esgType.APPLY_SET_ESG_STATE,
            payload: {
                openIndex,
                scrollY: page.scrollTop,
                isIndustryScore,
            },
        });
    }

    tableListener = () => {
        this.resizeObserver = new ResizeObserver(() => {
            this.checkTableSize();
        });
        this.resizeObserver.observe(
            document.getElementsByClassName('esg_score-main')[0]
        );
    };

    checkTableSize = () => {
        const pageW =
            document.getElementsByClassName('esg_score_page')[0].offsetWidth;
        const tableW =
            document.getElementsByClassName('esg_score-main')[0].clientWidth;
        const { tableOverflow } = this.state;
        if (tableOverflow && tableW <= pageW) {
            this.setState({ tableOverflow: false });
        } else if (!tableOverflow && tableW > pageW) {
            this.setState({ tableOverflow: true });
        }
    };

    initOpenIndex = () => {
        const {
            data: { breadCrumbs },
            esg,
            mobileView,
            isIndustryScore,
            dispatch,
            paths,
        } = this.props;
        if (esg && breadCrumbs && Object.keys(breadCrumbs).length > 0) {
            if (
                typeof esg.isIndustryScore === 'boolean' &&
                isIndustryScore !== esg.isIndustryScore
            ) {
                dispatch({
                    type: esgType.APPLY_SET_ESG_STATE,
                    payload: {
                        openIndex: null,
                        name: null,
                        scrollY: -1,
                        isNameChanged: false,
                    },
                });
                return;
            }
            const { openIndex, scrollY, isNameChanged } = esg;
            const { pillar, categoryIndex, dataPointIndex } = breadCrumbs;
            const pillarIndex =
                pillar === 'EnvironmentPillarScore'
                    ? 1
                    : pillar === 'SocialPillarScore'
                    ? 2
                    : pillar === 'GovernancePillarScore'
                    ? 3
                    : -1;
            let targetLevel = -1;
            let targetOpenIndex = null;
            if (pillarIndex > -1) {
                targetLevel = 1;
                targetOpenIndex = [pillarIndex * 2];
                if (typeof categoryIndex === 'number') {
                    targetLevel = 2;
                    targetOpenIndex.push(categoryIndex);
                }
                if (typeof dataPointIndex === 'number') {
                    targetLevel = 3;
                    targetOpenIndex.push(dataPointIndex);
                }
            }
            this.setState(
                {
                    openIndex: Array.isArray(openIndex)
                        ? openIndex
                        : targetOpenIndex
                        ? [targetOpenIndex]
                        : [],
                },
                () => {
                    const page =
                        document.getElementsByClassName('esg_score_page')[0];
                    if (scrollY && !isNameChanged) {
                        page.scrollTo({
                            top: scrollY,
                        });
                    } else if (pillarIndex > -1) {
                        let target =
                            document.getElementsByClassName('row-level-1')[
                                pillarIndex
                            ];
                        if (targetLevel > 1) {
                            target =
                                target.getElementsByClassName('row-level-2')[
                                    categoryIndex
                                ];
                        }
                        if (targetLevel > 2) {
                            target =
                                target.getElementsByClassName('row-level-3')[
                                    dataPointIndex
                                ];
                        }
                        target = target.children[0];
                        const bottom = window.innerHeight;
                        const { top: targetTop, bottom: targetBottom } =
                            target.getBoundingClientRect();
                        if (!(targetTop >= 272 && targetBottom <= bottom)) {
                            const targetPosition = mobileView
                                ? 320
                                : 272 + (bottom - 272) / 2;
                            const gap = targetPosition - targetTop;
                            const currentScrollY = page.scrollTop;
                            page.scrollTo({
                                top: currentScrollY - gap,
                            });
                        }
                    }
                }
            );
        } else if (paths[1] === '/esg-score/industry' || paths[1] === '/esg-score/competitor') {
            const { openIndex, scrollY } = esg;

            if (openIndex) {
                this.setState({ openIndex },
                    () => {
                        const page = document.getElementsByClassName('esg_score_page')[0];

                        page.scrollTo({ top: scrollY });
                    }
                )
            }
        } else {
            dispatch({
                type: esgType.APPLY_SET_ESG_STATE,
                payload: {
                    openIndex: null,
                    scrollY: 0,
                    isIndustryScore: false,
                },
            });
        }
    };

    /**
     * Make all row open
     *
     * @returns {Array}
     */
    getAllOpenedIndex() {
        const { data: { firstLevelRow, self } } = this.props;
        const openIndexes = [];

        if (firstLevelRow) {
            firstLevelRow.forEach((row, index) => {
                const secondLevelRow = self.dataMap[row.key] || {};
                const categories = secondLevelRow.categories;

                if (categories && categories.length) {
                    openIndexes.push([index * 2, -1]);
                    categories.forEach((category, secondLevelIndex) => {
                        if (category.dataPoints && category.dataPoints.length > 0) {
                            openIndexes.push([index * 2, secondLevelIndex]);
                        }
                    });
                }

            })
        }
        return openIndexes;
    }

    handleShowTooltip = () => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.setState({
                tooltip: {},
            });
            clearTimeout(this.timer);
            this.timer = null;
        }, 2500);
    };

    renderCompetitorChangedTip = () => {
        const {
            tooltip: { type, content },
        } = this.state;
        if (type && content) {
            return (
                <div className="competitor-update-tip">
                    <div
                        className={cns({
                            'icon-wrap': true,
                            'icon-wrap-reset': type === 'reset',
                        })}
                    >
                        {type === 'update' ? (
                            <Check color="FFFFFF" style={{ fontSize: 10 }} />
                        ) : (
                            <Reset color="233BC9" style={{ fontSize: 18 }} />
                        )}
                    </div>
                    <p className="tip-text">{content}</p>
                </div>
            );
        }
        return null;
    };

    toggleGlobalLoading = (globalLoading) => {
        this.props.dispatch({
            type: commonType.APPLY_SET_COMMON_STATE,
            payload: {
                globalLoading,
            },
        });
    };

    updateColumns = () => {
        const {
            changedCompetitor: {
                type,
                index = -1,
                data = {},
                newCompetitorIds = [],
                fromId,
                id
            },
        } = this.state;
        const { fetchData, locale, mobileView } = this.props;
        const columns = this.getColumns();
        if (type === 'edit' || type === 'add') {
            let ids = [];
            columns.forEach((c, i) => {
                if (i === index) {
                    ids.push({ id: data.organizationId });
                } else {
                    if (i > 1 && !c.isPlaceholder) {
                        ids.push({ id: c.id });
                    }
                }
            });
            const newState = {
                changedCompetitor: null,
                tooltip: { type: 'update', content: locale.changes_saved },
            };
            if (type === 'add') {
                ids = [
                    ...ids,
                    ...newCompetitorIds.map((n) => ({
                        id: n,
                        isNewCompetitor: true,
                    })),
                ];
                if (mobileView) {
                    newState.mobileSelectedCompetitor = ids[ids.length - 1].id;
                }
            }
            fetchData(true, ids, () => {
                this.setState(newState);
            });
        } else if (type === 'delete') {
            const {
                data: propsData,
                updateCompetitors,
            } = this.props;
            const { columnArr } = propsData;
            const newColumnArr = [];
            columnArr.forEach((c, i) => {
                if (i !== index) {
                    newColumnArr.push(c);
                }
            });
            updateCompetitors(
                newColumnArr.slice(2).map((i) => i.id),
                async () => {
                    await fetchData(true, null, () => {
                        this.setState(
                            {
                                changedCompetitor: null,
                                tooltip: {
                                    type: newColumnArr.length === 2 ? 'reset' : 'update',
                                    content: newColumnArr.length === 2 ? locale.competitor_reset : locale.changes_saved,
                                }
                            }
                        );
                    });
                }
            );
        } else if (type === 'reorder') {
            const {
                data: propsData,
                updateCompetitors,
            } = this.props;
            const { columnArr } = propsData;
            const newColumnArr = [...columnArr];
            const arrMap = newColumnArr.reduce((ev, arr, index) => {
                ev[arr.id] = index;
                return ev;
            }, {});
            const fromIndex = arrMap[fromId];
            const toIndex = arrMap[id];

            if (!fromIndex || !toIndex) {
                return;
            }

            const current = newColumnArr[toIndex];

            newColumnArr[toIndex] = newColumnArr[fromIndex];
            newColumnArr[fromIndex] = current;

            updateCompetitors(
                newColumnArr.slice(2).map((i) => i.id),
                async () => {
                    await fetchData(true, null, () => {
                        this.setState(
                            {
                                changedCompetitor: null,
                                tooltip: {
                                    type: 'update',
                                    content: locale.changes_saved,
                                }
                            }
                        );
                    });
                }
            );
        }
    };

    getOrganizationLogo = () => {
        const { account } = this.props;
        const organization = account.organization || {};
        return (organization.logo && organization.logoUrl) || null;
    };

    renderCompetitorLogo = (item) => {
        const { logo, logoUrl } = item;
        return (
            <div className="competitor-logo-wrap">
                {logo && logoUrl ? <img alt="" src={logoUrl} /> : <Business />}
            </div>
        );
    };

    renderDropdownPlaceholder = () => {
        const { locale } = this.props;
        return (
            <div className="competitors-placeholder">
                <span>{locale.competitor_max_watch_title}</span>
            </div>
        );
    };

    renderAddCompetitor = (hideDropdown) => {
        const { locale } = this.props;
        return (
            <div
                className="dropdown_selector-item dropdown_selector-item-add"
                onClick={() => {
                    hideDropdown();
                    this.setState({ showAddCompetitorModal: true });
                }}
            >
                <div className="competitor-logo-wrap">
                    <Plus />
                </div>
                <span>{locale.add_new_competitors}</span>
            </div>
        );
    };

    renderCompanySelector = (data, index, isLastColumn) => {
        const {
            competitorArr,
            data: propsData,
            competitorEditable,
        } = this.props;


        const { competitors = [] } = propsData || {};
        const { changedCompetitor } = this.state;
        const selectedId =
            changedCompetitor &&
            changedCompetitor.index === index &&
            changedCompetitor.data
                ? changedCompetitor.data.organizationId
                : data.isPlaceholder
                ? ''
                : data.id;
        const list = competitorArr.filter(
            (i) =>
                (!data.isPlaceholder && i.organizationId === data.id) ||
                !competitors.find((j) => j.id === i.organizationId)
        );
        const hasMaxNumCompetitors =
            Array.isArray(competitorArr) && competitorArr.length === 10;
        return (
            <div className="competitor-selector-wrap">
                {!isLastColumn && (
                    <>
                        <Drag
                            className={cns({
                                'competitor-drag-btn': true,
                                'competitor-drag-btn-show': competitorEditable,
                            })}
                        />
                        <button
                            className={cns({
                                'competitor-remove-btn': true,
                                'competitor-remove-btn-show': competitorEditable,
                            })}
                            onClick={() => {
                                this.setState({
                                    changedCompetitor: {
                                        type: 'delete',
                                        index,
                                    },
                                });
                            }}
                        />
                    </>
                )}
                <Selector
                    list={list}
                    selected={selectedId}
                    onChange={(newSelected) => {
                        this.setState({
                            changedCompetitor: {
                                type: 'edit',
                                index,
                                data: newSelected,
                            },
                        });
                    }}
                    valueKey="organizationId"
                    labelKey="name"
                    editable={competitorEditable}
                    renderLogo={(item) => this.renderCompetitorLogo(item)}
                    renderAddCompetitor={
                        hasMaxNumCompetitors ? null : this.renderAddCompetitor
                    }
                    renderDropdownPlaceholder={
                        hasMaxNumCompetitors &&
                        (isLastColumn ? list.length === 0 : list.length === 1)
                            ? this.renderDropdownPlaceholder
                            : null
                    }
                    hideSelf
                    showTitleTooltip
                    needAddMask={index > 2}
                    listenToScroll
                />
            </div>
        );
    };

    renderMobileCompetitorSelector = () => {
        const { mobileSelectedCompetitor } = this.state;
        const { competitorArr, locale } = this.props;
        const columns = this.getColumns();
        const list = [
            { organizationId: 'avg', name: locale.competitors_avg },
            ...competitorArr.filter((i) =>
                columns.find((j) => j.id === i.organizationId && !j.myself)
            ),
        ];
        const hasMaxNumCompetitors =
            Array.isArray(competitorArr) && competitorArr.length === 10;
        return (
            <Selector
                list={list}
                selected={mobileSelectedCompetitor}
                onChange={(newSelected) => {
                    this.setState({
                        mobileSelectedCompetitor: newSelected.organizationId,
                    });
                }}
                valueKey="organizationId"
                labelKey="name"
                renderLogo={(item) =>
                    item.organizationId === 'avg'
                        ? null
                        : this.renderCompetitorLogo(item)
                }
                showTitleTooltip
                renderAddCompetitor={
                    hasMaxNumCompetitors ? null : this.renderAddCompetitor
                }
            />
        );
    };

    renderTableHeader = () => {
        const { locale, isIndustryScore, mobileView, competitorEditable } = this.props;
        const { mobileSelectedCompetitor } = this.state;
        const columns = this.getColumns();

        const onDrop = (ev, id) => {
            const fromId = ev.dataTransfer.getData("text/plain");

            if (fromId && id && fromId !== id) {
                this.setState({
                    changedCompetitor: {
                        type: 'reorder',
                        fromId,
                        id
                    },
                });
            }
        }

        const onDragStart = (ev, id) => {
            ev.dataTransfer.setData("text/plain", id);
            ev.dataTransfer.effectAllowed = "move";
            ev.currentTarget.style = 'opacity: 0.6;';
            this.setState({
                currentDragTarget: ev.currentTarget
            })
            this.page = document.querySelector('.esg_score_page');
            this.pageScrollTop = this.page.scrollTop;
            this.edgeRight = document.body.clientWidth / 10 + 20;
            this.edgeLeft = document.body.clientWidth / 10 + 705;
            this.maxScroll = this.page.scrollWidth - this.page.clientWidth;
            this.inScroll = false;
        }

        const onDragOver =  (ev) => {
            ev.preventDefault();
            ev.dataTransfer.dropEffect = "move";

            const { currentDragTarget } = this.state;

            if (currentDragTarget !== ev.currentTarget) {
                ev.currentTarget.style = 'border: 1px #e0e0e0 dashed; border-radius: 3px;';
            }
        }

        const onDragLeave = (ev) => {
            ev.currentTarget.style = '';
        }

        const onDragEnd = (ev) => {
            [...ev.currentTarget.parentNode.children].forEach(child => {
                child.style = '';
            })

            this.setState({
                currentDragTarget: null,
                isDragging: false
            })
        }

        const onDrag = (ev) => {
            if (!this.inScroll) {
                this.inScroll = true;
                setTimeout(() => {
                    this.inScroll = false;
                    if (document.body.clientWidth - ev.clientX < this.edgeRight) {
                        this.page.scrollTo({
                            top: this.pageScrollTop,
                            left: this.page.scrollLeft + 30 > this.maxScroll ? this.maxScroll : this.page.scrollLeft + 30,
                            behavior: 'smooth'
                        })
                    }
        
                    if (ev.clientX < this.edgeLeft) {
                        this.page.scrollTo({
                            top: this.pageScrollTop,
                            left: this.page.scrollLeft - 30 > 0 ? this.page.scrollLeft - 30 : 0,
                            behavior: 'smooth'
                        })
                    }
                }, 10);
            }
        }
        return (
            <div
                className={cns({
                    'table-header': true,
                    'mobile-table-header': mobileView
                })}
            >
                {Array.isArray(columns) && (
                    <div className="row header-row">
                        <div className="row-content">
                            <div className="col-wrap">
                                <div className="col" />
                                {columns.map((column, column_index) => {
                                    return (
                                        <div
                                            className="col"
                                            key={column_index}
                                            {...(column_index > 1  && competitorEditable ? {
                                                draggable: true,
                                                onDrag,
                                                onDrop: e => onDrop(e, column.id),
                                                onDragStart: e => onDragStart(e, column.id),
                                                onDragOver,
                                                onDragLeave,
                                                onDragEnd
                                            } : {})}
                                        >
                                            <div className="col-content">
                                                {column_index === 0 &&
                                                    (this.getOrganizationLogo() ? (
                                                        <img
                                                            className="logo-wrap"
                                                            src={this.getOrganizationLogo()}
                                                            alt=""
                                                        />
                                                    ) : (
                                                        <div className="logo-wrap">
                                                            <Business
                                                                color="49A09D"
                                                                style={{
                                                                    fontSize: 16,
                                                                }}
                                                            />
                                                        </div>
                                                    ))}
                                                {column_index === 1 && (
                                                    <div className="col-content-container">
                                                        <span className="header-between">
                                                            {isIndustryScore
                                                                ? locale.between
                                                                : locale.vs}
                                                        </span>
                                                        <span className="avg-title">
                                                            {isIndustryScore
                                                                ? locale.industry_avg
                                                                : mobileView
                                                                ? this.renderMobileCompetitorSelector()
                                                                : locale.competitors_avg}
                                                        </span>
                                                    </div>
                                                )}
                                                {column_index > 1 &&
                                                    this.renderCompanySelector(
                                                        column,
                                                        column_index,
                                                        column_index ===
                                                            (columns.length ===
                                                                12 &&
                                                            !columns[11]
                                                                .isPlaceholder
                                                                ? 12
                                                                : columns.length -
                                                                  1)
                                                    )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="col-wrap">
                                <div className="col" />
                                {columns.map((column, column_index) => {
                                    const currentColumn = columns.find(column => column.id === mobileSelectedCompetitor) || {};
                                    const isShowMobileDate = column_index === 1 && !isIndustryScore && mobileView && currentColumn.firstDropDate;
            
                                    return (
                                        <div className="col" key={column_index}>
                                            <div className={cns({
                                                    'col-content': true,
                                                    'mobile-date': isShowMobileDate
                                                })}>
                                                {
                                                    isShowMobileDate && <p className="col-content-date">{dayjs(currentColumn.firstDropDate).format('LL')}</p>
                                                }
                                                {
                                                    column.firstDropDate &&
                                                    column.id !== 'avg' &&
                                                    (column_index === 0 || column.id !== columns[0].id) &&
                                                    <p className="col-content-date">{dayjs(column.firstDropDate).format('LL')}</p>}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    renderRowTitle = (displayName, name, rootName, weight) => {
        const { mobileView } = this.props;

        return (
            <span className="table-row-title-wrapper">
                <span>
                    <span
                        className="table-row-title"
                        onClick={() => this.goToDataPointPage(name)}
                    >
                        {displayName}
                        <ArrowLeft />
                    </span>
                </span>
                {
                    rootName && weight && pillarColors[rootName] && <span className="table-row-title-weight">
                        <SectorRadio
                            sectorColor={pillarColors[rootName]}
                            weight={weight}
                            diameter={mobileView ? 18 : 28}
                        />
                        { weight }
                    </span>
                }
            </span>
        );
    };

    renderTableBody = () => {
        const { data, locale } = this.props;
        const { firstLevelRow, self, noAvg } = data || {};
        const columns = this.getColumns(true);
        if (!data || !Array.isArray(columns)) {
            return null;
        }
        const list = [];
        firstLevelRow.forEach((r) => {
            list.push(r, null);
        });
        return (
            <div className="table-body">
                {list.map((level_1_row, level_1_row_index) => {
                    const { openIndex } = this.state;
                    const isShow = !!openIndex.find(
                        (i) => i[0] === level_1_row_index
                    );
                    let hasSubRow = false;
                    let itemWeight = null;
                    if (level_1_row) {
                        const { categories, weight } =
                            self.dataMap[level_1_row.key] || {};
                        hasSubRow =
                            Array.isArray(categories) && categories.length > 0;
                        itemWeight = weight;
                    }
                    const rowTitle = level_1_row ? (
                        <>
                            {level_1_row_index > 0 && (
                                <ArrowDownFull
                                    style={{
                                        fontSize: 24,
                                        visibility: hasSubRow
                                            ? 'visible'
                                            : 'hidden',
                                    }}
                                />
                            )}
                            {this.renderRowTitle(
                                level_1_row.name,
                                level_1_row.key,
                                level_1_row.key,
                                itemWeight
                            )}
                        </>
                    ) : null;
                    return (
                        <div
                            className={cns({
                                row: true,
                                'row-level-1': !!level_1_row,
                                'row-placeholder': !level_1_row,
                                'row-level-1-open': isShow,
                            })}
                            key={level_1_row_index}
                        >
                            <div
                                className="row-content"
                                onClick={() =>
                                    this.toggleOpenFirstLevelRow(
                                        level_1_row_index,
                                        hasSubRow
                                    )
                                }
                            >
                                <div className="col-wrap">
                                    <div className="col">
                                        {level_1_row && (
                                            <div className="col-content">
                                                {rowTitle}
                                            </div>
                                        )}
                                    </div>
                                    {columns.map((column, column_index) => {
                                        if (!level_1_row) {
                                            return this.renderColumns(
                                                null,
                                                null,
                                                null,
                                                column_index
                                            );
                                        }
                                        const c =
                                            column.dataMap[level_1_row.key];
                                        const selfRow =
                                            self.dataMap[level_1_row.key];
                                        let valueBtnLabel = '';
                                        if (
                                            level_1_row_index === 0 &&
                                            column_index === 2 &&
                                            noAvg
                                        ) {
                                            valueBtnLabel =
                                                locale.add_competitors;
                                        }
                                        const needCheckColumn =
                                            level_1_row_index === 0;
                                        return this.renderColumns(
                                            rowTitle,
                                            c,
                                            selfRow,
                                            column_index,
                                            valueBtnLabel,
                                            needCheckColumn
                                        );
                                    })}
                                </div>
                            </div>
                            {level_1_row &&
                                level_1_row_index > 0 &&
                                this.renderLevel2Row(
                                    level_1_row.key,
                                    level_1_row_index
                                )}
                        </div>
                    );
                })}
            </div>
        );
    };

    renderLevel2Row = (rowKey, rowIndex) => {
        const {
            data: { self },
        } = this.props;
        const columns = this.getColumns(true);
        const secLevelRow = self.dataMap[rowKey].categories;
        const { openIndex } = this.state;
        return (
            <div className="row-level-2-wrap">
                {secLevelRow.map((level_2_row, level_2_row_index) => {
                    const isLevel2Open = !!openIndex.find(
                        (i) => i[0] === rowIndex && i[1] === level_2_row_index
                    );
                    const { dataPoints, weight } = secLevelRow[level_2_row_index] || {};
                    const hasSubRow =
                        Array.isArray(dataPoints) && dataPoints.length > 0;
                    const rowTitle = (
                        <>
                            <ArrowDownFull
                                style={{
                                    fontSize: 24,
                                    visibility: hasSubRow
                                        ? 'visible'
                                        : 'hidden',
                                }}
                            />
                            {this.renderRowTitle(
                                level_2_row.displayName,
                                level_2_row.name,
                                rowKey,
                                weight
                            )}
                        </>
                    );
                    return (
                        <div
                            className={cns({
                                'row row-level-2': true,
                                'row-level-2-open': isLevel2Open,
                            })}
                            key={level_2_row.displayName}
                        >
                            <div
                                className="row-content"
                                onClick={() =>
                                    this.toggleOpenSecLevelRow(
                                        rowIndex,
                                        level_2_row_index,
                                        hasSubRow
                                    )
                                }
                            >
                                <div className="col-wrap">
                                    <div className="col row-title">
                                        <div className="col-content">
                                            {rowTitle}
                                        </div>
                                    </div>
                                    {columns.map((column, column_index) => {
                                        const c =
                                            column.dataMap[level_2_row.pillar]
                                                .categories[level_2_row_index];
                                        const selfRow =
                                            self.dataMap[level_2_row.pillar]
                                                .categories[level_2_row_index];
                                        return this.renderColumns(
                                            rowTitle,
                                            c,
                                            selfRow,
                                            column_index
                                        );
                                    })}
                                </div>
                            </div>
                            {isLevel2Open &&
                                this.renderLevel3Row(rowKey, level_2_row_index)}
                        </div>
                    );
                })}
            </div>
        );
    };

    renderLevel3Row = (rowKey, rowIndex) => {
        const {
            data: { self },
        } = this.props;
        const columns = this.getColumns(true);
        const thirdLevelRow =
            self.dataMap[rowKey].categories[rowIndex].dataPoints;
        return (
            <div className="row-level-3-wrap">
                {thirdLevelRow.map((level_3_row, level_3_row_index) => {
                    const rowTitle = this.renderRowTitle(
                        level_3_row.displayName,
                        level_3_row.name
                    );
                    return (
                        <div
                            className="row row-level-3"
                            key={level_3_row.displayName}
                        >
                            <div
                                className="row-content"
                                onClick={() => {
                                    this.goToDataPointPage(level_3_row.name);
                                }}
                            >
                                <div className="col-wrap">
                                    <div className="col row-title">
                                        <div className="col-content">
                                            <Popover
                                                tooltip={
                                                    level_3_row.description
                                                }
                                                hideOnMobile
                                            >
                                                {rowTitle}
                                            </Popover>
                                        </div>
                                    </div>
                                    {columns.map((column, column_index) => {
                                        const c =
                                            column.dataMap[rowKey].categories[
                                                rowIndex
                                            ].dataPoints[level_3_row_index];
                                        const selfRow =
                                            self.dataMap[rowKey].categories[
                                                rowIndex
                                            ].dataPoints[level_3_row_index];
                                        return this.renderColumns(
                                            rowTitle,
                                            c,
                                            selfRow,
                                            column_index
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    renderColumns = (
        title,
        c,
        selfRow,
        column_index,
        valueBtnLabel,
        needCheckColumn = false
    ) => {
        const {
            data: { noAvg, noCompetitors },
            isIndustryScore,
            hasCompetitor
        } = this.props;
        const columns = this.getColumns(true);
        return (
            <div
                className={cns({
                    col: true,
                    'col-no-avg': noAvg && column_index === 1,
                })}
                key={column_index}
            >
                {c && (
                    <div
                        className={cns({
                            'col-content': true,
                            'col-content-empty':
                                !c.scoreValue ||
                                !c.scoreValue.displayValue ||
                                (noAvg && column_index === 1) ||
                                (noCompetitors && column_index > 1),
                        })}
                    >
                        {valueBtnLabel && !hasCompetitor ? (
                            <button
                                className="redirect-btn"
                                onClick={() =>
                                    this.props.history.push(
                                        '/settings/competitor'
                                    )
                                }
                            >
                                {valueBtnLabel}
                            </button>
                        ) : (
                            <>
                                {title && column_index === 1 && (
                                    <p className="row-title-mobile">{title}</p>
                                )}
                                <div className="col-content-container">
                                    {column_index === 1 && (
                                        <div className="compare-data-wrap">
                                            {selfRow?.scoreValue?.displayValue ? (
                                                    <CompareData
                                                        competitorData={
                                                            this.isMobileCompetitorColumn()
                                                                ? c
                                                                : null
                                                        }
                                                        data={selfRow}
                                                        showPercent={
                                                            isIndustryScore
                                                        }
                                                    />
                                                ) : '--'}
                                        </div>
                                    )}
                                    <div className="score-wrap">
                                        {c.scoreValue &&
                                        c.scoreValue.displayValue &&
                                        !(noAvg && column_index === 1) &&
                                        !columns[column_index].isPlaceholder ? (
                                            <>
                                                <span
                                                    className={this.getDisplayClass(
                                                        c,
                                                        column_index,
                                                        'score-value'
                                                    )}
                                                >
                                                    {c.scoreValue.displayValue}
                                                </span>
                                                <span
                                                    className={this.getDisplayClass(
                                                        c,
                                                        column_index,
                                                        'score-grade'
                                                    )}
                                                >
                                                    {c.scoreValue.grade}
                                                </span>
                                            </>
                                        ) : (
                                            <span className="no-wrap-text">
                                                {!columns[column_index]
                                                    .isPlaceholder &&
                                                    (needCheckColumn || !c.isIndustry ? 'N/A' : '--')}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                )}
            </div>
        );
    };

    isMobileCompetitorColumn = () => {
        const { mobileView, isIndustryScore } = this.props;
        const { mobileSelectedCompetitor } = this.state;
        return (
            mobileView && !isIndustryScore && mobileSelectedCompetitor !== 'avg'
        );
    };

    getColumns = (render = false) => {
        const { data, competitorEditable } = this.props;
        const { mobileSelectedCompetitor } = this.state;
        const { noCompetitors, self, columnArr } = data || {};
        const columns =
            competitorEditable && !noCompetitors && columnArr.length < 12
                ? [...columnArr, { ...self, isPlaceholder: true }]
                : columnArr;
        if (render && this.isMobileCompetitorColumn()) {
            const target = columnArr.find(
                (i) =>
                    i.id === mobileSelectedCompetitor &&
                    !i.isPlaceholder &&
                    !i.myself
            );
            const newColumnArr = [...columnArr];
            newColumnArr[1] = target;
            return newColumnArr;
        }
        return columns;
    };

    toggleOpenFirstLevelRow = (rowIndex, hasSubRow) => {
        if (rowIndex === 0 || !hasSubRow) {
            return;
        }
        const { openIndex } = this.state;
        if (!openIndex.find((i) => i[0] === rowIndex)) {
            this.setState({
                openIndex: [...openIndex, [rowIndex, -1]],
            });
        } else {
            this.setState({
                openIndex: openIndex.filter((i) => i[0] !== rowIndex),
            });
        }
    };

    toggleOpenSecLevelRow = (rowIndex, level_2_row_index, hasSubRow) => {
        if (!hasSubRow) {
            return;
        }
        const { openIndex } = this.state;
        if (
            !openIndex.find(
                (i) => i[0] === rowIndex && i[1] === level_2_row_index
            )
        ) {
            this.setState({
                openIndex: [...openIndex, [rowIndex, level_2_row_index]],
            });
        } else {
            this.setState({
                openIndex: openIndex.filter(
                    (i) => !(i[0] === rowIndex && i[1] === level_2_row_index)
                ),
            });
        }
    };

    goToDataPointPage = (name) => {
        const { isIndustryScore, dispatch } = this.props;
        const { openIndex } = this.state;
        const page = document.getElementsByClassName('esg_score_page')[0];
        dispatch({
            type: esgType.APPLY_SET_ESG_STATE,
            payload: {
                openIndex,
                name,
                scrollY: page.scrollTop,
                isIndustryScore,
            },
        });
        this.props.history.push(
            `/esg-score/${
                isIndustryScore ? 'industry' : 'competitor'
            }/component?name=${name}`
        );
    };

    getDisplayClass = (c, column_index, defaultClass) => {
        const needHighLight =
            this.isMobileCompetitorColumn() || column_index > 1;
        return cns({
            [defaultClass]: true,
            'score-display-green':
                needHighLight && c.scoreValue.compareMyself > 0,
            'score-display-red':
                needHighLight && c.scoreValue.compareMyself < 0,
        });
    };

    renderMobileMenu = () => {
        const {
            locale,
            router: {
                location: { pathname },
            },
            history,
        } = this.props;
        const menus = [
            {
                path: '/esg-score/industry',
                title: locale.industry,
            },
            {
                path: '/esg-score/competitor',
                title: locale.competitors,
            },
        ];
        return (
            <div className="mobile-score-menu">
                <div className="menu-wrap">
                    {menus.map((m) => (
                        <button
                            key={m.path}
                            className={cns({
                                'menu-item': true,
                                'menu-item-active': pathname === m.path,
                            })}
                            onClick={() => history.push(m.path)}
                        >
                            {m.title}
                        </button>
                    ))}
                </div>
            </div>
        );
    };

    render() {
        const { isIndustryScore, data, mobileView, competitorArr } = this.props;
        const { openIndex, showAddCompetitorModal, tableOverflow } = this.state;
        return (
            <div
                className={cns({
                    'esg_score-main': true,
                    'esg_score-main-industry': isIndustryScore,
                })}
            >
                {mobileView && this.renderMobileMenu()}
                <div
                    className={cns({
                        'main-container': true,
                        'main-container-with-scroll-flag':
                            !mobileView && tableOverflow,
                    })}
                >
                    <div
                        className={cns({
                            'main-table-wrap esgai_scrollbar': true,
                            'main-table-wrap-competitors': !isIndustryScore,
                        })}
                    >
                        <div className="main-table">
                            {this.renderTableHeader()}
                            {this.renderTableBody()}
                        </div>
                    </div>
                    <IndustrySummary
                        isIndustryScore={isIndustryScore}
                        logo={this.getOrganizationLogo()}
                        info={(data && data.rank) || {}}
                        rows={(data && data.firstLevelRow) || []}
                        openIndex={openIndex}
                        updateParentState={(newState, cb) =>
                            this.setState(newState, cb)
                        }
                    />
                </div>
                {!isIndustryScore && this.renderCompetitorChangedTip()}
                {showAddCompetitorModal && (
                    <AddCompetitorModal
                        handleCancel={() =>
                            this.setState({ showAddCompetitorModal: false })
                        }
                        handleAdd={(newCompetitors) =>
                            this.setState({
                                showAddCompetitorModal: false,
                                changedCompetitor: {
                                    type: 'add',
                                    newCompetitorIds: newCompetitors.map(
                                        (i) => i.id
                                    ),
                                },
                            })
                        }
                        competitorArr={competitorArr || []}
                    />
                )}
            </div>
        );
    }
}

export default withRouter(
    connect(
        ({
            persist: { account, locale, paths },
            router,
            common: { mobileView },
            esg,
        }) => ({
            account,
            locale,
            router,
            mobileView,
            esg,
            paths
        })
    )(EsgScoreMain)
);
