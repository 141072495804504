import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import cns from 'classnames';
import './index.scss';
import logoMobile from '../../../assets/image/esgailogo_black.png';
import logo from '../../../assets/image/logo_2022_11.png';
import poweredByImg from '../../../assets/image/powered-by.png';
import {
    ArrowDown,
    Star,
    Industry,
    Competitor,
    Partner,
    Business,
    Menu,
    Close,
    ArrowDownFull,
} from '../../../component/Icon';
import { persistType } from '../../../constant/actionTypes';
import Notify from '../../Notify';
import Help from '../../Help';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openIndex: -1,
        };
        this.dropdownRef = React.createRef();
        this.mobileMenuRef = React.createRef();
        this.mobileMenuOpenRef = React.createRef();
    }

    componentDidMount() {
        this.addHeaderListener();
        window.addEventListener('click', this.tryHidePopup);
    }

    componentWillUnmount() {
        this.resizeObserver.disconnect();
        window.removeEventListener('click', this.tryHidePopup);
    }

    addHeaderListener = () => {
        this.resizeObserver = new ResizeObserver(() => {
            const { width } = document
                .getElementsByClassName('esgai_header')[0]
                .getBoundingClientRect();
            const gap = window.innerWidth - width;
            document.documentElement.style.setProperty(
                '--scroll_bar',
                `${gap}px`
            );
        });
        this.resizeObserver.observe(
            document.getElementsByClassName('esgai_header')[0]
        );
    };

    tryHidePopup = (e) => {
        const { showHeaderDropdown, showMobileMenu, updateState } = this.props;
        const path = e.path || (e.composedPath && e.composedPath());
        if (path.indexOf(this.dropdownRef.current) < 0 && showHeaderDropdown) {
            updateState('showHeaderDropdown', false);
        }
        if (
            path.indexOf(this.mobileMenuRef.current) < 0 &&
            path.indexOf(this.mobileMenuOpenRef.current) < 0 &&
            showMobileMenu
        ) {
            updateState('showMobileMenu', false);
        }
    };

    toggleDropDown = () => {
        const { showHeaderDropdown, updateState } = this.props;
        updateState('showHeaderDropdown', !showHeaderDropdown);
    };

    toggleMobileMenu = () => {
        const { showMobileMenu, updateState } = this.props;
        updateState('showMobileMenu', !showMobileMenu);
    };

    matchRoute = (path) => {
        const {
            router: {
                location: { pathname },
            },
        } = this.props;
        return (
            pathname.slice(1).split('/').indexOf(path.slice(1).split('/')[0]) >
            -1
        );
    };

    goToSettings = () => {
        const { updateState, history } = this.props;
        updateState('showHeaderDropdown', false, () => {
            history.push('/settings/profile-info');
        });
    };

    goToPage = (path) => {
        const { updateState, history } = this.props;
        updateState('showMobileMenu', false, () => {
            if (path) {
                history.push(path);
            }
        });
    };

    logout = () => {
        const { updateState, dispatch } = this.props;
        updateState('showHeaderDropdown', false, () => {
            dispatch({
                type: persistType.LOGOUT,
            });
        });
    };

    getTopMenus = () => {
        const { locale } = this.props;
        return [
            { title: locale.dashboard, path: '/dashboard' },
            // {
            //     title: locale.news,
            //     path: '/news/company',
            //     children: this.getNewsMenus(),
            //     showInMobileMenu: true,
            // },
            {
                title: locale.esg_score,
                path: '/esg-score/industry',
                children: this.getEsgScoreMenus(),
                showInMobileMenu: false,
            },
            {
                title: locale.optimizer,
                path: '/optimizer/my-board',
            },
        ];
    };

    getNewsMenus = () => {
        const { locale } = this.props;
        return [
            {
                path: '/news/company',
                title: locale.company,
                icon: <Star className="esgai-icon-special" />,
            },
            {
                path: '/news/industry',
                title: locale.industry,
                icon: <Industry className="esgai-icon-special" />,
            },
            {
                path: '/news/competitor',
                title: locale.competitors,
                icon: <Competitor />,
            },
            {
                path: '/news/partner',
                title: locale.partners,
                icon: <Partner />,
            },
            // {
            //     path: '/news/search',
            //     title: locale.search_news,
            //     icon: <Newspaper />,
            // },
        ];
    };

    getEsgScoreMenus = () => {
        const { locale } = this.props;
        return [
            {
                path: '/esg-score/industry',
                title: locale.industry,
                icon: <Industry className="esgai-icon-special" />,
            },
            {
                path: '/esg-score/competitor',
                title: locale.competitors,
                icon: <Competitor />,
            },
        ];
    };

    renderSubHeader = () => {
        const {
            router: {
                location: { pathname },
            },
            history,
        } = this.props;
        const topRoute = pathname.slice(1).split('/')[0];
        const targetRoute = this.getTopMenus().find(
            (i) => i.path.slice(1).split('/')[0] === topRoute
        );
        if (!targetRoute || !Array.isArray(targetRoute.children)) {
            return null;
        }
        return (
            <div className="esgai_sub_menu">
                {targetRoute.children.map((i) => (
                    <div
                        className={cns({
                            'sub_menu-item': true,
                            'sub_menu-item-active':
                                pathname.indexOf(i.path) > -1,
                        })}
                        key={i.path}
                        onClick={() => history.push(i.path)}
                    >
                        {i.icon}
                        <span className="sub_menu-item-title">{i.title}</span>
                    </div>
                ))}
            </div>
        );
    };

    renderMobileMenu = () => {
        const { openIndex } = this.state;
        const { showMobileMenu } = this.props;
        const version = process.env.REACT_APP_BUILD_VERSION;
        return (
            <div
                className="mobile-menu-wrap"
                style={{ left: showMobileMenu ? 0 : -350 }}
                ref={this.mobileMenuRef}
            >
                <button
                    className="mobile-menu-close-btn"
                    onClick={() => this.goToPage()}
                >
                    <Close color="111111" style={{ fontSize: 25 }} />
                </button>
                <div className="mobile-menu-list esgai_scrollbar">
                    <div>
                        {this.getTopMenus().map((i, index) => {
                            const hasSubMenu =
                                Array.isArray(i.children) && i.showInMobileMenu;
                            const isActive = openIndex === index;
                            return (
                                <div
                                    className="mobile-menu-item-wrap"
                                    key={i.path}
                                    style={{
                                        height: isActive
                                            ? (i.children.length + 1) * 58
                                            : 58,
                                    }}
                                >
                                    <div
                                        className="mobile-menu-item"
                                        onClick={() => {
                                            if (hasSubMenu) {
                                                this.setState({
                                                    openIndex: isActive
                                                        ? -1
                                                        : index,
                                                });
                                            } else {
                                                this.goToPage(i.path);
                                            }
                                        }}
                                    >
                                        <span>{i.title}</span>
                                        {hasSubMenu && (
                                            <span
                                                className={cns({
                                                    'icon-wrap': true,
                                                    'icon-wrap-active':
                                                        isActive,
                                                })}
                                            >
                                                <ArrowDownFull
                                                    color="111111"
                                                    style={{ fontSize: 24 }}
                                                />
                                            </span>
                                        )}
                                    </div>
                                    {hasSubMenu &&
                                        i.children.map((j) => (
                                            <div
                                                key={j.path}
                                                className="mobile-menu-sub-item"
                                                onClick={() =>
                                                    this.goToPage(j.path)
                                                }
                                            >
                                                {j.title}
                                            </div>
                                        ))}
                                </div>
                            );
                        })}
                    </div>
                    {typeof version === 'string' && (
                        <p className="version">{version}</p>
                    )}
                </div>
            </div>
        );
    };

    render() {
        const { account, locale, showHeaderDropdown, history } = this.props;
        const organization = account.organization || {};
        if (!organization.name) {
            organization.name = 'No Organization';
        }
        const topMenus = this.getTopMenus();

        return (
            <>
                <div className="esgai_header">
                    <div className="esgai_header-content">
                        <button
                            className="mobile-menu-btn"
                            onClick={this.toggleMobileMenu}
                            ref={this.mobileMenuOpenRef}
                        >
                            <Menu color="4D4D4D" style={{ fontSize: 48 }} />
                        </button>
                        <button
                            className="esgai_header-logo-wrap"
                            onClick={() => history.push('/dashboard')}
                        >
                            <img
                                className="esgai_header-logo esgai_header-logo-mobile"
                                src={logoMobile}
                                alt="ESG AI Logo"
                            />
                            <img
                                className="esgai_header-logo esgai_header-logo-pc"
                                src={logo}
                                alt="ESG AI Logo"
                            />
                            <div className="esgai_header-logo-devider"></div>
                            <img
                                className="esgai_header-poweredby"
                                src={poweredByImg}
                                alt="ESG AI Logo"
                            />
                        </button>
                        <div className="esgai_header-right">
                            <div
                                ref={this.dropdownRef}
                                className="esgai_header-organization-wrap"
                            >
                                <div
                                    className="esgai_header-organization"
                                    onClick={this.toggleDropDown}
                                >
                                    <div className="esgai_header-organization-logo-wrap">
                                        {organization.logo &&
                                        organization.logoUrl ? (
                                            <img
                                                className="esgai_header-organization-logo"
                                                src={organization.logoUrl}
                                                alt="Organization Logo"
                                            />
                                        ) : (
                                            <Business
                                                color="C8C8C9"
                                                style={{ fontSize: 18 }}
                                            />
                                        )}
                                    </div>
                                    <span
                                        className="esgai_header-organization-name"
                                        title={organization.name}
                                    >
                                        {organization.name}
                                    </span>
                                    <span
                                        className="esgai_header-organization-arrow"
                                        style={{
                                            transform: `rotate(${
                                                showHeaderDropdown ? 180 : 0
                                            }deg)`,
                                        }}
                                    >
                                        <ArrowDown
                                            style={{ fontSize: 16 }}
                                            color="111111"
                                        />
                                    </span>
                                </div>
                                {showHeaderDropdown && (
                                    <div className="esgai_header-organization-dropdown">
                                        <div className="organization-list">
                                            <div className="organization-item">
                                                <div className="organization-logo-wrap">
                                                    {organization.logo &&
                                                    organization.logoUrl ? (
                                                        <img
                                                            className="organization-logo"
                                                            src={
                                                                organization.logoUrl
                                                            }
                                                            alt="Organization Logo"
                                                        />
                                                    ) : (
                                                        <Business
                                                            color="C8C8C9"
                                                            style={{
                                                                fontSize: 18,
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                                <span className="organization-name">
                                                    {organization.name}
                                                </span>
                                            </div>
                                        </div>
                                        <p
                                            className="esgai_header-organization-dropdown-item"
                                            onClick={this.goToSettings}
                                        >
                                            {locale.profile_settings}
                                        </p>
                                        <p
                                            className="esgai_header-organization-dropdown-item"
                                            onClick={this.logout}
                                        >
                                            {locale.logout}
                                        </p>
                                    </div>
                                )}
                            </div>
                            <Help />
                            <Notify />
                            <div className="esgai_header-menu">
                                {topMenus.map((i) => (
                                    <Link
                                        className={cns({
                                            'esgai_header-menu-item': true,
                                            'esgai_header-menu-item-active':
                                                this.matchRoute(i.path),
                                        })}
                                        key={i.path}
                                        to={i.path}
                                    >
                                        {i.title}
                                    </Link>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderSubHeader()}
                {this.renderMobileMenu()}
            </>
        );
    }
}

export default withRouter(
    connect(({ persist: { account, locale }, router }) => ({
        account,
        router,
        locale,
    }))(Header)
);
