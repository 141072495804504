const countryMap = {
    CA: 'Canada',
    // CN: 'China',
    // DE: 'Germany',
    // FR: 'France',
    // GB: 'United Kingdom',
    // JP: 'Japan',
    US: 'United States',
};

export default countryMap;
