import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { ArrowLeft } from '../../../../component/Icon';
import Button from '../../../../component/Button';
import './index.scss';
import Modal from '../../../../component/Modal';
import { persistType } from '../../../../constant/actionTypes';

class OneTimeTutorial extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stepWrapStyle: {},
            stepTwoOnLeft: false,
        };
    }

    componentDidMount() {
        document
            .getElementsByTagName('body')[0]
            .setAttribute('class', 'body_disable_scroll');
        window.addEventListener('resize', this.setStepWrapPosition);
    }

    componentDidUpdate(prevProps, prevState) {
        const { oneTimeTutorialStep } = this.props;
        const { oneTimeTutorialStep: prevOneTimeTutorialStep } = prevProps;
        if (
            oneTimeTutorialStep !== prevOneTimeTutorialStep &&
            oneTimeTutorialStep &&
            oneTimeTutorialStep < 5
        ) {
            this.setStepWrapPosition();
        }
    }

    componentWillUnmount() {
        document.getElementsByTagName('body')[0].removeAttribute('class');
        window.removeEventListener('resize', this.setStepWrapPosition);
    }

    setStepWrapStyle = (data) => {
        this.setState({
            stepWrapStyle: data,
        });
    };

    setStepWrapPosition = () => {
        const { oneTimeTutorialStep } = this.props;
        const page = document.getElementsByClassName(
            'optimizer_board_detail_page'
        )[0];
        if (oneTimeTutorialStep === 1) {
            const target = page
                .getElementsByClassName('table-header')[0]
                .getElementsByClassName('table-right-mask')[0];
            const { width, top, left } = target.getBoundingClientRect();
            const clientH =
                parseFloat(
                    document.documentElement.style.getPropertyValue('--vh')
                ) * 100;
            this.setStepWrapStyle({
                width: width + 24,
                height: clientH - top,
                bottom: 12,
                left: left - 12,
            });
        } else if (oneTimeTutorialStep === 2) {
            const target = page.getElementsByClassName('overview-target-simplify')[0];
            const { width, height, top, left } = target.getBoundingClientRect();
            this.setStepWrapStyle({
                width: width + 24,
                height: height + 24,
                top: top - 12,
                left: left - 12,
            });
            // Compatible with small height screens
            this.setState({
                stepTwoOnLeft:
                    top - 12 + height + 24 + 40 + 277 > window.innerHeight,
            });
        } else if (oneTimeTutorialStep === 3) {
            const target =
                page.getElementsByClassName('row-header')[0].children[2];
            const { width, top, left } = target.getBoundingClientRect();
            const clientH =
                parseFloat(
                    document.documentElement.style.getPropertyValue('--vh')
                ) * 100;
            this.setStepWrapStyle({
                width: width + 10,
                height: clientH - top,
                bottom: 12,
                left: left - 16,
            });
        } else if (oneTimeTutorialStep === 4) {
            const target = page.getElementsByClassName('overview-current-simplify')[0];
            const { width, height, top, left } = target.getBoundingClientRect();
            this.setStepWrapStyle({
                width: width + 24,
                height: height + 24,
                top: top - 12,
                left: left - 12,
            });
        }
    };

    nextStep = (step) => {
        const { handleUpdateState } = this.props;
        if (step < 5) {
            handleUpdateState({ oneTimeTutorialStep: step });
        } else {
            handleUpdateState({
                showOneTimeTutorial: false,
                oneTimeTutorialStep: 0,
            });
        }
    };

    handleStartTutorial = () => {
        const {
            oneTimeNote,
            account: { username },
            dispatch,
        } = this.props;
        this.nextStep(1);
        const newItem = { ...oneTimeNote[username], optimizerTip: true };
        dispatch({
            type: persistType.APPLY_UPDATE_ONE_TIME_NOTE,
            payload: { ...oneTimeNote, [username]: newItem },
        });
    };

    renderOneTimeTutorialSteps = () => {
        const { locale, oneTimeTutorialStep } = this.props;
        const { stepTwoOnLeft, stepWrapStyle } = this.state;
        const stepArr = [...new Array(4).keys()].map((i) => i + 1);
        const stepContent = stepArr.map((i) => ({
            title: locale[`optimizer-tutorial-step-${i}-title`],
            description: locale[`optimizer-tutorial-step-${i}-description`],
        }));
        const currentContent = stepContent[oneTimeTutorialStep - 1];
        const isFirstStep = oneTimeTutorialStep === 1;
        return (
            <div
                className={classNames({
                    [`optimizer-one-time-tutorial optimizer-one-time-tutorial-step-${oneTimeTutorialStep}`]: true,
                    'optimizer-one-time-tutorial-step-2-on-left':
                        oneTimeTutorialStep === 2 && stepTwoOnLeft,
                })}
            >
                <div className="step-content-wrap" style={stepWrapStyle}>
                    <div className="step-content">
                        <ArrowLeft />
                        <div className="step-dot-list">
                            {stepArr.map((i) => (
                                <span
                                    key={i}
                                    className={classNames({
                                        'dot-active': oneTimeTutorialStep === i,
                                    })}
                                />
                            ))}
                        </div>
                        <h4 className="step-title">{currentContent.title}</h4>
                        <p className="step-description">
                            {currentContent.description}
                        </p>
                        <div className="step-actions">
                            <Button
                                label={
                                    oneTimeTutorialStep === 4
                                        ? locale.done
                                        : locale.next
                                }
                                onClick={() =>
                                    this.nextStep(oneTimeTutorialStep + 1)
                                }
                            />
                            {!isFirstStep && (
                                <Button
                                    label={locale.skip_all}
                                    onClick={() => this.nextStep(5)}
                                    type="link"
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    renderModal = () => {
        const { locale } = this.props;
        return (
            <Modal>
                <div className="optimizer-one-time-tutorial-modal">
                    <h4>{locale.optimizer_tutorial_modal_title}</h4>
                    <p>{locale.optimizer_tutorial_modal_content}</p>
                    <div className="modal-actions">
                        <Button
                            label={locale.start}
                            onClick={this.handleStartTutorial}
                        />
                    </div>
                </div>
            </Modal>
        );
    };

    render() {
        const { oneTimeTutorialStep } = this.props;
        return oneTimeTutorialStep
            ? this.renderOneTimeTutorialSteps()
            : this.renderModal();
    }
}

export default withRouter(
    connect(
        ({
            persist: { locale, oneTimeNote, account },
            common: { mobileView },
            router,
        }) => ({
            locale,
            router,
            mobileView,
            oneTimeNote,
            account,
        })
    )(OneTimeTutorial)
);
