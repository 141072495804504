import React from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SideMenuLayoutPage from '../../component/layouts/SideMenuLayoutPage';
import './index.scss';
import { profileUrl } from '../../constant/endpoint';
import service from '../../service';
import { commonType } from '../../constant/actionTypes';
import UserInfo from './component/UserInfo';
import CompanyInfo from './component/CompanyInfo';
import Modal from '../../component/Modal';
import Button from '../../component/Button';

class ProfileInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userInfo: null,
            organizationInfo: null,
            showDiscardModal: false,
            discardChanges: false,
            geoList: [],
            industryList: [],
        };
    }

    componentDidMount() {
        this.fetchAllProfile();
    }

    fetchAllProfile = async () => {
        const { account } = this.props;
        const organization = account.organization || {};
        if (!organization.organizationId) {
            return;
        }
        this.toggleGlobalLoading(true);
        try {
            const res = await service.get(profileUrl.ALL_PROFILE);
            if (res && res.userInfo && res.organizationInfo) {
                this.setState({
                    userInfo: res.userInfo,
                    organizationInfo: res.organizationInfo,
                });
            }
            const geoList = await service.get(profileUrl.GEOS, {
                size: 1000,
            });
            if (Array.isArray(geoList)) {
                this.setState({ geoList });
            }
            const industryList = await service.get(profileUrl.INDUSTRIES, {
                size: 100,
            });
            if (Array.isArray(industryList)) {
                this.setState({ industryList });
            }
        } catch (err) {
            console.log(err);
        }
        this.toggleGlobalLoading(false);
    };

    toggleGlobalLoading = (globalLoading) => {
        this.props.dispatch({
            type: commonType.APPLY_SET_COMMON_STATE,
            payload: {
                globalLoading,
            },
        });
    };

    updateState = (key, value) => {
        this.setState({
            [key]: value,
        });
    };

    render() {
        const {
            userInfo,
            organizationInfo,
            showDiscardModal,
            discardChanges,
            geoList,
            industryList,
        } = this.state;
        const { locale } = this.props;
        return (
            <SideMenuLayoutPage>
                <Helmet title={locale.user_profile} />
                <div className="profile-wrap">
                    <h4 className="profile-title">
                        <span>{locale.my_profile}</span>
                    </h4>
                    <div className="profile-content scrollbar_hide">
                        <UserInfo
                            userInfo={userInfo}
                            updateParentState={this.updateState}
                            discardChanges={discardChanges}
                            showDiscardModal={showDiscardModal}
                        />
                        <CompanyInfo
                            organizationInfo={organizationInfo}
                            updateParentState={this.updateState}
                            discardChanges={discardChanges}
                            showDiscardModal={showDiscardModal}
                            geoList={geoList}
                            industryList={industryList}
                        />
                    </div>
                </div>
                {showDiscardModal && (
                    <Modal>
                        <div className="profile-settings-modal">
                            <h4>{locale.unsaved_changes}</h4>
                            <p>{locale.unsaved_changes_desc}</p>
                            <div className="profile-settings-modal-actions">
                                <Button
                                    loading={false}
                                    label={locale.discard}
                                    onClick={() =>
                                        this.setState({ discardChanges: true })
                                    }
                                />
                                <Button
                                    type="link"
                                    label={locale.cancel}
                                    onClick={() =>
                                        this.setState({
                                            showDiscardModal: false,
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </Modal>
                )}
            </SideMenuLayoutPage>
        );
    }
}

export default withRouter(
    connect(({ persist: { account, locale }, router }) => ({
        account,
        router,
        locale,
    }))(ProfileInfo)
);
