import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { ArrowDown, Calendar } from '../Icon';
import './index.scss';
import Button from '../Button';
import { displaySelectedDate } from '../../utils';

class DatePicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showDropdown: false,
            cardType: 'day', // year
            year: '',
            month: '',
            selectedDate: [],
            yearCardIndex: 0,
        };
        this.dropdownRef = React.createRef();
    }

    componentDidMount() {
        window.addEventListener('click', this.tryHideDropdown);
    }

    componentDidUpdate(prevProps, prevState) {
        const { showDropdown } = this.state;
        const { showDropdown: prevShowDropdown } = prevState;
        if (!prevShowDropdown && showDropdown) {
            this.initDate();
        }
        if (prevShowDropdown && !showDropdown) {
            this.resetState();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.tryHideDropdown);
    }

    tryHideDropdown = (e) => {
        const path = e.path || (e.composedPath && e.composedPath());
        if (
            path.indexOf(this.dropdownRef.current) < 0 &&
            this.state.showDropdown
        ) {
            this.setState({ showDropdown: false });
        }
    };

    toggleOpen = () => {
        const { showDropdown } = this.state;
        this.setState({ showDropdown: !showDropdown });
    };

    handleClickCell = (i) => {
        const { year, month, day } = i;
        const dateArr = [year, month, day];
        const { selectedDate } = this.state;
        let newSelectedDate = [...selectedDate];
        if (selectedDate.length === 0) {
            newSelectedDate = [dateArr];
        } else if (selectedDate.length === 1) {
            const x = new Date(...selectedDate[0]).getTime();
            const y = new Date(...dateArr).getTime();
            if (x === y) {
                newSelectedDate = [];
            } else if (x > y) {
                newSelectedDate = [dateArr, ...selectedDate];
            } else {
                newSelectedDate = [...selectedDate, dateArr];
            }
        } else if (selectedDate.length === 2) {
            newSelectedDate = [dateArr];
            // const x = new Date(...selectedDate[0]).getTime();
            // const y = new Date(...selectedDate[1]).getTime();
            // const z = new Date(...dateArr).getTime();
            // if (z === x) {
            //     newSelectedDate = [selectedDate[1]];
            // } else if (z === y) {
            //     newSelectedDate = [selectedDate[0]];
            // } else if (z < x) {
            //     newSelectedDate = [dateArr, selectedDate[1]];
            // } else {
            //     newSelectedDate = [selectedDate[0], dateArr];
            // }
        }
        this.setState({ selectedDate: newSelectedDate });
        // if (i.monthIndex !== 0) {
        //     this.handleChangeMonth(i.monthIndex);
        // }
    };

    handleChangeMonth = (index) => {
        const { month, year } = this.state;
        let newState = null;
        if (index < 0) {
            if (month === 0) {
                newState = {
                    year: year - 1,
                    month: 11,
                };
            } else {
                newState = {
                    month: month - 1,
                };
            }
        } else if (index > 0) {
            if (month === 11) {
                newState = {
                    year: year + 1,
                    month: 0,
                };
            } else {
                newState = {
                    month: month + 1,
                };
            }
        }
        this.setState(newState);
    };

    handleSetDate = () => {
        const { selectedDate } = this.state;
        const { setDate } = this.props;
        if (typeof setDate === 'function') {
            setDate(selectedDate, this.toggleOpen);
        }
    };

    renderDayPicker = () => {
        const { locale, values } = this.props;
        const { year, month, selectedDate } = this.state;
        if (typeof year !== 'number' || typeof month !== 'number') {
            return null;
        }
        const prevMonth =
            month === 0
                ? {
                      year: year - 1,
                      month: 11,
                  }
                : {
                      year,
                      month: month - 1,
                  };
        const nextMonth =
            month === 11
                ? {
                      year: year + 1,
                      month: 0,
                  }
                : { year, month: month + 1 };
        const today = new Date();
        const yearOfToday = today.getFullYear();
        const monthOfToday = today.getMonth();
        const dateOfToday = today.getDate();
        const firstDay = new Date(year, month, 1);
        const lastDay = new Date(nextMonth.year, nextMonth.month, 0);
        const firstDayWeekday = firstDay.getDay() || 7;
        const lastDayNum = lastDay.getDate();
        const arr = [...Array(lastDayNum).keys()].map((i) => {
            const isToday =
                year === yearOfToday &&
                month === monthOfToday &&
                i + 1 === dateOfToday;
            return {
                year,
                month,
                day: i + 1,
                monthIndex: 0,
                isToday,
            };
        });
        if (firstDayWeekday !== 1) {
            const prevMonthLastDay = new Date(year, month, 0);
            const prevMonthLastDayNum = prevMonthLastDay.getDate();
            for (let i = 0; i < firstDayWeekday - 1; i++) {
                arr.unshift({
                    ...prevMonth,
                    day: prevMonthLastDayNum - i,
                    monthIndex: -1,
                });
            }
        }
        if (arr.length < 42) {
            const gap = 42 - arr.length;
            for (let i = 0; i < gap; i++) {
                arr.push({
                    ...nextMonth,
                    day: i + 1,
                    monthIndex: 1,
                });
            }
        }
        return (
            <div className="day-picker">
                <div className="picker-header">
                    <button
                        onClick={() => this.handleChangeMonth(-1)}
                        disabled={year === 1901 && month === 0}
                    >
                        <ArrowDown />
                    </button>
                    <span
                        onClick={() =>
                            this.setState({
                                cardType: 'year',
                                yearCardIndex: 0,
                            })
                        }
                    >
                        {locale.months[month]} {year}
                    </span>
                    <button
                        disabled={
                            year === yearOfToday && month === monthOfToday
                        }
                        onClick={() => this.handleChangeMonth(1)}
                    >
                        <ArrowDown />
                    </button>
                </div>
                <div className="weekday-list">
                    {locale.weekdays.map((d) => (
                        <div className="weekday picker-cell" key={d}>
                            <span>{d}</span>
                        </div>
                    ))}
                </div>
                <div className="day-list">
                    {arr.map((i) => {
                        const iDateArr = [i.year, i.month, i.day];
                        const iTimestamp = new Date(...iDateArr).getTime();
                        const selectedCellEdgeIndex = selectedDate
                            .map((d) => new Date(...d).getTime())
                            .indexOf(iTimestamp);
                        const isSelectedCellMid =
                            selectedDate.length === 2 &&
                            new Date(...selectedDate[0]).getTime() <=
                                iTimestamp &&
                            new Date(...selectedDate[1]).getTime() >=
                                iTimestamp;
                        return (
                            <button
                                className={classNames({
                                    'day-cell picker-cell': true,
                                    'today-cell': i.isToday,
                                    'other-month-cell': i.monthIndex !== 0,
                                    'selected-cell-edge':
                                        selectedCellEdgeIndex > -1,
                                    'edge-cell-start':
                                        selectedCellEdgeIndex === 0,
                                    'edge-cell-end':
                                        selectedCellEdgeIndex === 1,
                                    'selected-cell-mid': isSelectedCellMid,
                                })}
                                key={iTimestamp}
                                onClick={() => this.handleClickCell(i)}
                                disabled={
                                    i.year > yearOfToday ||
                                    (i.year === yearOfToday &&
                                        i.month > monthOfToday) ||
                                    (i.year === yearOfToday &&
                                        i.month === monthOfToday &&
                                        i.day > dateOfToday) ||
                                    i.year < 1901
                                }
                            >
                                <span>{i.day}</span>
                            </button>
                        );
                    })}
                </div>
                <div className="picker-actions">
                    <Button
                        label={locale.set_date}
                        onClick={this.handleSetDate}
                        disabled={
                            selectedDate[0] === values[0] &&
                            selectedDate[1] === values[1]
                        }
                    />
                    <Button
                        type="link"
                        label={locale.cancel}
                        onClick={this.toggleOpen}
                    />
                </div>
            </div>
        );
    };

    handleChangeYear = (y) => {
        this.setState({ year: y, cardType: 'day' });
    };

    renderYearPicker = () => {
        const { year, yearCardIndex } = this.state;
        let base = year - (year % 20) + 1;
        base = base + yearCardIndex * 20;
        const arr = [...Array(20).keys()].map((i) => {
            const y = base + i;
            return y;
        });
        const nowYear = new Date().getFullYear();
        return (
            <div className="day-picker year-picker">
                <div className="picker-header">
                    <button
                        onClick={() =>
                            this.setState({ yearCardIndex: yearCardIndex - 1 })
                        }
                        disabled={base <= 1901}
                    >
                        <ArrowDown />
                    </button>
                    <span>{`${arr[0]} - ${arr[arr.length - 1]}`}</span>
                    <button
                        onClick={() =>
                            this.setState({ yearCardIndex: yearCardIndex + 1 })
                        }
                        disabled={nowYear >= base && nowYear < base + 20}
                    >
                        <ArrowDown />
                    </button>
                </div>
                <div className="year-list">
                    {arr.map((y) => (
                        <button
                            key={y}
                            className={classNames({
                                'year-cell': true,
                                'active-cell': y === year,
                                'now-year-cell': y === nowYear,
                            })}
                            disabled={y > nowYear}
                            onClick={() => this.handleChangeYear(y)}
                        >
                            <span>{y}</span>
                        </button>
                    ))}
                </div>
            </div>
        );
    };

    resetState = () => {
        this.setState({
            cardType: 'day', // year
            year: '',
            month: '',
            selectedDate: [],
        });
    };

    initDate = () => {
        const { values } = this.props;
        let targetDate = null;
        const newState = {};
        if (Array.isArray(values) && values.length > 0) {
            targetDate = new Date(...values[values.length - 1]);
            newState.selectedDate = values;
        } else {
            targetDate = new Date();
        }
        const year = targetDate.getFullYear();
        const month = targetDate.getMonth();
        this.setState({
            ...newState,
            year,
            month,
        });
    };

    render() {
        const { showDropdown, cardType } = this.state;
        const { values } = this.props;
        const hasValue = Array.isArray(values) && values.length > 0;
        return (
            <div
                className={classNames({
                    esgai_date_picker: true,
                    'esgai_date_picker-open': showDropdown,
                    'esgai_date_picker-with-value': hasValue,
                })}
                ref={this.dropdownRef}
            >
                <div className="display-date-wrap" onClick={this.toggleOpen}>
                    <Calendar />
                    <span className="display-date">
                        {displaySelectedDate(values)}
                    </span>
                </div>
                {showDropdown && (
                    <div className="date-picker-wrap">
                        {cardType === 'day'
                            ? this.renderDayPicker()
                            : this.renderYearPicker()}
                    </div>
                )}
            </div>
        );
    }
}

export default connect(({ persist: { locale } }) => ({
    locale,
}))(DatePicker);
