import React from 'react';
import { connect } from 'react-redux';
import firebase from 'firebase/app';
import { withRouter, Redirect, matchPath } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import BlankLayout from './BlankLayout';
import MainLayout from './MainLayout/MainLayout';
import GlobalLoading from '../../component/GlobalLoading';
import SetupProfileReminder from '../../component/SetupProfileReminder';
import { routerGuide } from '../../utils';
import routesMap from '../../routes/routesMap';
import service from '../../service';
import { accountUrl } from '../../constant/endpoint';
import { commonType, persistType } from '../../constant/actionTypes';
import EmailSupport from '../EmailSupport';

class Layout extends React.Component {
    componentDidMount() {
        this.initI18n();
        const { account, dispatch, history, router: {
            location: { pathname: currentPath },
        } } = this.props;
        if (account.username) {
            this.getProfile();
        }
        firebase.auth().onAuthStateChanged(function (user) {
            if (user) {
                dispatch({
                    type: persistType.APPLY_UPDATE_FIREBASE_USER,
                    payload: user,
                });
            } else {
                dispatch({
                    type: persistType.APPLY_UPDATE_FIREBASE_USER,
                    payload: null,
                });
            }
        });

        this.storePaths(currentPath);
        history.listen(({ pathname }) => {
            this.storePaths(pathname);
        });
        this.handleWindowResize();
        window.addEventListener('resize', this.handleWindowResize);
    }

    storePaths(pathname) {
        const { dispatch} = this.props;

        dispatch({
            type: persistType.APPLY_CHANGE_PATHS,
            payload: { pathname },
        });
    }

    componentDidUpdate(prevProps) {
        const {
            router: {
                location: { pathname },
            },
        } = this.props;
        const {
            router: {
                location: { pathname: prevPathname },
            },
        } = prevProps;
        if (prevPathname !== pathname) {
            window.scrollTo(0, 0);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
    }

    handleWindowResize = () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        this.props.dispatch({
            type: commonType.APPLY_SET_COMMON_STATE,
            payload: {
                mobileView: window.innerWidth < 1128,
                clientWidth: window.innerWidth,
            },
        });
    };

    initI18n = () => {
        const { lang, dispatch } = this.props;
        dispatch({
            type: persistType.APPLY_CHANGE_LOCALE,
            payload: { lang },
        });
    };

    getProfile = async () => {
        try {
            const res = await service.get(accountUrl.PROFILE);
            if (res && res.username) {
                this.props.dispatch({
                    type: persistType.APPLY_UPDATE_PROFILE,
                    payload: res,
                });
            }
        } catch (err) {
            console.error(err);
        }
    };

    setupLayout = () => {
        const {
            router: {
                location: { pathname },
            },
            children,
        } = this.props;
        const currentRoute = routesMap.find((i) => matchPath(pathname, i));
        if (currentRoute && currentRoute.pageWrap) {
            return <MainLayout>{children}</MainLayout>;
        }
        return <BlankLayout>{children}</BlankLayout>;
    };

    render() {
        const {
            account,
            history: {
                location: { pathname },
            },
            locale,
        } = this.props;
        const redirect = routerGuide(account, pathname);
        return (
            <>
                <Helmet titleTemplate="%s - ESG AI" title={locale.portal} />
                {this.setupLayout()}
                {!!redirect && <Redirect to={redirect} />}
                <GlobalLoading />
                <EmailSupport />
                {!account.mute && account.username && <SetupProfileReminder />}
            </>
        );
    }
}

export default withRouter(
    connect(({ persist: { account, lang, locale }, router }) => ({
        lang,
        account,
        router,
        locale,
    }))(Layout)
);
