import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { commonType } from '../../../constant/actionTypes';
import service from '../../../service';
import './index.scss';
import { Newspaper, Business } from '../../../component/Icon';
import NewsWithoutImg from '../../../component/NewsWithoutImg';
import NewsPageTitle from '../../../component/NewsPageTitle';
import NewsEmpty from '../../../component/NewsEmpty';
import { newsUrl } from '../../../constant/endpoint';
import { formatDate, processNewsApi, displaySelectedDate } from '../../../utils';
import Pagination from '../../../component/Pagination';
import NewsFilter from '../../../component/NewsFilter';
import { NewsContext } from '../index';

class NewsRelatedCompany extends React.Component {
    static contextType = NewsContext;
    constructor(props) {
        super(props);
        this.state = {
            news: [],
            totalCount: 0,
            page: 0,
            size: 10,
            companies: [],
            companiesMap: {},
            selectedCompanies: [],
            period: [],
            nextCompanies: [],
            paginationCount: 0,
            newsTypes: [],
            nextNewsTypes: [],
            onlyControversial: false,
            nextOnlyControversial: false,
        };
    }

    componentDidMount() {
        const { period, onlyControversial, newsTypes } = this.context;

        this.setState({
            period, onlyControversial, newsTypes
        })
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            selectedCompanies,
            page,
            period,
            paginationCount,
            newsTypes,
            onlyControversial,
        } = this.state;
        const {
            selectedCompanies: prevSelectedCompanies,
            page: prevPage,
            period: prevPeriod,
            newsTypes: prevNewsTypes,
            onlyControversial: prevOnlyControversial,
        } = prevState;
        const filterChange =
            selectedCompanies !== prevSelectedCompanies ||
            period !== prevPeriod ||
            newsTypes !== prevNewsTypes ||
            onlyControversial !== prevOnlyControversial;
        if (filterChange || page !== prevPage) {
            this.fetchData();
        }
        if (filterChange) {
            this.setState({ paginationCount: paginationCount + 1 });
        }
    }

    fetchData = async () => {
        const { companies } = this.state;
        const staticData = this.getStaticData();
        try {
            this.toggleGlobalLoading(true);
            if (companies.length === 0) {
                const companiesRes = await service.get(staticData.dropdownUrl);
                if (Array.isArray(companiesRes)) {
                    let companiesMap = {};
                    companiesRes.forEach((i) => {
                        companiesMap[i.organizationId] = i;
                    });
                    this.setState({ companies: companiesRes, companiesMap });
                }
            }
            const newsRes = await service.get(
                processNewsApi(
                    this.state,
                    this.getStaticData().newsUrl,
                    'selectedCompanies',
                    'organizationIds',
                    'organizationId',
                    companies.length
                )
            );
            if (newsRes && Array.isArray(newsRes.data)) {
                this.setState({
                    news: newsRes.data,
                    totalCount: newsRes.totalCount,
                });
            }
        } catch (err) {
            console.log(err);
        }
        this.toggleGlobalLoading(false);
    };

    toggleGlobalLoading = (globalLoading) => {
        this.props.dispatch({
            type: commonType.APPLY_SET_COMMON_STATE,
            payload: {
                globalLoading,
            },
        });
    };

    renderNewsList = () => {
        const {
            news,
            page,
            size = 10,
            totalCount,
            companiesMap,
            paginationCount,
            selectedCompanies,
            period,
        } = this.state;
        const { mobileView } = this.props;
        let groupedNews = [];
        news.forEach((i) => {
            const date = formatDate(i.versionCreated, 'mid', { month: 'long' });
            if (
                groupedNews.length === 0 ||
                groupedNews[groupedNews.length - 1].date !== date
            ) {
                const group = [];
                (i.organizationIds || []).forEach((k) => {
                    if (
                        (selectedCompanies.length === 0 && companiesMap[k]) ||
                        selectedCompanies.find((s) => s.organizationId === k)
                    ) {
                        group.push({
                            groupId: k,
                            groupInfo: companiesMap[k],
                            news: [i],
                        });
                    }
                });
                groupedNews.push({
                    date,
                    group,
                });
            } else {
                (i.organizationIds || []).forEach((l) => {
                    if (
                        (selectedCompanies.length === 0 && companiesMap[l]) ||
                        selectedCompanies.find((s) => s.organizationId === l)
                    ) {
                        let targetIndex = -1;
                        const targetGroup = groupedNews[
                            groupedNews.length - 1
                        ].group.find((j, jIndex) => {
                            if (j.groupId === l) {
                                targetIndex = jIndex;
                                return true;
                            }
                            return false;
                        });
                        if (targetGroup) {
                            groupedNews[groupedNews.length - 1].group.splice(
                                targetIndex,
                                1,
                                {
                                    ...targetGroup,
                                    news: [...targetGroup.news, i],
                                }
                            );
                        } else {
                            groupedNews[groupedNews.length - 1].group.push({
                                groupId: l,
                                groupInfo: companiesMap[l],
                                news: [i],
                            });
                        }
                    }
                });
            }
        });

        return (
            <div className="news-list-wrap">
                <div className="news-list">
                    {groupedNews.length > 0 ? (
                        groupedNews.map((i) => (
                            <div key={i.date} className="date-group">
                                <p className="date-group-date">{i.date}</p>
                                {i.group.map((j) => (
                                    <div className="sub-group" key={j.groupId}>
                                        <div className="sub-group-header">
                                            <div className="sub-group-header-left">
                                                <div className="news-group-logo">
                                                    {j.groupInfo.logo &&
                                                    j.groupInfo.logoUrl ? (
                                                        <img
                                                            src={
                                                                j.groupInfo
                                                                    .logoUrl
                                                            }
                                                            alt=""
                                                        />
                                                    ) : (
                                                        <Business
                                                            color="C8C8C9"
                                                            style={{
                                                                fontSize: 20,
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                                <span className="sub-group-name">
                                                    {j.groupInfo.name}
                                                </span>
                                            </div>
                                            <div className="sub-group-num-wrap">
                                                <Newspaper color="7A7A7A" />
                                                <span className="sub-group-num">
                                                    {j.news.length}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="sub-group-list">
                                            {j.news.map((k) => (
                                                <NewsWithoutImg
                                                    key={k.storyId}
                                                    news={{
                                                        title: k.text,
                                                        brief: k.abstract,
                                                        logo: k.thumbnail,
                                                        date: k.versionCreated,
                                                        topic: k.topic,
                                                        storyId: k.storyId,
                                                    }}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))
                    ) : (
                        <>
                            <div className="group-header-empty">
                                <p className="date-group-date">
                                    {displaySelectedDate(period)}
                                </p>
                                <div className="group-num-wrap">
                                    <Newspaper
                                        style={{ fontSize: 24 }}
                                        color="7A7A7A"
                                    />
                                    <span className="group-num">0</span>
                                </div>
                            </div>
                            <NewsEmpty size={mobileView ? 'tiny' : 'default'} />
                        </>
                    )}
                </div>
                <Pagination
                    count={paginationCount}
                    page={page}
                    size={size}
                    totalCount={totalCount}
                    onChange={(value) => this.setState({ page: value })}
                />
            </div>
        );
    };

    isPartnerNews = () => {
        const {
            router: {
                location: { pathname },
            },
        } = this.props;
        return pathname === '/news/partner';
    };

    getStaticData = () => {
        const { locale } = this.props;
        const dataMap = {
            competitor: {
                title: locale.competitors_news,
                titleNote: locale.competitors_news_note,
                allItemsLabel: locale.all_competitors,
                multiItemsLabel: locale.competitors,
                singleItemLabel: locale.competitor,
                dropdownUrl: newsUrl.COMPETITORS_DROPDOWN,
                newsUrl: newsUrl.COMPETITORS_NEWS,
            },
            partner: {
                title: locale.partners_news,
                titleNote: locale.partners_news_note,
                allItemsLabel: locale.all_partners,
                multiItemsLabel: locale.partners,
                singleItemLabel: locale.partner,
                dropdownUrl: newsUrl.PARTNERS_DROPDOWN,
                newsUrl: newsUrl.PARTNERS_NEWS,
            },
        };
        const key = this.isPartnerNews() ? 'partner' : 'competitor';
        return dataMap[key];
    };

    render() {
        const text = this.getStaticData();
        const { mobileView } = this.props;
        const {
            period,
            newsTypes,
            onlyControversial,
            nextOnlyControversial,
            companies,
            selectedCompanies,
            nextCompanies,
            nextNewsTypes,
        } = this.state;
        const { setPeriod, setOnlyControversial, setNewsTypes } = this.context;
        return (
            <div className="page news_related_company_page">
                <Helmet title={text.title} />
                <NewsPageTitle title={text.title} tooltip={text.titleNote} />
                <div className="news_related_company-main">
                    {this.renderNewsList()}
                    <NewsFilter
                        period={period}
                        newsTypes={mobileView ? nextNewsTypes : newsTypes}
                        onlyControversial={
                            mobileView
                                ? nextOnlyControversial
                                : onlyControversial
                        }
                        updateParentState={(newState, cb = () => {}) =>{
                            const { newsTypes, onlyControversial, period } = newState;

                            newsTypes && setNewsTypes(newsTypes);
                            typeof onlyControversial === 'boolean' && setOnlyControversial(onlyControversial);
                            period && setPeriod(period);

                            this.setState(newState, cb)
                        }}
                        otherFilters={[
                            {
                                title: text.multiItemsLabel,
                                list: companies,
                                itemAllLabel: text.allItemsLabel,
                                stateKey: mobileView
                                    ? 'nextCompanies'
                                    : 'selectedCompanies',
                                valueKey: 'organizationId',
                                labelKey: 'name',
                                selected: mobileView
                                    ? nextCompanies
                                    : selectedCompanies,
                            },
                        ]}
                        handleShowMobileFilter={(cb) => {
                            this.setState(
                                {
                                    nextCompanies: selectedCompanies,
                                    nextNewsTypes: newsTypes,
                                    nextOnlyControversial: onlyControversial,
                                },
                                cb
                            );
                        }}
                        handleApplyMobileFilter={() => {
                            setNewsTypes(nextNewsTypes);
                            setOnlyControversial(nextOnlyControversial);
                            this.setState({
                                selectedCompanies: nextCompanies,
                                newsTypes: nextNewsTypes,
                                onlyControversial: nextOnlyControversial,
                                page: 0,
                            });
                        }}
                    />
                </div>
            </div>
        );
    }
}

export default withRouter(
    connect(
        ({ persist: { account, locale }, common: { mobileView }, router }) => ({
            account,
            router,
            locale,
            mobileView,
        })
    )(NewsRelatedCompany)
);
