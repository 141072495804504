import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { ArrowLeft, Vector } from '../../../../component/Icon';
import './index.scss';
import { commonType } from '../../../../constant/actionTypes';
import service from '../../../../service';
import { optimizerUrl } from '../../../../constant/endpoint';
import BoardActivity from '../BoardActivity';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            board: null,
            nameWidth: 0,
        };
        this.resizeObserver = null;
        this.nameInputRef = React.createRef();
        this.debounceTimer = null;
        this.taskTimer = null;
    }
    componentDidMount() {
        window.addEventListener(
            'visibilitychange',
            this.handleVisibilityChange
        );
        if (isMobile) {
            window.addEventListener('touchstart', this.setExitTimer);
        } else {
            window.addEventListener('mousemove', this.setExitTimer);
        }
        this.addBoardNameElementListener();
        window.addEventListener('keydown', this.handleKeydown);
    }

    componentDidUpdate(prevProps, prevState) {
        const { board } = this.props;
        const { board: prevBoard } = prevProps;
        if (board !== prevBoard) {
            this.syncBoardInfo();
        }
    }

    componentWillUnmount() {
        window.removeEventListener(
            'visibilitychange',
            this.handleVisibilityChange
        );
        if (isMobile) {
            window.removeEventListener('touchstart', this.setExitTimer);
        } else {
            window.removeEventListener('mousemove', this.setExitTimer);
        }
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
        window.removeEventListener('keydown', this.handleKeydown);
        this.clearTimer(this.debounceTimer);
        this.clearTimer(this.taskTimer);
    }

    handleVisibilityChange = () => {
        if (document.hidden) {
            this.setExitTimer();
        } else {
            this.clearTimer(this.taskTimer);
        }
    };

    setExitTimer = () => {
        this.clearTimer(this.debounceTimer);
        this.debounceTimer = setTimeout(() => {
            this.clearTimer(this.taskTimer);
            this.taskTimer = setTimeout(() => {
                this.goBack();
            }, 1000 * 60 * 10);
            this.clearTimer(this.debounceTimer);
        }, 500);
    };

    clearTimer = (timer) => {
        if (timer) {
            clearTimeout(timer);
            timer = null;
        }
    };

    handleKeydown = (event) => {
        var key = event.which || event.keyCode || event.charCode;
        if (
            key === 13 &&
            this.nameInputRef.current === document.activeElement
        ) {
            this.nameInputRef.current.blur();
        }
    };

    toggleGlobalLoading = (globalLoading) => {
        this.props.dispatch({
            type: commonType.APPLY_SET_COMMON_STATE,
            payload: {
                globalLoading,
            },
        });
    };

    syncBoardInfo = () => {
        const { board } = this.props;
        this.setState({ board });
    };

    goBack = () => {
        const {
            board: { bePublic, trashed },
            history,
        } = this.props;
        if (trashed) {
            history.push('/optimizer/archive');
        } else if (bePublic) {
            history.push('/optimizer/team-board');
        } else {
            history.push('/optimizer/my-board');
        }
    };

    addBoardNameElementListener = () => {
        if (document.getElementsByClassName('optimizer_board_detail-name')[0]) {
            this.resizeObserver = new ResizeObserver((data) => {
                const nameWidth = document
                    .getElementsByClassName('optimizer_board_detail-name')[0]
                    .getBoundingClientRect().width;
                this.setState({ nameWidth });
            });
            this.resizeObserver.observe(
                document.getElementsByClassName(
                    'optimizer_board_detail-name'
                )[0]
            );
        }
    };

    updateBoardName = async (name) => {
        this.toggleGlobalLoading(true);
        try {
            const { updateBoard } = this.props;
            const { board } = this.state;
            await service.put(optimizerUrl.SPECIFIC_BOARD(board.id), {
                name,
                bePublic: board.bePublic,
            });
            updateBoard({ ...board, name });
        } catch (err) {
            console.log(err);
        }
        this.toggleGlobalLoading(false);
    };

    handleNameInputBlur = () => {
        const { board: propsBoard } = this.props;
        const { board } = this.state;
        const { name = '' } = board || {};
        const newName = name.trim();
        if (!newName) {
            this.setState({ board: propsBoard });
        } else if (newName !== propsBoard.name) {
            this.updateBoardName(newName);
        }
    };

    handleNameChange = (e) => {
        const { board } = this.state;
        this.setState({
            board: { ...board, name: e.target.value },
        });
    };

    render() {
        const { handleUpdateState, currentHolder, firebaseUser, exportCSV } = this.props;
        if (!firebaseUser) {
            return null;
        }
        const { board, nameWidth } = this.state;
        const { name = '', createByUserId } = board || {};
        const { uid } = firebaseUser;
        const { userId } = currentHolder;
        const disableInput =
            typeof userId === 'number' &&
            (String(userId) !== String(uid) ||
                String(createByUserId) !== String(uid));
        return (
            <div className="optimizer_board_detail-header">
                <div className="detail-header-content">
                    <div className="detail-header-left">
                        <button className="go-back" onClick={this.goBack}>
                            <ArrowLeft />
                        </button>
                        <div className="board-name">
                            {board && (
                                <input
                                    ref={this.nameInputRef}
                                    className={classNames({
                                        'input-no-name': !name.trim(),
                                    })}
                                    style={{ width: nameWidth }}
                                    maxLength={40}
                                    value={name}
                                    onChange={this.handleNameChange}
                                    onBlur={this.handleNameInputBlur}
                                    disabled={disableInput}
                                />
                            )}
                            <span className="optimizer_board_detail-name">
                                {name}
                            </span>
                        </div>
                    </div>
                    <div className="detail-header-right">
                        {board && board.bePublic && (
                            <BoardActivity
                                board={board}
                                handleUpdateState={handleUpdateState}
                                currentHolder={currentHolder}
                            />
                        )}
                        <div className="header-export-csv" onClick={exportCSV}>
                            <Vector />
                            <span>EXPORT CSV</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(
    connect(
        ({
            persist: { locale, firebaseUser },
            common: { mobileView },
            router,
        }) => ({
            locale,
            router,
            mobileView,
            firebaseUser,
        })
    )(Header)
);
