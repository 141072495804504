import React, { Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import routes from './routesMap';
import Layout from '../component/layouts';
import GlobalLoading from '../component/GlobalLoading';
import redux from '../redux';

const Router = () => (
    <ConnectedRouter history={redux.history}>
        <Suspense fallback={<GlobalLoading isProgressBar />}>
            <Layout>
                <Switch>
                    {routes.map((route) => (
                        <Route
                            path={route.path}
                            component={route.component}
                            key={route.path}
                            exact={route.exact}
                        />
                    ))}
                    <Redirect from="/esg-score" to="/esg-score/industry" />
                    <Redirect from="/**" to="/login" />
                </Switch>
            </Layout>
        </Suspense>
    </ConnectedRouter>
);

export default Router;
