import Immutable from 'seamless-immutable';
import { persistType } from '../../constant/actionTypes';
import locales from '../../locale';
import { defaultLang } from '../../constant/enum';

const initialState = Immutable({
    account: {},
    firebaseUser: null,
    log: '',
    setupProfileLog: {},
    oneTimeNote: {},
    lang: defaultLang,
    locale: locales[defaultLang],
    paths: []
});

const persist = (state = initialState, action) => {
    switch (action.type) {
        case persistType.APPLY_LOGIN: {
            return {
                ...state,
                account: action.payload,
            };
        }
        case persistType.APPLY_UPDATE_FIREBASE_USER: {
            return {
                ...state,
                firebaseUser: action.payload,
            };
        }
        case persistType.APPLY_UPDATE_CERTIFICATE: {
            return {
                ...state,
                log: action.payload,
            };
        }
        case persistType.APPLY_UPDATE_PROFILE: {
            return {
                ...state,
                account: { ...state.account, ...action.payload },
            };
        }
        case persistType.APPLY_UPDATE_ONE_TIME_NOTE: {
            return {
                ...state,
                oneTimeNote: action.payload,
            };
        }
        case persistType.APPLY_UPDATE_SETUP_PROFILE_REMINDER_LOG: {
            return {
                ...state,
                setupProfileLog: {
                    ...state.setupProfileLog,
                    ...action.payload,
                },
            };
        }
        case persistType.APPLY_LOGOUT: {
            return {
                ...state,
                account: action.payload,
            };
        }
        case persistType.APPLY_CHANGE_LOCALE: {
            const { lang } = action.payload;
            const locale = locales[lang]
                ? { ...locales[defaultLang], ...locales[lang] }
                : locales[defaultLang];
            return {
                ...state,
                lang,
                locale,
            };
        }
        case persistType.APPLY_CHANGE_PATHS: {
            const { pathname } = action.payload;
            return {
                ...state,
                paths: ([pathname, ...state.paths]).slice(0, 10),
            };
        }
        default:
            return state;
    }
};

export default persist;
