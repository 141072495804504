import { createRef, useCallback, useEffect } from "react"


function SectorRatio({ backgroundColor="#e6e6e6", sectorColor="#819daf", weight = 0, diameter = 28 }) {
    const canvasRef = createRef();

    const draw = useCallback(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const width = canvas.width = 100;
        const height = canvas.height = 100;
        ctx.clearRect(0, 0, width, height);
        ctx.save();
        ctx.beginPath();
        ctx.arc(width/2, height/2, width / 2, -Math.PI / 2, Math.PI * 3 / 2);
        ctx.lineTo(width / 2, height / 2);
        ctx.fillStyle = backgroundColor;
        ctx.fill();

        ctx.save();
        ctx.beginPath();
        ctx.arc(width/2, height/2, width / 2, -Math.PI / 2, (-Math.PI / 2 + 2 * weight * Math.PI));
        ctx.lineTo(width / 2, height / 2);
        ctx.fillStyle = sectorColor;
        ctx.fill();
        ctx.restore();
    }, [backgroundColor, canvasRef, sectorColor, weight])

    useEffect(() => {
        draw();
    }, [draw])

    return (
        <canvas ref={canvasRef} style={{ width: `${diameter}px`, height: `${diameter}px` }}></canvas>
    )
}

export default SectorRatio;