import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import {
    ArrowLeft,
    Actionability,
    Priority,
    RawData,
    Competitor,
    Edit,
    Note,
    File,
} from '../../../../component/Icon';
import './index.scss';
import Popover from '../../../../component/Popover';
import Selector from '../Selector';
import EditMetricModal from '../EditMetricModal';
import { processScore } from '../../../../utils';
import service from '../../../../service';
import { optimizerUrl } from '../../../../constant/endpoint';
import MiniBarChart from '../MiniBarChart';

const standards = ['gri', 'sasb', 'cdp', 'tcfd', 'gresb', 'wef', 'djsi'];

class Table extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedItem: null,
            statMap: {},
            loadedArr: [],
            allCompetitorsMap: {},
        };
        this.timer = null;
    }

    componentDidMount() {
        this.fetchData();
        this.checkLoadedThrottle();
        window.addEventListener('scroll', this.checkLoadedThrottle);
    }

    componentDidUpdate(prevProps, prevState) {
        const { data } = this.props;
        const { data: prevData } = prevProps;
        if (data.length !== prevData.length) {
            this.setState({ loadedArr: data.map((_) => false) }, () => {
                this.checkLoadedThrottle();
            });
        }
    }

    componentWillUnmount() {
        this.clearTimer();
        window.removeEventListener('scroll', this.checkLoadedThrottle);
    }

    clearTimer = () => {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
    };

    checkLoadedThrottle = () => {
        if (this.timer) {
            return;
        }
        this.timer = setTimeout(() => {
            this.checkLoaded();
            this.clearTimer();
        }, 400);
    };

    checkLoaded = () => {
        const { loadedArr } = this.state;
        const { mobileView } = this.props;
        let tableBodyClass = 'table-body';
        let rowClass = 'row';
        if (mobileView) {
            tableBodyClass = 'table-mobile';
            rowClass = 'row-mobile';
        }
        const tableBody = document.getElementsByClassName(tableBodyClass)[0];
        if (!tableBody) {
            return;
        }
        const boundaryTop = document
            .getElementsByClassName('optimizer_board_detail-header')[0]
            .getBoundingClientRect().bottom;
        const boundaryBottom = window.innerHeight;
        const arr = tableBody.getElementsByClassName(rowClass);
        for (let i = 0; i < arr.length; i += 1) {
            if (
                arr[i].getBoundingClientRect().top <= boundaryBottom &&
                arr[i].getBoundingClientRect().bottom >= boundaryTop &&
                !loadedArr[i]
            ) {
                loadedArr[i] = true;
            }
            if (
                (arr[i].getBoundingClientRect().top > boundaryBottom ||
                    arr[i].getBoundingClientRect().bottom < boundaryTop) &&
                loadedArr[i]
            ) {
                loadedArr[i] = false;
            }
        }
        this.setState({ loadedArr });
    };

    fetchData = async () => {
        try {
            const { boardId } = this.props;
            const res = await service.get(
                optimizerUrl.GET_NUMERIC_STAT(boardId)
            );
            if (res) {
                this.setState({ statMap: res });
            }
        } catch (err) {
            console.log(err);
        }
    };

    isDisabled = () => {
        const {
            currentHolder: { userId },
            firebaseUser,
        } = this.props;
        if (!firebaseUser) {
            return true;
        }
        const { uid } = firebaseUser;
        return typeof userId === 'number' && String(userId) !== String(uid);
    };

    renderPriority = (row, disabled) => (
        <Selector
            type="priority"
            row={row}
            handleSelected={this.props.handleUpdatePriorityOrEase}
            disabled={disabled}
        />
    );

    renderEase = (row, disabled) => (
        <Selector
            type="ease"
            row={row}
            handleSelected={this.props.handleUpdatePriorityOrEase}
            disabled={disabled}
        />
    );

    processPillarKey = (key) => {
        if (key === 'EnvironmentPillarScore') {
            return 'environmental';
        }
        if (key === 'SocialPillarScore') {
            return 'social';
        }
        if (key === 'GovernancePillarScore') {
            return 'governance';
        }
    };

    renderMetric = (row) => {
        const { locale } = this.props;
        const { displayName, rootPillar, rawValueType, description } = row;
        const pillar = this.processPillarKey(rootPillar);
        return (
            <div className="metric">
                <Popover
                    tooltip={description}
                    placement="rightBottom"
                >
                     <h6>{displayName}</h6>
                </Popover>
                <div className="metric-bottom">
                    <span
                        className={classNames({
                            [`${pillar}-tag`]: true,
                        })}
                    >
                        {locale[pillar]}
                    </span>
                    {rawValueType === 'NUMERIC' && <span>#</span>}
                </div>
            </div>
        );
    };

    renderCurrentScore = (row) => {
        const { mobileView, locale } = this.props;
        const { displayScore, displayRawValue } = processScore(row, locale);
        return (
            <div className="current-score">
                <span>{displayScore}</span>
                <Popover
                    tooltip={displayRawValue}
                    autoWidth
                    isMobileView={mobileView}
                >
                    <span>
                        <RawData />
                    </span>
                </Popover>
                {!mobileView && this.renderCompetitorsContent(row)}
            </div>
        );
    };

    renderCompetitorsScore = (row) => {
        const { competitorMetrics, locale } = this.props;
        const data = competitorMetrics[row.name] || [];
        return (
            <div className="competitors-score-rank-wrap">
                <h6>{locale.competitors_score_rank}</h6>
                <div className="competitors-score-rank-list">
                    {data.length > 0
                        ? data.map((i) => (
                            <div
                                key={i.organizationId}
                                className="competitors-score-rank-item"
                            >
                                <b title={i.name}>{i.name}</b>
                                <span>
                                    {`${typeof i.score === 'number' && i.grade
                                            ? `${i.displayValue} ${i.grade}`
                                            : '--'
                                        }`}
                                    /
                                    {Array.isArray(i.ranks) &&
                                        i.ranks.length > 0 &&
                                        typeof i.ranks[0].rankValue === 'number'
                                        ? i.ranks[0].rankValue
                                        : '--'}
                                </span>
                            </div>
                        ))
                        : '--'}
                </div>
            </div>
        );
    };

    renderStat = (row) => {
        const { competitorMetrics, locale } = this.props;
        const { name, rawValueType, score } = row;
        const { statMap } = this.state;
        const currentStatData = statMap[name];
        const { min, max } = currentStatData || {};
        const isNonNumeric = rawValueType === 'BOOLEAN';
        const showChart = currentStatData && typeof min === 'number' && typeof max === 'number';

        return (
            <div className="position-stat">
                {(() => {
                    if (!isNonNumeric) {
                        return showChart ? (
                            <MiniBarChart
                                url={this.state[row.name]}
                                metric={row}
                                data={currentStatData}
                                competitors={competitorMetrics[name]}
                            />
                        ) : locale.no_data_available
                    }

                    if (!score) {
                        return '---';
                    }
        
                    return locale.reported;
                })()}
            </div>
        );
    };

    renderTargetScore = (row) => {
        const { mobileView, locale } = this.props;
        const defaultScore = {
            displayValue: null,
            grade: null,
            rawValue: null,
            score: null,
        };
        let { targetScore = defaultScore, metricNote } = row;
        const { note } = metricNote ? metricNote : { note: '' };
        if (!targetScore) {
            targetScore = defaultScore;
        }
        const isEdited = !!row.targetScore;
        const { displayScore, displayRawValue } = processScore(
            { ...row, ...targetScore },
            locale
        );
        return (
            <div
                className={classNames({
                    'current-score target-score': true,
                    'target-score-no-score': !isEdited,
                })}
            >
                <span onClick={() => this.setState({ selectedItem: row })}>
                    {isEdited ? displayScore : <Edit />}
                </span>
                {isEdited && (
                    <Popover
                        tooltip={displayRawValue}
                        autoWidth
                        isMobileView={mobileView}
                    >
                        <span>
                            <RawData />
                        </span>
                    </Popover>
                )}
                {!!note && (
                    <Popover
                        tooltip={note}
                        autoWidth
                        isMobileView={mobileView}
                        maxWidth={257}
                        mobileMaxWidth={300}
                    >
                        <span>
                            <Note />
                        </span>
                    </Popover>
                )}
            </div>
        );
    };

    processRank = (ranks) => {
        if (
            Array.isArray(ranks) &&
            ranks[0] &&
            typeof ranks[0].rankValue === 'number'
        ) {
            return ranks[0].rankValue;
        }
        return '--';
    };

    renderTargetRank = (row) => {
        const { ranks, targetRanks } = row;
        return (
            <div className="target-rank">
                <span>{this.processRank(ranks)}</span>
                <ArrowLeft />
                <span>{this.processRank(targetRanks)}</span>
            </div>
        );
    };

    getColumns = () => {
        const { locale, mobileView } = this.props;
        const targetColumns = [
            {
                key: 'targetScore',
                headerTitle: locale.target_score,
                supportSort: true,
                render: this.renderTargetScore,
                tooltip: {
                    width: 257,
                    tooltip: locale.target_score_note,
                },
            },
            {
                key: 'targetRank',
                headerTitle: locale.target_rank,
                supportSort: true,
                render: this.renderTargetRank,
                tooltip: {
                    width: 257,
                    tooltip: locale.target_rank_note,
                },
            },
        ];
        const metricColumn = {
            key: 'metric',
            headerTitle: locale.metric,
            supportSort: true,
            render: this.renderMetric,
            tooltip: {
                width: 257,
                tooltip: locale.metric_note,
            },
        };
        const mainColumns = [
            {
                key: 'currentScore',
                headerTitle: locale.current_score,
                supportSort: true,
                render: this.renderCurrentScore,
                tooltip: {
                    autoWidth: true,
                    tooltip: locale.current_score_note,
                },
            },
            {
                key: 'mmi',
                headerTitle: locale.mmi,
                supportSort: true,
                render: (row) => (
                    <span className="common-value">
                        {/* {typeof row.mmi === 'number'
                            ? parseInt(row.mmi * 100000) / 1000
                            : '--'} */}
                        {row.mmiDisplayValue || '--'}
                    </span>
                ),
                tooltip: {
                    width: 257,
                    tooltip: locale.mmi_note,
                },
            },
            {
                key: 'position',
                headerTitle: locale.position,
                supportSort: false,
                render: this.renderStat,
                tooltip: {
                    width: 257,
                    tooltip: locale.position_note,
                },
            },
            {
                key: 'standards',
                headerTitle: 'standards',
                supportSort: true,
                render: (row) => {
                    return (
                        <div className='standards'>
                            {
                                standards.filter(standard => row[standard]).map(item => (
                                    <span key={item}>{item}</span>
                                ))
                            }
                        </div>
                    )
                }
            },
        ];
        const noteColumns = [
            {
                key: 'priority',
                headerTitle: <Priority />,
                title: locale.priority,
                supportSort: true,
                render: this.renderPriority,
                tooltip: {
                    autoWidth: true,
                    tooltip: locale.priority_note,
                },
            },
            {
                key: 'ease',
                headerTitle: <Actionability />,
                title: locale.actionability,
                supportSort: true,
                render: this.renderEase,
                tooltip: {
                    width: 257,
                    tooltip: locale.ease_note,
                },
            },
        ];
        if (mobileView) {
            mainColumns.splice(1, 0, {
                key: 'competitors',
                headerTitle: locale.competitors,
                supportSort: false,
                render: this.renderCompetitors,
            });
            return { targetColumns, metricColumn, mainColumns, noteColumns };
        } else {
            return [
                ...noteColumns,
                metricColumn,
                ...mainColumns,
                ...targetColumns,
            ];
        }
    };

    renderCompetitorsContent = (row) => {
        const { mobileView } = this.props;
        return (
            <Popover
                tooltip={this.renderCompetitorsScore(row)}
                isInnerHTML={false}
                autoWidth
                isMobileView={mobileView}
            >
                <span>
                    <Competitor />
                </span>
            </Popover>
        );
    };

    renderCompetitors = (row) => {
        return (
            <div className="current-score competitors">
                {this.renderCompetitorsContent(row)}
            </div>
        );
    };

    renderTableHeaderColumn = (c) => {
        return (
            <div className="column-content">
                <span className="table-column-section">{c.headerTitle}</span>
                {c.supportSort && (
                    <span className="table-column-section">
                        <ArrowLeft />
                    </span>
                )}
            </div>
        );
    };

    handleSort = (column) => {
        const { handleUpdateState, sort } = this.props;
        const newSort = { key: column.key };
        if (sort.key === newSort.key) {
            newSort.base = sort.base === 'desc' ? 'asc' : 'desc';
        } else {
            newSort.base = 'desc';
        }
        handleUpdateState({
            sort: newSort,
        });
    };

    renderMasks = () => (
        <>
            <div className="table-mask table-left-mask" />
            <div className="table-mask table-right-mask" />
        </>
    );

    renderTableHeader = (columns) => {
        const { sort } = this.props;
        return (
            <div className="table-header table-part table-stick-header">
                {this.renderMasks()}
                <div className="row row-header">
                    {columns.map((c) => {
                        const isSortActive = sort.key === c.key;
                        return (
                            <div
                                key={c.key}
                                className={classNames({
                                    column: true,
                                    'column-support-sort': c.supportSort,
                                    [`column-support-sort-active column-support-sort-${sort.base}`]:
                                        isSortActive,
                                })}
                                onClick={
                                    c.supportSort
                                        ? () => this.handleSort(c)
                                        : null
                                }
                            >
                                {c.tooltip ? (
                                    <Popover
                                        hideOnMobile
                                        placement="topLeft"
                                        {...c.tooltip}
                                    >
                                        {this.renderTableHeaderColumn(c)}
                                    </Popover>
                                ) : (
                                    this.renderTableHeaderColumn(c)
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    renderTableBody = (columns) => {
        const { data } = this.props;
        if (!Array.isArray(data) || data.length === 0) {
            return this.renderEmpty();
        }
        const { loadedArr, statMap } = this.state;
        const disabled = this.isDisabled();
        return (
            <div className="table-body table-part">
                {this.renderMasks()}
                {data.map((r, i) => {
                    const currentStatData = statMap[r.name];
                    const { min, max } = currentStatData || {};
                    const hasMiniChart =
                        currentStatData &&
                        typeof min === 'number' &&
                        typeof max === 'number';
                    return (
                        <div
                            className={classNames({
                                row: true,
                                'row-with-mini-chart': hasMiniChart,
                            })}
                            key={r.id}
                        >
                            {columns.map((c) => (
                                <div key={c.key} className="column">
                                    <div className="column-content">
                                        {loadedArr[i] || c.key === 'metric'
                                            ? c.render(r, disabled)
                                            : null}
                                    </div>
                                </div>
                            ))}
                        </div>
                    );
                })}
            </div>
        );
    };

    renderMobileTable = (columns) => {
        const { loadedArr } = this.state;
        const { data } = this.props;
        if (!Array.isArray(data) || data.length === 0) {
            return this.renderEmpty();
        }
        const { targetColumns, metricColumn, mainColumns, noteColumns } =
            columns;
        const disabled = this.isDisabled();
        return (
            <div className="table-mobile">
                {data.map((r, i) => {
                    const shouldRender = loadedArr[i];
                    return (
                        <div className="row-mobile" key={r.id}>
                            <div className="row-top">
                                {shouldRender &&
                                    targetColumns.map((c) => (
                                        <div key={c.key} className="item">
                                            <h5>{c.title || c.headerTitle}</h5>
                                            <div className="item-content">
                                                {c.render(r)}
                                            </div>
                                        </div>
                                    ))}
                            </div>
                            <div className="row-main">
                                <div className="metric-item item">
                                    <div className="item-content">
                                        {metricColumn.render(r)}
                                    </div>
                                </div>
                                <div className="main-items">
                                    {shouldRender &&
                                        [
                                            ...mainColumns,
                                            noteColumns[1],
                                            noteColumns[0],
                                        ].map((c) => (
                                            <div key={c.key} className="item">
                                                <h5>
                                                    {c.title || c.headerTitle}
                                                </h5>
                                                <div className="item-content">
                                                    {c.render(r, disabled)}
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    renderEmpty = () => {
        const { locale } = this.props;
        return (
            <div className="empty-table">
                <div className="empty-icon-wrap">
                    <File />
                </div>
                <p className="empty-note">{locale.no_data}</p>
            </div>
        );
    };

    render() {
        const columns = this.getColumns();
        const {
            oneTimeTutorialStep: step,
            mobileView,
            competitorMetrics,
            boardId,
            handleUpdateState,
            logo,
        } = this.props;
        const { selectedItem, statMap, allCompetitorsMap } = this.state;
        const disabled = this.isDisabled();
        return (
            <div className="optimizer_board_detail-table-container">
                {mobileView ? (
                    this.renderMobileTable(columns)
                ) : (
                    <div
                        className={classNames({
                            table: true,
                            [`table-with-${step > 2 ? 'right' : 'left'}-mask`]:
                                !!step,
                        })}
                    >
                        {this.renderTableHeader(columns)}
                        {this.renderTableBody(columns)}
                    </div>
                )}
                {selectedItem && (
                    <EditMetricModal
                        disabled={disabled}
                        logo={logo}
                        boardId={boardId}
                        competitors={competitorMetrics[selectedItem.name]}
                        selectedItem={selectedItem}
                        close={() => this.setState({ selectedItem: null })}
                        handleUpdateState={handleUpdateState}
                        handleUpdateAllCompetitorsMap={(m) =>
                            this.setState({
                                allCompetitorsMap: {
                                    ...allCompetitorsMap,
                                    ...m,
                                },
                            })
                        }
                        allCompetitorsScore={
                            allCompetitorsMap[selectedItem.name]
                        }
                        stat={statMap[selectedItem.name]}
                    />
                )}
            </div>
        );
    }
}

export default withRouter(
    connect(
        ({
            persist: { locale, firebaseUser },
            common: { clientWidth },
            router,
        }) => ({
            locale,
            router,
            mobileView: clientWidth < 1220,
            firebaseUser,
        })
    )(Table)
);
