import React from 'react';
import logo from '../../../assets/image/esgailogo_black.png';
import './index.scss';
import bg_governance from '../../../assets/image/bg_governance.png';
import earth_grey from '../../../assets/image/earth_grey.png';

const PasswordManage = (props) => {
    return (
        <div className="page password_manage_page">
            <img className="bg_img bg_desktop" src={bg_governance} alt="" />
            <img className="bg_img bg_mobile" src={earth_grey} alt="" />
            <div className="password_manage-content">
                <img src={logo} alt="ESG AI Logo" />
                {props.children}
            </div>
        </div>
    );
};

export default PasswordManage;
