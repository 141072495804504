import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import './index.scss';
import SearchInput from '../../../../component/SearchInput';
import Modal from '../../../../component/Modal';
import { Competitor } from '../../../../component/Icon';
import Button from '../../../../component/Button';

const AddCompetitorModal = (props) => {
    const { locale, handleAdd, handleCancel, competitorArr, account } = props;
    const [competitors, setCompetitors] = useState([]);
    useEffect(() => {
        const body = document.getElementsByTagName('body')[0];
        body.setAttribute('class', 'body_disable_scroll');
        return () => {
            body.removeAttribute('class');
        };
    }, []);
    return (
        <div className="add-competitor-modal-wrap">
            <Modal>
                <div className="modal-container">
                    <div className="modal-main">
                        <Competitor />
                        <h5 className="modal-title">
                            {locale.add_new_competitors}
                        </h5>
                        <p className="modal-title-note">
                            {locale.add_new_competitors_note}
                        </p>
                        <SearchInput
                            placeholder={locale.add_new_competitors}
                            tags={competitors}
                            tagIcon={Competitor}
                            tagDisplayKey="normalizedName"
                            updateTags={(tags) => setCompetitors(tags)}
                            maxTagsNum={10 - competitorArr.length}
                            reachMaxNote={locale.competitor_max_watch_title}
                            otherSelected={[
                                ...competitorArr.map((i) => ({
                                    ...i,
                                    id: i.organizationId,
                                })),
                                { id: account.organization.organizationId },
                            ]}
                        />
                    </div>
                    <div className="modal-actions">
                        <Button
                            label={locale.add}
                            onClick={() => handleAdd(competitors)}
                            disabled={competitors.length === 0}
                        />
                        <Button
                            type="link"
                            label={locale.cancel}
                            onClick={handleCancel}
                        />
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default connect(
    ({ persist: { locale, account }, common: { mobileView } }) => ({
        locale,
        mobileView,
        account,
    })
)(AddCompetitorModal);
