import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import cns from 'classnames';
import { isMobile } from 'react-device-detect';
import './index.scss';

class NotifyMsg extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            slideIn: false,
            slideOut: false,
            remove: false,
        };
        this.timer = null;
        this.start = null;
        this.remaining = 3400;
    }

    componentDidMount() {
        this.timer = window.setTimeout(() => {
            this.setState(
                {
                    slideIn: true,
                },
                () => {
                    this.resume();
                }
            );
        }, 100);
    }

    componentWillUnmount() {
        this.clearTimer();
    }

    pause = () => {
        this.clearTimer();
        this.remaining -= Date.now() - this.start;
    };

    resume = () => {
        const { handleMsgComplete } = this.props;
        this.start = Date.now();
        this.clearTimer();
        this.timer = window.setTimeout(() => {
            this.setState(
                {
                    slideOut: true,
                },
                () => {
                    this.clearTimer();
                    this.timer = window.setTimeout(() => {
                        this.setState({ remove: true }, () => {
                            this.clearTimer();
                            handleMsgComplete();
                        });
                    }, 800);
                }
            );
        }, this.remaining);
    };

    clearTimer = () => {
        window.clearTimeout(this.timer);
        this.timer = null;
    };

    render() {
        const { key, msg } = this.props;
        const { slideIn, slideOut, remove } = this.state;

        return (
            <div
                key={key}
                className={cns({
                    'esgai_notify-msg': true,
                    'esgai_notify-msg-slide-in': slideIn,
                    'esgai_notify-msg-slide-out': slideOut,
                    'esgai_notify-msg-remove': remove,
                })}
                onMouseEnter={!isMobile ? this.pause : null}
                onMouseLeave={!isMobile ? this.resume : null}
            >
                <div className="msg-container">{msg.content}</div>
            </div>
        );
    }
}

export default withRouter(
    connect(({ persist: { account, locale }, router }) => ({
        account,
        router,
        locale,
    }))(NotifyMsg)
);
