import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { Check, Plus } from '../../../../component/Icon';
import Modal from '../../../../component/Modal';
import './index.scss';
import Button from '../../../../component/Button';

class IndustriesOfInterest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showListModal: false,
            nextIndustryList: [],
        };
    }

    showAdd = () => {
        const {
            organizationInfo: { economicSectorTopics },
        } = this.props;
        this.setState({
            nextIndustryList: Array.isArray(economicSectorTopics)
                ? economicSectorTopics
                : [],
            showListModal: true,
        });
    };

    toggleCheck = (item) => {
        const { nextIndustryList } = this.state;
        let targetIndex = -1;
        nextIndustryList.find((i, index) => {
            if (i.trbc === item.trbc) {
                targetIndex = index;
                return true;
            }
            return false;
        });
        if (targetIndex === -1) {
            this.setState({
                nextIndustryList: [...nextIndustryList, item],
            });
        }
        // else {
        //     const newList = JSON.parse(JSON.stringify(nextIndustryList));
        //     newList.splice(targetIndex, 1);
        //     this.setState({
        //         nextIndustryList: newList,
        //     });
        // }
    };

    handleDelItem = (item) => {
        const {
            organizationInfo: {
                economicSectorTopics,
                defaultEconomicSectorTopic,
            },
            updateCompanyInfo,
            updateReset,
            locale,
        } = this.props;
        if (economicSectorTopics.length === 1) {
            updateCompanyInfo(
                defaultEconomicSectorTopic ? [defaultEconomicSectorTopic] : []
            );
            updateReset(
                locale.companyFieldReset(locale.industries_of_interest)
            );
            return;
        }
        const newList = JSON.parse(JSON.stringify(economicSectorTopics || []));
        let targetIndex = -1;
        newList.find((i, index) => {
            if (i.trbc === item.trbc) {
                targetIndex = index;
                return true;
            }
            return false;
        });
        newList.splice(targetIndex, 1);
        updateCompanyInfo(newList);
    };

    handleAdd = () => {
        const { nextIndustryList } = this.state;
        const { updateCompanyInfo } = this.props;
        updateCompanyInfo(nextIndustryList);
        this.setState({ showListModal: false });
    };

    render() {
        const { organizationInfo, companyEditable, industryList, locale } =
            this.props;
        const { economicSectorTopics = [] } = organizationInfo;
        const { showListModal, nextIndustryList } = this.state;
        return (
            <div className="industries-of-interest">
                {economicSectorTopics.map((i) => (
                    <div
                        className={classNames({
                            'industries-of-interest-item': true,
                            'industries-of-interest-item-edit': companyEditable,
                        })}
                        key={i.trbc}
                    >
                        <span title={i.title}>{i.title}</span>
                        {companyEditable && (
                            <button onClick={() => this.handleDelItem(i)} />
                        )}
                    </div>
                ))}
                {companyEditable ? (
                    <button
                        className="industries-of-interest-add"
                        onClick={this.showAdd}
                    >
                        <Plus style={{ fontSize: 16 }} />
                    </button>
                ) : (
                    economicSectorTopics.length === 0 && (
                        <div className="industries-of-interest-item">--</div>
                    )
                )}
                {showListModal && (
                    <Modal>
                        <h5>{locale.industries_of_interest}</h5>
                        <div className="industries-checkbox-list">
                            {industryList.map((i) => (
                                <div
                                    key={i.trbc}
                                    className={classNames({
                                        'industries-checkbox-list-item': true,
                                        'industries-checkbox-list-item-checked':
                                            !!nextIndustryList.find(
                                                (j) => j.trbc === i.trbc
                                            ),
                                    })}
                                    onClick={() => this.toggleCheck(i)}
                                >
                                    <div className="industries-checkbox-wrap">
                                        <Check
                                            color="FFFFFF"
                                            style={{ fontSize: 12 }}
                                        />
                                    </div>
                                    <p className="industries-checkbox-label">
                                        {i.title}
                                    </p>
                                </div>
                            ))}
                        </div>
                        <div className="industries-of-interest-modal-actions">
                            <Button
                                label={locale.add}
                                onClick={this.handleAdd}
                            />
                            <Button
                                type="link"
                                label={locale.cancel}
                                onClick={() =>
                                    this.setState({ showListModal: false })
                                }
                            />
                        </div>
                    </Modal>
                )}
            </div>
        );
    }
}

export default withRouter(
    connect(({ persist: { account, locale }, router }) => ({
        account,
        router,
        locale,
    }))(IndustriesOfInterest)
);
