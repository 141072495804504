import { formatYLabel, getYScope } from '../../utils';

const getChartOptions = (XArr, series, selectedIndex, mobileView) => {
    /** series item
        {
            name, highlight, data, validDataCount, id
        },
     */
    let hasValidData = false;
    let totalArr = [];
    series.forEach((s) => {
        if (s.validDataCount > 0) {
            hasValidData = true;
        }
        totalArr = totalArr.concat(s.data);
    });

    let scoreYLabelSettings = {
        min: 0,
        max: 100,
        interval: 25,
    };
    if (hasValidData) {
        const { max, min } = getYScope(
            totalArr.map((i) => (i ? i.value : null))
        );
        scoreYLabelSettings = {
            min,
            max,
            interval: (max - min) / 4,
        };
        if (max === min) {
            scoreYLabelSettings = {
                min: 0,
                max: 100,
                interval: 25,
            };
        }
    }

    let labelPadding = [8, 12];
    let axisLabelFontSize = 12;
    let tooltipFontSize = 14;
    let tooltipLineHeight = 18;
    let left = 64;
    let right = 30;
    let bottom = 50;
    let xLabelNum = 10;
    if (mobileView) {
        labelPadding = [6, 8];
        axisLabelFontSize = 10;
        tooltipFontSize = 12;
        tooltipLineHeight = 16;
        left = 48;
        right = 20;
        bottom = 36;
        xLabelNum = 6;
    }

    return {
        title: { show: false },
        legend: { show: false },
        grid: {
            show: false,
            top: 40,
            bottom,
            left,
            right,
        },
        xAxis: {
            type: 'category',
            boundaryGap: true,
            triggerEvent: true,
            axisLine: {
                lineStyle: {
                    color: 'transparent',
                    type: [5, 10],
                    dashOffset: 5,
                },
            },
            axisTick: {
                show: false,
            },
            axisLabel: {
                interval:
                    XArr.length <= xLabelNum
                        ? 0
                        : (index) => {
                              let gap = Math.ceil(XArr.length / xLabelNum);
                              if (gap === 1) {
                                  gap = 2;
                              }
                              return (XArr.length - 1 - index) % gap === 0;
                          },
                margin: 12,
                formatter: (value, index) => {
                    let date = '';
                    if (value.length <= 6) {
                        date = value;
                    }
                    const isActive =
                        (index >= selectedIndex[0] &&
                            index <= selectedIndex[1]) ||
                        selectedIndex.indexOf(index) > -1;
                    if (isActive) {
                        return '{dateActive|' + date + '}';
                    }
                    return '{date|' + date + '}';
                },
                rich: {
                    date: {
                        color: '#7a7a7a',
                        fontWeight: 'bold',
                        padding: labelPadding,
                        borderRadius: 5,
                        backgroundColor: 'transparent',
                        fontFamily: 'Mulish',
                        fontSize: axisLabelFontSize,
                    },
                    dateActive: {
                        color: '#233BC9',
                        fontWeight: 'bold',
                        padding: labelPadding,
                        borderRadius: 5,
                        backgroundColor: '#f3f3f3',
                        fontFamily: 'Mulish',
                        fontSize: axisLabelFontSize,
                    },
                },
            },
            axisPointer: {
                label: {
                    show: false,
                    formatter: () => '',
                },
                lineStyle: {
                    color: '#9C42CC',
                },
            },
            data: XArr,
        },
        yAxis: {
            type: 'value',
            boundaryGap: false,
            axisLine: {
                lineStyle: {
                    color: 'transparent',
                    type: [5, 10],
                    dashOffset: 5,
                },
            },
            axisTick: {
                show: false,
            },
            axisLabel: {
                formatter: (value) => {
                    return `${formatYLabel(value, scoreYLabelSettings)}`;
                },
                color: '#7A7A7A',
                fontFamily: 'Mulish',
                fontWeight: 700,
            },
            splitLine: {
                show: true,
                lineStyle: {
                    color: '#ebebeb',
                    type: [3, 6],
                    dashOffset: 3,
                },
            },
            axisPointer: {
                show: false,
            },
            ...scoreYLabelSettings,
        },
        tooltip: {
            show: true,
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                snap: true,
            },
            formatter: (arr) => {
                const rawDate = String(XArr[arr[0].dataIndex]);
                let date = '';
                if (rawDate.length <= 6) {
                    date = rawDate;
                }
                let content = '';
                series.forEach((i, index) => {
                    const target = arr.find((j) => j.seriesIndex === index);
                    const value =
                        target && typeof target.value === 'number'
                            ? target.value
                            : '--';
                    let grade = '';
                    if (
                        target &&
                        typeof target.dataIndex === 'number' &&
                        i.data[target.dataIndex]
                    ) {
                        grade = i.data[target.dataIndex].grade;
                    }
                    content += `<p style="display: flex; align-items: center">
                                    <b style="width: 4px; height: 4px; border-radius: 50%; margin-right: 8px; background-color: ${
                                        i.highlight.color
                                    }"></b>
                                    <span style="max-width: 120px; white-space: no-wrap; overflow: hidden; text-overflow: ellipsis">${
                                        i.name
                                    }</span>
                                    <span style="font-family: Montserrat; font-weight: 700; margin-left: 4px; color: ${
                                        i.highlight.color
                                    }">${value}${
                        grade ? ` ${grade}` : ''
                    }</span>
                                </p>`;
                });
                return `<div style="font-size: 12px; line-height: 20px; font-family: Mulish; color: #4D4D4D">
                            <p style="color: #7a7a7a; font-weight: 700; margin-bottom: 8px">${date}</p>
                            ${content}
                        </div>`;
            },
            backgroundColor: '#ffffff',
            padding: labelPadding,
            textStyle: {
                color: '#4D4D4D',
                fontWeight: 600,
                fontFamily: 'Montserrat',
                fontSize: tooltipFontSize,
                lineHeight: tooltipLineHeight,
            },
        },
        toolbox: {
            show: false,
        },
        series: series.map((s, index) => {
            const isMyself = s.id === 'myself';
            const isAvg = s.id === 'avg';
            return {
                type: 'line',
                lineStyle: {
                    color: s.highlight.color,
                    shadowColor: s.highlight.shadow,
                    shadowBlur: 4,
                    shadowOffsetY: 7,
                    width: isMyself ? 3 : 1.5,
                    type: isAvg ? 'dashed' : 'solid',
                },
                itemStyle: {
                    color: s.highlight.color,
                    borderColor: 'rgba(255, 255, 255, 0.9)',
                    borderWidth: 1.5,
                },
                emphasis: {
                    lineStyle: {
                        width: s.id === 'myself' ? 3 : 1.5,
                    },
                },
                symbol: 'circle',
                symbolSize: isMyself ? 7 : 6,
                // connectNulls: true,
                data: s.data.map((i) => (i ? i.value : null)),
                markLine:
                    index === 0
                        ? {
                              symbol: ['none', 'none'],
                              label: {
                                  show: true,
                                  formatter: (o) => {
                                      const rawDate = String(XArr[o.value]);
                                      let date = '';
                                      if (rawDate.length <= 6) {
                                          date = rawDate;
                                      }
                                      return date;
                                  },
                                  padding: labelPadding,
                                  color: '#233BC9',
                                  fontWeight: 600,
                                  fontFamily: 'Montserrat',
                                  fontSize: tooltipFontSize,
                                  lineHeight: tooltipLineHeight,
                                  backgroundColor: '#ffffff',
                                  shadowColor: '#dfdfdf',
                                  shadowBlur: 14,
                                  borderRadius: 5,
                              },
                              itemStyle: {
                                  color: '#233BC9',
                              },
                              data: selectedIndex.map((i) => ({
                                  xAxis: i,
                              })),
                          }
                        : {},
                markArea:
                    index === 0
                        ? {
                              label: {
                                  show: false,
                              },
                              itemStyle: {
                                  color: 'rgba(156, 66, 204, 0.1)',
                              },
                              data:
                                  selectedIndex.length === 2
                                      ? [
                                            selectedIndex.map((i) => ({
                                                xAxis: String(XArr[i]),
                                            })),
                                        ]
                                      : [],
                          }
                        : {},
            };
        }),
    };
};

export default getChartOptions;
