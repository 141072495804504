import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import cns from 'classnames';
import logo from '../../assets/image/esgailogo_black.png';
import './index.scss';
import Input from '../../component/Input';
import Button from '../../component/Button';
import SearchInput from '../../component/SearchInput';
import { User, Competitor, Partner } from '../../component/Icon';
import clover from '../../assets/image/bg_setup_profile_clover.png';
import service from '../../service';
import { accountUrl } from '../../constant/endpoint';
import { persistType } from '../../constant/actionTypes';

class SetupProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: {
                firstName: '',
                lastName: '',
                position: '',
                competitors: [],
                associations: [],
            },
            activeStepIndex: 0,
            loading: false,
        };
    }

    renderHeadSteps = () => {
        const steps = [
            {
                key: 'profileConfigured',
                icon: User,
            },
            {
                key: 'competitor',
                icon: Competitor,
            },
            {
                key: 'association',
                icon: Partner,
            },
        ];
        const { activeStepIndex } = this.state;
        return steps.map((i, index) => {
            const { key, icon: StepIcon } = i;
            return (
                <button
                    type="button"
                    key={key}
                    className={cns({
                        'step-btn': true,
                        'step-btn-complete': index < activeStepIndex,
                        'step-btn-active': activeStepIndex === index,
                    })}
                    disabled={index > activeStepIndex}
                    onClick={() => this.setState({ activeStepIndex: index })}
                >
                    <StepIcon
                        style={{ fontSize: 24 }}
                        color={index <= activeStepIndex ? '233BC9' : 'E6E6E6'}
                    />
                    <span className="step-index">{index + 1}</span>
                </button>
            );
        });
    };

    renderProfile = () => {
        const {
            profile: { firstName, lastName, position },
        } = this.state;
        const { locale } = this.props;
        return (
            <div className="setup_profile-step-content">
                <h5 style={{ marginBottom: 30 }}>
                    {locale.setup_profile_step1_title}
                </h5>
                <Input
                    id="setup_profile-first-name"
                    label={locale.first_name}
                    value={firstName}
                    onChange={(e) => this.handleInputChange(e, 'firstName')}
                    showRequired
                />
                <Input
                    id="setup_profile-last-name"
                    label={locale.last_name}
                    value={lastName}
                    onChange={(e) => this.handleInputChange(e, 'lastName')}
                    showRequired
                />
                <Input
                    id="setup_profile-position"
                    label={locale.position}
                    value={position}
                    onChange={(e) => this.handleInputChange(e, 'position')}
                />
            </div>
        );
    };

    handleInputChange = (e, field) => {
        const { profile } = this.state;
        this.setState({
            profile: { ...profile, [field]: e.target.value },
        });
    };

    renderCompetitor = () => {
        const { profile } = this.state;
        const { locale } = this.props;
        return (
            <div className="setup_profile-step-content">
                <h5>
                    <span>{locale.setup_profile_step2_title}</span>
                </h5>
                <p className="setup_profile-step-tip">
                    {locale.setup_profile_step2_note}
                </p>
                <SearchInput
                    placeholder={locale.add_competitors_placeholder}
                    tags={profile.competitors}
                    tagIcon={Competitor}
                    tagDisplayKey="normalizedName"
                    updateTags={(tags) =>
                        this.setState({
                            profile: { ...profile, competitors: tags },
                        })
                    }
                    maxTagsNum={3}
                />
            </div>
        );
    };

    renderPartner = () => {
        const { profile } = this.state;
        const { locale } = this.props;
        return (
            <div className="setup_profile-step-content">
                <h5>{locale.add_partners}</h5>
                <p className="setup_profile-step-tip">
                    {locale.setup_profile_step3_note}
                </p>
                <SearchInput
                    placeholder={locale.add_partners_placeholder}
                    tags={profile.associations}
                    tagIcon={Partner}
                    tagDisplayKey="normalizedName"
                    updateTags={(tags) =>
                        this.setState({
                            profile: { ...profile, associations: tags },
                        })
                    }
                />
            </div>
        );
    };

    handleNext = (remindMe = false) => {
        if (remindMe || !this.disableNextBtn()) {
            const { activeStepIndex } = this.state;
            if (activeStepIndex < 2) {
                this.setState({ activeStepIndex: activeStepIndex + 1 });
            } else {
                this.submit();
            }
        }
    };

    submit = async () => {
        const { profile } = this.state;
        const { competitors, associations } = profile;
        this.setState({ loading: true });
        try {
            const res = await service.put(accountUrl.PROFILE, {
                ...profile,
                competitors: competitors.map((i) => i.id),
                associations: associations.map((i) => i.id),
            });
            this.setState({ loading: false }, () => {
                this.props.dispatch({
                    type: persistType.APPLY_UPDATE_PROFILE,
                    payload: res,
                });
            });
        } catch (err) {
            console.log(err);
            this.setState({ loading: false });
        }
    };

    disableNextBtn = () => {
        const {
            profile: { firstName, lastName, competitors, associations },
            loading,
            activeStepIndex,
        } = this.state;
        if (
            loading ||
            (activeStepIndex === 0 && !(firstName && lastName)) ||
            (activeStepIndex === 1 && competitors.length === 0) ||
            (activeStepIndex === 2 && associations.length === 0)
        ) {
            return true;
        }
        return false;
    };

    handleRemindMe = () => {
        const { activeStepIndex, profile } = this.state;
        if (activeStepIndex === 1) {
            this.setState({
                profile: {
                    ...profile,
                    competitors: [],
                },
                activeStepIndex: 2,
            });
        } else {
            this.setState(
                {
                    profile: {
                        ...profile,
                        associations: [],
                    },
                },
                () => {
                    this.handleNext(true);
                }
            );
        }
    };

    render() {
        const { activeStepIndex, loading } = this.state;
        const { locale } = this.props;
        return (
            <div className="page setup_profile_page">
                <Helmet title={locale.setup_profile} />
                <div className="setup_profile-content">
                    <img
                        className="setup_profile-logo"
                        src={logo}
                        alt="ESG AI Logo"
                    />
                    <h3 className="setup_profile-title">
                        {locale.setup_profile_title}
                    </h3>
                    <div className="setup_profile-form">
                        <div>
                            <div
                                className={cns({
                                    'setup_profile-steps': true,
                                    [`setup_profile-steps-${activeStepIndex}`]: true,
                                })}
                            >
                                {this.renderHeadSteps()}
                            </div>
                            {activeStepIndex === 0 && this.renderProfile()}
                            {activeStepIndex === 1 && this.renderCompetitor()}
                            {activeStepIndex === 2 && this.renderPartner()}
                        </div>
                        <div className="setup_profile-actions">
                            <Button
                                disabled={this.disableNextBtn()}
                                loading={loading}
                                label={
                                    activeStepIndex < 2
                                        ? locale.next
                                        : locale.done
                                }
                                onClick={this.handleNext}
                                onClickEnter={this.handleNext}
                            />
                            {activeStepIndex > 0 && (
                                <Button
                                    type="link"
                                    disabled={false}
                                    label={locale.setup_profile_remind}
                                    onClick={this.handleRemindMe}
                                />
                            )}
                        </div>
                    </div>
                    <img
                        className="setup_profile-bg-clover"
                        src={clover}
                        alt=""
                    />
                </div>
            </div>
        );
    }
}

export default withRouter(
    connect(({ router, persist: { account, locale } }) => ({
        router,
        account,
        locale,
    }))(SetupProfile)
);
