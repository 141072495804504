import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Check, Close, Filter } from '../Icon';
import './index.scss';
import Button from '../Button';
import DatePicker from '../DatePicker';

const CheckBox = (props) => {
    const { label, checked, handleClick, value } = props;
    return (
        <div
            className={classNames({
                'news_filter-checkbox': true,
                'news_filter-checkbox-all-checked': value === 'ALL' && checked,
            })}
        >
            <div className="checkbox-main" onClick={handleClick}>
                <div
                    className={classNames({
                        checkbox: true,
                        'checkbox-checked': checked,
                    })}
                >
                    <Check />
                </div>
                <span className="checkbox-label">{label}</span>
            </div>
        </div>
    );
};

const allTypes = ['M:1QP', 'M:1QR', 'M:1QQ', 'NON_ESG'];

class NewsFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showMobileFilter: false,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const { mobileView } = this.props;
        const { mobileView: prevMobileView } = prevProps;
        const { showMobileFilter } = this.state;
        const { showMobileFilter: prevShowMobileFilter } = prevState;
        if (prevMobileView && !mobileView && showMobileFilter) {
            this.toggleOpenMobileFilter(false);
        }
        if (mobileView && !prevShowMobileFilter && showMobileFilter) {
            const body = document.getElementsByTagName('body')[0];
            body.setAttribute('class', 'body_disable_scroll');
        }
        if (prevShowMobileFilter && !showMobileFilter) {
            const body = document.getElementsByTagName('body')[0];
            body.removeAttribute('class');
            if (!mobileView) {
                window.scrollTo({
                    top: 0,
                });
            }
        }
    }

    handleUpdateFilter = (data, cb, forceResetPage = false) => {
        const { mobileView, updateParentState } = this.props;
        const newState =
            forceResetPage || !mobileView ? { ...data, page: 0 } : data;
        if (typeof cb === 'function') {
            updateParentState(newState, cb);
        } else {
            updateParentState(newState);
        }
    };

    handleClickNewsType = (i) => {
        const { key } = i;
        const { newsTypes, onlyControversial, mobileView } = this.props;
        const onlyControversialKey = mobileView
            ? 'nextOnlyControversial'
            : 'onlyControversial';
        const newsTypesKey = mobileView ? 'nextNewsTypes' : 'newsTypes';
        if (key === 'M:1QI') {
            this.handleUpdateFilter({
                [onlyControversialKey]: !onlyControversial,
            });
        } else {
            if (key === 'ALL') {
                if (
                    !(
                        newsTypes.length === 0 ||
                        newsTypes.length === allTypes.length
                    )
                ) {
                    this.handleUpdateFilter({
                        [newsTypesKey]: allTypes,
                    });
                }
            } else {
                if (newsTypes.indexOf(key) > -1) {
                    if (newsTypes.length > 1) {
                        this.handleUpdateFilter({
                            [newsTypesKey]: newsTypes.filter((i) => i !== key),
                        });
                    } else {
                        this.handleUpdateFilter({
                            [newsTypesKey]: allTypes,
                        });
                    }
                } else {
                    if (newsTypes.length === 0) {
                        this.handleUpdateFilter({
                            [newsTypesKey]: allTypes.filter((i) => i !== key),
                        });
                    } else {
                        this.handleUpdateFilter({
                            [newsTypesKey]: [...newsTypes, key],
                        });
                    }
                }
            }
        }
    };

    processNewsTypeChecked = (i) => {
        const { key } = i;
        const { newsTypes, onlyControversial } = this.props;
        if (key === 'M:1QI') {
            return onlyControversial;
        } else {
            if (
                newsTypes.length === 0 ||
                newsTypes.length === allTypes.length
            ) {
                return true;
            } else if (key === 'ALL') {
                return false;
            } else {
                return newsTypes.indexOf(key) > -1;
            }
        }
    };

    processItemChecked = (item, group) => {
        const { selected, list, valueKey } = group;
        if (selected.length === 0 || selected.length === list.length) {
            return true;
        } else {
            if (item[valueKey] === 'ALL') {
                return false;
            } else {
                return selected.find((i) => i[valueKey] === item[valueKey]);
            }
        }
    };

    handleClickItem = (item, group) => {
        const { selected, list, valueKey, stateKey } = group;
        const key = item[valueKey];
        if (key === 'ALL') {
            if (!(selected.length === 0 || selected.length === list.length)) {
                this.handleUpdateFilter({
                    [stateKey]: list,
                });
            }
        } else {
            if (!!selected.find((i) => i[valueKey] === key)) {
                if (selected.length > 1) {
                    this.handleUpdateFilter({
                        [stateKey]: selected.filter((i) => i[valueKey] !== key),
                    });
                } else {
                    this.handleUpdateFilter({
                        [stateKey]: list,
                    });
                }
            } else {
                if (selected.length === 0) {
                    this.handleUpdateFilter({
                        [stateKey]: list.filter((i) => i[valueKey] !== key),
                    });
                } else {
                    this.handleUpdateFilter({
                        [stateKey]: [...selected, item],
                    });
                }
            }
        }
    };

    toggleOpenMobileFilter = (show) => {
        const { showMobileFilter } = this.state;
        this.setState({
            showMobileFilter:
                typeof show === 'boolean' ? show : !showMobileFilter,
        });
    };

    handleShowMobileFilter = () => {
        this.props.handleShowMobileFilter(() =>
            this.toggleOpenMobileFilter(true)
        );
    };

    handleApply = () => {
        const { handleApplyMobileFilter } = this.props;
        this.setState({ showMobileFilter: false }, handleApplyMobileFilter);
    };

    render() {
        const { showMobileFilter } = this.state;
        const { period, locale, otherFilters, mobileView } = this.props;
        const newsTypesArr = [
            {
                key: 'ALL',
                label: locale.all_news,
            },
            {
                key: 'M:1QP',
                label: locale.environmental,
            },
            {
                key: 'M:1QR',
                label: locale.social,
            },
            {
                key: 'M:1QQ',
                label: locale.governance,
            },
            {
                key: 'NON_ESG',
                label: locale.non_esg_related,
            },
            {
                key: 'M:1QI',
                label: locale.only_controversial,
            },
        ];
        return (
            <div className="news_filter">
                <DatePicker
                    values={period}
                    setDate={(period, cb) =>
                        this.handleUpdateFilter({ period }, cb, true)
                    }
                />
                <button
                    className={classNames({
                        'filter-mobile-entry': true,
                        'filter-mobile-entry-open': showMobileFilter,
                    })}
                    onClick={this.handleShowMobileFilter}
                >
                    <Filter />
                    <span>{locale.filter}</span>
                </button>
                <div
                    className={classNames({
                        'news_filter-container': true,
                        'news_filter-container-mobile-show':
                            mobileView && showMobileFilter,
                        'news_filter-container-mobile-hide':
                            mobileView && !showMobileFilter,
                    })}
                >
                    <div className="news_filter-content">
                        <div className="filter-header">
                            <div className="header-left">
                                <Filter />
                                <span>{locale.filter}</span>
                            </div>
                            <button
                                onClick={() => {
                                    this.toggleOpenMobileFilter(false);
                                }}
                            >
                                <Close />
                            </button>
                        </div>
                        <div className="filter-main">
                            <div className="filter-group">
                                <h6 className="group-title">
                                    {locale.news_type}
                                </h6>
                                <div className="group-content news-group-content">
                                    {newsTypesArr.map((i) => (
                                        <CheckBox
                                            key={i.key}
                                            value={i.key}
                                            {...i}
                                            checked={this.processNewsTypeChecked(
                                                i
                                            )}
                                            handleClick={() =>
                                                this.handleClickNewsType(i)
                                            }
                                        />
                                    ))}
                                </div>
                            </div>
                            {Array.isArray(otherFilters) &&
                                otherFilters.length > 0 &&
                                otherFilters.map((j) => {
                                    if (j.list.length === 0) {
                                        return null;
                                    }
                                    return (
                                        <div
                                            className="filter-group"
                                            key={j.title}
                                        >
                                            <h6 className="group-title">
                                                {j.title}
                                            </h6>
                                            <div className="group-content">
                                                {[
                                                    {
                                                        [j.labelKey]:
                                                            j.itemAllLabel,
                                                        [j.valueKey]: 'ALL',
                                                    },
                                                    ...j.list,
                                                ].map((i) => (
                                                    <CheckBox
                                                        key={i[j.valueKey]}
                                                        value={i[j.valueKey]}
                                                        label={i[j.labelKey]}
                                                        checked={this.processItemChecked(
                                                            i,
                                                            j
                                                        )}
                                                        handleClick={() =>
                                                            this.handleClickItem(
                                                                i,
                                                                j
                                                            )
                                                        }
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                        <div className="filter-actions">
                            <Button
                                label={locale.apply}
                                onClick={this.handleApply}
                                disabled={false}
                            />
                            <Button
                                type="link"
                                label={locale.cancel}
                                onClick={() =>
                                    this.toggleOpenMobileFilter(false)
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(({ persist: { locale }, common: { mobileView } }) => ({
    locale,
    mobileView,
}))(NewsFilter);
