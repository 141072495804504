import React from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as echarts from 'echarts';
import cns from 'classnames';
import { commonType } from '../../constant/actionTypes';
import { dashboardUrl } from '../../constant/endpoint';
import service from '../../service';
import './index.scss';
import earth from '../../assets/image/earth.png';
import {
    ArrowDownFull,
    Environmental,
    Governance,
    Social,
    Minus,
    // Newspaper,
    Business,
    Competitor,
} from '../../component/Icon';
import getMainChartOptions from './mainChartOptions';
import getPillarChartOptions from './pillarChartOptions';
// import NewsWithoutImg from '../../component/NewsWithoutImg';
// import NewsEmpty from '../../component/NewsEmpty';
import getCompetitorChartOptions from './competitorChartOption';
import Button from '../../component/Button';
import Popover from '../../component/Popover';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            stockPrice: '',
            stockLabel: '',
            mobileActivePillar: 0,
            pillarTouchStart: null,
        };
    }
    componentDidMount() {
        this.fetchData();
        this.chartListener();
    }

    componentDidUpdate(prevProps, prevState) {
        const { mobileView } = this.props;
        const { mobileView: prevMobileView } = prevProps;
        if (mobileView !== prevMobileView) {
            this.renderCharts();
        }
    }

    componentWillUnmount() {
        this.resizeObserver.disconnect();
    }

    chartListener = () => {
        this.resizeObserver = new ResizeObserver(() => {
            this.resizeCharts();
        });
        this.resizeObserver.observe(
            document.getElementsByClassName('dashboard-main')[0]
        );
    };

    resizeCharts = () => {
        if (this.mainChart) {
            this.mainChart.resize();
        }
        if (this.ePillarChart) {
            this.ePillarChart.resize();
        }
        if (this.sPillarChart) {
            this.sPillarChart.resize();
        }
        if (this.gPillarChart) {
            this.gPillarChart.resize();
        }
        const {
            data: { competitors = [] },
        } = this.state;
        if (competitors.length > 0) {
            competitors.forEach((i) => {
                if (this[`competitor-chart-${i.organizationId}`]) {
                    this[`competitor-chart-${i.organizationId}`].resize();
                }
            });
        }
    };

    getChartListData = (list) => {
        const { locale } = this.props;
        let XArr = [];
        let YArr = [];
        if (list.length > 0) {
            list.forEach((i, index) => {
                XArr.push(index === list.length - 1 ? locale.latest : i.year);
                YArr.push(this.formatScore(i));
            });
        } else {
            const yearOfToday = new Date().getFullYear();
            XArr = [
                ...[...Array(10).keys()].map((i) => yearOfToday - (10 - i)),
                locale.latest,
            ];
            YArr = XArr.map((i) => null);
        }
        return { XArr, YArr };
    };

    renderMainChart = () => {
        const {
            data: { esg = { ESGScore: [] } },
        } = this.state;
        const { locale, mobileView } = this.props;
        const { XArr, YArr } = this.getChartListData(esg.ESGScore);
        if (!this.mainChart) {
            this.mainChart = echarts.init(
                document.getElementById('market-cap-and-score-chart')
            );
        }
        this.mainChart.setOption(
            getMainChartOptions(XArr, YArr, locale, mobileView)
        );
    };

    renderPillarChart = (pillar, chartKey, id, color, shadowColor) => {
        const {
            data: {
                esg = {
                    [pillar]: [],
                },
            },
        } = this.state;
        const { mobileView } = this.props;
        const { XArr, YArr } = this.getChartListData(esg[pillar]);
        if (!this[chartKey]) {
            this[chartKey] = echarts.init(document.getElementById(id));
        }
        this[chartKey].setOption(
            getPillarChartOptions(XArr, YArr, color, shadowColor, mobileView)
        );
    };

    renderCompetitorChart = (item) => {
        const {
            esg = {
                ESGScore: [],
                EnvironmentPillarScore: [],
                GovernancePillarScore: [],
                SocialPillarScore: [],
            },
            organizationId,
        } = item;
        const {
            ESGScore,
            EnvironmentPillarScore,
            GovernancePillarScore,
            SocialPillarScore,
        } = esg;
        if (
            EnvironmentPillarScore.length === 0 &&
            SocialPillarScore.length === 0 &&
            GovernancePillarScore.length === 0
        ) {
            return;
        }
        const key = `competitor-chart-${organizationId}`;
        const XArr = [];
        const EArr = [];
        const SArr = [];
        const GArr = [];
        for (let i = 0; i < ESGScore.length; i += 1) {
            const overall = ESGScore[i];
            const e = EnvironmentPillarScore[i];
            const s = SocialPillarScore[i];
            const g = GovernancePillarScore[i];
            XArr.push(
                i === ESGScore.length - 1
                    ? this.props.locale.latest
                    : overall.year
            );
            EArr.push(this.formatScore(e));
            SArr.push(this.formatScore(s));
            GArr.push(this.formatScore(g));
        }
        if (!this[key]) {
            this[key] = echarts.init(document.getElementById(key));
        }
        this[key].setOption(getCompetitorChartOptions(XArr, EArr, SArr, GArr));
    };

    formatScore = (item) => {
        if (!item || typeof item.numberValue !== 'number') {
            return null;
        } else {
            return Number((parseFloat(item.numberValue) * 100).toFixed(2));
        }
    };

    renderCharts = () => {
        this.renderMainChart();
        this.renderPillarChart(
            'EnvironmentPillarScore',
            'ePillarChart',
            'e-score-chart',
            '#838D72',
            'rgba(116, 134, 86, 0.2)'
        );
        this.renderPillarChart(
            'SocialPillarScore',
            'sPillarChart',
            's-score-chart',
            '#799EB2',
            'rgba(80, 116, 136, 0.2)'
        );
        this.renderPillarChart(
            'GovernancePillarScore',
            'gPillarChart',
            'g-score-chart',
            '#BA782C',
            'rgba(149, 100, 44, 0.2)'
        );
        const {
            data: { competitors = [] },
        } = this.state;
        if (competitors.length > 0) {
            competitors.forEach((i) => {
                this.renderCompetitorChart(i);
            });
        }
    };

    fetchData = async () => {
        this.toggleGlobalLoading(true);
        try {
            const res = await service.get(dashboardUrl.DASHBOARD);
            if (res && res.name) {
                this.setState({ data: res }, () => {
                    this.renderCharts();
                });
            }
        } catch (err) {
            console.log(err);
        }
        this.toggleGlobalLoading(false);
    };

    toggleGlobalLoading = (globalLoading) => {
        this.props.dispatch({
            type: commonType.APPLY_SET_COMMON_STATE,
            payload: {
                globalLoading,
            },
        });
    };

    renderHeader = () => {
        const {
            data: { name, logo, logoUrl, esgScore },
        } = this.state;
        const { locale } = this.props;
        return (
            <div className="header">
                <div className="header-main">
                    <img alt="" src={earth} className="header-main-bg" />
                    <div className="header-left">
                        {logo !== undefined && (
                            <div className="header-logo-wrap">
                                {logo && logoUrl ? (
                                    <img alt="" src={logoUrl} />
                                ) : (
                                    <Business
                                        color="C8C8C9"
                                        style={{ fontSize: 32 }}
                                    />
                                )}
                            </div>
                        )}
                        <span title={name}>{name}</span>
                    </div>
                    <div className="header-right">
                        <div className="header-score-wrap">
                            <span className="header-score-title">
                                {locale.latest_esg_score}
                            </span>
                            <p className="header-score">
                                <b>
                                    {(esgScore && esgScore.displayValue) ||
                                        '--'}
                                    {` / `}
                                </b>
                                <span> 100</span>
                            </p>
                        </div>
                        <span className="header-score-grade no-wrap-text">
                            {(esgScore && esgScore.grade) || '--'}
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    processTranslate = (i) => {
        const { mobileActivePillar } = this.state;
        const gap = i - mobileActivePillar;
        if (gap === 0) {
            return '0px';
        }
        const percent = `${gap * 100}%`;
        const px = `${4 * gap}px`;
        return `calc(${percent} + ${px})`;
    };

    handlePillarTouchMove = (e) => {
        const { pillarTouchStart, mobileActivePillar } = this.state;
        const { touches, target } = pillarTouchStart || {};
        if (!pillarTouchStart || target !== e.target) {
            return;
        }
        const [{ clientX: startX }] = touches;
        const [{ clientX: currentX }] = e.touches;
        if (currentX - startX < -20 && mobileActivePillar < 2) {
            this.setState({
                mobileActivePillar: mobileActivePillar + 1,
                pillarTouchStart: null,
            });
        }
        if (currentX - startX > 20 && mobileActivePillar > 0) {
            this.setState({
                mobileActivePillar: mobileActivePillar - 1,
                pillarTouchStart: null,
            });
        }
    };

    renderTop = () => {
        const { locale, mobileView } = this.props;
        const {
            data: { esg = {} },
            mobileActivePillar,
        } = this.state;
        const esgCharts = [
            {
                title: locale.environmental,
                icon: <Environmental color="838D72" />,
                field: 'EnvironmentPillarScore',
                id: 'e-score-chart',
                color: '#838D72',
            },
            {
                title: locale.social,
                icon: <Social color="799EB2" />,
                field: 'SocialPillarScore',
                id: 's-score-chart',
                color: '#799EB2',
            },
            {
                title: locale.governance,
                icon: <Governance color="BA782C" />,
                field: 'GovernancePillarScore',
                id: 'g-score-chart',
                color: '#BA782C',
            },
        ];
        return (
            <div className="main-top">
                <div className="top-left-wrap">
                    <h6 className="top-left-title-wrap">
                        <span className="title">{locale.esg_score}</span>
                    </h6>
                    <div className="top-left">
                        <div className="top-left-chart-wrap">
                            <div className="top-left-chart-title">
                                <span></span>
                                <div className="chart-legend">
                                    <span>{locale.esg_score}</span>
                                </div>
                            </div>
                            <div
                                className="top-left-chart"
                                id="market-cap-and-score-chart"
                            />
                        </div>
                    </div>
                </div>
                <div className="top-right">
                    <h6 className="top-right-title-wrap">
                        <span className="title">{locale.esg_trend}</span>
                        <Popover
                            tooltip={locale.esg_trend_note}
                            infoIconSize={18}
                        />
                    </h6>
                    <div className="top-right-charts">
                        {esgCharts.map((i, index) => {
                            const dataArr = esg[i.field] || [];
                            let score = '--';
                            let trend = null;
                            const { length } = dataArr;
                            if (length > 0) {
                                const { displayValue = '--', comparePrevious } =
                                    dataArr[length - 1] || {};
                                score = displayValue;
                                if (typeof comparePrevious === 'number') {
                                    trend = comparePrevious;
                                }
                            }
                            return (
                                <div
                                    className="pillar-item"
                                    onTouchStart={(e) => {
                                        this.setState({
                                            pillarTouchStart: e,
                                        });
                                    }}
                                    onTouchMove={this.handlePillarTouchMove}
                                    key={i.id}
                                    style={
                                        mobileView
                                            ? {
                                                  transform: `translateX(calc(${this.processTranslate(
                                                      index
                                                  )}))`,
                                                  height:
                                                      mobileActivePillar ===
                                                      index
                                                          ? 220
                                                          : 200,
                                              }
                                            : {}
                                    }
                                >
                                    <div className="pillar-item-header">
                                        <div className="pillar-header-left">
                                            {i.icon}
                                            <span
                                                className="pillar-header-title"
                                                style={{ color: i.color }}
                                            >
                                                {i.title}
                                            </span>
                                        </div>
                                        <div className="pillar-header-right">
                                            <span className="pillar-header-score no-wrap-text">
                                                {score}
                                            </span>
                                            {typeof trend === 'number' && (
                                                <span
                                                    className={cns({
                                                        'pillar-header-trend': true,
                                                        'pillar-header-trend-equal':
                                                            trend === 0,
                                                        'pillar-header-trend-up':
                                                            trend > 0,
                                                        'pillar-header-trend-down':
                                                            trend < 0,
                                                    })}
                                                >
                                                    {trend === 0 ? (
                                                        <Minus />
                                                    ) : (
                                                        <ArrowDownFull />
                                                    )}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        id={i.id}
                                        className="pillar-item-chart"
                                    />
                                </div>
                            );
                        })}
                    </div>
                    {mobileView && (
                        <div className="pillar-charts-dots">
                            {[...new Array(3).keys()].map((i) => (
                                <p
                                    key={i}
                                    className={cns({
                                        dot: true,
                                        [`active-dot-${i} active-dot`]:
                                            mobileActivePillar === i,
                                    })}
                                />
                            ))}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    renderBottom = () => {
        const { locale, history } = this.props;
        const {
            data: { competitors = [] },
        } = this.state;
        return (
            <div className="main-bottom">
                <h5 className="competitors-title">{locale.top_competitors}</h5>
                <div className="competitors-list">
                    {competitors.length > 0 ? (
                        competitors.map((i) => {
                            const {
                                esg = {
                                    ESGScore: [],
                                    EnvironmentPillarScore: [],
                                    GovernancePillarScore: [],
                                    SocialPillarScore: [],
                                },
                                logo,
                                logoUrl,
                                name,
                                organizationId,
                            } = i;
                            const {
                                ESGScore,
                                EnvironmentPillarScore,
                                GovernancePillarScore,
                                SocialPillarScore,
                            } = esg;
                            return (
                                <div
                                    className="competitors-item"
                                    key={organizationId}
                                >
                                    <div className="competitor-header">
                                        <div className="competitor-header-left">
                                            <div className="competitor-logo-wrap">
                                                {logo && logoUrl ? (
                                                    <img alt="" src={logoUrl} />
                                                ) : (
                                                    <Business
                                                        color="C8C8C9"
                                                        style={{ fontSize: 23 }}
                                                    />
                                                )}
                                            </div>
                                            <span
                                                className="competitor-name"
                                                title={name}
                                            >
                                                {name}
                                            </span>
                                        </div>
                                        <div className="competitor-header-right">
                                            <span className="competitor-header-score no-wrap-text">
                                                {ESGScore.length > 0
                                                    ? ESGScore[
                                                          ESGScore.length - 1
                                                      ].displayValue
                                                    : '--'}
                                            </span>
                                            <span className="competitor-header-score-label">
                                                {locale.overall}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="competitor-chart-wrap">
                                        {EnvironmentPillarScore.length === 0 &&
                                        SocialPillarScore.length === 0 &&
                                        GovernancePillarScore.length === 0 ? (
                                            <div className="competitor-chart-placeholder">
                                                N/A
                                            </div>
                                        ) : (
                                            <>
                                                <p className="competitor-chart-title">
                                                    <span>E</span>
                                                    <span>S</span>
                                                    <span>G</span>
                                                </p>
                                                <div
                                                    id={`competitor-chart-${organizationId}`}
                                                    className="competitor-chart"
                                                />
                                            </>
                                        )}
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <div className="add-competitors-wrap">
                            <div className="add-competitors-left">
                                <div className="competitors-icon-wrap">
                                    <Competitor
                                        style={{ fontSize: 48 }}
                                        color="BFBFBF"
                                    />
                                </div>
                                <p>{locale.add_competitor_note}</p>
                            </div>
                            <Button
                                label={locale.add_competitors}
                                onClick={() =>
                                    history.push('/settings/competitor')
                                }
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    };

    render() {
        const { locale } = this.props;
        return (
            <div className="page dashboard_page">
                <Helmet title={locale.dashboard} />
                {this.renderHeader()}
                <div className="dashboard-main">
                    <div className="content-wrap">
                        {this.renderTop()}
                        {/* {this.renderMiddle()} */}
                        {this.renderBottom()}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(
    connect(
        ({
            persist: { account, oneTimeNote, locale },
            common: { mobileView },
            router,
        }) => ({
            account,
            locale,
            router,
            oneTimeNote,
            mobileView,
        })
    )(Dashboard)
);
