import React from 'react';
import cns from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import classNames from 'classnames';
import './index.scss';
import service from '../../service';
import { accountUrl, profileUrl } from '../../constant/endpoint';
import {
    Search,
    Loading,
    Close,
    Star,
    Trash,
    Business,
} from '../../component/Icon';
import SideMenuLayoutPage from '../../component/layouts/SideMenuLayoutPage';
import { commonType } from '../../constant/actionTypes';
import Modal from '../../component/Modal';
import Button from '../../component/Button';
import Popover from '../../component/Popover';

class ManageRelatedCompany extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchedValue: '',
            searching: false,
            searchedList: null,
            companyList: [],
            loading: false,
            waitForUndoItem: null,
            modalContent: null, // 'maxWatched', 'maxMonitor', {} item to be removed
            deleting: false,
            headerHeight: 0,
        };
        this.timer = null;
        this.inputRef = React.createRef();
        this.headerRef = React.createRef();
    }

    componentDidMount() {
        this.setHeaderHeight();
        this.fetchRelatedCompany();
        window.addEventListener('click', this.tryHideDropdown);
    }

    componentDidUpdate(prevProps, prevState) {
        const { mobileView } = this.props;
        const { mobileView: prevMobileView } = prevProps;
        if (mobileView !== prevMobileView) {
            this.setHeaderHeight();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.tryHideDropdown);
    }

    tryHideDropdown = (e) => {
        const { searchedList } = this.state;
        const path = e.path || (e.composedPath && e.composedPath());
        if (
            path.indexOf(this.inputRef.current) < 0 &&
            Array.isArray(searchedList)
        ) {
            this.setState({
                searchedValue: '',
                searchedList: null,
            });
        }
    };

    setHeaderHeight = () => {
        if (this.headerRef && this.headerRef.current) {
            setTimeout(() => {
                this.setState({
                    headerHeight: this.headerRef.current.offsetHeight,
                });
            }, 300);
        }
    };

    handleInputFocus = () => {
        const { searchedValue, searchedList } = this.state;
        if (searchedValue.trim() && !searchedList) {
            this.searchList(searchedValue);
        }
    };

    fetchRelatedCompany = async () => {
        this.toggleGlobalLoading(true);
        try {
            const res = await service.get(this.getEndpoints().ITEMS);
            if (Array.isArray(res)) {
                this.setState({
                    companyList: res,
                });
            }
        } catch (err) {
            console.log(err);
        }
        this.toggleGlobalLoading(false);
    };

    toggleGlobalLoading = (globalLoading) => {
        this.props.dispatch({
            type: commonType.APPLY_SET_COMMON_STATE,
            payload: {
                globalLoading,
            },
        });
    };

    getEndpoints = () => {
        const { MANAGE_COMPANY } = profileUrl;
        const key = this.isCompetitor() ? 'COMPETITORS' : 'ASSOCIATIONS';
        return MANAGE_COMPANY[key];
    };

    renderSearchInput = () => {
        const text = this.getPageText();
        const { searchedValue, searching, searchedList, companyList } =
            this.state;
        const {
            account: { organization },
        } = this.props;
        return (
            <div
                className="esgai_company-search-input-wrap"
                ref={this.inputRef}
            >
                <input
                    onFocus={this.handleInputFocus}
                    placeholder={text.searchInputPlaceholder}
                    maxLength={60}
                    autoComplete="off"
                    type="text"
                    value={searchedValue}
                    onChange={this.onChange}
                />
                <span
                    className={cns({
                        'esgai_company-search-input-icon': true,
                        'esgai_company-search-input-icon-loading': searching,
                    })}
                >
                    {searching ? (
                        <Loading style={{ fontSize: 16 }} color="233BC9" />
                    ) : (
                        <Search style={{ fontSize: 16 }} color="4D4D4D" />
                    )}
                </span>
                {searchedValue && !searching && (
                    <button
                        onClick={() => {
                            this.setState({
                                searchedValue: '',
                                searchedList: null,
                            });
                        }}
                        className="esgai_company-search-input-clear"
                    >
                        <Close style={{ fontSize: 12 }} color="111111" />
                    </button>
                )}
                {Array.isArray(searchedList) && (
                    <div className="esgai_company-search-list esgai_scrollbar">
                        {searchedList.length > 0 ? (
                            searchedList.map((i) => {
                                const isSelected =
                                    !!companyList.find(
                                        (com) => com.organizationId === i.id
                                    ) || i.id === organization.organizationId;
                                return (
                                    <p
                                        className={cns({
                                            'esgai_company-search-item': true,
                                            'esgai_company-search-item-selected':
                                                isSelected,
                                        })}
                                        key={i.id}
                                        onClick={() =>
                                            this.addCompany(i, isSelected)
                                        }
                                    >
                                        {i.normalizedName}
                                    </p>
                                );
                            })
                        ) : (
                            <p className="esgai_company-search-list-empty">
                                {this.props.locale.setup_profile_no_result}
                            </p>
                        )}
                    </div>
                )}
            </div>
        );
    };

    onChange = (e) => {
        const {
            target: { value },
        } = e;
        this.setState(
            {
                searchedValue: value,
            },
            () => {
                this.searchList(value);
            }
        );
    };

    clearTimer = () => {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
    };

    searchList = (name) => {
        this.clearTimer();
        if (!name.trim()) {
            this.setState({
                searchedList: null,
            });
            return;
        }
        this.timer = setTimeout(async () => {
            this.setState({ searching: true });
            try {
                const res = await service.get(accountUrl.ORGANIZATIONS, {
                    name,
                    size: 100,
                });
                if (
                    res &&
                    Array.isArray(res.data) &&
                    this.state.searchedValue.trim()
                ) {
                    this.setState({
                        searchedList: res.data,
                    });
                }
            } catch (err) {
                console.log(err);
            }
            this.setState({ searching: false });
        }, 800);
    };

    addCompany = async (item, isSelected, isUndo = false) => {
        if (!isSelected) {
            this.setState({
                searchedValue: '',
                searchedList: null,
            });
            const { companyList } = this.state;
            if (companyList.length < 10) {
                try {
                    this.toggleGlobalLoading(true);
                    await service.post(this.getEndpoints().ITEMS, {
                        organizationId: isUndo ? item.organizationId : item.id,
                    });
                    this.setState({ waitForUndoItem: null });
                    this.fetchRelatedCompany();
                } catch (err) {
                    this.toggleGlobalLoading(false);
                    console.log(err);
                }
            } else {
                this.setState({ modalContent: 'maxWatched' });
            }
        }
    };

    toggleStarCompany = async (item) => {
        const { watched, id } = item;
        const { companyList } = this.state;
        if (companyList.filter((i) => i.watched).length < 3 || watched) {
            try {
                this.toggleGlobalLoading(true);
                await service.put(this.getEndpoints().WATCH_ITEM(id), {
                    watched: !watched,
                });
                this.fetchRelatedCompany();
            } catch (err) {
                this.toggleGlobalLoading(false);
                console.log(err);
            }
        } else {
            this.setState({ modalContent: 'maxMonitor' });
        }
    };

    deleteCompany = async () => {
        const { modalContent } = this.state;
        const { id } = modalContent;
        try {
            this.setState({ deleting: true });
            await service.delete(this.getEndpoints().SPECIFIC_ITEM(id));
            this.setState(
                {
                    deleting: false,
                    modalContent: null,
                    waitForUndoItem: modalContent,
                },
                () => {
                    this.fetchRelatedCompany();
                }
            );
        } catch (err) {
            this.setState({ deleting: false });
            console.log(err);
        }
    };

    showDeleteWarning = (item) => {
        this.setState({ modalContent: item });
    };

    hideModal = () => {
        this.setState({ modalContent: null });
    };

    renderModal = () => {
        const { modalContent, deleting } = this.state;
        const { locale } = this.props;
        const text = this.getPageText();
        const textMap = {
            maxWatched: {
                title: text.watchMaxTitle,
                subtitle: text.watchMaxDesc,
            },
            maxMonitor: {
                title: text.monitorMaxTitle,
                subtitle: text.monitorMaxDesc,
            },
            delete: {
                title: text.deleteModalTitle,
                subtitle: text.deleteModalDesc,
            },
        };
        const isDelete = typeof modalContent !== 'string';
        const msg = !isDelete ? textMap[modalContent] : textMap.delete;
        return (
            <div className="esgai_company-modal-content">
                <h4>{msg.title}</h4>
                <h5>{msg.subtitle}</h5>
                {isDelete && (
                    <div className="modal-company-info">
                        {modalContent.logoUrl ? (
                            <img
                                className="company-item-logo"
                                src={modalContent.logoUrl}
                                alt=""
                            />
                        ) : (
                            <div className="company-item-logo company-item-logo-placeholder">
                                <Business
                                    style={{ fontSize: 18 }}
                                    color="C8C8C9"
                                />
                            </div>
                        )}
                        <span>{modalContent.name}</span>
                    </div>
                )}
                <div className="modal-company-actions">
                    {isDelete ? (
                        <>
                            <Button
                                type="error"
                                label={locale.delete}
                                onClick={this.deleteCompany}
                                loading={deleting}
                            />
                            <Button
                                type="link"
                                label={locale.cancel}
                                onClick={this.hideModal}
                            />
                        </>
                    ) : (
                        <Button
                            label={locale.got_it}
                            onClick={this.hideModal}
                        />
                    )}
                </div>
            </div>
        );
    };

    isCompetitor = () => {
        const {
            router: {
                location: { pathname },
            },
        } = this.props;
        return pathname === '/settings/competitor';
    };

    getPageText = () => {
        const { locale } = this.props;
        const textMap = {
            competitor: {
                title: locale.competitors,
                titleNote: locale.competitors_title_note,
                // subtitle: locale.competitors_subtitle,
                searchInputPlaceholder: locale.add_competitors_placeholder,
                deleteModalTitle: locale.delete_competitor,
                deleteModalDesc: locale.delete_competitor_note,
                undoNote: locale.undo_competitor_note,
                monitorMaxTitle: locale.competitor_max_monitor_title,
                monitorMaxDesc: locale.competitor_max_monitor_desc,
                watchMaxTitle: locale.competitor_max_watch_title,
                watchMaxDesc: locale.competitor_max_watch_desc,
            },
            partner: {
                title: locale.business_partners,
                titleNote: locale.partner_title_note,
                searchInputPlaceholder: locale.add_partners_placeholder,
                deleteModalTitle: locale.delete_partner,
                deleteModalDesc: locale.delete_partner_note,
                undoNote: locale.undo_partner_note,
                watchMaxTitle: locale.partner_max_watch_title,
                watchMaxDesc: locale.partner_max_watch_desc,
            },
        };
        const key = this.isCompetitor() ? 'competitor' : 'partner';
        return textMap[key];
    };

    renderCompanyList = () => {
        const { companyList } = this.state;
        const isCompetitor = false;
        return (
            <div className="company-list">
                {companyList.map((i = {}) => (
                    <div
                        key={i.id}
                        className={classNames({
                            'company-item': true,
                            'company-item-without-star': !isCompetitor,
                        })}
                    >
                        <div className="company-item-left">
                            {isCompetitor && (
                                <button
                                    onClick={() => this.toggleStarCompany(i)}
                                    className={classNames({
                                        'company-item-star': true,
                                        'company-item-star-active': i.watched,
                                    })}
                                >
                                    <Star style={{ fontSize: 20 }} />
                                </button>
                            )}
                            {i.logoUrl ? (
                                <img
                                    className="company-item-logo"
                                    src={i.logoUrl}
                                    alt=""
                                />
                            ) : (
                                <div className="company-item-logo company-item-logo-placeholder">
                                    <Business
                                        style={{ fontSize: 26 }}
                                        color="C8C8C9"
                                    />
                                </div>
                            )}
                            <span className="company-item-name">{i.name}</span>
                        </div>
                        <button
                            className="company-item-delete"
                            onClick={() => this.showDeleteWarning(i)}
                        >
                            <Trash style={{ fontSize: 24 }} />
                        </button>
                    </div>
                ))}
            </div>
        );
    };

    render() {
        const text = this.getPageText();
        const { waitForUndoItem, modalContent, headerHeight } = this.state;
        return (
            <SideMenuLayoutPage>
                <Helmet title={text.title} />
                <div className="esgai_company-wrap">
                    <div className="esgai_company-header" ref={this.headerRef}>
                        <h4 className="esgai_company-title">
                            <span className="esgai_company-title-text">
                                {text.title}
                            </span>
                            <Popover tooltip={text.titleNote} />
                        </h4>
                        {text.subtitle && (
                            <h6 className="esgai_company-subtitle">
                                {text.subtitle}
                            </h6>
                        )}
                        {this.renderSearchInput()}
                    </div>
                    <div
                        className="esgai_company-content"
                        style={{
                            paddingTop: headerHeight,
                            paddingBottom: waitForUndoItem ? 50 : 0,
                        }}
                    >
                        {this.renderCompanyList()}
                    </div>
                    {waitForUndoItem && (
                        <div className="company-undo">
                            <div className="company-undo-left">
                                <Trash
                                    style={{ fontSize: 24 }}
                                    color="E65C47"
                                />
                                <p
                                    className="company-undo-text"
                                    dangerouslySetInnerHTML={{
                                        __html: text.undoNote(
                                            waitForUndoItem.name
                                        ),
                                    }}
                                />
                            </div>
                            <button
                                className="company-undo-btn"
                                onClick={() =>
                                    this.addCompany(
                                        waitForUndoItem,
                                        false,
                                        true
                                    )
                                }
                            >
                                {this.props.locale.undo}
                            </button>
                        </div>
                    )}
                    {!!modalContent && <Modal>{this.renderModal()}</Modal>}
                </div>
            </SideMenuLayoutPage>
        );
    }
}

export default withRouter(
    connect(
        ({ persist: { account, locale }, common: { mobileView }, router }) => ({
            account,
            router,
            locale,
            mobileView,
        })
    )(ManageRelatedCompany)
);
