/**
 * Enum for URLs for the API endpoints.
 */

export const accountUrl = {
    LOGIN: '/token',
    PASSWORD_RESET: '/password-reset',
    PROFILE: '/profile',
    ORGANIZATIONS: '/organizations',
    MUTE_REMINDER: '/mute-status',
    USERS: '/users',
    USER: (id) => `/users/${id}`,
    PASSWORD_STATUS: '/password-reset-status',
    SEND_MAIL: '/email-support'
};

export const profileUrl = {
    ALL_PROFILE: '/user-and-organization-info',
    UPDATE_PROFILE: '/user-info',
    UPDATE_COMPANY: '/organization-info',
    INDUSTRIES: '/dictionaries/economic-sector-topics',
    GEOS: '/dictionaries/geo',
    MANAGE_COMPANY: {
        ASSOCIATIONS: {
            ITEMS: '/associations',
            SPECIFIC_ITEM: (id) => `/associations/${id}`,
            WATCH_ITEM: (id) => `/associations/${id}/watched`,
        },
        COMPETITORS: {
            ITEMS: '/competitors',
            SPECIFIC_ITEM: (id) => `/competitors/${id}`,
            WATCH_ITEM: (id) => `/competitors/${id}/watched`,
        },
    },
    NOTIFY_SETTINGS: '/notification-setting',
    NOTIFY_THRESHOLD: '/notification-threshold',
    NOTIFY_COUNT: '/app-notifications/status-count',
    NOTIFY: '/app-notifications',
    DELETE_NOTIFY: (maxId) => `/app-notifications?maxId=${maxId}`,
    NOTIFY_PUSHED: '/app-notifications/pushed',
    NOTIFY_ACKNOWLEDGE: '/app-notifications/acknowledged',
    SPECIFY_NOTIFY_ACKNOWLEDGE: (id) => `/app-notifications/${id}/acknowledged`,
    NOTIFY_READ: '/app-notifications/readed',
    SPECIFY_NOTIFY_READ: (id) => `/app-notifications/${id}/readed`,
    SCORE_CONTACTS: '/score-notification-contacts',
    NEWS_CONTACTS: '/news-notification-contacts',
};

export const esgScoreUrl = {
    SCORE_CARD_COMPETITORS: '/score-cards/competitor-score-list',
    SCORE_CARD_INDUSTRY: '/score-cards/industry-score-list',
    SCORE_HISTORY_INDUSTRY: '/industry-metric-data-history',
    UPDATE_COMPETITORS: '/competitor-preference',
    COMPETITOR_GRAPH_AND_NEWS:
        '/score-cards/competitor-score-graph-and-esg-news',
    COMPETITOR_NEWS_OVERALL: '/score-cards/competitor-esg-news',
    DATA_POINT_NEWS: '/sources',
    LAST_AUDITED_DATE: '/metric/last-audited-date'
}

export const newsUrl = {
    COMPANY_NEWS: '/organization-news',
    NEWS_CONTENT: '/news-content',
    ESG_SCORE_NEWS_CONTENT: (id) => `/sources/${id}`,
    INDUSTRIES_TOPICS: '/industry-topics',
    INDUSTRIES_NEWS: '/industry-news',
    COMPETITORS_DROPDOWN: '/competitor-dropdown',
    COMPETITORS_NEWS: '/competitor-news',
    PARTNERS_DROPDOWN: '/association-dropdown',
    PARTNERS_NEWS: '/association-news',
};

export const dashboardUrl = {
    DASHBOARD: '/dashboard',
    STOCK_PRICE_LATEST: (symbol) =>
        `https://cloud.iexapis.com/v1/stock/${symbol}/delayed-quote`,
    STOCK_PRICE_HISTORY: (symbol) =>
        `https://cloud.iexapis.com/v1/stock/${symbol}/chart/5d`,
};

export const optimizerUrl = {
    MY_BOARD: '/my-boards',
    TEAM_BOARD: '/team-boards',
    ARCHIVED: '/archive-boards',
    TRASH: '/trashed-boards',
    BOARDS: '/boards',
    SPECIFIC_BOARD: (id) => `/boards/${id}`,
    COPY_BOARD: (id) => `/boards/${id}/copy`,
    ARCHIVE_BOARD: (id) => `/boards/${id}/archive`,
    UNARCHIVE_BOARD: (id) => `/boards/${id}/unarchive`,
    TRASH_BOARD: (id) => `/boards/${id}/trashed`,
    RESTORE_BOARD: (id) => `/boards/${id}/restored`,
    BOARD_DATA: (id) => `/boards/${id}/optimizer-score-list`,
    UPDATE_METRIC_NOTE: (id) => `/boards/${id}/metric-note`,
    METRIC_TARGET_SCORE: (id) => `/boards/${id}/metric-raw-value`,
    METRIC_TARGET_ROW_VALUE: (id) => `/boards/${id}/metric-score`,
    GET_NUMERIC_STAT: (id) => `/boards/${id}/numeric-stat`,
    GET_ALL_COMPETITOR_SCORE: (id) => `/boards/${id}/metric-score-list`,
    UPDATE_BOARD_USERS: (id) => `/boards/${id}/users`,
    GET_BOARD_REVERSIONS_COUNT: (id) =>
        `/boards/${id}/revision-history-un-acknowledge-count`,
    GET_BOARD_REVERSIONS: (id) => `/boards/${id}/revision-history`,
    ACK_BOARD_REVERSIONS: (id) => `/boards/${id}/revision-history-acknowledged`,
};
