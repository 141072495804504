import React from 'react';
import { connect } from 'react-redux';
import './index.scss';
import Popover from '../../../../component/Popover';
import classNames from 'classnames';
import { Edit, Vector } from '../../../../component/Icon';

const EsgScoreHeader = (props) => {
    const {
        locale,
        isIndustryScore,
        data,
        competitorEditable,
        updateParentState,
        hasCompetitor,
        exportCSV,
    } = props;
    return (
        <div
            className={classNames({
                'esg_score-header': true,
                'esg_score-header-industry': isIndustryScore,
            })}
        >
            <div className="header-container">
                <h4 className="header-title">
                    <span>{locale.esg_scorecard}</span>
                    <Popover
                        tooltip={
                            isIndustryScore
                                ? locale.esg_scorecard_industry_note
                                : locale.esg_scorecard_competitor_note
                        }
                    />
                    <div className="header-export-csv" onClick={exportCSV}>
                        <Vector />
                        <span>EXPORT CSV</span>
                    </div>
                </h4>
                {!isIndustryScore && ((data && !data.noAvg) || hasCompetitor) && (
                    <div className="competitor-edit-btn-wrap">
                        <button
                            className={classNames({
                                'competitor-edit-btn': true,
                                'competitor-edit-btn-active':
                                    competitorEditable,
                            })}
                            onClick={() =>
                                updateParentState({
                                    competitorEditable: !competitorEditable,
                                })
                            }
                        >
                            <Edit style={{ fontSize: 24 }} />
                            <span className="btn-text">
                                {competitorEditable
                                    ? locale.finish_editing
                                    : locale.edit_competitors}
                            </span>
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default connect(({ persist: { locale }, common: { mobileView } }) => ({
    locale,
    mobileView,
}))(EsgScoreHeader);
