import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import urlRegex from 'url-regex';
import cns from 'classnames';
import Button from '../../../../component/Button';
import {
    Edit,
    Close,
    Check,
    Info,
    Business,
    Reset,
} from '../../../../component/Icon';
import { profileUrl } from '../../../../constant/endpoint';
import service from '../../../../service';
import './index.scss';
import RegionOfOperation from '../RegionOfOperation';
import IndustriesOfInterest from '../IndustriesOfInterest';

class CompanyInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            organizationInfo: {},
            saving: false,
            companyEditable: false,
            saveSuccess: false,
            websiteError: '',
            tooltip: {},
        };
        this.timer = null;
    }

    componentDidUpdate(prevProps, prevState) {
        const { organizationInfo, discardChanges } = this.props;
        const {
            organizationInfo: prevOrganizationInfo,
            discardChanges: prevDiscardChanges,
        } = prevProps;
        const { tooltip } = this.state;
        const { tooltip: prevTooltip } = prevState;
        if (
            prevOrganizationInfo !== organizationInfo ||
            (!prevDiscardChanges && discardChanges)
        ) {
            this.syncCompanyInfoWithProps();
        }
        if (prevTooltip !== tooltip && tooltip.content) {
            this.handleShowTooltip();
        }
    }

    componentWillUnmount() {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
    }

    handleShowTooltip = () => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.setState({
                tooltip: {},
            });
            clearTimeout(this.timer);
            this.timer = null;
        }, 2500);
    };

    syncCompanyInfoWithProps = () => {
        const { organizationInfo, discardChanges, updateParentState } =
            this.props;
        const {
            economicSectorTopics,
            regions,
            defaultEconomicSectorTopic,
            defaultRegions,
        } = organizationInfo;
        if (
            (!Array.isArray(economicSectorTopics) ||
                economicSectorTopics.length === 0) &&
            defaultEconomicSectorTopic
        ) {
            organizationInfo.economicSectorTopics = [
                defaultEconomicSectorTopic,
            ];
        }
        if (!Array.isArray(regions) || regions.length === 0) {
            organizationInfo.regions = defaultRegions;
        }
        this.setState(
            {
                organizationInfo,
                companyEditable: false,
            },
            () => {
                if (discardChanges) {
                    updateParentState('showDiscardModal', false);
                }
                this.setState({ saveSuccess: false });
            }
        );
    };

    toggleEditCompany = () => {
        const { companyEditable } = this.state;
        const { updateParentState } = this.props;
        if (!companyEditable) {
            this.setState({
                companyEditable: true,
            });
            updateParentState('discardChanges', false);
        } else {
            this.cancelCompanyInfo();
        }
    };

    handleSaveSuccess = (newCompanyInfo) => {
        const { updateParentState } = this.props;
        if (this.timer) {
            clearTimeout(this.timer);
            if (this.state.tooltip.content) {
                this.setState({ tooltip: {} });
            }
        }
        this.timer = setTimeout(() => {
            updateParentState('organizationInfo', newCompanyInfo);
        }, 500);
    };

    saveCompanyInfo = async () => {
        const { saving } = this.state;
        const { showDiscardModal, organizationInfo } = this.props;
        if (saving || showDiscardModal || this.disableSaveBtn()) {
            return;
        }
        const {
            name,
            website,
            regions,
            economicSectorTopics: economicTopics,
        } = this.getLatestInfo();

        this.setState({ saving: true });
        try {
            const res = await service.put(profileUrl.UPDATE_COMPANY, {
                name,
                website,
                regions,
                economicTopics,
            });
            if (res && res.id) {
                this.setState({ saving: false, saveSuccess: true }, () => {
                    this.handleSaveSuccess({ ...organizationInfo, ...res });
                });
            }
        } catch (err) {
            console.log(err);
            this.setState({ saving: false });
        }
    };

    isChanged = (field, key) => {
        const { organizationInfo } = this.props;
        const newInfo = this.getLatestInfo();
        return !!newInfo[field].find(
            (j, jIndex) =>
                !Array.isArray(organizationInfo[field]) ||
                organizationInfo[field].length !== newInfo[field].length ||
                !organizationInfo[field][jIndex] ||
                j !== organizationInfo[field][jIndex][key]
        );
    };

    cancelCompanyInfo = () => {
        const { organizationInfo, updateParentState } = this.props;
        const newInfo = this.getLatestInfo();
        const hasChange = !!Object.keys(newInfo).find((i) => {
            if (i === 'economicSectorTopics') {
                return this.isChanged(i, 'trbc');
            } else if (i === 'regions') {
                return this.isChanged(i, 'rcs');
            } else {
                return newInfo[i] !== organizationInfo[i];
            }
        });
        if (hasChange) {
            updateParentState('showDiscardModal', true);
        } else {
            this.setState({ companyEditable: false });
        }
    };

    getLatestInfo = () => {
        const {
            organizationInfo: { name, website, economicSectorTopics, regions },
        } = this.state;
        return {
            name,
            website,
            economicSectorTopics: Array.isArray(economicSectorTopics)
                ? economicSectorTopics.map((i) => i.trbc)
                : [],
            regions: Array.isArray(regions) ? regions.map((i) => i.rcs) : [],
        };
    };

    handleCompanyInfoChange = (e, key) => {
        const { organizationInfo, websiteError } = this.state;
        const { locale } = this.props;
        if (key === 'website') {
            const isValid = urlRegex({ exact: true }).test(
                e.target.value.trim()
            );
            if (!websiteError) {
                if (e.target.value.trim() && !isValid) {
                    this.setState({
                        websiteError: locale.invalid_website,
                    });
                }
            } else {
                if (!e.target.value.trim() || isValid) {
                    this.setState({
                        websiteError: '',
                    });
                }
            }
        }
        this.setState({
            organizationInfo: { ...organizationInfo, [key]: e.target.value },
        });
    };

    updateTooltip = (content) =>
        this.setState({
            tooltip: {
                content,
            },
        });

    renderFields = () => {
        const { organizationInfo, companyEditable, websiteError } = this.state;
        const { geoList, industryList, locale } = this.props;
        const fields = [
            {
                key: 'base',
                items: [
                    {
                        key: 'name',
                        label: locale.company_name,
                    },
                    {
                        key: 'website',
                        label: locale.website,
                    },
                ],
            },
            {
                key: 'industries',
                items: [
                    {
                        key: 'primaryIndustry',
                        label: locale.primary_industry,
                        note: locale.primary_industry_note,
                    },
                    {
                        key: 'economicSectorTopics',
                        label: locale.industries_of_interest,
                        note: locale.customize_news_note,
                    },
                ],
            },
            {
                key: 'regions',
                label: locale.region_of_operation,
                note: locale.customize_news_note,
            },
        ];
        return (
            <div className="company-info-fields">
                {fields.map((i) => {
                    const items = Array.isArray(i.items) ? i.items : [i];
                    return (
                        <div className="company-info-field-group" key={i.key}>
                            {items.map((j) => {
                                if (i.key !== 'base') {
                                    return (
                                        <div
                                            key={j.key}
                                            className="company-info-field-sub-group"
                                        >
                                            <div className="sub-group-left">
                                                <h5>
                                                    {j.label}
                                                    {companyEditable && (
                                                        <b className="required-flag">
                                                            *
                                                        </b>
                                                    )}
                                                </h5>
                                                <p>{j.note}</p>
                                            </div>
                                            <div className="sub-group-right">
                                                {j.key ===
                                                    'primaryIndustry' && (
                                                    <p className="primary-industry">
                                                        {organizationInfo.industryGroup &&
                                                        typeof organizationInfo
                                                            .industryGroup
                                                            .title === 'string'
                                                            ? organizationInfo
                                                                  .industryGroup
                                                                  .title
                                                            : '--'}
                                                    </p>
                                                )}
                                                {j.key ===
                                                    'economicSectorTopics' && (
                                                    <IndustriesOfInterest
                                                        organizationInfo={
                                                            organizationInfo
                                                        }
                                                        companyEditable={
                                                            companyEditable
                                                        }
                                                        industryList={
                                                            industryList
                                                        }
                                                        updateCompanyInfo={(
                                                            list
                                                        ) =>
                                                            this.handleCompanyInfoChange(
                                                                {
                                                                    target: {
                                                                        value: list,
                                                                    },
                                                                },
                                                                j.key
                                                            )
                                                        }
                                                        updateReset={
                                                            this.updateTooltip
                                                        }
                                                    />
                                                )}
                                                {j.key === 'regions' && (
                                                    <RegionOfOperation
                                                        displayKey="name"
                                                        idKey="rcs"
                                                        key={j.key}
                                                        label={j.label}
                                                        editable={
                                                            companyEditable
                                                        }
                                                        data={
                                                            organizationInfo[
                                                                j.key
                                                            ]
                                                        }
                                                        defaultValue={
                                                            organizationInfo.defaultRegions
                                                        }
                                                        options={geoList || []}
                                                        updateCompanyInfoList={(
                                                            list
                                                        ) =>
                                                            this.handleCompanyInfoChange(
                                                                {
                                                                    target: {
                                                                        value: list,
                                                                    },
                                                                },
                                                                j.key
                                                            )
                                                        }
                                                        updateReset={
                                                            this.updateTooltip
                                                        }
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    );
                                }
                                return (
                                    <div
                                        className="company-info-field"
                                        key={j.key}
                                    >
                                        <span className="company-info-field-title">
                                            {j.label}
                                            {companyEditable && (
                                                <b className="required-flag">
                                                    *
                                                </b>
                                            )}
                                        </span>
                                        <input
                                            className={cns({
                                                'settings-input-open':
                                                    companyEditable,
                                                'settings-input-disabled': false,
                                            })}
                                            disabled={!companyEditable}
                                            value={
                                                organizationInfo[j.key] ||
                                                (companyEditable ? '' : '--')
                                            }
                                            onChange={(e) =>
                                                this.handleCompanyInfoChange(
                                                    e,
                                                    j.key
                                                )
                                            }
                                            maxLength={60}
                                            autoComplete="off"
                                        />
                                        {j.key === 'website' &&
                                            companyEditable &&
                                            websiteError && (
                                                <span className="company-info-field-error">
                                                    {websiteError}
                                                </span>
                                            )}
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        );
    };

    disableSaveBtn = () => {
        const {
            organizationInfo: { name, website, economicSectorTopics, regions },
        } = this.state;
        return !(
            typeof name === 'string' &&
            name.trim() &&
            typeof website === 'string' &&
            urlRegex({ exact: true }).test(website.trim()) &&
            Array.isArray(economicSectorTopics) &&
            economicSectorTopics.length > 0 &&
            Array.isArray(regions) &&
            regions.length > 0
        );
    };

    render() {
        const {
            companyEditable,
            saving,
            saveSuccess,
            organizationInfo,
            tooltip: { content },
        } = this.state;
        const { locale } = this.props;
        const CompanyEditIcon = companyEditable ? Close : Edit;
        return (
            <div className="company-info">
                <div className="company-info-content">
                    <div className="company-info-top">
                        <h6 className="company-info-title">
                            {locale.company_info}
                        </h6>
                        <button
                            className={cns({
                                'company-info-edit-btn': true,
                                'company-info-edit-btn-close': companyEditable,
                            })}
                            onClick={this.toggleEditCompany}
                        >
                            <CompanyEditIcon style={{ fontSize: 24 }} />
                        </button>
                    </div>
                    <div className="company-info-mid">
                        <div className="company-info-mid-left">
                            <div className="company-info-avatar-wrap">
                                {organizationInfo.logo &&
                                organizationInfo.logoUrl ? (
                                    <img
                                        src={organizationInfo.logoUrl}
                                        className="company-info-avatar"
                                        alt=""
                                    />
                                ) : (
                                    <Business
                                        color="C8C8C9"
                                        style={{ fontSize: 63 }}
                                    />
                                )}
                            </div>
                        </div>
                        {this.renderFields()}
                    </div>
                    {companyEditable && (
                        <div className="company-info-actions-wrap">
                            <p className="company-info-edit-note">
                                <span className="company-info-edit-note-text">
                                    {locale.change_effect_note}
                                </span>
                                <Info style={{ fontSize: 18 }} color="7A7A7A" />
                            </p>
                            <div
                                className={cns({
                                    'company-info-actions': true,
                                    'company-info-actions-success': saveSuccess,
                                })}
                            >
                                <Button
                                    loading={saving}
                                    label={
                                        saveSuccess ? (
                                            <Check
                                                style={{ fontSize: 24 }}
                                                color="FFFFFF"
                                            />
                                        ) : saving ? (
                                            locale.saving
                                        ) : (
                                            locale.save
                                        )
                                    }
                                    onClick={this.saveCompanyInfo}
                                    disabled={this.disableSaveBtn()}
                                />
                                <Button
                                    type="link"
                                    label={locale.cancel}
                                    onClick={this.cancelCompanyInfo}
                                />
                            </div>
                        </div>
                    )}
                </div>
                {content && (
                    <div className="reset-tooltip">
                        <Reset style={{ fontSize: 24 }} />
                        <p>{content}</p>
                    </div>
                )}
            </div>
        );
    }
}

export default withRouter(
    connect(({ persist: { account, locale }, router }) => ({
        account,
        router,
        locale,
    }))(CompanyInfo)
);
