import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import {
    seamlessImmutableReconciler,
    seamlessImmutableTransformCreator,
} from 'redux-persist-seamless-immutable';
import rootReducer from './reducers';
import rootSaga from './sagas';

// Add other middleWares
const middleWares = [];

// --- Add saga ---
const sagaMiddleware = createSagaMiddleware();
middleWares.push(sagaMiddleware);
// --- Add saga ---

// --- Add router to redux ---
const history = createBrowserHistory(); // use one of [createBrowserHistory, createHashHistory]
middleWares.push(routerMiddleware(history));
// --- Add router to redux ---

const middlewareEnhancer = applyMiddleware(...middleWares);

// Add other enhancers
const enhancers = [middlewareEnhancer];

// compose with redux dev tools
const composedEnhancers = composeWithDevTools(...enhancers);

const persistConfig = {
    key: 'root',
    storage,
    whitelist: [],
    blacklist: ['persist'],
    stateReconciler: seamlessImmutableReconciler,
    transforms: [seamlessImmutableTransformCreator({})],
};

const persistedReducer = persistReducer(persistConfig, rootReducer(history));

const store = createStore(persistedReducer, composedEnhancers);

const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

const redux = { store, persistor, history };

export default redux;
