import React from 'react';
import classNames from 'classnames';
import './index.scss';

const Modal = (props) => {
    const { children, style = {}, zIndex = 99999, className } = props;
    return (
        <div
            className={classNames({
                esgai_modal: true,
                [className]: !!className,
            })}
            style={{ zIndex }}
        >
            {children && (
                <div className="esgai_modal-content" style={style}>
                    {children}
                </div>
            )}
        </div>
    );
};

export default Modal;
