import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import './MainLayout.scss';
import { persistType } from '../../../constant/actionTypes';
import Header from '../Header';
import Modal from '../../Modal';

class MainLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showHeaderDropdown: false,
            showMobileMenu: false,
        };
    }

    componentDidUpdate(prevProps) {
        const {
            router: {
                location: { pathname },
            },
            globalLoading,
        } = this.props;
        const {
            router: {
                location: { prevPathname },
            },
            globalLoading: prevIsPageLoading,
        } = prevProps;
        if (
            (prevIsPageLoading && !globalLoading) ||
            pathname !== prevPathname
        ) {
            this.scrollToTop();
        }
    }

    scrollToTop = () => {
        // const mainContentDom = document.getElementsByClassName(
        //     'main_layout-right'
        // )[0];
        // mainContentDom.scrollTo(0, 0);
    };

    logout = () => {
        const { dispatch } = this.props;
        dispatch({
            type: persistType.LOGOUT,
        });
    };

    hasSubMenu = () => {
        const {
            router: {
                location: { pathname },
            },
        } = this.props;
        const topRoute = pathname.slice(1).split('/')[0];
        const routesWithSubMenu = ['news', 'esg-score'];
        return routesWithSubMenu.indexOf(topRoute) > -1;
    };

    updateState = (key, value, cb) => {
        this.setState(
            {
                [key]: value,
            },
            () => {
                if (typeof cb === 'function') {
                    cb();
                }
            }
        );
    };

    render() {
        const {
            children,
            router: {
                location: { pathname },
            },
        } = this.props;
        const { showHeaderDropdown, showMobileMenu } = this.state;

        return (
            <div
                className={classNames({
                    'page_wrap main_layout': true,
                    'main_layout-without-height':
                        pathname === '/esg-score/competitor',
                })}
            >
                <Header
                    showHeaderDropdown={showHeaderDropdown}
                    showMobileMenu={showMobileMenu}
                    updateState={this.updateState}
                />
                <div
                    className={classNames({
                        'main_layout-container': true,
                        'main_layout-container-with-sub-menu':
                            this.hasSubMenu(),
                    })}
                >
                    {children}
                </div>
                {(showHeaderDropdown || showMobileMenu) && (
                    <Modal zIndex={showMobileMenu ? 1000 : 998} />
                )}
            </div>
        );
    }
}

export default withRouter(
    connect(({ persist: { account }, common: { globalLoading }, router }) => ({
        globalLoading,
        router,
        account,
    }))(MainLayout)
);
