export const targetConfigureStatus = {
    NOT_CONFIGURED: 'NOT_CONFIGURED',
    CONFIGURED: 'CONFIGURED',
    SKIPPED: 'SKIPPED',
};

export const scoreChartGroupType = {
    WEEK: 'WEEK',
    YEAR: 'YEAR',
};

export const defaultLang = 'en-US';

export const newsTopic = {
    'M:1QP': 'environmental',
    'M:1QR': 'social',
    'M:1QQ': 'governance',
    'M:1QI': 'controversy',
};

export const newsPeriod = {
    LAST_24_HOURS: 'last_24_hours',
    '7_DAYS': '7_days',
    '30_DAYS': '30_days',
    '90_DAYS': '90_days',
};

export const esgPillar = {
    OVERALL: 'OVERALL',
    ENVIRONMENTAL: 'ENVIRONMENTAL',
    SOCIAL: 'SOCIAL',
    GOVERNANCE: 'GOVERNANCE',
};

export const colors = [
    {
        color: '#233BC9',
        shadow: 'rgba(35, 59, 201, 0.2)',
    },
    {
        color: '#9C42CC',
        shadow: 'rgba(127, 36, 175, 0.2)',
    },
    {
        color: '#BA782C',
        shadow: 'rgba(186, 120, 44, 0.2)',
    },
    {
        color: '#17909C',
        shadow: 'rgba(35, 179, 193, 0.2)',
    },
    {
        color: '#838D72',
        shadow: 'rgba(131, 141, 114, 0.2)',
    },
    {
        color: '#FF9141',
        shadow: 'rgba(255, 145, 65, 0.2)',
    },
    {
        color: '#D2BF11',
        shadow: 'rgba(237, 216, 27, 0.2)',
    },
    {
        color: '#A64361',
        shadow: 'rgba(166, 67, 97, 0.2)',
    },
    {
        color: '#FE63DC',
        shadow: 'rgba(206, 106, 184, 0.2)',
    },
    {
        color: '#3CBEE8',
        shadow: 'rgba(60, 190, 232, 0.2)',
    },
    {
        color: '#55CC42',
        shadow: 'rgba(85, 204, 66, 0.2)',
    },
    {
        color: '#E13030',
        shadow: 'rgba(225, 48, 48, 0.2)',
    },
];

export const pillarColors = {
    'EnvironmentPillarScore': '#838D72',
    'SocialPillarScore': '#799EB2',
    'GovernancePillarScore': '#BA782C',
    'Default': '#BA782C',
}
