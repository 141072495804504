import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import './index.scss';
import Popover from '../../../../component/Popover';
import { ArrowLeft, Close, Filter } from '../../../../component/Icon';
import BottomSheet from '../../../../component/BottomSheet';
import Button from '../../../../component/Button';
import Filters from '../../../../component/Filters';
import MobileFilters from '../../../../component/Filters/Mobile';

class TableSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bottomSheetType: '', // filter, sort
            pillar: 'ALL',
            category: 'ALL',
            dataType: 'ALL',
            standardsFilter: [],
            filtering: false,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const { bottomSheetType } = this.state;
        const { bottomSheetType: prevBottomSheetType } = prevState;
        if (
            bottomSheetType !== prevBottomSheetType &&
            bottomSheetType === 'filter'
        ) {
            this.syncFilterState();
        }
    }

    syncFilterState = () => {
        const { pillar, category, dataType } = this.props;
        this.setState({
            pillar,
            category,
            dataType,
            filtering: false,
        });
    };

    handleClickOption = (stateKey, o, isMobile) => {
        let { handleUpdateState } = this.props;
        const currentValue = isMobile
            ? this.state[stateKey]
            : this.props[stateKey];
        if (currentValue === o.keyName) {
            return;
        }
        if (isMobile) {
            handleUpdateState = (data) => this.setState(data);
        }
        if (stateKey === 'pillar') {
            handleUpdateState({ [stateKey]: o.keyName, category: 'ALL' });
        } else {
            handleUpdateState({ [stateKey]: o.keyName });
        }
    };

    renderOptions = (options, stateKey, isMobile) => {
        return (
            <div className="option-group">
                {options.map((o) => (
                    <span
                        className={classNames({
                            option: true,
                            'option-selected':
                                o.keyName ===
                                (isMobile
                                    ? this.state[stateKey]
                                    : this.props[stateKey]),
                        })}
                        key={o.keyName}
                        onClick={() =>
                            this.handleClickOption(stateKey, o, isMobile)
                        }
                    >
                        {o.displayName}
                    </span>
                ))}
            </div>
        );
    };

    renderFilterMain = (isMobile = false, state) => {
        const {
            locale,
            groupFilters,
            oneTimeTutorialStep: step,
            pillar: propsPillar,
            mobileView,
            standardsFilter
        } = this.props;
        const { pillar: statePillar } = state || this.state;
        const pillar = isMobile ? statePillar : propsPillar;
        const esgOptions = [
            {
                keyName: 'ALL',
                displayName: locale.overall_esg,
            },
            {
                keyName: 'EnvironmentPillarScore',
                displayName: 'E',
            },
            {
                keyName: 'SocialPillarScore',
                displayName: 'S',
            },
            {
                keyName: 'GovernancePillarScore',
                displayName: 'G',
            },
        ];

        const dataTypeOptions = [
            {
                keyName: 'ALL',
                displayName: locale.all_types,
            },
            {
                keyName: 'NUMERIC',
                displayName: locale.numeric,
            },
            {
                keyName: 'BOOLEAN',
                displayName: locale.non_numeric,
            },
        ];
        return (
            <div
                className={classNames({
                    'table-search': true,
                    'table-search-with-mask': !!step,
                })}
            >
                <div className="search-group">
                    <h6>{locale.group}</h6>
                    <div className="option-group-list">
                        {this.renderOptions(esgOptions, 'pillar', isMobile)}
                        {pillar !== 'ALL' &&
                            this.renderOptions(
                                [
                                    {
                                        keyName: 'ALL',
                                        displayName: locale.all,
                                    },
                                    ...groupFilters.find(
                                        (g) => g.keyName === pillar
                                    ).children,
                                ],
                                'category',
                                isMobile
                            )}
                    </div>
                </div>
                <div className="search-group">
                    <h6>
                        <span>{locale.data_type}</span>
                        <Popover
                            width={257}
                            tooltip={locale.data_type_note}
                            isMobileView={mobileView}
                        />
                    </h6>
                    <div className="option-group-list">
                        {this.renderOptions(
                            dataTypeOptions,
                            'dataType',
                            isMobile
                        )}
                    </div>
                </div>

                {
                    isMobile ? (
                    <MobileFilters
                        value={standardsFilter}
                        onChange={(selOptions) => {
                            this.setState({standardsFilter: selOptions})
                        }}
                    />) : (
                    <div className="option-filters">
                        <Filters
                            value={standardsFilter}
                            onChange={this.handleStandardsChange}
                        />
                    </div>)
                }
            </div>
        );
    };

    handleStandardsChange = (options) => {
        const { handleUpdateState } = this.props;

        handleUpdateState({ standardsFilter: options });
    }

    handleCloseBottomSheet = (cb) => {
        this.setState(
            {
                bottomSheetType: '',
            },
            () => {
                if (typeof cb === 'function') {
                    cb();
                }
            }
        );
    };

    renderMobileFilter = (state, data) => {
        const { pillar, category, dataType, filtering, standardsFilter } = this.state;
        const { locale, handleUpdateState } = this.props;
        const { close } = data;
        return (
            <>
                <div className="filter-header">
                    <div className="filter-header-left">
                        <Filter color="7A7A7A" style={{ fontSize: 24 }} />
                        <span>{locale.filter}</span>
                    </div>
                    <button onClick={close}>
                        <Close color="7A7A7A" style={{ fontSize: 24 }} />
                    </button>
                </div>
                {this.renderFilterMain(true, state)}
                <div className="filter-actions">
                    <Button
                        label={locale.apply}
                        onClick={() =>
                            this.setState({ filtering: true }, () =>
                                handleUpdateState(
                                    { pillar, category, dataType, standardsFilter },
                                    close
                                )
                            )
                        }
                        loading={filtering}
                    />
                    <Button label={locale.cancel} type="link" onClick={close} />
                </div>
            </>
        );
    };

    getColumns = () => {
        const { locale } = this.props;
        return [
            {
                key: 'priority',
                title: locale.priority,
            },
            {
                key: 'ease',
                title: locale.actionability,
            },
            {
                key: 'currentScore',
                title: locale.current_score,
            },
            {
                key: 'mmi',
                title: locale.mmi,
            },
            {
                key: 'targetScore',
                title: locale.target_score,
            },
            {
                key: 'targetRank',
                title: locale.target_rank,
            },
            {
                key: 'metric',
                title: locale.metric
            },
            {
                key: 'standards',
                title: locale.standards
            }
        ];
    };

    renderSortList = (data) => {
        const { handleUpdateState, sort } = this.props;
        return (
            <div className="option-list">
                {this.getColumns().map((i) => (
                    <span
                        className={classNames({
                            'option-item': true,
                            'option-item-danger': !!i.isDangerous,
                            'option-item-active': i.key === sort.key,
                        })}
                        key={i.key}
                        onClick={() => {
                            if (i.key === sort.key) {
                                data.close();
                            } else {
                                handleUpdateState(
                                    {
                                        sort: { key: i.key, base: 'desc' },
                                    },
                                    data.close
                                );
                            }
                        }}
                    >
                        {i.title}
                    </span>
                ))}
            </div>
        );
    };

    renderMobileSearch = () => {
        const { locale, sort, handleUpdateState } = this.props;
        const { bottomSheetType } = this.state;
        const isSort = bottomSheetType === 'sort';
        return (
            <div
                className={classNames({
                    'mobile-table-search': true,
                    'mobile-table-search-filter': !isSort,
                })}
            >
                <div className="filter-group">
                    <div
                        className="name-wrap"
                        onClick={() =>
                            this.setState({ bottomSheetType: 'filter' })
                        }
                    >
                        <Filter />
                        <span>{locale.filter}</span>
                    </div>
                </div>
                <div className="filter-group sort-group">
                    <div className="name-wrap">
                        <span>{`${locale.sort_by}: `}</span>
                        <button
                            onClick={() =>
                                this.setState({
                                    bottomSheetType: 'sort',
                                })
                            }
                        >
                            {sort.key
                                ? this.getColumns().find(
                                      (j) => j.key === sort.key
                                  ).title
                                : '--'}
                        </button>
                        {sort.key && (
                            <button
                                className={classNames({
                                    'arrow-up': sort.base === 'asc',
                                })}
                                onClick={() => {
                                    handleUpdateState({
                                        sort: {
                                            ...sort,
                                            base:
                                                sort.base === 'asc'
                                                    ? 'desc'
                                                    : 'asc',
                                        },
                                    });
                                }}
                            >
                                <ArrowLeft />
                            </button>
                        )}
                    </div>
                </div>
                {bottomSheetType && (
                    <BottomSheet
                        showHeader={isSort}
                        title={isSort ? locale.sort_by : ''}
                        fullScreen={!isSort}
                        renderContent={
                            isSort
                                ? this.renderSortList
                                : (data) =>
                                      this.renderMobileFilter(this.state, data)
                        }
                        close={this.handleCloseBottomSheet}
                    />
                )}
            </div>
        );
    };

    render() {
        return (
            <div className="optimizer_board_detail-table-search-wrap">
                {this.renderFilterMain()}
                {this.renderMobileSearch()}
            </div>
        );
    }
}

export default withRouter(
    connect(({ persist: { locale }, common: { clientWidth } }) => ({
        locale,
        mobileView: clientWidth < 1220,
    }))(TableSearch)
);
