import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
    getCountries,
    getCountryCallingCode,
    AsYouType,
    getExampleNumber,
    parsePhoneNumber,
} from 'libphonenumber-js/mobile';
import examples from 'libphonenumber-js/examples.mobile.json';
import { hasFlag } from 'country-flag-icons';
import Flags from 'country-flag-icons/react/3x2';
import './index.scss';
import Modal from '../../../../component/Modal';
import Button from '../../../../component/Button';
import { Plus, ArrowDownFull } from '../../../../component/Icon';
import countryMap from './countryMap';
import service from '../../../../service';
import { profileUrl } from '../../../../constant/endpoint';

class AddContactModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contacts: [],
            activeDropdown: -1,
            saving: false,
        };
        this.countrySelectorRef = React.createRef();
    }
    componentDidMount() {
        window.addEventListener('click', this.tryHideDropdown);
        this.initContact();
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.tryHideDropdown);
    }

    tryHideDropdown = (e) => {
        const { activeDropdown } = this.state;
        if (activeDropdown < 0) {
            return;
        }
        const path = e.path || (e.composedPath && e.composedPath());
        if (path.indexOf(this.countrySelectorRef.current) < 0) {
            this.setState({
                activeDropdown: -1,
            });
        }
    };

    initContact = () => {
        const { currentContact } = this.props;
        if (!Array.isArray(currentContact) || currentContact.length < 1) {
            this.setState({
                contacts: [
                    {
                        name: '',
                        countryCode: 'CA',
                        phoneNumber: '',
                    },
                ],
            });
        } else {
            this.setState({
                contacts: currentContact.map((c) => ({ ...c, valid: true })),
            });
        }
    };

    addPlaceholder = () => {
        const { contacts } = this.state;
        this.setState({
            contacts: [
                ...contacts,
                {
                    name: '',
                    countryCode: 'CA',
                    phoneNumber: '',
                },
            ],
        });
    };

    removeContact = (index) => {
        const { contacts } = this.state;
        const newContacts = contacts.filter((_, i) => i !== index);
        this.setState({
            contacts: newContacts,
        });
    };

    infoUpdate = (i, key, value, valid) => {
        const { contacts } = this.state;
        const newContacts = contacts.map((c, index) => {
            if (i === index) {
                const newItem = { ...c, [key]: value };
                if (key === 'countryCode') {
                    newItem.phoneNumber = '';
                    newItem.valid = false;
                } else if (key === 'phoneNumber') {
                    newItem.valid = valid;
                }
                return newItem;
            }
            return c;
        });
        this.setState({
            contacts: newContacts,
            activeDropdown: -1,
        });
    };

    toggleShowDropdown = (index) => {
        const { activeDropdown } = this.state;
        this.setState({
            activeDropdown: activeDropdown === index ? -1 : index,
        });
    };

    renderCountrySelector = (index) => {
        const { activeDropdown, contacts } = this.state;
        const showDropdown = activeDropdown === index;
        const countries = [];
        getCountries().forEach((code) => {
            const name = countryMap[code];
            if (name && hasFlag(code)) {
                const Flag = (props) => {
                    const SpecificFlag = Flags[code];
                    return <SpecificFlag {...props} />;
                };
                countries.push({
                    code,
                    name,
                    flag: <Flag title={name} className="flag-img" />,
                    prefix: getCountryCallingCode(code),
                });
            }
        });
        const targetContact = contacts[index];
        const currentCountry = countries.find(
            (c) => c.code === targetContact.countryCode
        );
        return (
            <div
                className="country-selector"
                ref={showDropdown ? this.countrySelectorRef : null}
            >
                <div
                    className={classNames({
                        'current-value-wrap': true,
                        'current-value-wrap-active': showDropdown,
                    })}
                    onClick={() => this.toggleShowDropdown(index)}
                >
                    <div className="flag-wrap">{currentCountry.flag}</div>
                    <ArrowDownFull />
                </div>
                {showDropdown && (
                    <div className="dropdown-list esgai_scrollbar">
                        {countries.map((c) => (
                            <div
                                className={classNames({
                                    'dropdown-item': true,
                                    'dropdown-item-selected':
                                        currentCountry.code === c.code,
                                })}
                                key={c.code}
                                onClick={(e) =>
                                    this.infoUpdate(
                                        index,
                                        'countryCode',
                                        c.code
                                    )
                                }
                            >
                                <div className="flag-wrap">{c.flag}</div>
                                <span className="country-name">{c.name}</span>
                                <span className="country-num">+{c.prefix}</span>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    };

    handlePhoneNumberChange = (value, index) => {
        const { contacts } = this.state;
        const { phoneNumber, countryCode } = contacts[index];
        const lastType = new AsYouType(countryCode);
        const nextType = new AsYouType(countryCode);
        if (phoneNumber) {
            lastType.input(phoneNumber);
        }
        const reg = /^\d*$/gi;
        const phoneNumberMock = getExampleNumber(countryCode, examples);
        if (
            value.length <= phoneNumber.length ||
            ((!phoneNumber ||
                !lastType.getNumber() ||
                lastType.getNumber().number.length <
                    phoneNumberMock.number.length) &&
                reg.test(value[value.length - 1]))
        ) {
            const nextValue = nextType.input(
                value.length <= phoneNumber.length &&
                    phoneNumber[phoneNumber.length - 1] === ')'
                    ? value.slice(0, -1)
                    : value
            );
            const isValid = nextType.isValid();
            this.infoUpdate(index, 'phoneNumber', nextValue, isValid);
        }
    };

    renderContacts = () => {
        const { contacts } = this.state;
        const { locale } = this.props;
        return (
            <div className="contact-list">
                {contacts.map((c, i) => (
                    <div key={i} className="contact-item">
                        <div className="contact-index-wrap">
                            <span>#{i + 1}</span>
                        </div>
                        <div className="contact-info">
                            <div className="contact-info-item">
                                <p className="info-item-label">
                                    {locale.contact_name}
                                </p>
                                <div className="contact-input-wrap">
                                    <input
                                        placeholder={locale.contact_placeholder(
                                            `${i < 9 ? '0' : ''}${i + 1}`
                                        )}
                                        maxLength={60}
                                        value={c.name}
                                        onChange={(e) =>
                                            this.infoUpdate(
                                                i,
                                                'name',
                                                e.target.value
                                            )
                                        }
                                    />
                                </div>
                            </div>
                            <div className="contact-info-item">
                                <p className="info-item-label">
                                    {locale.phone_number}
                                </p>
                                <div
                                    className={classNames({
                                        'contact-input-wrap': true,
                                        'contact-input-wrap-error':
                                            c.phoneNumber && !c.valid,
                                    })}
                                >
                                    {this.renderCountrySelector(i)}
                                    <input
                                        type="tel"
                                        value={c.phoneNumber}
                                        onChange={(e) =>
                                            this.handlePhoneNumberChange(
                                                e.target.value,
                                                i
                                            )
                                        }
                                        maxLength={30}
                                    />
                                </div>
                            </div>
                            <div className="contact-remove-btn-wrap">
                                {contacts.length > 1 && (
                                    <button
                                        onClick={() => this.removeContact(i)}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                ))}
                <button
                    className="add-contact-btn"
                    onClick={this.addPlaceholder}
                >
                    <Plus color="233BC9" style={{ fontSize: 16 }} />
                    <span>{locale.add_another_number}</span>
                </button>
            </div>
        );
    };

    handleSave = async () => {
        const {
            addContactType,
            updateNotifySettings,
            handleCancel,
            formatPhoneNumber,
        } = this.props;
        const { contacts } = this.state;
        const list = [];
        contacts.forEach(({ name, phoneNumber, countryCode }) => {
            if (phoneNumber) {
                list.push({
                    name,
                    countryCode,
                    phoneNumber: parsePhoneNumber(
                        phoneNumber,
                        countryCode
                    ).number.slice(1),
                });
            }
        });
        this.setState({ saving: true });
        try {
            const api =
                addContactType === 'score'
                    ? profileUrl.SCORE_CONTACTS
                    : profileUrl.NEWS_CONTACTS;
            const res = await service.put(api, list);
            updateNotifySettings(
                {
                    [`${addContactType}Message`]: true,
                },
                {
                    [`${addContactType}NotificationContacts`]:
                        formatPhoneNumber(res),
                },
                () => this.setState({ saving: false }, handleCancel)
            );
        } catch (err) {
            console.log(err);
            this.setState({ saving: false });
        }
    };

    render() {
        const { locale, handleCancel } = this.props;
        const { contacts, saving } = this.state;
        let hasValidContacts = false;
        let hasError = false;
        let hasDuplicatePhoneNumber = false;
        contacts.forEach((c) => {
            if (!hasValidContacts && c.phoneNumber && c.valid) {
                hasValidContacts = true;
            }
            if (!hasError && c.phoneNumber && !c.valid) {
                hasError = true;
            }
            if (
                !hasDuplicatePhoneNumber &&
                contacts.filter(
                    (i) =>
                        i.phoneNumber &&
                        c.phoneNumber &&
                        i.valid &&
                        c.valid &&
                        parsePhoneNumber(i.phoneNumber, i.countryCode)
                            .number ===
                            parsePhoneNumber(c.phoneNumber, c.countryCode)
                                .number
                ).length > 1
            ) {
                hasDuplicatePhoneNumber = true;
            }
        });
        return (
            <div className="add-contact-modal-wrap">
                <Modal>
                    <div className="modal-container">
                        <div className="modal-main">
                            <h5 className="modal-title">
                                {locale.add_phone_number}
                            </h5>
                            {this.renderContacts()}
                        </div>
                        <div className="modal-actions">
                            <Button
                                label={locale.save}
                                onClick={this.handleSave}
                                disabled={
                                    !hasValidContacts ||
                                    hasError ||
                                    hasDuplicatePhoneNumber
                                }
                                loading={saving}
                            />
                            <Button
                                type="link"
                                label={locale.cancel}
                                onClick={handleCancel}
                            />
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default connect(
    ({ persist: { locale, account }, common: { mobileView } }) => ({
        locale,
        mobileView,
        account,
    })
)(AddContactModal);
