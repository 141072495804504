import React from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { commonType } from '../../constant/actionTypes';
import { optimizerUrl, esgScoreUrl } from '../../constant/endpoint';
import service from '../../service';
import './index.scss';
import Header from './components/Header';
import SimpleOverview from './components/SimpleOverview';
import TableSearch from './components/TableSearch';
import Table from './components/Table';
import OneTimeTutorial from './components/OneTimeTutorial';
import OneTimeTutorialMobile from './components/OneTimeTutorialMobile';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';

const standards = ['gri', 'sasb', 'cdp', 'tcfd', 'gresb', 'wef', 'djsi'];
class OptimizerBoardDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pillar: 'ALL',
            category: 'ALL',
            dataType: 'ALL',
            resource: {},
            sort: {
                key: '',
                base: 'desc',
            },
            showOneTimeTutorial: false,
            showOneTimeTutorialMobile: false,
            oneTimeTutorialStep: 0,
            currentHolder: {},
            standardsFilter: []
        };
    }
    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState) {
        const { mobileView } = this.props;
        const { mobileView: prevMobileView, clientWidth: prevClientWidth } =
            prevProps;
        if (prevClientWidth && mobileView !== prevMobileView) {
            this.removeOneTimeTutorial();
        }
    }

    removeOneTimeTutorial = () => {
        this.setState({
            showOneTimeTutorial: false,
            showOneTimeTutorialMobile: false,
            oneTimeTutorialStep: 0,
        });
    };

    fetchData = async () => {
        this.toggleGlobalLoading(true);

        try {
            await Promise.all([this.fetchBoardData(), this.fetchCompetitorsData()]);
        } catch (err) {
            console.log(err);
        }

        this.toggleGlobalLoading(false);
    };

    fetchBoardData = async () => {
        const {
            match: {
                params: { boardId },
            },
        } = this.props;
        const res = await service.get(optimizerUrl.BOARD_DATA(boardId));
        if (res) {
            this.setState({ resource: res }, () => {
                if (Array.isArray(res.metrics) && res.metrics.length > 0) {
                    this.handleShowOneTimeTutorial();
                }
            });
        }
    }

    fetchCompetitorsData = async () => {
        const res = await service.get(esgScoreUrl.SCORE_CARD_COMPETITORS);
        if (res) {
            const self = res.score.find(item => item.myself);
            const competitors = res.score.filter(item => (!item.myself && item.name !== 'Competitors avg'));

            this.setState({ competitorsData: { self,  competitors} });
        }
    }

    handleShowOneTimeTutorial = () => {
        const {
            oneTimeNote,
            account: { username },
            mobileView,
        } = this.props;
        const { optimizerTip } = oneTimeNote[username] || {};
        if (!optimizerTip) {
            if (mobileView) {
                this.setState({ showOneTimeTutorialMobile: true });
            } else {
                this.setState({ showOneTimeTutorial: true });
            }
        }
    };

    toggleGlobalLoading = (globalLoading) => {
        this.props.dispatch({
            type: commonType.APPLY_SET_COMMON_STATE,
            payload: {
                globalLoading,
            },
        });
    };

    handleUpdateState = (data, cb) => {
        this.setState(data, () => {
            if (typeof cb === 'function') {
                cb();
            }
        });
    };

    updateBoard = (board) => {
        const { resource } = this.state;
        this.handleUpdateState({ resource: { ...resource, board } });
    };

    handleProcessMetrics = () => {
        const { pillar, category, dataType, resource, sort, standardsFilter } = this.state;
        const { metrics = [] } = resource;
        if (!Array.isArray(metrics) || metrics.length === 0) {
            return [];
        }
        const standards = standardsFilter.filter(stand => stand !== 'all');
        const processedMetrics = metrics.filter((m) => {
            if (dataType !== 'ALL' && m.rawValueType !== dataType) {
                return false;
            }
            if (
                pillar !== 'ALL' &&
                (m.rootPillar !== pillar ||
                    (category !== 'ALL' && m.parent !== category))
            ) {
                return false;
            }
            return standards.every(standard => m[standard]);
        });
        if (sort && sort.key) {
            processedMetrics.sort(this.compare(sort.key));
        }
        return processedMetrics;
    };

    compare = (key) => {
        const { sort } = this.state;
        return (m, n) => {
            const a = this[`${key}ValueFormat`](m);
            const b = this[`${key}ValueFormat`](n);

            if (typeof a === 'number' && typeof b === 'number') {
                return sort.base === 'asc' ? a - b  : b -a;
            }

            if (typeof a === 'string' && typeof b === 'string') {
                return sort.base === 'asc' ? a.localeCompare(b)  : b.localeCompare(a);
            }

            return 0;
        };
    };

    priorityValueFormat = (row) => {
        const { sort } = this.state;
        const priorityMap = {
            HIGH: 3,
            MEDIUM: 2,
            LOW: 1,
        };
        const defaultValue = sort.base === 'asc' ? 4 : 0;
        return priorityMap[row.metricNote.priority] || defaultValue;
    };

    easeValueFormat = (row) => {
        const { sort } = this.state;
        const edgeNum = sort.base === 'asc' ? 999999 : -1;
        return row.metricNote.ease || edgeNum;
    };

    currentScoreValueFormat = (row) => {
        const { sort } = this.state;
        const defaultValue = sort.base === 'asc' ? 2 : -1;
        return typeof row.score === 'number' ? row.score : defaultValue;
    };

    mmiValueFormat = (row) => {
        const { mmi } = row;
        const { sort } = this.state;
        const defaultValue = sort.base === 'asc' ? 999999 : -1;
        return typeof mmi === 'number' ? mmi : defaultValue;
    };

    targetScoreValueFormat = (row) => {
        const { targetScore } = row;
        const { sort } = this.state;
        const defaultValue = sort.base === 'asc' ? 2 : -1;
        return targetScore && typeof targetScore.score === 'number'
            ? targetScore.score
            : defaultValue;
    };

    targetRankValueFormat = (row) => {
        const { targetRanks } = row;
        const { sort } = this.state;
        const defaultValue = sort.base === 'asc' ? 999999 : -1;
        return Array.isArray(targetRanks) &&
            targetRanks[0] &&
            typeof targetRanks[0].rankValue === 'number'
            ? targetRanks[0].rankValue
            : defaultValue;
    };

    metricValueFormat = (row) => row.displayName || '';

    standardsValueFormat = (row) => {
        const { displayName } = row;
        const standardsNum = standards.filter(standard => row[standard]).length;

        return `${standardsNum}0000${displayName}`;
    }

    handleUpdatePriorityOrEase = async (data) => {
        this.toggleGlobalLoading(true);
        try {
            const { resource } = this.state;
            const res = await service.put(
                optimizerUrl.UPDATE_METRIC_NOTE(resource.board.id),
                data
            );
            if (res && res.boardId) {
                this.setState({
                    resource: {
                        ...resource,
                        metrics: resource.metrics.map((m) => {
                            if (m.name === data.name) {
                                return { ...m, metricNote: res };
                            }
                            return m;
                        }),
                    },
                });
            }
        } catch (err) {
            console.log(err);
        }
        this.toggleGlobalLoading(false);
    };

    exportCSV = () => {
        const zip = new JSZip();
        const time = dayjs().format('YYYY-MM-DD_HHmm');
        const summary = this.getCsvSummary();
        const data = this.getCsvData();

        zip.file(`optimizer-board-summary-${time}.csv`, summary);
        zip.file(`optimizer-board-data-${time}.csv`, data);
        zip.generateAsync({type:"blob"})
        .then((content) => {
            saveAs(content, `optimizer-board-${time}.zip`);
        });
    }

    getCsvSummary = () => {
        const { account } = this.props;
        const { resource: {board, pillars} } = this.state;
        const ranks = pillars?.ESGScore?.ranks;
        const targetRanks = pillars?.ESGScore?.targetRanks;
        const groupRank = ranks?.find?.(rank => !rank.geographical);
        const groupTargetRank = targetRanks?.find?.(rank => !rank.geographical);
        const geoRank = ranks?.find?.(rank => rank.geographical);
        const geoTargetRank = targetRanks?.find?.(rank => rank.geographical);

        const summary = [
            ['Export Type', 'Optimizer Board'],
            ['Company', `"${account?.organization?.name || '--'} "`],
            ['Board Name', `"${board?.name} "`],
            ['Data Export Date', `"${dayjs().format('LL')}"`],
            ['Overall ESG Score', `"${pillars?.ESGScore?.displayValue || '--'} "`],
            ['E Pillar Numeric Score', `"${pillars?.EnvironmentPillarScore?.displayValue || '--'} "`],
            ['E Pillar Letter Grade', `"${pillars?.EnvironmentPillarScore?.grade || '--'} "`],
            ['S Pillar Numeric Score', `"${pillars?.SocialPillarScore?.displayValue || '--'} "`],
            ['S Pillar Letter Grade', `"${pillars?.SocialPillarScore?.grade || '--'} "`],
            ['G Pillar Numeric Score', `"${pillars?.GovernancePillarScore?.displayValue || '--'} "`],
            ['G Pillar Letter Grade', `"${pillars?.GovernancePillarScore?.grade || '--'} "`],
            ['Target ESG Overall Score (Numeric)', `"${board?.score?.ESGScore?.displayValue || '--'} "`],
            ['Target ESG Overall Score (Letter)', `"${board?.score?.ESGScore?.grade || '--'} "`],
            ['Target E Pillar Numeric Score', `"${board?.score?.EnvironmentPillarScore?.displayValue || '--'} "`],
            ['Target E Pillar Letter Grade', `"${board?.score?.EnvironmentPillarScore?.grade || '--'} "`],
            ['Target S Pillar Numeric Score', `"${board?.score?.SocialPillarScore?.displayValue || '--'} "`],
            ['Target S Pillar Letter Grade', `"${board?.score?.SocialPillarScore?.grade || '--'} "`],
            ['Target G Pillar Numeric Score', `"${board?.score?.GovernancePillarScore?.displayValue || '--'} "`],
            ['Target G Pillar Letter Grade', `"${board?.score?.GovernancePillarScore?.grade || '--'} "`],
            ['Industry Group Rank', `"${groupRank?.rankValue} -> ${groupTargetRank?.rankValue || groupRank?.rankValue} / ${groupRank?.rankTotal}"`],
            ['Geographical Rank', `"${geoRank?.rankValue} -> ${geoTargetRank?.rankValue || geoRank?.rankValue} / ${geoRank?.rankTotal}"`],
        ]

        return summary.map(item => item.join(',')).join('\n');
    }

    getCsvData = () => {
        const { locale } = this.props;
        const { competitorsData, resource: {metrics, competitorMetrics, pillars } } = this.state;
        const { self } = competitorsData;
        const maxCompetitors = (Object.values(competitorMetrics || {}).reduce((ev, m) => {
            if (ev.len < m.length) {
                ev.len = m.length;
                ev.competitors = m;
            }

            return ev;
        }, {len: 0, competitors: []}).competitors || [])
        .map(({name, organizationId: id, name: displayName }) => ({name, id, displayName }));

        const allData = Object.entries(self?.pillars || {})
            .filter(([_, item]) => item.name !== 'ESGScore' && item.displayName)
            .reduce((ev, [key, item]) => {
                ev.push([
                    'N/A',
                    `"${item?.displayName}"`,
                    `"${item?.weight || '--'}  "`,
                    `"${item?.scoreValue?.displayValue || '--'}  "`,
                    `"${(item?.displayName || '--').toUpperCase()} PILLAR SCORE"`,
                    `"${(item?.displayName || '--').toUpperCase()} PILLAR SCORE"`,
                    `"${(item?.displayName || '--').toUpperCase()} PILLAR SCORE"`,
                    `"${(item?.displayName || '--').toUpperCase()} PILLAR SCORE"`,
                    `"${(item?.displayName || '--').toUpperCase()} PILLAR SCORE"`,
                    `"${(item?.displayName || '--').toUpperCase()} PILLAR SCORE"`,
                    `"${(item?.displayName || '--').toUpperCase()} PILLAR SCORE"`,
                    `"${(item?.displayName || '--').toUpperCase()} PILLAR SCORE"`,
                    '--',
                    '--',
                    `"${(item?.displayName || '--').toUpperCase()} PILLAR SCORE"`,
                    `"${(item?.displayName || '--').toUpperCase()} PILLAR SCORE"`,
                    `"${pillars[item.name]?.targetScore?.displayValue || '--'}" `,
                    `"${pillars[item.name]?.targetRanks?.[0]?.rankValue || '--'}" `,
                    '--',
                    ...maxCompetitors.reduce((ev, competitor) => {
                        ev.push('--',);
                        ev.push('--',);
                        ev.push('--',);

                        return ev;
                    }, []),
                ]);

                item.categories.forEach((categorie, cateIndex) => {
                    ev.push([
                        'N/A',
                        `"${item?.displayName}"`,
                        `"${item?.weight || '--'}  "`,
                        `"${item?.scoreValue?.displayValue || '--'}  "`,
                        `"${(categorie?.displayName || '--').toUpperCase()} CATEGORY SCORE"`,
                        `"${categorie?.weight || '--'}  "`,
                        `"${categorie?.scoreValue?.displayValue || '--'}  "`,
                        `"${(categorie?.displayName || '--').toUpperCase()} CATEGORY SCORE"`,
                        `"${(categorie?.displayName || '--').toUpperCase()} CATEGORY SCORE"`,
                        `"${(categorie?.displayName || '--').toUpperCase()} CATEGORY SCORE"`,
                        `"${(categorie?.displayName || '--').toUpperCase()} CATEGORY SCORE"`,
                        `"${(categorie?.displayName || '--').toUpperCase()} CATEGORY SCORE"`,
                        '--',
                        '--',
                        `"${(categorie?.displayName || '--').toUpperCase()} CATEGORY SCORE"`,
                        `"${(categorie?.displayName || '--').toUpperCase()} CATEGORY SCORE"`,
                        '--',
                        '--',
                        '--',
                        ...maxCompetitors.reduce((ev, competitor) => {
                            ev.push('--');
                            ev.push('--');
                            ev.push('--');

                            return ev;
                        }, []),
                    ]);
                    metrics
                    .filter(metric => metric.rootPillar === item.name && metric.parent === categorie.name)
                    .forEach((metric) => {
                        const numeric = metric?.rawValueType === 'NUMERIC' ? 'NUMERIC' : 'Non-Numeric';
                        const reported = (!metric?.score && metric?.score !== 0) ? locale.not_reported : locale.reported;
                        ev.push([
                            'N/A',
                            `"${item?.displayName}"`,
                            `"${item?.weight || '--'}  "`,
                            `"${item?.scoreValue?.displayValue || '--'}  "`,
                            `"${categorie?.displayName}  "`,
                            `"${categorie?.weight || '--'}  "`,
                            `"${categorie?.scoreValue?.displayValue || '--'}  "`,
                            `"${metric?.displayName}"`,
                            `"${metric?.description.replace(/"/g, '""')} "`,
                            `"${metric?.metricNote?.priority || '--'}"`,
                            `"${metric?.metricNote?.ease || '--'}"`,
                            `"${numeric}  "`,
                            `"${metric?.displayValue || '--'}  "`,
                            `"${metric?.grade || '--'}  "`,
                            `"${metric?.mmiDisplayValue || '--'}  "`,
                            `"${reported}"`,
                            `"${metric?.targetScore?.displayValue || '--'}  "`,
                            `"${metric?.targetRanks?.[0]?.rankValue || '--'}  "`,
                            `"${metric?.metricNote?.note || '--'}"`,
                            ...maxCompetitors.reduce((ev, competitor) => {
                                const comMetric = (competitorMetrics?.[metric?.name] || []).find(com => com.organizationId === competitor.id);
                                ev.push(`"${comMetric?.displayValue || '--'}"`);
                                ev.push(`"${comMetric?.grade || '--'}"`);
                                ev.push(`"${comMetric?.ranks?.[0]?.rankValue || '--'}"`);

                                return ev;
                            }, []),
                        ]);
                    })
                })
                return ev;
            }, []);

        const data = [
            [
                'XBRL Mapping',
                'ESG Pillar Name',
                'Pillar Weighting',
                'ESG Pillar Total Score',
                'Category Name',
                'Category Weighting',
                'Category Total Score',
                'Metric Name',
                'Metric Description',
                'Priority',
                'Level of Difficulty',
                'Numeric/Non-Numeric',
                'Metric Numeric Score',
                'Metric Letter Grade',
                'Metric Impact (MI)',
                'Position',
                'Target Score',
                'Target Rank',
                'User Notes',
                ...maxCompetitors.reduce((ev, competitor) => {
                    ev.push(`${competitor.name} Numeric Score`);
                    ev.push(`${competitor.name} Letter Grade`);
                    ev.push(`${competitor.name} Rank`);

                    return ev;
                }, []),
            ],
            ...allData
        ]

        return data.map(item => item.join(',')).join('\n');
    }

    render() {
        const {
            locale,
            match: {
                params: { boardId },
            },
        } = this.props;
        const {
            pillar,
            category,
            dataType,
            resource,
            sort,
            showOneTimeTutorial,
            showOneTimeTutorialMobile,
            oneTimeTutorialStep,
            currentHolder,
            standardsFilter,
        } = this.state;
        const {
            board,
            pillars,
            targetPillars,
            country,
            industry,
            groupFilters,
            competitorMetrics,
            logoUrl,
            logo,
        } = resource;
        const filteredMetrics = this.handleProcessMetrics();
        return (
            <div className="page optimizer_board_detail_page">
                <Helmet
                    title={`${locale.board_detail} | ${locale.optimizer}`}
                />
                <Header
                    board={board}
                    updateBoard={this.updateBoard}
                    exportCSV={this.exportCSV}
                    oneTimeTutorialStep={oneTimeTutorialStep}
                    handleUpdateState={this.handleUpdateState}
                    currentHolder={currentHolder}
                />
                <div className="detail-main">
                    <SimpleOverview
                        {...{
                            pillars,
                            targetPillars,
                            country,
                            industry,
                            oneTimeTutorialStep,
                        }}
                    />
                    <div className="detail-table-wrap">
                        <h4 className="mobile-metric-title">
                            {locale.metrics}
                        </h4>
                        <TableSearch
                            {...{
                                sort,
                                groupFilters,
                                pillar,
                                category,
                                dataType,
                                oneTimeTutorialStep,
                                handleUpdateState: this.handleUpdateState,
                                standardsFilter
                            }}
                        />
                        <Table
                            logo={logo && logoUrl ? logoUrl : ''}
                            boardId={boardId}
                            data={filteredMetrics}
                            handleUpdatePriorityOrEase={
                                this.handleUpdatePriorityOrEase
                            }
                            handleUpdateState={this.handleUpdateState}
                            sort={sort}
                            competitorMetrics={competitorMetrics || {}}
                            oneTimeTutorialStep={oneTimeTutorialStep}
                            currentHolder={currentHolder}
                        />
                    </div>
                </div>
                {showOneTimeTutorial && (
                    <OneTimeTutorial
                        oneTimeTutorialStep={oneTimeTutorialStep}
                        handleUpdateState={this.handleUpdateState}
                    />
                )}
                {showOneTimeTutorialMobile && (
                    <OneTimeTutorialMobile
                        handleUpdateState={this.handleUpdateState}
                    />
                )}
            </div>
        );
    }
}

export default withRouter(
    connect(
        ({
            persist: { account, oneTimeNote, locale },
            common: { clientWidth },
            router,
        }) => ({
            account,
            locale,
            router,
            oneTimeNote,
            clientWidth,
            mobileView: clientWidth < 1220,
        })
    )(OptimizerBoardDetail)
);
