const envMap = {
    dev: {
        apiBaseUrl: 'https://api.dev.esgai.app',
        firebaseConfig: {
            apiKey: 'AIzaSyAg2d7xrjPGA4Tn7b4rVhTqRx-SxHrkqpY',
            authDomain: 'sharp-imprint-312117.firebaseapp.com',
            projectId: 'sharp-imprint-312117',
            storageBucket: 'sharp-imprint-312117.appspot.com',
            messagingSenderId: '965274799525',
            appId: '1:965274799525:web:e39dceaf0bec06c418ec4c',
            measurementId: 'G-NE4SVHCE9M',
        },
    },
    release: {
        apiBaseUrl: 'https://api.release.esgai.app',
        firebaseConfig: {
            apiKey: "AIzaSyCghMqdL2Bmv00rMyCcPvRAar2yoy2VMQ8",
            authDomain: "esgai-release.firebaseapp.com",
            projectId: "esgai-release",
            storageBucket: "esgai-release.appspot.com",
            messagingSenderId: "284348815306",
            appId: "1:284348815306:web:56cdbeff63b7ebd6862133",
            measurementId: "G-11FR3FPREM"
        }
    },
    live: {
        apiBaseUrl: 'https://api.live.esgai.app',
        firebaseConfig: {
            apiKey: 'AIzaSyAvI902GJWKWw5J3tkWaj2Gl04jkLjrUfU',
            authDomain: 'esgai-live-313004.firebaseapp.com',
            projectId: 'esgai-live-313004',
            storageBucket: 'esgai-live-313004.appspot.com',
            messagingSenderId: '839214619181',
            appId: '1:839214619181:web:0d5cd6f04ee947258bb6a4',
            measurementId: 'G-VWHJVZ88W0',
        },
    },
    local: {
        apiBaseUrl: 'http://localhost:8080',
        firebaseConfig: {
            apiKey: "AIzaSyDkVejgrs8Jv5jktfgt45gHztpz_Kzk4BA",
            authDomain: "esgai-local.firebaseapp.com",
            projectId: "esgai-local",
            storageBucket: "esgai-local.appspot.com",
            messagingSenderId: "171424011370",
            appId: "1:171424011370:web:409a3aede2cbb405d0e613"
        },
    }
};

const envKey = process.env.REACT_APP_ENV || 'dev';

const env = envMap[envKey];

export default env;
