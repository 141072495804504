import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import cns from 'classnames';
import reactDom from 'react-dom';
import { LoadingOutlined } from '@ant-design/icons';
import './index.scss';
import {
    ArrowDownFull,
    Bell,
    Email,
    EmailSend,
    Newspaper,
    Settings,
} from '../Icon';
import NotifyMsg from '../NotifyMsg';
import { commonType } from '../../constant/actionTypes';
import service from '../../service';
import { profileUrl } from '../../constant/endpoint';
import { Spin } from 'antd';
import { formatDate, isSomeday } from '../../utils';

const Loading = (props) => <Spin indicator={<LoadingOutlined />} {...props} />;

class Notify extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showNotify: false,
            msg: [],
            msgCompleteNum: 0,
            bellLoading: false,
            bellData: {},
            notifyList: [],
            notifyCenterLoading: false,
        };
        this.dropdownRef = React.createRef();
        this.pushNotificationRef = React.createRef();
    }

    componentDidMount() {
        window.addEventListener('click', this.tryHidePopup);
        this.getNotifyData();
    }

    componentDidUpdate(prevProps, prevState) {
        const { bellData, showNotify } = this.state;
        const { bellData: prevBellData, showNotify: prevShowNotify } =
            prevState;
        if (
            (bellData !== prevBellData && Object.keys(prevBellData).length) ===
            0
        ) {
            this.handlePushNotification();
        }
        if (!prevShowNotify && showNotify) {
            this.handleShowNotify();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.tryHidePopup);
    }

    tryHidePopup = (e) => {
        const { showNotify } = this.state;
        const path = e.path || (e.composedPath && e.composedPath());
        if (
            path.indexOf(this.dropdownRef.current) < 0 &&
            path.indexOf(this.pushNotificationRef.current) < 0 &&
            showNotify
        ) {
            this.setState({ showNotify: false });
        }
    };

    toggleDropDown = () => {
        const { showNotify } = this.state;
        this.setState({ showNotify: !showNotify });
    };

    handleShowNotify = () => {
        const { bellData } = this.state;
        this.setState({
            msg: [],
            msgCompleteNum: 0,
        });
        if (bellData.unAcknowledgedCount) {
            this.setState(
                {
                    bellData: { ...bellData, unAcknowledgedCount: 0 },
                },
                () => {
                    service.put(profileUrl.NOTIFY_ACKNOWLEDGE, {
                        maxId: bellData.maxId,
                        acknowledged: true,
                    });
                }
            );
        }
    };

    getNotifyData = async () => {
        this.setState({ bellLoading: true });
        try {
            const bellData = await service.get(profileUrl.NOTIFY_COUNT);
            const notifyList = await service.get(profileUrl.NOTIFY);
            const newState = { bellLoading: false };
            if (
                bellData &&
                (bellData.unPushedMetricCount || bellData.unPushedNewsCount)
            ) {
                await service.put(profileUrl.NOTIFY_PUSHED, {
                    maxId: bellData.maxId,
                    pushed: true,
                });
            }
            if (bellData && bellData.maxId) {
                newState.bellData = bellData;
            }
            if (Array.isArray(notifyList)) {
                newState.notifyList = notifyList;
            }
            this.setState(newState);
        } catch (err) {
            console.log(err);
            this.setState({ bellLoading: false });
        }
    };

    toggleGlobalLoading = (globalLoading) => {
        this.props.dispatch({
            type: commonType.APPLY_SET_COMMON_STATE,
            payload: {
                globalLoading,
            },
        });
    };

    handleClickGroupedNotify = () => {
        this.setState({
            showNotify: true,
        });
    };

    handleClickSingleNotify = (i, badgeGap) => {
        const { bellData, notifyList } = this.state;
        const newState = {
            msg: [],
            msgCompleteNum: 0,
            showNotify: false,
        };
        if (badgeGap) {
            newState.bellData = {
                ...bellData,
                unAcknowledgedCount: bellData.unAcknowledgedCount - badgeGap,
            };
        }
        this.setState(newState, async () => {
            const nextRoute =
                i.type === 'score'
                    ? `/esg-score/industry/component?name=${i.data.keyName}`
                    : i.type === 'news'
                    ? `/news/company?type=controversial&startDate=${i.newsStartDate}`
                    : '/settings/notification';
            this.props.history.push(nextRoute);
            if (badgeGap) {
                await service.put(profileUrl.SPECIFY_NOTIFY_ACKNOWLEDGE(i.id), {
                    acknowledged: true,
                });
            }
            if (!i.readed) {
                await service.put(profileUrl.SPECIFY_NOTIFY_READ(i.id), {
                    readed: true,
                });
                const { bellData: newBellData } = this.state;
                this.setState({
                    notifyList: notifyList.map((n) => {
                        if (n.id !== i.id) {
                            return n;
                        }
                        return { ...n, readed: true };
                    }),
                    bellData: {
                        ...newBellData,
                        unReadedCount: newBellData.unReadedCount - 1,
                    },
                });
            }
        });
    };

    renderGroupedNotify = (i) => {
        const { locale } = this.props;
        return (
            <>
                <div className="msg-content">
                    <p
                        dangerouslySetInnerHTML={{
                            __html: locale[i.notifyLocaleKey](i.notifyNum),
                        }}
                    />
                </div>
                <button
                    className="msg-view-btn"
                    onClick={this.handleClickGroupedNotify}
                >
                    {locale.view}
                </button>
            </>
        );
    };

    renderSingleScoreNotify = (i) => {
        const { locale } = this.props;
        const {
            data: { title, diff },
        } = i;
        return (
            <>
                <div
                    className={cns({
                        'msg-content': true,
                        'msg-content-up': diff > 0,
                        'msg-content-down': diff < 0,
                    })}
                >
                    <div className="score-trend-wrap">
                        <ArrowDownFull />
                    </div>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: locale.score_change_notify(diff, title),
                        }}
                    />
                </div>
                <button
                    className="msg-view-btn"
                    onClick={() =>
                        this.handleClickSingleNotify(
                            { ...i, type: 'score', readed: false },
                            1
                        )
                    }
                >
                    {locale.view}
                </button>
            </>
        );
    };

    renderNewsNotify = () => {
        const { locale } = this.props;
        const {
            bellData: { unPushedNewsCount, newsStartDate, newsNotificationId },
        } = this.state;
        return (
            <>
                <div className="msg-content">
                    <div className="news-icon-wrap">
                        <Newspaper />
                    </div>
                    <p>{locale.has_controversial_news(unPushedNewsCount)}</p>
                </div>
                <button
                    className="msg-view-btn"
                    onClick={() =>
                        this.handleClickSingleNotify(
                            {
                                id: newsNotificationId,
                                newsStartDate,
                                type: 'news',
                                readed: false,
                            },
                            unPushedNewsCount
                        )
                    }
                >
                    {locale.view}
                </button>
            </>
        );
    };

    handlePushNotification = () => {
        const {
            bellData: {
                appNotifications,
                unPushedMetricCount,
                unPushedNewsCount,
            },
        } = this.state;
        let msg = null;
        if (unPushedMetricCount && unPushedNewsCount) {
            const mapArr = [
                {
                    notifyLocaleKey: 'unread_score_notify',
                    notifyNum: unPushedMetricCount,
                },
                // {
                //     notifyLocaleKey: 'unread_news_notify',
                //     notifyNum: unPushedNewsCount,
                // },
            ];
            msg = mapArr.map((i) => ({
                content: this.renderGroupedNotify(i),
            }));
        } else if (unPushedMetricCount) {
            if (unPushedMetricCount <= 3) {
                msg = appNotifications.map((i) => ({
                    content: this.renderSingleScoreNotify(i),
                }));
            } else {
                msg = [
                    {
                        content: this.renderGroupedNotify({
                            notifyLocaleKey: 'unread_score_notify',
                            notifyNum: unPushedMetricCount,
                        }),
                    },
                ];
            }
        } else if (unPushedNewsCount) {
            msg = [
                {
                    content: this.renderNewsNotify(),
                },
            ];
        }
        if (Array.isArray(msg) && msg.length > 0) {
            msg.forEach((i, index) => {
                if (index === 0) {
                    this.addMsg(i);
                } else {
                    setTimeout(() => {
                        this.addMsg(i);
                    }, index * 200);
                }
            });
        }
    };

    addMsg = (i) => {
        const { msg } = this.state;
        this.setState({
            msg: [...msg, { content: i.content }],
        });
    };

    handleMsgComplete = (nextMsgCompleteNum) => {
        const { msgCompleteNum, msg } = this.state;
        const areAllComplete = msgCompleteNum + 1 >= msg.length;
        const newState = {
            msgCompleteNum: areAllComplete
                ? 0
                : nextMsgCompleteNum
                ? nextMsgCompleteNum
                : msgCompleteNum + 1,
        };
        if (areAllComplete) {
            newState.msg = [];
        }
        this.setState(newState);
    };

    renderPushNotification = () => {
        const { msg, msgCompleteNum } = this.state;
        return reactDom.createPortal(
            <div
                className={cns({
                    'notify-wrap': true,
                    'notify-wrap-hide': !(
                        msg.length > 0 && msgCompleteNum < msg.length
                    ),
                })}
                ref={this.pushNotificationRef}
            >
                {msg.map((i, index) => (
                    <NotifyMsg
                        key={index}
                        msg={i}
                        handleMsgComplete={this.handleMsgComplete}
                    />
                ))}
            </div>,
            document.getElementsByTagName('body')[0]
        );
    };

    processDiff = (diff) => {
        let str = (diff * 100).toFixed(2);
        if (diff >= 0) {
            str = `+${str}`;
        }
        return str;
    };

    handleClickNotifyItem = (item) => {
        const { id, notificationType, readed, dateCreated } = item;
        const type =
            notificationType === 'METRIC'
                ? 'score'
                : notificationType === 'NEWS'
                ? 'news'
                : 'email';
        const data = {
            type,
            id,
            readed,
        };
        if (type === 'news') {
            data.newsStartDate = dateCreated;
        } else if (type === 'score') {
            data.data = item.data;
        }
        this.handleClickSingleNotify(data);
    };

    makeAllRead = async () => {
        this.setState({ notifyCenterLoading: true });
        try {
            const { bellData, notifyList } = this.state;
            await service.put(profileUrl.NOTIFY_READ, {
                maxId: bellData.maxId,
                readed: true,
            });
            this.setState({
                notifyList: notifyList.map((n) => {
                    return { ...n, readed: true };
                }),
                bellData: {
                    ...bellData,
                    unReadedCount: 0,
                },
            });
        } catch (err) {
            console.log(err);
        }
        this.setState({ notifyCenterLoading: false });
    };

    deleteAllNotify = async () => {
        this.setState({ notifyCenterLoading: true });
        try {
            const {
                bellData: { maxId },
            } = this.state;
            await service.delete(profileUrl.DELETE_NOTIFY(maxId));
            this.setState({
                notifyList: [],
                bellData: {},
            });
        } catch (err) {
            console.log(err);
        }
        this.setState({ notifyCenterLoading: false });
    };

    renderNotifyCenter = (notifyKeys, notifyMap) => {
        const { locale } = this.props;
        return (
            <>
                <div className="notify-list esgai_scrollbar">
                    {notifyKeys.map((key) => {
                        const i = notifyMap[key];
                        return (
                            <div className="notify-group" key={key}>
                                <h6 className="notify-group-title">
                                    {locale[key]}
                                </h6>
                                <div className="group-list">
                                    {i.map((j, jIndex) => {
                                        const isEmail =
                                            j.notificationType ===
                                                'SCORE_REMINDER' ||
                                            j.notificationType ===
                                                'NEWS_REMINDER';
                                        const isNews =
                                            j.notificationType === 'NEWS';
                                        const isScoreUp =
                                            j.notificationType === 'METRIC' &&
                                            j.data &&
                                            j.data.diff > 0;
                                        const isScoreDown =
                                            j.notificationType === 'METRIC' &&
                                            j.data &&
                                            j.data.diff < 0;
                                        let text = '';
                                        if (
                                            j.notificationType ===
                                            'SCORE_REMINDER'
                                        ) {
                                            text =
                                                locale.enable_score_notify_note;
                                        } else if (
                                            j.notificationType ===
                                            'NEWS_REMINDER'
                                        ) {
                                            text =
                                                locale.enable_news_notify_note;
                                        } else if (isNews) {
                                            text = locale.news_notify_content(
                                                j.data.count
                                            );
                                        } else if (isScoreUp) {
                                            text =
                                                locale.score_up_notify_content(
                                                    j.data.title,
                                                    this.processDiff(
                                                        j.data.diff
                                                    )
                                                );
                                        } else if (isScoreDown) {
                                            text =
                                                locale.score_down_notify_content(
                                                    j.data.title,
                                                    this.processDiff(
                                                        j.data.diff
                                                    )
                                                );
                                        }
                                        const isYesterday = isSomeday(
                                            j.dateCreated,
                                            'yesterday'
                                        );
                                        return (
                                            <div
                                                className={cns({
                                                    'notify-item': true,
                                                    'notify-item-unread':
                                                        !j.readed,
                                                })}
                                                key={jIndex}
                                                onClick={() =>
                                                    this.handleClickNotifyItem(
                                                        j
                                                    )
                                                }
                                            >
                                                <div
                                                    className={cns({
                                                        'notify-icon-wrap': true,
                                                        'notify-icon-wrap-email':
                                                            isEmail,
                                                        'notify-icon-wrap-news':
                                                            isNews,
                                                        'notify-icon-wrap-up':
                                                            isScoreUp,
                                                        'notify-icon-wrap-down':
                                                            isScoreDown,
                                                    })}
                                                >
                                                    {isEmail ? (
                                                        <Email />
                                                    ) : isNews ? (
                                                        ''
                                                    ) : (
                                                        <ArrowDownFull />
                                                    )}
                                                </div>
                                                <div className="notify-item-content">
                                                    <p
                                                        dangerouslySetInnerHTML={{
                                                            __html: text,
                                                        }}
                                                    />
                                                    <p>
                                                        {`${
                                                            isYesterday
                                                                ? `${locale.yesterday} `
                                                                : ''
                                                        }${formatDate(
                                                            j.dateCreated,
                                                            key === 'today' ||
                                                                isYesterday
                                                                ? 'newsTime'
                                                                : 'newsDateTime'
                                                        )}`}
                                                    </p>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="notify-dropdown-footer">
                    <button onClick={this.deleteAllNotify}>
                        {locale.delete_all}
                    </button>
                </div>
            </>
        );
    };

    goToSettings = () => {
        this.setState(
            {
                showNotify: false,
            },
            () => {
                const {
                    history,
                    router: {
                        location: { pathname },
                    },
                } = this.props;
                const settingsPath = '/settings/notification';
                if (pathname !== settingsPath) {
                    history.push(settingsPath);
                }
            }
        );
    };

    render() {
        const { locale } = this.props;
        const {
            showNotify,
            bellLoading,
            bellData,
            notifyList,
            notifyCenterLoading,
        } = this.state;
        const notifyMap = {};
        notifyList.forEach((i) => {
            if (isSomeday(i.dateCreated)) {
                if (notifyMap.today) {
                    notifyMap.today.push(i);
                } else {
                    notifyMap.today = [i];
                }
            } else {
                if (notifyMap.previous) {
                    notifyMap.previous.push(i);
                } else {
                    notifyMap.previous = [i];
                }
            }
        });
        const notifyKeys =
            Object.keys(notifyMap).length === 2
                ? ['today', 'previous']
                : Object.keys(notifyMap);

        return (
            <div ref={this.dropdownRef} className="esgai_header-notify-wrap">
                <Loading spinning={bellLoading}>
                    <div
                        className={cns({
                            'bell-wrap': true,
                            'bell-wrap-active': showNotify,
                        })}
                        onClick={this.toggleDropDown}
                    >
                        <Bell />
                        {!!bellData.unAcknowledgedCount && !bellLoading && (
                            <span className="notify-unread-number">
                                {bellData.unAcknowledgedCount < 100
                                    ? bellData.unAcknowledgedCount
                                    : '99+'}
                            </span>
                        )}
                    </div>
                </Loading>
                {showNotify && (
                    <div className="notify-dropdown-wrap">
                        <Loading spinning={notifyCenterLoading}>
                            <div className="notify-dropdown-header">
                                <h5>{locale.notifications}</h5>
                                <div className="header-actions">
                                    <button
                                        className="header-make-all-read"
                                        disabled={!bellData.unReadedCount}
                                        onClick={this.makeAllRead}
                                    >
                                        {locale.mark_all_as_read}
                                    </button>
                                    <button
                                        className="header-settings"
                                        onClick={this.goToSettings}
                                    >
                                        <Settings />
                                    </button>
                                </div>
                            </div>
                            {notifyKeys.length > 0 ? (
                                this.renderNotifyCenter(notifyKeys, notifyMap)
                            ) : (
                                <div className="notify-dropdown-placeholder">
                                    <EmailSend />
                                    <p>{locale.no_notifications}</p>
                                    <p>{locale.no_notifications_note}</p>
                                </div>
                            )}
                        </Loading>
                    </div>
                )}
                {this.renderPushNotification()}
            </div>
        );
    }
}

export default withRouter(
    connect(({ persist: { account, locale }, router }) => ({
        account,
        router,
        locale,
    }))(Notify)
);
