import qs from 'qs';
import { matchPath } from 'react-router-dom';
import { newsPeriod } from '../constant/enum';
import routesMap from '../routes/routesMap';

export const parseQueries = (queries) => {
    // queries is something like: '?a=1&b=2&name=allen'
    if (!queries) {
        return null;
    }
    return qs.parse(queries.split('?')[1]);
};

export const pattern = {
    // eslint-disable-next-line
    email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    // eslint-disable-next-line
    url: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
    required: /\S/,
    password: /^.{6,}$/,
};

export const random = (min = 0, max = 10) => {
    return Math.round(Math.random() * (max - min)) + min;
};

export const formatDate = (timeStamp, mode = 'default', customOptions = {}) => {
    const date = new Date(Number(timeStamp));
    let options = {
        month: 'short',
        day: 'numeric',
        // timeZone: 'UTC',
    };
    if (mode === 'mid') {
        options = {
            ...options,
            year: 'numeric',
        };
    } else if (mode === 'full') {
        options = {
            ...options,
            month: 'long',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
        };
    } else if (mode === 'newsTime') {
        options = {
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
        };
    } else if (mode === 'newsDateTime') {
        options = {
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
        };
    }
    return date
        .toLocaleString('en-US', { ...options, ...customOptions })
        .replace('24:', '00:');
};

export const routerGuide = (account, pathname) => {
    // Use router guide to get redirect router
    const target = routesMap.find((router) => matchPath(pathname, router));
    if (!target) {
        // if (username && pathname === '/news') {
        //     return '/news/company';
        // }
        return '';
    } else {
        const { username, profileConfigured } = account;
        if (pathname !== '/setup-profile' && username) {
            if (!profileConfigured) {
                return '/setup-profile';
            }
        }
        if (pathname === '/setup-profile' && username) {
            if (profileConfigured) {
                return '/dashboard';
            }
        }
        if (!!target.public && !!username) {
            return '/dashboard';
        } else if (!target.public && !username) {
            return '/login';
        }
        return '';
    }
};

export const getPeriodList = (locale) => {
    return Object.keys(newsPeriod).map((i) => {
        const key = newsPeriod[i];
        return {
            label: locale[key],
            value: key,
        };
    });
};

export const splitDate = (timestamp) => {
    const date = new Date(timestamp);
    const y = date.getFullYear();
    const m = date.getMonth();
    const d = date.getDate();
    return { y, m, d };
};

export const getDayTime = (timestamp) => {
    const { y, m, d } = splitDate(timestamp);
    return {
        start: new Date(y, m, d, 0, 0, 0).getTime(),
        end: new Date(y, m, d, 23, 59, 59).getTime(),
    };
};

export const isSomeday = (timestamp, target = 'today') => {
    const today = new Date();
    if (target === 'yesterday') {
        today.setDate(today.getDate() - 1);
    }
    const date = new Date(timestamp);
    return (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
    );
};

export const getYearTime = (year) => {
    return {
        start: new Date(year, 0, 1, 0, 0, 0).getTime(),
        end: new Date(year, 11, 31, 23, 59, 59).getTime(),
    };
};

export const getETDate = () => {
    const date = new Date();
    const dateStringET = date.toLocaleTimeString('en-GB', {
        timeZone: 'America/New_York',
        hour12: false,
    });
    const [hour, min, sec] = dateStringET.split(':');
    return { hour, min, sec };
};

export const getYScope = (yArr, base = 1) => {
    const list = yArr.filter((i) => typeof i === 'number');
    let rawMax = Math.max(...list);
    let rawMin = Math.min(...list);
    if (rawMax === rawMin) {
        return {
            max: rawMax,
            min: rawMin,
            step: 0,
        };
    }
    const rawGap = rawMax - rawMin;
    const step = rawGap / (base * 4);
    rawMax += rawGap / 6;
    if (base === 1 && rawMax > 100) {
        rawMax = 100;
    }
    rawMin = rawMin > rawGap / 6 ? rawMin - rawGap / 6 : 0;
    let max = Math.ceil(rawMax / (base / 100)) / 100;
    let min = Math.floor(rawMin / (base / 100)) / 100;

    if (step >= 100) {
        max = Math.ceil(max / 100) * 100;
        min = Math.floor(min / 100) * 100;
    } else if (step >= 10) {
        max = Math.ceil(max / 10) * 10;
        min = Math.floor(min / 10) * 10;
    } else if (step >= 1) {
        max = Math.ceil(max);
        min = Math.floor(min);
    } else if (step >= 0.1) {
        max = Math.ceil(max * 10) / 10;
        min = Math.floor(min * 10) / 10;
    }
    if (step >= 0.5) {
        max = Math.ceil(max);
        min = Math.floor(min);
        const gap = (max - min) % 4;
        if (gap > 0) {
            max += 4 - gap;
            if (base === 1 && max > 100) {
                min -= max - 100;
                max = 100;
            }
        }
    }
    if (step < 0.5 && step >= 0.05) {
        if (Math.round(min * 10) % 2 > 0) {
            min = Math.round(min * 10 - 1) / 10;
        }
        if (min < 0) {
            min = 0;
        }
        const gap = Math.round((max - min) * 10) % 4;
        if (gap > 0) {
            max = Math.round(max * 10 + (4 - gap)) / 10;
            if (base === 1 && max > 100) {
                min -= max - 100;
                max = 100;
            }
        }
        const yLabelStep = Math.round(((max - min) / 4) * 10) / 10;
        if ((yLabelStep * 10) % 2 > 0) {
            if (min >= 0.4) {
                min -= 0.4;
            } else if (min >= 0.2) {
                min -= 0.2;
                max += 0.2;
            } else {
                max += 0.4;
            }
        }
        max = Math.round(max * 10) / 10;
        min = Math.round(min * 10) / 10;
    }
    if (step < 0.05 && Math.round((max - min) * 100) % 4 > 0) {
        max = (max * 100 + (4 - (Math.round((max - min) * 100) % 4))) / 100;
    }
    max = Math.round(max * 100) / 100;
    min = Math.round(min * 100) / 100;
    return {
        max: max * base,
        min: min * base,
        step,
    };
};

export const formatYLabel = (value, settings, base = 1) => {
    let display = Math.round((value / base) * 100) / 100;
    let { min, max, interval } = settings;
    if (!min && !max && !interval) {
        return display;
    }
    min = Math.round((min / base) * 100) / 100;
    max = Math.round((max / base) * 100) / 100;
    interval = Math.round((interval / base) * 100) / 100;
    if ((min * 10) % 1 > 0 || (max * 10) % 1 > 0 || (interval * 10) % 1 > 0) {
        return display.toFixed(2);
    }
    if (min % 1 > 0 || max % 1 > 0 || interval % 1 > 0) {
        return display.toFixed(1);
    }
    return display;
};

export const getMarketCapBase = (yArr) => {
    const marketCapList = yArr.filter((i) => typeof i === 'number');
    const marketCapMax = Math.max(...marketCapList);
    const marketCapMin = Math.min(...marketCapList);
    const marketCapBase =
        (marketCapMax === marketCapMin && marketCapMin / 10 ** 9 > 0.1) ||
        (marketCapMax - marketCapMin) / 10 ** 9 >= 0.03
            ? 10 ** 9
            : 10 ** 6;
    return { list: marketCapList, base: marketCapBase };
};

export const displaySelectedDate = (period) => {
    if (!Array.isArray(period) || period.length < 2) {
        const targetDate =
            period.length === 0 ? Date.now() : new Date(...period[0]).getTime();
        return formatDate(targetDate, 'default', {
            month: 'long',
            year: 'numeric',
        });
    } else {
        const start = new Date(...period[0]).getTime();
        const end = new Date(...period[1]).getTime();
        return `${formatDate(start, 'default', {
            year: 'numeric',
        })} - ${formatDate(end, 'default', {
            year: 'numeric',
        })}`;
    }
};

export const processNewsApi = (
    state,
    baseApi,
    additionalParamStateKey,
    additionalParamKey,
    additionalParamValueKey,
    additionalParamTotalLength
) => {
    const { period, page = 0, size = 10, newsTypes, onlyControversial } = state;
    let url = `${baseApi}?page=${page}&size=${size}`;
    let additionalParamsArr = [];
    let startDate = null;
    let endDate = null;
    const now = new Date();
    const nowTimestamp = now.getTime();
    const y = now.getFullYear();
    const m = now.getMonth();
    const d = now.getDate();
    if (period.length < 2) {
        const targetDayArr = period.length === 0 ? [y, m, d] : period[0];
        startDate = new Date(...targetDayArr, 0, 0, 0).getTime();
        endDate = new Date(...targetDayArr, 23, 59, 59).getTime();
    } else {
        startDate = new Date(...period[0], 0, 0, 0).getTime();
        endDate = new Date(...period[1], 23, 59, 59).getTime();
    }
    if (endDate > nowTimestamp) {
        endDate = nowTimestamp;
    }
    url += `&startDate=${startDate}&endDate=${endDate}`;
    if (newsTypes.length > 0 && newsTypes.length < 4) {
        if (newsTypes.length === 1 && newsTypes[0] === 'NON_ESG') {
            url += `&esgTopics&nonESG=true`;
        } else {
            let nonESG = false;
            newsTypes.forEach((i) => {
                if (i === 'NON_ESG') {
                    nonESG = true;
                } else {
                    url += `&esgTopics=${i}`;
                }
            });
            url += `&nonESG=${nonESG}`;
        }
    }
    url += `&controversy=${onlyControversial}`;
    if (
        additionalParamStateKey &&
        additionalParamKey &&
        additionalParamValueKey &&
        additionalParamTotalLength &&
        state[additionalParamStateKey].length > 0 &&
        state[additionalParamStateKey].length < additionalParamTotalLength
    ) {
        additionalParamsArr = state[additionalParamStateKey].map(
            (i) => `${additionalParamKey}=${i[additionalParamValueKey]}`
        );
    }
    if (additionalParamsArr.length > 0) {
        url += `&${additionalParamsArr.join('&')}`;
    }
    return url;
};

export const processScore = (data, locale, showGrade = true) => {
    const { grade, score, displayValue, rawValue, rawValueType, numericUnit } =
        data;
    const isBool = rawValueType === 'BOOLEAN';
    const hasScore = typeof score === 'number' && grade;
    const displayScore = hasScore
        ? `${parseFloat(displayValue) === 100 ? 100 : displayValue}${
              showGrade ? ` ${grade}` : ''
          }`
        : '--';
    let displayRawValue = isBool ? locale.not_reported : '--';
    if (rawValue) {
        if (isBool) {
            displayRawValue = rawValue === 'true' ? locale.yes : locale.no;
        } else {
            const { dataType } = numericUnit;
            displayRawValue =
                dataType === 'Integer'
                    ? parseInt(rawValue)
                    : parseFloat(rawValue).toFixed(2);
        }
    }
    return { displayScore, displayRawValue };
};
