import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { message, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import cns from 'classnames';
import Button from '../../../../component/Button';
import {
    Edit,
    Close,
    User,
    Plus,
    Loading,
    Check,
} from '../../../../component/Icon';
import env from '../../../../constant/env';
import { profileUrl } from '../../../../constant/endpoint';
import service from '../../../../service';
import './index.scss';

class UserInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userInfo: {},
            uploadImage: {},
            uploadingAvatar: false,
            saving: false,
            touchedPosition: false,
            userEditable: false,
            saveSuccess: false,
            showMobileBottomSheet: false,
        };
        this.positionRef = React.createRef();
        this.timer = null;
    }

    componentDidUpdate(prevProps) {
        const { userInfo, discardChanges } = this.props;
        const { userInfo: prevUserInfo, discardChanges: prevDiscardChanges } =
            prevProps;
        if (
            prevUserInfo !== userInfo ||
            (!prevDiscardChanges && discardChanges)
        ) {
            this.syncUserInfoWithProps();
        }
    }

    componentWillUnmount() {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
    }

    syncUserInfoWithProps = () => {
        const { userInfo, discardChanges, updateParentState } = this.props;
        this.setState(
            {
                userInfo,
                uploadImage: {},
                userEditable: false,
            },
            () => {
                if (discardChanges) {
                    updateParentState('showDiscardModal', false);
                }
                this.setState({ saveSuccess: false });
            }
        );
    };

    toggleEditUser = () => {
        const { userEditable } = this.state;
        const { updateParentState } = this.props;
        if (!userEditable) {
            this.setState({
                userEditable: true,
                touchedPosition: false,
            });
            updateParentState('discardChanges', false);
        } else {
            this.cancelUserInfo();
        }
    };

    deleteAvatar = () => {
        const {
            userInfo,
            uploadImage: { key: uploadKey, keyUrl: uploadKeyUrl },
            showMobileBottomSheet,
        } = this.state;
        if (showMobileBottomSheet) {
            this.setState({ showMobileBottomSheet: false });
        }
        const { avatar, avatarUrl } = userInfo;
        if (uploadKey && uploadKeyUrl) {
            this.setState({ uploadImage: {} });
        } else if (avatar && avatarUrl) {
            this.setState({
                userInfo: { ...userInfo, avatar: '', avatarUrl: '' },
            });
        }
    };

    handleSaveSuccess = (newUserInfo) => {
        const { updateParentState } = this.props;
        if (this.timer) {
            clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
            updateParentState('userInfo', newUserInfo);
        }, 500);
    };

    saveUserInfo = async () => {
        const { saving } = this.state;
        const { showDiscardModal } = this.props;
        if (saving || showDiscardModal || this.disableSaveBtn()) {
            return;
        }
        const newInfo = this.getLatestInfo();
        this.setState({ saving: true });
        try {
            const res = await service.put(profileUrl.UPDATE_PROFILE, newInfo);
            if (res && res.id) {
                this.setState({ saving: false, saveSuccess: true }, () => {
                    this.handleSaveSuccess(res);
                });
            }
        } catch (err) {
            console.log(err);
            this.setState({ saving: false });
        }
    };

    cancelUserInfo = () => {
        const { userInfo, updateParentState } = this.props;
        const newInfo = this.getLatestInfo();
        const hasChange = !!Object.keys(newInfo).find(
            (i) => newInfo[i] !== userInfo[i]
        );
        if (hasChange) {
            updateParentState('showDiscardModal', true);
        } else {
            this.setState({ userEditable: false });
        }
    };

    getLatestInfo = () => {
        const {
            userInfo: { firstName, lastName, position },
        } = this.state;
        const avatarData = this.getAvatar();
        const avatar = avatarData ? avatarData.key : null;
        return {
            firstName,
            lastName,
            position,
            avatar,
        };
    };

    handleUserInfoChange = (e, key) => {
        const { userInfo, touchedPosition } = this.state;
        if (key === 'position' && !touchedPosition) {
            this.setState({
                touchedPosition: true,
            });
        }
        this.setState({
            userInfo: { ...userInfo, [key]: e.target.value },
        });
    };

    renderFields = () => {
        const { userInfo, userEditable, touchedPosition } = this.state;
        const {
            account: { username },
            locale,
        } = this.props;

        const fields = [
            {
                key: 'firstName',
                label: locale.first_name,
                required: true,
            },
            {
                key: 'lastName',
                label: locale.last_name,
                required: true,
            },
            {
                key: 'email',
                label: locale.email,
                required: true,
            },
            {
                key: 'position',
                label: locale.position,
                required: false,
            },
        ];
        return (
            <div className="user-info-fields">
                {fields.map((i) => {
                    const isEmail = i.key === 'email';
                    const isPosition = i.key === 'position';
                    const showAddIcon =
                        isPosition &&
                        !touchedPosition &&
                        !userInfo[i.key] &&
                        userEditable;
                    return (
                        <div className="user-info-field" key={i.key}>
                            <span className="user-info-field-title">
                                {i.label}
                                {userEditable && i.required && <b>*</b>}
                            </span>
                            <input
                                ref={isPosition ? this.positionRef : null}
                                className={cns({
                                    'settings-input-open':
                                        !isEmail && userEditable,
                                    'settings-input-disabled': isEmail,
                                    'settings-input-with-add-icon': showAddIcon,
                                })}
                                disabled={isEmail || !userEditable}
                                value={
                                    (isEmail ? username : userInfo[i.key]) ||
                                    (userEditable ? '' : '--')
                                }
                                onChange={(e) =>
                                    this.handleUserInfoChange(e, i.key)
                                }
                                maxLength={60}
                                autoComplete="off"
                            />
                            {showAddIcon && (
                                <div
                                    className="user-info-add-icon"
                                    onClick={() =>
                                        this.positionRef.current.focus()
                                    }
                                >
                                    <Plus style={{ fontSize: 16 }} />
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    };

    handleAvatarChange = (info) => {
        const { file } = info;
        const { status: uploadStatus } = file;
        if (uploadStatus === 'uploading') {
            this.setState({ uploadingAvatar: true });
            return;
        } else if (uploadStatus === 'done') {
            this.setState({
                uploadingAvatar: false,
            });
            const { response } = file;
            if (response && response.key && response.keyUrl) {
                this.setState({ uploadImage: response });
            }
        } else if (uploadStatus === 'error') {
            this.setState({
                uploadingAvatar: false,
            });
            const { error } = file;
            if (error.status && error.message) {
                message.error(`Error ${error.status}: ${error.message}`);
            } else {
                message.error(this.props.locale.unexpected_error);
            }
        }
    };

    beforeUpload = (file) => {
        const isLt10M = file.size / 1024 / 1024 < 10;
        if (!isLt10M) {
            message.error(this.props.locale.max_img_size);
        }
        return isLt10M;
    };

    renderUploadButton = () => {
        const { uploadingAvatar } = this.state;
        return (
            <div className="uploader-text-wrap">
                {uploadingAvatar ? (
                    <Loading style={{ fontSize: 28 }} color="26D8EA" />
                ) : (
                    this.props.locale.upload
                )}
            </div>
        );
    };

    getAvatar = () => {
        const {
            userInfo: { avatar, avatarUrl },
            uploadImage: { key: uploadKey, keyUrl: uploadKeyUrl },
        } = this.state;
        if (uploadKey && uploadKeyUrl) {
            return { key: uploadKey, url: uploadKeyUrl };
        } else if (avatar && avatarUrl) {
            return { key: avatar, url: avatarUrl };
        }
        return null;
    };

    disableSaveBtn = () => {
        const {
            userInfo: { firstName = '', lastName = '' },
        } = this.state;
        return !(firstName.trim() && lastName.trim());
    };

    mobileUpload = () => {
        this.setState(
            {
                showMobileBottomSheet: false,
            },
            () => {
                const uploadInput = document
                    .getElementsByClassName('user-info-avatar-uploader-wrap')[0]
                    .getElementsByTagName('input')[0];
                uploadInput.click();
            }
        );
    };

    render() {
        const {
            account: { token },
            locale,
        } = this.props;
        const {
            userEditable,
            uploadingAvatar,
            saving,
            saveSuccess,
            showMobileBottomSheet,
        } = this.state;
        const avatar = this.getAvatar();
        const UserEditIcon = userEditable ? Close : Edit;
        return (
            <div className="user-info">
                <div className="user-info-top">
                    <h6 className="user-info-title">{locale.user_info}</h6>
                    <button
                        className={cns({
                            'user-info-edit-btn': true,
                            'user-info-edit-btn-close': userEditable,
                        })}
                        onClick={this.toggleEditUser}
                    >
                        <UserEditIcon style={{ fontSize: 24 }} />
                    </button>
                </div>
                <div className="user-info-mid">
                    <div className="user-info-mid-left">
                        <div className="user-info-avatar-wrap">
                            {avatar && avatar.url ? (
                                <img
                                    src={avatar.url}
                                    className="user-info-avatar"
                                    alt="User Avatar"
                                />
                            ) : (
                                <User style={{ fontSize: 68 }} color="B6B8C5" />
                            )}
                            {userEditable && (
                                <>
                                    <div
                                        className={cns({
                                            'user-info-avatar-uploader-wrap': true,
                                            'user-info-avatar-uploader-wrap-uploading':
                                                uploadingAvatar,
                                        })}
                                    >
                                        <ImgCrop
                                            rotate
                                            // quality={1}
                                            modalTitle="Edit Image"
                                            modalOk={locale.save.toUpperCase()}
                                            modalCancel={locale.cancel.toUpperCase()}
                                        >
                                            <Upload
                                                beforeUpload={this.beforeUpload}
                                                disabled={uploadingAvatar}
                                                name="file"
                                                className="user-info-avatar-uploader"
                                                showUploadList={false}
                                                action={`${env.apiBaseUrl}/images`}
                                                onChange={
                                                    this.handleAvatarChange
                                                }
                                                headers={{
                                                    'X-Auth-Token': token,
                                                }}
                                            >
                                                {this.renderUploadButton()}
                                            </Upload>
                                        </ImgCrop>
                                    </div>
                                    {!uploadingAvatar && (
                                        <button
                                            className="mobile-avatar-edit-btn"
                                            onClick={() =>
                                                this.setState({
                                                    showMobileBottomSheet: true,
                                                })
                                            }
                                        >
                                            <Edit
                                                color="FFFFFF"
                                                style={{ fontSize: 15 }}
                                            />
                                        </button>
                                    )}
                                </>
                            )}
                        </div>
                        {userEditable && avatar && avatar.url && (
                            <Button
                                type="link"
                                label={locale.remove}
                                onClick={this.deleteAvatar}
                            />
                        )}
                    </div>
                    {this.renderFields()}
                </div>
                {userEditable && (
                    <div
                        className={cns({
                            'user-info-actions': true,
                            'user-info-actions-success': saveSuccess,
                        })}
                    >
                        <Button
                            loading={saving}
                            label={
                                saveSuccess ? (
                                    <Check
                                        style={{ fontSize: 24 }}
                                        color="FFFFFF"
                                    />
                                ) : saving ? (
                                    locale.saving
                                ) : (
                                    locale.save
                                )
                            }
                            onClick={this.saveUserInfo}
                            disabled={this.disableSaveBtn()}
                        />
                        <Button
                            type="link"
                            label={locale.cancel}
                            onClick={this.cancelUserInfo}
                        />
                    </div>
                )}
                <div
                    className="profile-mobile-bottom-sheet"
                    style={{ zIndex: showMobileBottomSheet ? 1002 : -1 }}
                >
                    <div
                        className="bottom-sheet-content"
                        style={{ bottom: showMobileBottomSheet ? 0 : -170 }}
                    >
                        <button onClick={this.mobileUpload}>
                            {locale.upload_photo}
                        </button>
                        {userEditable && avatar && avatar.url && (
                            <button onClick={this.deleteAvatar}>
                                {locale.remove}
                            </button>
                        )}
                        <button
                            onClick={() =>
                                this.setState({ showMobileBottomSheet: false })
                            }
                        >
                            {locale.cancel}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(
    connect(({ persist: { account, locale }, router }) => ({
        account,
        router,
        locale,
    }))(UserInfo)
);
