import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import './index.scss';
import Popover from '../../../../component/Popover';
import { Business } from '../../../../component/Icon';
import { processScore } from '../../../../utils';
import { optimizerUrl } from '../../../../constant/endpoint';
import service from '../../../../service';

const enlarge = 10;

class NumericChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            scale: 1,
            startP: null,
            endP: null,
            showScrubber: true,
            originOffset: 0,
            scrubberStartLeft: 0,
            canvasWrapWidth: 0,
            scrubberMaskPosition: {
                left: 0,
                top: 0,
            },
            loading: false,
        };
        this.scrollBoxRef = React.createRef();
        this.canvasRef = React.createRef();
        this.scrubberRef = React.createRef();
        this.scrubberMaskRef = React.createRef();
        this.scrollTimer = null;
        this.syncScrubberTimer = null;
    }

    componentDidMount() {
        this.addCanvasListener();
        if (isMobile) {
            window.addEventListener('touchmove', this.handleMousemove);
            window.addEventListener('touchend', this.handleMouseup);
        } else {
            window.addEventListener('mousemove', this.handleMousemove);
            window.addEventListener('mouseup', this.handleMouseup);
        }
        this.scrollBoxRef.current.addEventListener(
            'scroll',
            this.syncScrubberMaskPositionThrottle
        );
    }

    componentDidUpdate(prevProps, prevState) {
        const { scale } = this.state;
        const { scale: prevScale } = prevState;
        if (prevScale !== scale) {
            if (scale === 4) {
                this.syncScrubberMaskPositionThrottle();
                this.checkData();
            }
        }
    }

    componentWillUnmount() {
        this.removeCanvasListener();
        this.clearScrollTimer();
        this.clearSyncScrubberTimer();
        if (isMobile) {
            window.removeEventListener('touchmove', this.handleMousemove);
            window.removeEventListener('touchend', this.handleMouseup);
        } else {
            window.removeEventListener('mousemove', this.handleMousemove);
            window.removeEventListener('mouseup', this.handleMouseup);
        }
        this.scrollBoxRef.current.removeEventListener(
            'scroll',
            this.syncScrubberMaskPositionThrottle
        );
    }

    checkData = async () => {
        const {
            boardId,
            handleUpdateAllCompetitorsMap,
            allCompetitorsScore,
            selectedItem: { name },
            account: {
                organization: { organizationId },
            },
        } = this.props;
        if (allCompetitorsScore) {
            return;
        }
        try {
            this.setState({ loading: true });
            const res = await service.get(
                optimizerUrl.GET_ALL_COMPETITOR_SCORE(boardId),
                {
                    name,
                }
            );
            if (res) {
                const allCompetitors = {};
                const canvas = this.canvasRef.current;
                const { width } = canvas.getBoundingClientRect();
                res.forEach((c) => {
                    const score = parseFloat(c.score);
                    if (!isNaN(score) && c.organizationId !== organizationId) {
                        const w = c.competitor ? 20 : 12;
                        const left = (score / 1) * width;
                        const leftBoundary = left - w / 2;
                        const rightBoundary = left + w / 2;
                        const newC = {
                            ...c,
                            left,
                            leftBoundary,
                            rightBoundary,
                        };
                        const key = score.toString();
                        if (Array.isArray(allCompetitors[key])) {
                            allCompetitors[key].push(newC);
                        } else {
                            allCompetitors[key] = [newC];
                        }
                    }
                });
                handleUpdateAllCompetitorsMap({ [name]: allCompetitors });
            }
        } catch (err) {
            console.log(err);
        }
        this.setState({ loading: false });
    };

    syncScrubberMaskPositionThrottle = () => {
        if (this.syncScrubberTimer) {
            return;
        }
        this.syncScrubberTimer = setTimeout(() => {
            this.syncScrubberMaskPosition();
            this.clearSyncScrubberTimer();
        }, 400);
    };

    syncScrubberMaskPosition = () => {
        const scrubber = this.scrubberRef.current.getBoundingClientRect();
        const scrubberMask =
            this.scrubberMaskRef.current.getBoundingClientRect();
        const left = scrubber.left - (scrubberMask.width - scrubber.width) / 2;
        const top = scrubber.top - (scrubberMask.height - scrubber.height) / 2;
        const { scrubberMaskPosition } = this.state;
        if (
            left !== scrubberMaskPosition.left ||
            top !== scrubberMaskPosition.top
        ) {
            this.setState({
                scrubberMaskPosition: {
                    left,
                    top,
                },
            });
        }
    };

    setBoundary = () => {
        const { left, right } =
            this.scrollBoxRef.current.getBoundingClientRect();
        this.setState({
            boundary: {
                minLeft: left + (20 - 24 / 2),
                maxLeft: right - (20 + 24 / 2),
            },
        });
    };

    addCanvasListener = () => {
        this.resizeObserver = new ResizeObserver(() => {
            this.handleScaleChange();
        });
        this.resizeObserver.observe(this.canvasRef.current);
    };

    removeCanvasListener = () => {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
            this.resizeObserver = null;
        }
    };

    boxScrollTo = (left) => {
        this.scrollBoxRef.current.scrollTo({
            top: 0,
            left,
            behavior: 'auto',
        });
    };

    handleScaleChange = () => {
        const { width } = this.scrollBoxRef.current.getBoundingClientRect();
        this.setState(
            {
                canvasWrapWidth: width - 40,
            },
            () => {
                this.setBoundary();
                this.setState({ showScrubber: false }, () => {
                    this.setState({ showScrubber: true }, () => {
                        this.drawCanvas();
                        const { scale } = this.state;
                        const { targetScore } = this.props;
                        if (scale === 4) {
                            let leftPoint =
                                targetScore <= 0.12
                                    ? 0
                                    : parseFloat(
                                          (targetScore - 0.12).toFixed(2)
                                      );
                            let rightPoint = leftPoint + 0.25;
                            if (rightPoint > 1) {
                                leftPoint -= rightPoint - 1;
                                rightPoint = 1;
                            }
                            const canvas = this.canvasRef.current;
                            const { width } = canvas.getBoundingClientRect();
                            this.boxScrollTo((leftPoint / 1) * width);
                        } else {
                            this.boxScrollTo(0);
                        }
                    });
                });
            }
        );
    };

    formatScore = (score) => {
        if (typeof score !== 'number') {
            return null;
        }
        return (score * 100).toFixed(2);
    };

    includedCompetitors = (index) => {
        const { competitors } = this.props;
        if (!Array.isArray(competitors) || competitors.length === 0) {
            return [];
        }
        const min = 0.02 * index;
        const max = min + 0.02;
        return competitors.filter(
            (c) =>
                typeof c.score === 'number' &&
                c.score >= min &&
                (c.score < max || (max === 1 && c.score === 1))
        );
    };

    drawCanvas = () => {
        const {
            stat: { min, max, data },
        } = this.props;
        const { scale } = this.state;
        const is4x = scale === 4;
        const arr = data.map((i) => i.count);
        const canvas = this.canvasRef.current;
        const { width } = canvas.getBoundingClientRect();
        const ctx = canvas.getContext('2d');
        /* x-axis */
        ctx.fillStyle = '#D4D6E2';
        ctx.fillRect(0, 70 * enlarge, width * enlarge, 1 * enlarge);
        ctx.fillRect(0, 63 * enlarge, 1 * enlarge, 8 * enlarge);
        ctx.fillRect(
            (width - 1) * enlarge,
            63 * enlarge,
            1 * enlarge,
            8 * enlarge
        );
        ctx.font = `600 ${8 * enlarge}px/${10 * enlarge}px sans-serif`;
        ctx.fillStyle = '#7A7A7A';
        const axisTickLength = is4x ? 5 : 25;
        const axisTickArr = [...new Array(100 / axisTickLength + 1).keys()].map(
            (i) => i * axisTickLength
        );
        axisTickArr.forEach((i) => {
            let x = 0;
            if (i > 0 && i < 100) {
                x =
                    (width / (100 / i)) * enlarge -
                    ctx.measureText(i).width / 2;
            } else if (i === 100) {
                x = width * enlarge;
            }
            ctx.textAlign = i === 100 ? 'end' : 'start';
            ctx.fillText(i, x, 80 * enlarge);
        });
        if (typeof min === 'number' && typeof max === 'number') {
            /* min -> max score area */
            // background
            const minX = (width * min) / 1;
            const maxX = (width * max) / 1;
            ctx.fillStyle = '#F1F4F7';
            ctx.fillRect(
                minX * enlarge,
                0,
                (maxX - minX) * enlarge,
                70 * enlarge
            );
            // boundary
            ctx.beginPath();
            ctx.lineWidth = enlarge;
            ctx.setLineDash([enlarge * 5, enlarge * 5]);
            ctx.moveTo(minX * enlarge, 0);
            ctx.lineTo(minX * enlarge, 81 * enlarge);
            ctx.moveTo((maxX - 1) * enlarge, 0);
            ctx.lineTo((maxX - 1) * enlarge, 81 * enlarge);
            ctx.strokeStyle = '#D4D6E2';
            ctx.stroke();
            ctx.closePath();
            ctx.font = `600 ${10 * enlarge}px sans-serif`;
            ctx.fillStyle = '#8B94CC';
            ctx.textAlign = 'start';
            ctx.fillText('Min', minX * enlarge, 89 * enlarge);
            ctx.fillText(this.formatScore(min), minX * enlarge, 99 * enlarge);
            ctx.textAlign = 'end';
            ctx.fillText('Max', maxX * enlarge, 89 * enlarge);
            ctx.fillText(this.formatScore(max), maxX * enlarge, 99 * enlarge);
            if (is4x) {
                return;
            }
            // bar chart
            const maxCompanyNum = Math.max(...arr);
            let perHeight = 0;
            if (isFinite(maxCompanyNum)) {
                perHeight = 28 / maxCompanyNum;
            }
            const barW = width / 50;
            arr.forEach((i, index) => {
                if (i === 0) {
                    return;
                }
                const barH = perHeight * i;
                ctx.fillStyle =
                    this.includedCompetitors(index).length > 0
                        ? '#9C42CC'
                        : '#CDD2D4';
                ctx.fillRect(
                    index * barW * enlarge,
                    (70 - barH) * enlarge,
                    barW * enlarge,
                    barH * enlarge
                );
            });
        }
    };

    getScrubberPosition = () => {
        const { left } = this.scrubberRef.current.getBoundingClientRect();
        return { left };
    };

    clearScrollTimer = () => {
        if (this.scrollTimer) {
            clearInterval(this.scrollTimer);
            this.scrollTimer = null;
        }
    };

    clearSyncScrubberTimer = () => {
        if (this.syncScrubberTimer) {
            clearTimeout(this.syncScrubberTimer);
            this.syncScrubberTimer = null;
        }
    };

    handleClickScrubber = (e) => {
        const eventInfo = isMobile ? e.touches[0] : e;
        if (!eventInfo) {
            return;
        }
        const { clientX, clientY } = eventInfo;
        let { left } = this.getScrubberPosition();
        const { boundary } = this.state;
        if (left < boundary.minLeft) {
            const scrollBox = this.scrollBoxRef.current;
            const scrollLeft = scrollBox.scrollLeft;
            this.boxScrollTo(scrollLeft - (boundary.minLeft - left));
            left = boundary.minLeft;
        }
        if (left > boundary.maxLeft) {
            const scrollBox = this.scrollBoxRef.current;
            const scrollLeft = scrollBox.scrollLeft;
            this.boxScrollTo(scrollLeft + (left - boundary.maxLeft));
            left = boundary.maxLeft;
        }
        this.setState({
            startP: {
                x: clientX,
                y: clientY,
            },
            scrubberStartLeft: left,
        });
    };

    handleMousemove = (e) => {
        const {
            startP,
            scale,
            boundary,
            scrubberStartLeft,
            canvasWrapWidth,
            loading,
        } = this.state;
        if (startP && !loading) {
            const eventInfo = isMobile ? e.touches[0] : e;
            if (!eventInfo) {
                return;
            }
            const { clientX, clientY } = eventInfo;
            this.setState(
                {
                    endP: {
                        x: clientX,
                        y: clientY,
                    },
                },
                () => {
                    if (scale === 4) {
                        this.syncScrubberMaskPositionThrottle();
                        const scrollBox = this.scrollBoxRef.current;
                        const canvas = this.canvasRef.current;
                        const scrollLeft = scrollBox.scrollLeft;
                        const maxScrollLeft =
                            canvas.getBoundingClientRect().width -
                            canvasWrapWidth;
                        if (
                            ((startP.x - clientX >=
                                scrubberStartLeft - boundary.minLeft &&
                                scrollLeft > 0) ||
                                (clientX - startP.x >=
                                    boundary.maxLeft - scrubberStartLeft &&
                                    scrollLeft < maxScrollLeft)) &&
                            !this.scrollTimer
                        ) {
                            this.scrollTimer = setInterval(() => {
                                const { originOffset } = this.state;
                                const baseStep = 4;
                                const step =
                                    startP.x - clientX >=
                                    scrubberStartLeft - boundary.minLeft
                                        ? baseStep
                                        : -baseStep;
                                this.setState(
                                    { originOffset: originOffset + step },
                                    () => {
                                        let newScrollLeft =
                                            this.scrollBoxRef.current
                                                .scrollLeft - step;
                                        if (newScrollLeft <= 0) {
                                            newScrollLeft = 0;
                                        } else if (
                                            newScrollLeft >= maxScrollLeft
                                        ) {
                                            newScrollLeft = maxScrollLeft;
                                        }
                                        this.boxScrollTo(newScrollLeft);
                                        if (
                                            newScrollLeft <= 0 ||
                                            newScrollLeft >= maxScrollLeft
                                        ) {
                                            this.clearScrollTimer();
                                        }
                                    }
                                );
                            }, 50);
                        }
                        if (
                            startP.x - clientX <
                                scrubberStartLeft - boundary.minLeft &&
                            clientX - startP.x <
                                boundary.maxLeft - scrubberStartLeft
                        ) {
                            this.clearScrollTimer();
                        }
                    }
                }
            );
        }
    };

    getNearbyScore = async (score) => {
        const {
            boardId,
            selectedItem: { name },
        } = this.props;
        try {
            this.setState({ loading: true });
            const res = await service.get(
                optimizerUrl.METRIC_TARGET_SCORE(boardId),
                {
                    name,
                    score:
                        parseFloat(score) < 0
                            ? 0
                            : parseFloat(score) > 1
                            ? 1
                            : parseFloat(score),
                }
            );
            if (res) {
                this.setState({ loading: false });
                return res;
            }
        } catch (err) {
            console.log(err);
        }
        this.setState({ loading: false });
        return null;
    };

    handleMouseup = async () => {
        const { startP, endP } = this.state;
        const { targetScore, updateModalState } = this.props;
        if (startP || endP) {
            this.clearScrollTimer();
            const newState = { startP: null, endP: null, originOffset: 0 };
            const { score, rawScore } = this.getNewPosition();
            if (
                (parseFloat(score) / 100).toFixed(4) !== targetScore.toFixed(4)
            ) {
                const newValue = await this.getNearbyScore(rawScore);
                if (newValue) {
                    const { rawValue: newRawValue, score: newScore } = newValue;
                    updateModalState({
                        targetScore: parseFloat(newScore),
                        targetRawValue: newRawValue,
                    });
                }
            }
            this.setState(newState);
        }
    };

    getNewPosition = () => {
        const {
            startP,
            endP,
            originOffset,
            scrubberStartLeft,
            scale,
            boundary,
        } = this.state;
        const { targetScore, currentScore } = this.props;
        const canvas = this.canvasRef.current;
        const { width } = canvas
            ? canvas.getBoundingClientRect()
            : { width: 0 };
        const startLeft = (targetScore / 1) * width;
        const startScore =
            targetScore > 0 && targetScore < 1
                ? (targetScore * 100).toFixed(2)
                : targetScore * 100;
        const formattedCurrentScore = currentScore > 0 && currentScore < 1 ? (currentScore * 100).toFixed(2) : currentScore * 100;
        let left = startLeft - originOffset;
        const currentLeft = (currentScore / 1) * width;
        let gapScore = 0;
        if (startP && endP) {
            let gap = endP.x - startP.x;
            if (scale === 4) {
                if (gap < 0 && gap <= boundary.minLeft - scrubberStartLeft) {
                    gap = boundary.minLeft - scrubberStartLeft;
                }
                if (gap > 0 && gap >= boundary.maxLeft - scrubberStartLeft) {
                    gap = boundary.maxLeft - scrubberStartLeft;
                }
            }

            left += gap;
            gapScore = ((gap - originOffset) * 1) / width;
        }
        let score = ((targetScore + gapScore) * 100).toFixed(2);
        if (left <= 0) {
            left = 0;
            score = 0;
        }
        if (left >= width) {
            left = width;
            score = 100;
        }
        return {
            left,
            score,
            startLeft,
            startScore,
            currentLeft,
            currentScore: formattedCurrentScore,
            rawScore: targetScore + gapScore,
        };
    };

    renderScrubberMask = () => {
        const { scale, scrubberMaskPosition } = this.state;
        return ReactDOM.createPortal(
            <div
                className={classNames({
                    'logo-scrubber-mask': true,
                    'logo-scrubber-mask-hide': scale === 1,
                })}
                style={scrubberMaskPosition}
                ref={this.scrubberMaskRef}
                onMouseDown={this.handleClickScrubber}
                onTouchStart={this.handleClickScrubber}
            />,
            document.getElementsByTagName('body')[0]
        );
    };

    renderScrubber = () => {
        const { logo } = this.props;
        const { scale } = this.state;
        const { left, score, startLeft, startScore, currentScore, currentLeft } = this.getNewPosition();
        const wrapClass4x = scale === 4 ? 'scrubber-wrap-4x' : '';
        const logoEl =
            typeof logo === 'string' ? (
                <img src={logo} alt="" />
            ) : (
                <Business color="C8C8C9" style={{ fontSize: 12 }} />
            );

        return (
            <>
                <div
                    className={`scrubber-wrap ${wrapClass4x}`}
                    style={{ left: left || 0 }}
                >
                    <span className="current-score">{score}</span>
                    {this.renderScrubberMask()}
                    <div
                        ref={this.scrubberRef}
                        className="logo-scrubber"
                        onMouseDown={this.handleClickScrubber}
                        onTouchStart={this.handleClickScrubber}
                    >
                        {logoEl}
                    </div>
                </div>
                <div
                    className={`scrubber-wrap scrubber-wrap-origin ${wrapClass4x}`}
                    style={{ left: startLeft || 0 }}
                >
                    <span className="current-score">{startScore  || ''}</span>
                    <div className="logo-scrubber">{logoEl}</div>
                </div>
                <div
                    className={`scrubber-wrap scrubber-wrap-initial ${wrapClass4x}`}
                    style={{ left: currentLeft || 0 }}
                >
                    <span className="current-score">{currentScore || ''}</span>
                    <div className="logo-scrubber">{logoEl}</div>
                </div>
            </>
        );
    };

    renderCompetitorsTooltip = (competitors, totalNum) => {
        const { locale, getUnit, selectedItem } = this.props;
        const { rawValueType, numericUnit } = selectedItem;
        const gap = totalNum - competitors.length;
        const unit = getUnit(selectedItem);
        return (
            <div className="numeric-competitors-tooltip">
                <div className="numeric-competitors">
                    {competitors.map((c) => {
                        const { displayScore, displayRawValue } = processScore(
                            { ...c, rawValueType, numericUnit },
                            locale,
                            false
                        );
                        return (
                            <div
                                key={c.organizationId}
                                className="numeric-competitor"
                            >
                                <h6>{c.name}</h6>
                                <p>{displayScore}</p>
                                <p>
                                    {displayRawValue}
                                    {unit}
                                </p>
                            </div>
                        );
                    })}
                    {gap > 0 && (
                        <div className="numeric-competitors-other">
                            <p>--</p>
                            <p>{`${gap} ${locale.other_companies}`}</p>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    renderAllCompetitors = () => {
        const { allCompetitorsScore, mobileView } = this.props;
        if (
            !allCompetitorsScore ||
            Object.keys(allCompetitorsScore).length === 0
        ) {
            return null;
        }
        const scrollBox = this.scrollBoxRef.current;
        const { width } = scrollBox.getBoundingClientRect();
        const scrollLeft = scrollBox.scrollLeft;

        return (
            <div className="hovered-board all-competitors-wrap">
                {Object.keys(allCompetitorsScore).map((key) => {
                    const dots = allCompetitorsScore[key];
                    const { left, leftBoundary, rightBoundary } = dots[0];
                    if (
                        rightBoundary < scrollLeft - 20 ||
                        leftBoundary > scrollLeft + width - 20
                    ) {
                        return null;
                    }
                    let c = dots[0];
                    const watchedCompetitors = dots.filter((d) => d.competitor);
                    if (watchedCompetitors.length === 0) {
                        if (dots.length > 1) {
                            c = dots[Math.floor(Math.random() * dots.length)];
                        }
                    } else if (watchedCompetitors.length === 1) {
                        c = watchedCompetitors[0];
                    } else {
                        c =
                            watchedCompetitors[
                                Math.floor(
                                    Math.random() * watchedCompetitors.length
                                )
                            ];
                    }
                    const logoEl = !c.competitor ? null : c.logo &&
                      c.logoUrl ? (
                        <img src={c.logoUrl} alt="" />
                    ) : (
                        <Business color="C8C8C9" style={{ fontSize: 10 }} />
                    );
                    return (
                        <div
                            className={classNames({
                                competitor: true,
                                'competitor-watched': c.competitor,
                            })}
                            style={{ left: left || 0 }}
                            key={c.organizationId}
                        >
                            <Popover
                                tooltip={this.renderCompetitorsTooltip(
                                    [
                                        {
                                            ...c,
                                            score: parseFloat(c.score),
                                            grade: 'A',
                                            displayValue: (
                                                parseFloat(c.score) * 100
                                            ).toFixed(2),
                                        },
                                    ],
                                    1
                                )}
                                isMobileView={mobileView}
                                width={150}
                                mobileMaxWidth={150}
                                placement="top"
                                mobilePlacement="top"
                                verticalGap={-16}
                                isInnerHTML={false}
                            >
                                <div className="competitor-logo-wrap">
                                    {logoEl}
                                </div>
                            </Popover>
                        </div>
                    );
                })}
            </div>
        );
    };

    render() {
        const { scale, showScrubber, canvasWrapWidth, loading } = this.state;
        const {
            locale,
            stat: { min, max, data },
            mobileView,
        } = this.props;
        const hasData = typeof min === 'number' && typeof max === 'number';
        const arr = hasData ? data.map((i) => i.count) : [];
        const maxCompanyNum = Math.max(...arr);
        let perHeight = 0;
        if (isFinite(maxCompanyNum)) {
            perHeight = 30 / maxCompanyNum;
        }
        const is1x = scale === 1;
        return (
            <div className="optimizer_board_detail-numeric-chart-wrap">
                <div className="zoom-actions">
                    {[1, 4].map((i) => (
                        <button
                            key={i}
                            className={classNames({
                                'zoom-item-active': i === scale,
                            })}
                            onClick={() => this.setState({ scale: i })}
                        >
                            {i}x
                        </button>
                    ))}
                </div>
                <Spin
                    indicator={
                        <LoadingOutlined
                            style={{ fontSize: 24, color: '#233bc9' }}
                        />
                    }
                    spinning={loading}
                >
                    <div
                        className={classNames({
                            'bar-chart-box': true,
                            esgai_scrollbar: !is1x,
                        })}
                        ref={this.scrollBoxRef}
                    >
                        {is1x && (
                            <div className="chart-legend">
                                {locale.your_competitors}
                            </div>
                        )}
                        <div
                            className="bar-chart-wrap"
                            style={{ width: `${scale}00%` }}
                        >
                            <canvas
                                ref={this.canvasRef}
                                width={canvasWrapWidth * scale * enlarge}
                                height={141 * enlarge}
                            />
                            {showScrubber && this.renderScrubber()}
                            {!is1x && this.renderAllCompetitors()}
                            {is1x && hasData && (
                                <div className="hovered-board">
                                    {arr.map((i, index) => {
                                        const competitors =
                                            this.includedCompetitors(index);
                                        return competitors.length === 0 ? (
                                            <span
                                                key={index}
                                                style={{
                                                    height: perHeight * i,
                                                }}
                                            />
                                        ) : (
                                            <Popover
                                                tooltip={this.renderCompetitorsTooltip(
                                                    competitors,
                                                    i
                                                )}
                                                isMobileView={mobileView}
                                                width={150}
                                                mobileMaxWidth={150}
                                                key={index}
                                                placement="top"
                                                mobilePlacement="top"
                                                verticalGap={-16}
                                                isInnerHTML={false}
                                            >
                                                <span
                                                    style={{
                                                        height: Math.ceil(
                                                            perHeight * i
                                                        ),
                                                    }}
                                                />
                                            </Popover>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                </Spin>
            </div>
        );
    }
}

export default withRouter(
    connect(
        ({
            persist: { locale, account },
            common: { clientWidth },
            router,
        }) => ({
            locale,
            account,
            router,
            mobileView: clientWidth < 1220,
        })
    )(NumericChart)
);
