export const commonType = {
    SET_COMMON_STATE: 'SET_COMMON_STATE',
    APPLY_SET_COMMON_STATE: 'APPLY_SET_COMMON_STATE',
};

export const persistType = {
    GET_PROFILE: 'GET_PROFILE',
    APPLY_UPDATE_PROFILE: 'APPLY_UPDATE_PROFILE',
    LOGIN: 'LOGIN',
    APPLY_LOGIN: 'APPLY_LOGIN',
    APPLY_UPDATE_CERTIFICATE: 'APPLY_UPDATE_CERTIFICATE',
    APPLY_UPDATE_FIREBASE_USER: 'APPLY_UPDATE_FIREBASE_USER',
    APPLY_UPDATE_SETUP_PROFILE_REMINDER_LOG:
        'APPLY_UPDATE_SETUP_PROFILE_REMINDER_LOG',
    LOGOUT: 'LOGOUT',
    APPLY_LOGOUT: 'APPLY_LOGOUT',
    APPLY_UPDATE_ONE_TIME_NOTE: 'APPLY_UPDATE_ONE_TIME_NOTE',
    APPLY_CHANGE_LOCALE: 'APPLY_CHANGE_LOCALE',
    APPLY_CHANGE_PATHS: 'APPLY_CHANGE_PATHS',
};

export const esgType = {
    SET_ESG_STATE: 'SET_ESG_STATE',
    APPLY_SET_ESG_STATE: 'APPLY_SET_ESG_STATE',
};
