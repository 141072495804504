const en_US = {
    // Header
    notifications: 'Notifications',
    mark_all_as_read: 'Mark all as read',
    today: 'Today',
    previous: 'Previous',
    delete_all: 'Delete All',
    no_notifications: 'No new notifications',
    no_notifications_note: 'Looks like you haven’t received any notifications',
    help_centre: 'Help Centre',
    email_support: 'Email Support',
    email_support_title: 'We are here to help!',
    email_support_subject: 'Subject',
    email_support_subject_placeholder: 'Choose a purpose',
    email_support_subject_bug: 'Bug',
    email_support_subject_comment: 'Comment',
    email_support_subject_other: 'Other',
    email_support_body: 'Body',
    email_support_cancel: 'Cancel',
    email_support_send: 'Send',

    // Menu
    dashboard: 'Dashboard',
    news: 'News',
    company: 'Company',
    industry: 'Industry',
    partners: 'Partners',
    search_news: 'Search News',
    esg_score: 'ESG Score',
    profile_settings: 'Profile settings',
    logout: 'Logout',
    profile: 'Profile',
    competitors: 'Competitors',
    business_partners: 'Suppliers & partners',
    my_board: 'My boards',
    team_board: 'Team boards',
    archive: 'Archive',
    archived_boards: 'Archived boards',
    optimizer: 'Optimizer',

    // Common
    portal: 'Portal',
    login: 'Login',
    forgot_password: 'Forgot Password',
    send: 'Send',
    done: 'Done',
    next: 'Next',
    unsaved_changes: 'Unsaved changes',
    unsaved_changes_desc: 'Do you want to discard the changes?',
    cancel: 'Cancel',
    discard: 'Discard',
    upload: 'Upload',
    upload_photo: 'Upload Photo',
    save: 'Save',
    remove: 'Remove',
    saving: 'Saving...',
    no_result: 'No results found',
    reset: 'Reset',
    got_it: 'Got it',
    add_now: 'Add now',
    delete: 'Delete',
    undo: 'Undo',
    last_24_hours: 'Last 24 hours',
    '7_days': 'Last 7 days',
    '30_days': 'Last 30 days',
    '90_days': 'Last 90 days',
    add_competitors: 'Add competitors',
    add_competitors_placeholder: 'Search for competitors',
    add_partners: 'Add suppliers & partners',
    add_partners_placeholder: 'Search for suppliers & partners',
    filter: 'filter',
    apply: 'apply',
    add: 'Add',
    months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ],
    weekdays: ['Mo', 'Tu', 'We', 'Th', 'Fri', 'Sa', 'Su'],
    set_date: 'Set date',
    create_new: 'Create New',
    start: 'Start',
    skip_all: 'Skip all',

    // Input labels
    email: 'Email',
    email_address: 'Email address',
    password: 'Password',
    confirm_psw: 'Confirm Password',
    new_psw: 'New password',
    confirm_new_psw: 'Confirm new password',
    first_name: 'First Name',
    last_name: 'Last Name',
    position: 'Position',
    company_name: 'Company Name',
    website: 'Website',
    region_of_operation: 'Region of Operation',

    // Error
    invalid_email: 'Invalid Email Address',
    invalid_website: 'Invalid Website',
    wrong_auth: 'Incorrect email or password',
    required: 'Required',
    psw_length_error: 'Password should be at least 6 characters',
    psw_not_match: 'Passwords do not match',
    max_select: (num) => `Currently select up to ${num}`,
    unexpected_error: 'An unexpected error occurred, please try again later',
    max_img_size: 'Image must smaller than 10MB!',

    // Login
    login_welcome: 'Welcome!',
    login_title: 'Login to dashboard.',
    login_remember: 'Remember me',
    login_forgot_psw: 'Forget password?',
    login_slogan: 'Maximize your ESG score with AI-powered insights',

    // Forgot Password
    forgotPSW_title: 'Reset password',
    forgotPSW_subtitle:
        'Enter the email associated with your account and we will send an email with instruction to reset your password.',
    forgotPSW_check_email: 'Check your mail',
    forgotPSW_send_email_note:
        'We have sent a reset password instruction to your email.',

    // Setup Password
    setupPSW_page_title: 'Setup Password',
    setupPSW_form_title: 'Setup your password',
    setupPSW_submit_label: 'Set password',
    setupPSW_psw_created: 'password created',
    setupPSW_psw_created_note:
        'You are good to go. Please login to your account!',

    // Reset Password
    resetPsw_page_title: 'Reset Password',
    resetPsw_form_subtitle:
        'Your new password must be different from previous used password.',
    resetPsw_submit_label: 'reset password',
    resetPsw_psw_updated: 'Your password has been reset!',

    // Setup Profile
    setup_profile: 'Setup Profile',
    setup_profile_title: 'complete your profile',
    setup_profile_remind: 'Skip for now',
    setup_profile_step1_title: 'Enter your name and position',
    setup_profile_step2_title: 'Track up to 3 competitors',
    setup_profile_step2_tooltip:
        'You can always add more competitors later in the Profile Settings.',
    setup_profile_step2_note:
        "Compare your company's ESG performance with selected competitors. You can always add or change competitors later in your profile settings.",
    setup_profile_step3_note:
        'Add your key suppliers and business partners to track their ESG performance',
    setup_profile_no_result:
        'The company is currently not in the database. Please try entering the name of the holding company.',
    reminder_1_title: 'Add competitors & business partners in Profile Settings',
    reminder_1_paragraph_1:
        'Add competitors that you would like to monitor in your dashboard. In addition, you can keep track of their ESG performance under your <b>ESG Score</b> tab.',
    reminder_1_paragraph_2:
        '',
    reminder_2_title: 'Add business partners in Profile Settings',
    reminder_2_paragraph_1:
        'Add business partners and suppliers that you would like to monitor.',
    reminder_2_paragraph_2:
        '',
    reminder_3_title: 'Add competitors in Profile Settings',
    reminder_3_paragraph_1:
        'Add competitors that you would like to monitor in your dashboard.',
    reminder_3_paragraph_2:
        '',

    // User Profile
    user_profile: 'User Profile',
    my_profile: 'My Profile',
    user_info: 'User Info',
    select_region: 'Select a region',
    search_industry: 'Search for industry',
    edit_company_note:
        'Edit your industry and location to customize your news feed and dashboard',
    company_info: 'Company info',
    change_effect_note: 'The changes will only reflect in your account',
    primary_industry: 'Primary Industry',
    primary_industry_note:
        'To see the ESG score (industry average) benchmark on the ESG score page.',
    industries_of_interest: 'Industries Of Interest',
    customize_news_note: 'To customize your news feed and dashboard',
    companyFieldReset: (field) => `${field} has been reset to default`,

    // Company management
    watched_competitors: 'Watched Competitors',
    competitors_title_note:
        'Add competitors that you would like to monitor. You will see their ESG score in your <b>ESG Score</b> tab and on your dashboard.',
    competitors_subtitle:
        'Use the ☆ to select up to 3 competitors to monitor their ESG score on your scorecard and on your dashboard.',
    delete_competitor: 'Delete competitor?',
    delete_competitor_note: 'Do you want to delete this competitor?',
    undo_competitor_note: (name) => `Competitor <i>${name}</i> deleted`,
    competitor_max_monitor_title:
        'You have reached the maximum number of competitors you can monitor',
    competitor_max_monitor_desc:
        'Please deselect one competitor before you indicate an other.',
    competitor_max_watch_title:
        'You have reached the maximum number(10) of competitors you can watch',
    competitor_max_watch_desc:
        'Please delete one competitor before you add another.',
    partner_title_note:
        'Add business partners and suppliers that you would like to monitor. ',
    delete_partner: 'Delete business partner?',
    delete_partner_note: 'Do you want to delete this business partner?',
    undo_partner_note: (name) => `Business partner <i>${name}</i> deleted`,
    partner_max_watch_title:
        'You have reached the maximum number(10) of partners you can watch',
    partner_max_watch_desc:
        'Please delete one partner before you add another.',

    // Notification Settings
    notification_settings: 'Notifications Settings',
    esg_score_notify_settings_note: `Receive notifications if your organization's ESG scores cross the set threshold`,
    news_notify_settings_note:
        'Receive notifications if there are controversial news about your organization',
    allow_notifications_weekly: 'Allow notifications (weekly)',
    allow_notifications_daily: 'Allow notifications (daily)',
    in_app: 'In-app',
    set_threshold: 'Set threshold',
    set_threshold_note:
        'Customize when you are notified about changes in your ESG scores',
    advanced_settings: 'Advanced Settings',
    threshold_percentage: 'Threshold percentage',
    unread_score_notify: (num) => `You have ${num} unread <b>score changes</b>`,
    unread_news_notify: (num) =>
        `You have ${num} unread <b>controversial news</b>`,
    score_change_notify: (diff, title) =>
        `<b>${title}</b> is ${
            diff > 0 ? 'up' : 'down'
        } <span class="diff-value">${(diff * 100).toFixed(2)}%</span>`,
    has_controversial_news: (num) =>
        `Your company has ${num} controversial news`,
    view: 'View',
    enable_score_notify_note:
        'Enable email notification for company ESG score changes',
    enable_news_notify_note:
        'Enable email notifications for controversial news',
    news_notify_content: (num) =>
        `${num} controversial news about your company`,
    score_up_notify_content: (name, diff) =>
        `${name} is up <span class="up-text">${diff}%</span>`,
    score_down_notify_content: (name, diff) =>
        `${name} is down <span class="down-text">${diff}%</span>`,
    yesterday: 'Yesterday',
    sms: 'SMS',
    add_phone_number: 'Add phone number',
    contact_name: 'Contact name',
    phone_number: 'Phone number*',
    add_another_number: 'Add another number',
    contact_placeholder: (num) => `Contact ${num}`,

    // ESG Score
    overall_score: 'Overall ESG Score',
    environmental: 'Environmental',
    social: 'Social',
    governance: 'Governance',
    competitors_avg: 'Competitors avg.',
    industry_avg: 'Industry avg.',
    starred_competitor: 'Starred Competitor',
    add_starred_competitors: 'add starred competitors',
    empty_star_tip:
        ' Star the competitors in your Profile Settings to keep track of their ESG performance',
    empty_avg_tip:
        'Add competitors in your Profile Settings to keep track of their average ESG performance',
    esg_score_subtitle:
        'Competitors avg. = the watched competitors set in your Profile Settings',
    esg_scorecard: 'ESG Scorecard',
    esg_scorecard_industry_note: `<p>ESG scorecard is an overview of your ESG score. You can benchmark your scores against that of your peers and industry. Select competitors of interest in your profile settings.</p>
    <p>Industry avg. = average score of all companies in your primary industry.</p>`,
    esg_scorecard_competitor_note: `<p>ESG scorecard is an overview of your ESG score. You can benchmark your scores against that of your peers and industry. Select competitors of interest in your profile settings.</p>
    <p>Competitor avg. = average score of all competitors in your profile settings.</p>`,
    more_than_yours: '> 20 than yours',
    less_than_yours: '< 20 than yours',
    edit_competitors: 'Edit competitors',
    finish_editing: 'Finish editing',
    industry_group_rank: 'Industry Group & Rank',
    last_updated: 'Last updated',
    metrics: 'Metrics',
    strongest: 'Strongest',
    weakest: 'Weakest',
    between: '% between',
    vs: 'vs',
    list_view: 'List view',
    graph_view: 'Graph view',
    all_competitors_displayed: 'All competitors have been displayed. ',
    add_more: 'Add more',
    changes_saved: 'Changes has been saved',
    competitor_reset: 'Competitors have been reset',
    overall_esg_score: 'Overall ESG Score',
    esg_pillars_score: 'ESG Pillars Score',
    news_for_company_score: '',
    add_new_competitors: 'Add New Competitors',
    add_new_competitors_note:
        'New competitors will also be added into your competitors set',

    // ESG Score Component
    by_week: 'By week',
    by_year: 'By year',
    score_component: 'Score Component',
    score_chart_tip:
        '<b>Click</b> or <b>Click & Drag</b> on the graph for the score.',
    description: 'Description',
    lastAuditedDate: 'Last audited date: ',
    relevant_news_for_score: '',
    compare_with_last_week: '% from last week',
    fiscal_year_score_data: 'Score By Fiscal Year',
    latest: 'Latest',
    esg_score_reported: 'ESG Score Reported (Previous 5 years)',
    esg_score_reported_note:
        'A status of ESG score for a fiscal year can either be reported (check mark) or null. The two dashes denote that ESG information is pending.',

    // News
    company_news: 'Company News',
    news_chart_tip:
        '<b>Click</b> or <b>Click & Drag</b> on the graph to select time frame for news.',
    company_news_note:
        'View news that are relevant to you and filter the news through an ESG lens.',
    market_cap: 'Market Cap',
    numbers_of_articles: 'Numbers of articles',
    controversial_news: 'Controversial News',
    news_detail: 'News Detail',
    industry_news: 'Industry News',
    industry_news_note:
        'News related to the industries of interest you have selected in your profile.',
    all_industries: 'All Industries',
    industries: 'Industries',
    competitors_news: 'Competitors News',
    competitors_news_note:
        'News about the competitors you selected in your profile.',
    all_competitors: 'All Competitors',
    competitor: 'Competitor',
    partners_news: 'Partners News',
    partners_news_note:
        'News about the suppliers and business partners you selected in your profile.',
    all_partners: 'All Partners',
    partner: 'Partner',
    time: 'Time',
    categories: 'Categories',
    all_news: 'All News',
    only_controversial: 'Only Controversial news',
    news_type: 'News type',
    non_esg_related: 'Non-ESG Related',

    // Dashboard
    latest_esg_score: 'Latest ESG Score',
    stock_price: 'Stock Price',
    usd: 'USD',
    stock_price_delay_tip: 'Stock price is 15 mins delayed',
    stock_price_last_close_tip: 'Previous day close price',
    no_stock_info: 'No stock price information available!',
    market_cap_score: 'Market Cap & ESG Score',
    esg_trend: 'ESG Trend',
    esg_trend_note:
        'ESG score reflects the environmental, social, and governance performance of an organization. A breakdown of ESG scores can be found in the <b>ESG Score</b> tab.',
    esg: 'ESG',
    numbers_of_articles_today: 'Numbers of new articles today',
    latest_articles: 'latest articles',
    newest_articles: (num) => `${num} newest articles`,
    no_news: 'No News Yet',
    top_competitors: 'Top competitors',
    overall: 'Overall',
    add_competitor_note:
        'Add competitors to monitor their ESG trend line in Dashboard',

    // Optimizer Board
    my_board_note:
        'Access your private boards. Only you can see and edit them.',
    team_board_note:
        'Share your boards with other users in your organization so that everyone is on the same page. All users in your organization can see and edit them.',
    archived_board_note:
    'Archived boards are never deleted. To make changes to the board, please unarchive it first.',
    bin_title_note:
        'Boards are deleted after 30 days. Restore the boards you would like to keep.',
    date: 'Date',
    last_modified: 'Last modified',
    name: 'Name',
    files: 'Files',
    shared_files: 'Shared Files',
    sort_by: 'Sort by',
    my_board_empty_note:
        'Click on CREATE NEW button to create your first file!',
    team_board_empty_note: 'You have no shared files yet',
    archived_empty_note: 'You have no archived files yet',
    bin_empty_note: 'Bin is empty',
    create_private_board_title: 'Create New Private Board',
    create_public_board_title: 'Create New Public Board',
    edit_name_privacy: 'Edit name & privacy',
    file_name: 'File Name',
    privacy: 'Privacy',
    private: 'Private',
    public: 'Public',
    shared_with_others: 'Shared with others',
    shared_with_me: 'Shared with me',
    target_esg_score: 'Overall ESG score',
    create_copy: 'Create a copy',
    export_CSV: 'Download CSV',
    unarchive: 'Unarchive',
    create_private_copy: 'Create a private copy',
    restore: 'Restore',
    permanently_delete: 'Permanently delete',
    privacy_note:
        'Only you can view your private boards, while public boards are shared across your organization.',
    trash_board_note: (name) => `<i>${name}</i> has been moved to bin`,
    copy_board_note: (name) =>
        `<i>${name}</i> has been duplicated into your Private board`,
    archive_board_note: (name) =>
        `<i>${name}</i> has been moved to Archived board`,
    go_to_my_board: 'go to my board',
    go_to_archived_board: 'go to archived board',
    trash_item_title: (name) => `Delete <i>${name}</i>`,
    archive_item_title: (name) => `Archive <i>${name}</i>`,
    trash_item_note:
        'The deleted file will be stored in Bin for <b>30 days</b> before it’s permanently deleted.',
    archive_item_note:
        'The archived file will be stored in Archived board.',
    bin: 'Bin',

    // Optimizer Board Detail
    board_detail: 'Board detail',
    industry_rank_title: 'Industry group Rank',
    geographical_rank_title: 'Geographical Rank',
    industry_rank_note: (target) =>
        `Industry group ranking affects your metric scores under the Environmental and Social pillars. Your industry group is: <b>${target}</b>.`,
    geographical_rank_note: (target) =>
        `Geographical rank ranking affects your metric scores under the Governance pillar. Your geographic group is: <b>${target}</b>.`,
    target_scores: 'Target Scores',
    export: 'Export',
    group: 'Group',
    overall_esg: 'Overall ESG',
    data_type: 'Data Type',
    data_type_note:
        'Non-numeric metrics indicate whether or not the company has fulfilled the criteria for the metric, while numeric metrics take a raw value that is a number.',
    all_types: 'All Types',
    numeric: 'Numeric',
    non_numeric: 'Non-numeric',
    priority: 'Priority',
    actionability: 'Actionability',
    metric: 'Metric',
    standards: 'Standards',
    current_score: 'Current score',
    mmi: 'MI',
    change_in_score: 'Est chg in score', // Change in metric score
    high: 'High',
    mid: 'Mid',
    low: 'Low',
    na: 'N/A',
    priority_note: 'Your prioritization of each metric',
    ease_note:
        'Your estimate of how difficult it is to address this metric (1 = very easy; 10 = very difficult)',
    metric_note:
        'Metrics are the sub-components of your ESG score. Metric scores are used to calculate your rank and overall ESG score.',
    current_score_note: 'Your current metric score',
    mmi_note:
        '<b>Metric Impact (MI)</b>: the approximate average change in overall ESG score for one increase in metric rank',
    position_note:
        'Your current position in your industry. Numerical data metrics have a bar graph shows the distribution organizations with a similar scores (Blue dotted line = your position; Purple bar(s) = position of your selected competitors). Non-numeric metrics have either "Reported" or "Not Reported".',
    target_score_note:
        'Your projected target metric score; click to open a popup to set your target score.',
    target_rank_note:
        'Your target rank calculated from your target score (displayed as "current rank -> target rank")',
    competitors_score_rank: 'Competitors Score/Rank',
    target_score: 'Target score',
    target_rank: 'Target rank',
    optimizer_tutorial_modal_title: 'Welcome to Optimizer!',
    optimizer_tutorial_modal_content:
        'Let’s go through a short tutorial of how to use the Optimizer.',
    'optimizer-tutorial-step-1-title': 'Set your target metric scores',
    'optimizer-tutorial-step-1-description':
        'Input estimates to create target metric scores. Your target metric rank is automatically calculated from your target metric score.',
    'optimizer-tutorial-step-2-title': 'Target scores',
    'optimizer-tutorial-step-2-description':
        'Target scores are automatically updated to reflect your estimated metric scores.',
    'optimizer-tutorial-step-3-title': 'Your metrics',
    'optimizer-tutorial-step-3-description':
        'Your ESG score is a reflection of your rank in these metrics.',
    'optimizer-tutorial-step-4-title': 'Your current scores',
    'optimizer-tutorial-step-4-description':
        'This is your current overall ESG score, pillar (Environmental, Social, Governance) scores, rank in your industry group, and rank in your geographic region. These scores are calculated by Refinitiv.',
    all: 'All',
    reported: 'Reported',
    not_reported: 'Not Reported',
    current_data: 'Current data',
    select_bool_value_title:
        'Please indicate your plan according to the metric description',
    negative_metric_note: 'Note: Selecting "No" would yield a positive score.',
    notes: 'Notes',
    set_target_score_tip:
        'The target you put will reflect on the other target scores',
    no: 'No',
    yes: 'Yes',
    no_data: 'No Data Yet!',
    score: 'Score',
    raw_data: 'Value',
    current: 'Current',
    target: 'Target',
    your_competitors: 'Your Competitors',
    other_companies: 'other companies',
    activity: 'Activity',
    edited_file: 'edited this file',
    current_holder_tip: (name) => `<b>${name}</b> has this file opened.`,
    you_can_edit_file: 'You can now edit this file!',
    no_data_available: 'No data available',
};

export default en_US;
