import Icon from '@ant-design/icons';
import './index.scss';
import { ReactComponent as EyeSvg } from '../../assets/image/eye.svg';
import { ReactComponent as KeySvg } from '../../assets/image/key.svg';
import { ReactComponent as EmailSendSvg } from '../../assets/image/email_send.svg';
import { ReactComponent as UserSvg } from '../../assets/image/user.svg';
import { ReactComponent as CompetitorSvg } from '../../assets/image/competitors.svg';
import { ReactComponent as PartnerSvg } from '../../assets/image/partners.svg';
import { ReactComponent as SearchSvg } from '../../assets/image/search.svg';
import { ReactComponent as InfoSvg } from '../../assets/image/info.svg';
import { ReactComponent as ArrowDownSvg } from '../../assets/image/arrow_down.svg';
import { ReactComponent as CloseSvg } from '../../assets/image/close.svg';
import { ReactComponent as EditSvg } from '../../assets/image/edit.svg';
import { ReactComponent as PlusSvg } from '../../assets/image/plus.svg';
import { ReactComponent as CheckSvg } from '../../assets/image/check.svg';
import { ReactComponent as StarSvg } from '../../assets/image/star.svg';
import { ReactComponent as TrashSvg } from '../../assets/image/trash.svg';
import { ReactComponent as BusinessSvg } from '../../assets/image/business.svg';
import { ReactComponent as MinusSvg } from '../../assets/image/minus.svg';
import { ReactComponent as ArrowDownFullSvg } from '../../assets/image/arrow_down_full.svg';
import { ReactComponent as ArrowDoubleFoldSvg } from '../../assets/image/arrow_double_fold.svg';
import { ReactComponent as ArrowDoubleUnfoldSvg } from '../../assets/image/arrow_double_unfold.svg';
import { ReactComponent as ArrowLeftSvg } from '../../assets/image/arrow_left.svg';
import { ReactComponent as NewspaperSvg } from '../../assets/image/newspaper.svg';
import { ReactComponent as IndustrySvg } from '../../assets/image/industry.svg';
import { ReactComponent as EnvironmentalSvg } from '../../assets/image/environmental.svg';
import { ReactComponent as SocialSvg } from '../../assets/image/social.svg';
import { ReactComponent as GovernanceSvg } from '../../assets/image/governance.svg';
import { ReactComponent as MenuSvg } from '../../assets/image/menu.svg';
import { ReactComponent as FilterSvg } from '../../assets/image/filter.svg';
import { ReactComponent as ResetSvg } from '../../assets/image/reset.svg';
import { ReactComponent as BellSvg } from '../../assets/image/bell.svg';
import { ReactComponent as HelpSvg } from '../../assets/image/help.svg';
import { ReactComponent as EmailSvg } from '../../assets/image/email.svg';
import { ReactComponent as SettingsSvg } from '../../assets/image/settings.svg';
import { ReactComponent as CalendarSvg } from '../../assets/image/calendar.svg';
import { ReactComponent as TeamSvg } from '../../assets/image/team.svg';
import { ReactComponent as FileSvg } from '../../assets/image/file.svg';
import { ReactComponent as MoreSvg } from '../../assets/image/more.svg';
import { ReactComponent as PrioritySvg } from '../../assets/image/priority.svg';
import { ReactComponent as ActionabilitySvg } from '../../assets/image/actionability.svg';
import { ReactComponent as RawDataSvg } from '../../assets/image/raw_data.svg';
import { ReactComponent as NoteSvg } from '../../assets/image/note.svg';
import { ReactComponent as LockSvg } from '../../assets/image/lock.svg';
import { ReactComponent as LockOpenSvg } from '../../assets/image/lock-open.svg';
import { ReactComponent as ExclamationSvg } from '../../assets/image/exclamation.svg';
import { ReactComponent as LoadingSvg } from '../../assets/image/loading.svg';
import { ReactComponent as DragSvg } from '../../assets/image/drag.svg';
import { ReactComponent as ArchiveSvg} from '../../assets/image/archive.svg'
import { ReactComponent as VectorSVG} from '../../assets/image/vector.svg'

const CustomIcon = (props) => {
    const {
        type = 'fill',
        component,
        color = '233BC9',
        className,
        ...rest
    } = props;
    return (
        <Icon
            className={`esgai-icon esgai-icon-${
                type === 'fill' ? '' : 'stroke-'
            }${color} ${type === 'fill' ? '' : 'esgai-icon-stroke'} ${
                className || ''
            }`}
            component={component}
            {...rest}
        />
    );
};

export const Eye = (props) => <CustomIcon component={EyeSvg} {...props} />;

export const Key = (props) => <CustomIcon component={KeySvg} {...props} />;

export const EmailSend = (props) => (
    <CustomIcon component={EmailSendSvg} {...props} />
);

export const User = (props) => <CustomIcon component={UserSvg} {...props} />;

export const Competitor = (props) => (
    <CustomIcon component={CompetitorSvg} {...props} />
);

export const Partner = (props) => (
    <CustomIcon component={PartnerSvg} {...props} />
);

export const Search = (props) => (
    <CustomIcon component={SearchSvg} {...props} />
);

export const Info = (props) => <CustomIcon component={InfoSvg} {...props} />;

export const ArrowDown = (props) => (
    <CustomIcon component={ArrowDownSvg} {...props} />
);

export const Close = (props) => <CustomIcon component={CloseSvg} {...props} />;

export const Edit = (props) => <CustomIcon component={EditSvg} {...props} />;

export const Plus = (props) => <CustomIcon component={PlusSvg} {...props} />;

export const Check = (props) => <CustomIcon component={CheckSvg} {...props} />;

export const Star = (props) => <CustomIcon component={StarSvg} {...props} />;

export const Trash = (props) => <CustomIcon component={TrashSvg} {...props} />;

export const Business = (props) => (
    <CustomIcon component={BusinessSvg} {...props} />
);

export const Minus = (props) => <CustomIcon component={MinusSvg} {...props} />;

export const ArrowDownFull = (props) => (
    <CustomIcon component={ArrowDownFullSvg} {...props} />
);

export const ArrowDoubleFold = (props) => (
    <CustomIcon component={ArrowDoubleFoldSvg} {...props} />
);

export const ArrowDoubleUnfold = (props) => (
    <CustomIcon component={ArrowDoubleUnfoldSvg} {...props} />
);

export const ArrowLeft = (props) => (
    <CustomIcon component={ArrowLeftSvg} {...props} />
);

export const Newspaper = (props) => (
    <CustomIcon component={NewspaperSvg} {...props} />
);

export const Industry = (props) => (
    <CustomIcon component={IndustrySvg} {...props} />
);

export const Environmental = (props) => (
    <CustomIcon component={EnvironmentalSvg} {...props} />
);

export const Social = (props) => (
    <CustomIcon component={SocialSvg} {...props} />
);

export const Governance = (props) => (
    <CustomIcon component={GovernanceSvg} {...props} />
);

export const Menu = (props) => <CustomIcon component={MenuSvg} {...props} />;

export const Filter = (props) => (
    <CustomIcon component={FilterSvg} {...props} />
);

export const Reset = (props) => <CustomIcon component={ResetSvg} {...props} />;

export const Bell = (props) => <CustomIcon component={BellSvg} {...props} />;

export const Question = (props) => <CustomIcon component={HelpSvg} {...props} />;

export const Email = (props) => <CustomIcon component={EmailSvg} {...props} />;

export const Settings = (props) => (
    <CustomIcon component={SettingsSvg} {...props} />
);

export const Calendar = (props) => (
    <CustomIcon component={CalendarSvg} {...props} />
);

export const Team = (props) => <CustomIcon component={TeamSvg} {...props} />;

export const File = (props) => <CustomIcon component={FileSvg} {...props} />;

export const More = (props) => <CustomIcon component={MoreSvg} {...props} />;

export const Archive = (props) => <CustomIcon component={ArchiveSvg} {...props} />;

export const Priority = (props) => (
    <CustomIcon component={PrioritySvg} {...props} />
);

export const Actionability = (props) => (
    <CustomIcon component={ActionabilitySvg} {...props} />
);

export const RawData = (props) => (
    <CustomIcon component={RawDataSvg} {...props} />
);

export const Note = (props) => <CustomIcon component={NoteSvg} {...props} />;

export const Lock = (props) => <CustomIcon component={LockSvg} {...props} />;

export const LockOpen = (props) => (
    <CustomIcon component={LockOpenSvg} {...props} />
);

export const Exclamation = (props) => (
    <CustomIcon component={ExclamationSvg} {...props} />
);

export const Loading = (props) => (
    <CustomIcon type="stroke" component={LoadingSvg} {...props} />
);

export const Drag = (props) => (
    <DragSvg type="stroke" component={LoadingSvg} {...props} />
)

export const Vector = (props) => (
    <CustomIcon type="stroke" component={VectorSVG} {...props} />
)
