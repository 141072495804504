import Login from '../page/Login';
import SetupPassword from '../page/SetupPassword';
import ForgotPassword from '../page/ForgotPassword';
import SetupProfile from '../page/SetupProfile';
import Dashboard from '../page/Dashboard';
import News from '../page/News';
import NewsCompany from '../page/News/NewsCompany';
import NewsIndustry from '../page/News/NewsIndustry';
import NewsRelatedCompany from '../page/News/NewsRelatedCompany';
import NewsDetail from '../page/News/NewsDetail';
import EsgScore from '../page/EsgScore';
import EsgScoreComponent from '../page/EsgScoreComponent';
import ProfileInfo from '../page/ProfileInfo';
import ManageRelatedCompany from '../page/ManageRelatedCompany';
import NotifySettings from '../page/NotifySettings';
import OptimizerBoard from '../page/OptimizerBoard';
import OptimizerBoardDetail from '../page/OptimizerBoardDetail';

const routes = [
    {
        path: '/login',
        component: Login,
        exact: true,
        public: true,
        pageWrap: false,
    },
    {
        path: '/setup-password',
        component: SetupPassword,
        exact: true,
        public: true,
        pageWrap: false,
    },
    {
        path: '/forgot-password',
        component: ForgotPassword,
        exact: true,
        public: true,
        pageWrap: false,
    },
    {
        path: '/reset-password',
        component: SetupPassword,
        exact: true,
        public: true,
        pageWrap: false,
    },
    {
        path: '/setup-profile',
        component: SetupProfile,
        exact: true,
        pageWrap: false,
    },
    {
        path: '/dashboard',
        component: Dashboard,
        exact: true,
        pageWrap: true,
    },
    {
        path: '/news',
        component: News,
        exact: false,
        pageWrap: true
    },
    {
        path: '/esg-score/industry',
        component: EsgScore,
        exact: true,
        pageWrap: true,
    },
    {
        path: '/esg-score/competitor',
        component: EsgScore,
        exact: true,
        pageWrap: true,
    },
    {
        path: '/esg-score/industry/component',
        component: EsgScoreComponent,
        exact: true,
        pageWrap: true,
    },
    {
        path: '/esg-score/competitor/component',
        component: EsgScoreComponent,
        exact: true,
        pageWrap: true,
    },
    {
        path: '/settings/profile-info',
        component: ProfileInfo,
        exact: true,
        pageWrap: true,
    },
    {
        path: '/settings/competitor',
        component: ManageRelatedCompany,
        exact: true,
        pageWrap: true,
    },
    {
        path: '/settings/partner',
        component: ManageRelatedCompany,
        exact: true,
        pageWrap: true,
    },
    {
        path: '/settings/notification',
        component: NotifySettings,
        exact: true,
        pageWrap: true,
    },
    {
        path: '/optimizer/my-board',
        component: OptimizerBoard,
        exact: true,
        pageWrap: true,
    },
    {
        path: '/optimizer/team-board',
        component: OptimizerBoard,
        exact: true,
        pageWrap: true,
    },
    {
        path: '/optimizer/trash',
        component: OptimizerBoard,
        exact: true,
        pageWrap: true,
    },
    {
        path: '/optimizer/archived-board',
        component: OptimizerBoard,
        exact: true,
        pageWrap: true,
    },
    {
        path: '/optimizer/boards/:boardId',
        component: OptimizerBoardDetail,
        exact: true,
        pageWrap: true,
    },
];

export const newsRoutes = [
    {
        path: '/news/company',
        component: NewsCompany,
        exact: true,
        pageWrap: true,
    },
    {
        path: '/news/industry',
        component: NewsIndustry,
        exact: true,
        pageWrap: true,
    },
    {
        path: '/news/competitor',
        component: NewsRelatedCompany,
        exact: true,
        pageWrap: true,
    },
    {
        path: '/news/partner',
        component: NewsRelatedCompany,
        exact: true,
        pageWrap: true,
    },
    {
        path: '/news/detail',
        component: NewsDetail,
        exact: true,
        pageWrap: true,
    },
]

export default routes;
