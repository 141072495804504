import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import * as echarts from 'echarts';
import classNames from 'classnames';
import { Popover as PopoverAntd } from 'antd';
import qs from 'qs';
import { commonType, esgType, persistType } from '../../constant/actionTypes';
import { colors } from '../../constant/enum';
import service from '../../service';
import './index.scss';
import {
    ArrowLeft,
    ArrowDown,
    ArrowDownFull,
    Minus,
    Check,
} from '../../component/Icon';
import Button from '../../component/Button';
import { esgScoreUrl } from '../../constant/endpoint';
import getOptions from './chartOptions';
import { formatDate, getYearTime } from '../../utils';
import Switch from '../../component/Switch';
import Popover from '../../component/Popover';
import SectorRadio from '../../component/SectorRatio';
import { pillarColors } from '../../constant/enum';

const standards = ['gri', 'sasb', 'cdp', 'tcfd', 'gresb', 'wef', 'djsi'];

class EsgScoreComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            selectedIndex: [],
            pressIndex: -1,
            showDesc: false,
            showChartTip: false,
            isClickChart: true,
            selectedLegend: ['myself'],
            breadCrumbs: [],
            paginationCount: 0,
            news: {
                data: [],
                totalCount: 0,
            },
            pageSize: 10,
            page: 0,
            disableFetchNews: false,
            showScoreProcessChart: true,
        };
        this.chart = null;
    }

    componentDidMount() {
        this.chart = echarts.init(document.getElementById('score-chart'));
        this.chart.getZr().on('mousedown', this.handleMouseDown);
        this.chart.getZr().on('mousemove', this.handleMouseMove);
        this.chart.getZr().on('mouseup', this.handleMouseUp);
        this.renderChart();
        this.fetchData(this.handleShowOneTimeNote);
        this.chartListener();
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            data,
            selectedIndex,
            selectedLegend,
            page,
            disableFetchNews,
            pressIndex,
            paginationCount,
        } = this.state;
        const {
            data: prevData,
            selectedIndex: prevSelectedIndex,
            selectedLegend: prevSelectedLegend,
            page: prevPage,
            pressIndex: prevPressIndex,
        } = prevState;
        const {
            mobileView,
            router: {
                location: {
                    query: { name },
                },
            },
        } = this.props;
        const {
            mobileView: prevMobileView,
            router: {
                location: {
                    query: { name: prevName },
                },
            },
        } = prevProps;
        if (
            (data !== prevData ||
                selectedIndex !== prevSelectedIndex ||
                mobileView !== prevMobileView ||
                selectedLegend !== prevSelectedLegend) &&
            data &&
            Array.isArray(this.processSeries().XArr)
        ) {
            this.renderChart();
        }
        if (prevName !== name && name) {
            this.fetchData();
        }
        if (
            ((prevPressIndex > -1 && pressIndex === -1) ||
                (selectedLegend !== prevSelectedLegend &&
                    (!this.isToggleSelectAvg(
                        prevSelectedLegend,
                        selectedLegend
                    ) ||
                        (prevSelectedIndex.length > 0 &&
                            selectedIndex.length === 0))) ||
                page !== prevPage) &&
            !disableFetchNews
        ) {
            this.fetchNews();
        }
        if (
            (prevPressIndex > -1 && pressIndex === -1) ||
            (selectedLegend !== prevSelectedLegend &&
                (!this.isToggleSelectAvg(prevSelectedLegend, selectedLegend) ||
                    (prevSelectedIndex.length > 0 &&
                        selectedIndex.length === 0)))
        ) {
            this.setState({ paginationCount: paginationCount + 1 });
        }
    }

    componentWillUnmount() {
        this.chart.getZr().off('mousedown', this.handleMouseDown);
        this.chart.getZr().off('mousemove', this.handleMouseMove);
        this.chart.getZr().off('mouseup', this.handleMouseUp);
        this.resizeObserver.disconnect();
    }

    isToggleSelectAvg = (prevLegend, legend) => {
        let short = [];
        let long = [];
        if (prevLegend.length > legend.length) {
            short = legend;
            long = prevLegend;
        } else {
            short = prevLegend;
            long = legend;
        }
        const target = long.find((i) => short.indexOf(i) === -1);
        return target === 'avg';
    };

    chartListener = () => {
        this.resizeObserver = new ResizeObserver(() => {
            this.resizeCharts();
        });
        this.resizeObserver.observe(
            document.getElementsByClassName('section-wrap')[0]
        );
    };

    resizeCharts = () => {
        if (this.chart) {
            this.chart.resize();
        }
    };

    handleShowOneTimeNote = () => {
        const {
            oneTimeNote,
            account: { username },
            dispatch,
            router: {
                location: {
                    query: { name },
                },
            },
        } = this.props;
        const { scoreComponentChartTip, scoreComponentDesc } =
            oneTimeNote[username] || {};
        const newItem = { ...oneTimeNote[username] };
        if (!scoreComponentChartTip) {
            this.setState({
                showChartTip: true,
            });
            newItem.scoreComponentChartTip = true;
        }
        if (
            !Array.isArray(scoreComponentDesc) ||
            scoreComponentDesc.indexOf(name) < 0
        ) {
            this.setState({
                showDesc: true,
            });
            newItem.scoreComponentDesc = [...(scoreComponentDesc || []), name];
        }
        dispatch({
            type: persistType.APPLY_UPDATE_ONE_TIME_NOTE,
            payload: { ...oneTimeNote, [username]: newItem },
        });
    };

    getTargetIndex = (event) => {
        const { offsetX, offsetY } = event;
        const x = this.chart.convertFromPixel({ seriesIndex: 0 }, [
            offsetX,
            offsetY,
        ]);
        return x[0] === -0 ? 0 : x[0];
    };

    updateSelectIndex = (targetIndex) => {
        const { pressIndex, selectedIndex } = this.state;
        const first = Math.min(pressIndex, targetIndex);
        const second = Math.max(pressIndex, targetIndex);
        let newSelectedIndex = [];
        if (first === second) {
            newSelectedIndex = [first];
        } else {
            newSelectedIndex = [first, second];
        }
        if (
            newSelectedIndex[0] !== selectedIndex[0] ||
            newSelectedIndex[1] !== selectedIndex[1]
        ) {
            this.setState({ selectedIndex: newSelectedIndex });
        }
    };

    handleMouseDown = (event) => {
        const targetIndex = this.getTargetIndex(event);
        if (
            targetIndex > -1 &&
            targetIndex < this.processSeries().XArr.length
        ) {
            this.setState({
                pressIndex: targetIndex,
            });
        }
    };

    handleMouseMove = (event) => {
        const { pressIndex, isClickChart } = this.state;
        const targetIndex = this.getTargetIndex(event);
        if (pressIndex > -1) {
            if (isClickChart) {
                this.setState({ isClickChart: false });
            }
            if (
                targetIndex < this.processSeries().XArr.length &&
                targetIndex > -1
            ) {
                this.updateSelectIndex(targetIndex);
            }
        }
    };

    handleMouseUp = (event) => {
        const { pressIndex, isClickChart } = this.state;
        const targetIndex = this.getTargetIndex(event);
        if (pressIndex > -1) {
            if (
                targetIndex > -1 &&
                targetIndex < this.processSeries().XArr.length
            ) {
                if (isClickChart) {
                    this.handleClickChart(targetIndex);
                } else {
                    this.updateSelectIndex(targetIndex);
                }
            }
            this.setState({ page: 0, pressIndex: -1, isClickChart: true });
        }
    };

    handleClickChart = (targetIndex) => {
        const { selectedIndex } = this.state;
        if (selectedIndex.length === 0) {
            this.setState({
                selectedIndex: [targetIndex],
            });
        } else if (selectedIndex.length === 1) {
            if (selectedIndex[0] === targetIndex) {
                this.setState({
                    selectedIndex: [],
                });
            } else {
                const first = Math.min(selectedIndex[0], targetIndex);
                const second = Math.max(selectedIndex[0], targetIndex);
                this.setState({
                    selectedIndex: [first, second],
                });
            }
        } else {
            this.setState({
                selectedIndex: [],
            });
        }
    };

    renderChart = () => {
        const { data, selectedIndex } = this.state;
        const { mobileView } = this.props;
        if (!data || Object.keys(data).length === 0) {
            return;
        }
        const { XArr, series } = this.processSeries();
        this.chart.setOption(
            getOptions(XArr, series, selectedIndex, mobileView),
            true
        );
    };

    processSeries = () => {
        const { data, selectedLegend } = this.state;
        if (!data || Object.keys(data).length === 0) {
            return { XArr: [], series: [] };
        }
        const { series, XArrFull } = data;
        if (!selectedLegend) {
            return { XArr: XArrFull, series };
        }
        const filtered = series.filter(
            (s) => !!selectedLegend.find((j) => j === s.id)
        );
        let min = null;
        let max = null;
        filtered.forEach((i) => {
            if ((!min && i.minYear) || (min && i.minYear && min > i.minYear)) {
                min = i.minYear;
            }
            if ((!max && i.maxYear) || (max && i.maxYear && max < i.maxYear)) {
                max = i.maxYear;
            }
        });
        if (filtered.length === 0 || (!min && !max)) {
            return {
                XArr: XArrFull,
                series: [{ ...series[0], data: [], validDataCount: 0 }],
            };
        }
        const minIndex = XArrFull.indexOf(min);
        const maxIndex = XArrFull.indexOf(max);
        const XArr = XArrFull.filter(
            (_, i) =>
                (i >= minIndex && i <= maxIndex) || i === XArrFull.length - 1
        );
        const ySeries = filtered.map((s) => ({
            ...s,
            data: s.data.filter(
                (_, i) =>
                    (i >= minIndex && i <= maxIndex) ||
                    (i === XArrFull.length - 1 && s.hasLatest)
            ),
        }));
        return { XArr, series: ySeries };
    };

    isIndustry = () => {
        const {
            router: {
                location: { pathname },
            },
        } = this.props;
        return pathname.indexOf('industry') > -1;
    };

    isCompetitorOverall = () => {
        const {
            router: {
                location: {
                    query: { name },
                },
            },
        } = this.props;
        return !this.isIndustry() && name === 'ESGScore';
    };

    xBase = (data) => {
        if (data && Array.isArray(data.years)) {
            return [...data.years, this.props.locale.latest];
        }
        return null;
    };

    processData = (data) => {
        const XArrFull = this.xBase(data);
        if (!Array.isArray(XArrFull)) {
            return null;
        }
        const { score = [] } = data;
        let series = [];
        let self = null;
        let avg = null;
        score.forEach((s) => {
            const id = s.myself ? 'myself' : s.id;
            const name = s.name;
            let maxYear = null;
            let minYear = null;
            let hasLatest = false;
            if (s.metricData.length > 0) {
                minYear = s.metricData[0].year;
                maxYear = s.metricData[s.metricData.length - 1].year;
            }
            let validDataCount = 0;
            const itemData = XArrFull.map((i) => {
                let target = null;
                if (i === this.props.locale.latest && s.metricData.length > 0) {
                    const lastItem = s.metricData[s.metricData.length - 1];
                    if (lastItem.year >= new Date().getFullYear() - 5) {
                        target = lastItem;
                        hasLatest = true;
                    }
                } else {
                    target = s.metricData.find((j) => j.year === i);
                }
                if (!target || typeof target.numberValue !== 'number') {
                    return null;
                }
                validDataCount += 1;
                return {
                    value: Number(
                        (parseFloat(target.numberValue) * 100).toFixed(2)
                    ),
                    grade: target.grade,
                    newsSourceMapId: target.id,
                };
            });
            const item = {
                id,
                name,
                minYear,
                maxYear,
                hasLatest,
                validDataCount,
                data: itemData,
            };
            if (id === 'myself') {
                self = { ...item, selfId: s.id };
            } else if (id === 'avg') {
                avg = item;
            } else {
                series.push(item);
            }
        });
        series = [self, avg, ...series];
        series = series.map((i, index) => {
            return {
                ...i,
                highlight: colors[index],
            };
        });
        return { XArrFull, series };
    };

    fetchData = async (cb) => {
        const {
            router: {
                location: {
                    query: { name },
                },
            },
        } = this.props;
        const { paginationCount, selectedLegend, data } = this.state;
        if (name) {
            this.setState(
                {
                    selectedIndex: [],
                    pressIndex: -1,
                    paginationCount: paginationCount + 1,
                    page: 0,
                    disableFetchNews: true,
                },
                async () => {
                    try {
                        this.toggleGlobalLoading(true);
                        let api = `${
                            this.isIndustry()
                                ? esgScoreUrl.SCORE_HISTORY_INDUSTRY
                                : esgScoreUrl.COMPETITOR_GRAPH_AND_NEWS
                        }?metricName=${name}`;
                        if (
                            selectedLegend.length === 0 ||
                            (selectedLegend.length === 1 &&
                                selectedLegend[0] === 'avg')
                        ) {
                            api = `${api}&selectOrganizationIds`;
                        } else {
                            if (
                                data &&
                                Array.isArray(data.series) &&
                                !this.isIndustry()
                            ) {
                                selectedLegend.forEach((id) => {
                                    if (id !== 'avg') {
                                        const target = data.series.find(
                                            (j) => j.id === id
                                        );
                                        if (target) {
                                            const targetId =
                                                id === 'myself'
                                                    ? target.selfId
                                                    : id;
                                            if (targetId) {
                                                api += `&selectOrganizationIds=${targetId}`;
                                            }
                                        }
                                    }
                                });
                            }
                        }
                        const res = await service.get(api);
                        if (res && res.name) {
                            const { XArrFull, series } = this.processData(res);
                            if (Array.isArray(res.breadCrumbs)) {
                                this.setState({
                                    breadCrumbs: res.breadCrumbs,
                                });
                            }
                            const newsKey = this.isCompetitorOverall()
                                ? 'news'
                                : 'sources';
                            if (
                                res[newsKey] &&
                                Array.isArray(res[newsKey].data)
                            ) {
                                this.setState({
                                    news: res[newsKey],
                                });
                            }
                            this.setState(
                                {
                                    data: { ...res, XArrFull, series },
                                    disableFetchNews: false,
                                },
                                () => {
                                    if (typeof cb === 'function') {
                                        cb();
                                    }
                                }
                            );
                        }
                    } catch (err) {
                        console.log(err);
                    }
                    this.toggleGlobalLoading(false);
                }
            );
        }
    };

    getPeriod = () => {
        const { selectedIndex } = this.state;
        if (!Array.isArray(selectedIndex) && selectedIndex.length === 0) {
            return { startDate: '', endDate: '' };
        }
        const { XArr = [] } = this.processSeries() || {};
        const yearOfToday = new Date().getFullYear();
        if (selectedIndex.length === 1) {
            let target = XArr[selectedIndex[0]];
            if (target === this.props.locale.latest) {
                target = yearOfToday;
            }
            const { start: startDate, end: endDate } = getYearTime(target);
            return {
                startDate,
                endDate,
            };
        } else {
            const startTarget = XArr[selectedIndex[0]];
            let endTarget = XArr[selectedIndex[1]];
            if (endTarget === this.props.locale.latest) {
                endTarget = yearOfToday;
            }
            const { start: startDate } = getYearTime(startTarget);
            const { end: endDate } = getYearTime(endTarget);
            return {
                startDate,
                endDate,
            };
        }
    };

    processPostData = (metricName) => {
        const {
            page,
            pageSize,
            data: { series },
            selectedIndex,
            selectedLegend,
        } = this.state;
        let postData = {
            page,
            pageSize,
            metricName,
        };
        const { startDate, endDate } = this.getPeriod();
        if (startDate && endDate) {
            postData = { ...postData, startDate, endDate };
        }
        const metricDataIds = [];
        const list = series.filter(
            (i) => i.id !== 'avg' && selectedLegend.indexOf(i.id) > -1
        );
        list.forEach((i) => {
            let targetPoints = i.data;
            if (selectedIndex.length === 1) {
                targetPoints = targetPoints.slice(
                    selectedIndex[0],
                    selectedIndex[0] + 1
                );
            } else if (selectedIndex.length === 2) {
                targetPoints = targetPoints.slice(
                    selectedIndex[0],
                    selectedIndex[1] + 1
                );
            }
            targetPoints.forEach((j) => {
                if (j && metricDataIds.indexOf(j.newsSourceMapId) === -1) {
                    metricDataIds.push(j.newsSourceMapId);
                }
            });
        });
        return { ...postData, metricDataIds };
    };

    processApiUrl = (originalApi) => {
        const {
            page,
            pageSize: size,
            data: { series },
            selectedLegend,
        } = this.state;
        let params = {
            page,
            size,
        };
        const { startDate, endDate } = this.getPeriod();
        if (startDate && endDate) {
            params = { ...params, startDate, endDate };
        }
        let newApi = `${originalApi}?${qs.stringify(params)}`;
        const list = series.filter(
            (i) => i.id !== 'avg' && selectedLegend.indexOf(i.id) > -1
        );
        if (list.length > 0) {
            list.forEach(
                (c) =>
                    (newApi += `&competitorIds=${
                        c.id === 'myself' ? c.selfId : c.id
                    }`)
            );
        } else {
            newApi += `&competitorIds`;
        }
        return newApi;
    };

    fetchNews = async () => {
        const {
            router: {
                location: {
                    query: { name },
                },
            },
        } = this.props;
        if (name) {
            try {
                this.toggleGlobalLoading(true);
                let res = null;
                if (this.isCompetitorOverall()) {
                    res = await service.get(
                        this.processApiUrl(esgScoreUrl.COMPETITOR_NEWS_OVERALL)
                    );
                } else {
                    res = await service.post(
                        esgScoreUrl.DATA_POINT_NEWS,
                        this.processPostData(name)
                    );
                }
                if (res && Array.isArray(res.data)) {
                    this.setState({ news: res });
                }
            } catch (err) {
                console.log(err);
            }
            this.toggleGlobalLoading(false);
        }
    };

    toggleGlobalLoading = (globalLoading) => {
        this.props.dispatch({
            type: commonType.APPLY_SET_COMMON_STATE,
            payload: {
                globalLoading,
            },
        });
    };

    toggleShowDesc = () => {
        const { showDesc } = this.state;
        this.setState({ showDesc: !showDesc });
    };

    processNews = (data) => {
        let groupedNews = [];
        data.forEach((i) => {
            const date = formatDate(i.date, 'mid');
            if (
                groupedNews.length === 0 ||
                groupedNews[groupedNews.length - 1].date !== date
            ) {
                groupedNews.push({
                    date,
                    news: [i],
                });
            } else {
                groupedNews[groupedNews.length - 1].news.push(i);
            }
        });
        return groupedNews;
    };

    getCompany = (organizationIds) => {
        const {
            data: { series },
        } = this.state;
        if (
            !Array.isArray(series) ||
            series.length < 1 ||
            !Array.isArray(organizationIds) ||
            organizationIds.length < 1
        ) {
            return null;
        }
        const arr = [];
        organizationIds.forEach((id) => {
            const target = series.find((s) => s.id === id || s.selfId === id);
            if (target) {
                arr.push(target);
            }
        });
        return arr[0];
    };

    handleClickBreadCrumbs = (item) => {
        this.props.history.push(
            `/esg-score/${
                this.isIndustry() ? 'industry' : 'competitor'
            }/component?name=${item.keyName}`
        );
    };

    renderTrend = (diff) => {
        if (typeof diff !== 'number') {
            return null;
        } else {
            const diffValue = Number((diff * 100).toFixed(2));
            return (
                <>
                    <div
                        className={classNames({
                            'compare-data': true,
                            'compare-data-up': diffValue > 0,
                            'compare-data-down': diffValue < 0,
                        })}
                    >
                        {diffValue === 0 ? <Minus /> : <ArrowDownFull />}
                        <span>
                            {diffValue >= 0 ? '+' : ''}
                            {diffValue === 0 ? '0' : diffValue}
                        </span>
                    </div>
                    <span className="compare-text">
                        {this.props.locale.compare_with_last_week}
                    </span>
                </>
            );
        }
    };

    handleBack = (currentName, backUrl) => {
        const { esg, history, dispatch } = this.props;
        dispatch({
            type: esgType.APPLY_SET_ESG_STATE,
            payload: {
                name: currentName,
                isNameChanged: esg.name !== currentName,
            },
        });
        history.push(backUrl);
    };

    renderScoreProcess = () => {
        const {
            data: { scoreStatusMap, series },
            selectedLegend,
            showScoreProcessChart,
        } = this.state;
        const { mobileView, locale } = this.props;
        if (this.isIndustry() || !scoreStatusMap) {
            return null;
        }
        const statusArr = [{ id: 'header' }];
        selectedLegend.forEach((i) => {
            const target = series.find((j) => j.id === i && i !== 'avg');
            if (target) {
                const id = i === 'myself' ? target.selfId : target.id;
                statusArr.push({
                    ...target,
                    status: scoreStatusMap[id],
                });
            }
        });
        const nowYear = new Date().getFullYear();
        const xArr = [...new Array(5).keys()].map((i) => nowYear - (4 - i));
        return (
            <div className="score-process">
                <div className="score-process-header">
                    <h6>
                        {locale.esg_score_reported}
                        <Popover tooltip={locale.esg_score_reported_note} />
                    </h6>
                    <button
                        onClick={() =>
                            this.setState({
                                showScoreProcessChart: !showScoreProcessChart,
                            })
                        }
                        className={classNames({
                            'toggle-btn': true,
                            'toggle-btn-hide': !showScoreProcessChart,
                        })}
                    >
                        <ArrowDown />
                    </button>
                </div>
                {statusArr.length > 1 && showScoreProcessChart && (
                    <div
                        className={classNames({
                            'score-process-chart': true,
                            'score-process-chart-only-one':
                                statusArr.length === 2,
                        })}
                    >
                        {statusArr.map((i) => {
                            const columns =
                                !Array.isArray(i.status) || i.status.length < 1
                                    ? xArr
                                    : i.status;
                            return (
                                <div key={i.id} className="row">
                                    <b className="row-title">
                                        {i.id === 'header' ? (
                                            ''
                                        ) : (
                                            <>
                                                <span
                                                    style={{
                                                        backgroundColor:
                                                            i.highlight.color,
                                                    }}
                                                    className="row-title-dot"
                                                />
                                                <span>{i.name}</span>
                                            </>
                                        )}
                                    </b>
                                    <div className="row-values">
                                        {columns.map((j, jIndex) => {
                                            const mobileViewYear =
                                                mobileView && (
                                                    <span>{xArr[jIndex]}</span>
                                                );
                                            let content = null;
                                            if (i.id === 'header') {
                                                content = j;
                                            } else if (j.status === 'YES') {
                                                content = (
                                                    <>
                                                        {mobileViewYear}
                                                        <div className="value-wrap value-yes">
                                                            <Check />
                                                        </div>
                                                    </>
                                                );
                                            } else if (j.status === 'PENDING') {
                                                content = (
                                                    <>
                                                        {mobileViewYear}
                                                        <div className="value-pending">
                                                            --
                                                        </div>
                                                    </>
                                                );
                                            } else {
                                                content = (
                                                    <>
                                                        {mobileViewYear}
                                                        <div className="value-wrap value-no" />
                                                    </>
                                                );
                                            }
                                            return (
                                                <span
                                                    key={jIndex}
                                                    className="row-value"
                                                >
                                                    {content}
                                                </span>
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        );
    };

    render() {
        const { locale, mobileView} = this.props;
        const { data, showDesc, showChartTip, selectedLegend, breadCrumbs } =
            this.state;
        const backUrl = `/esg-score/${
            this.isIndustry() ? 'industry' : 'competitor'
        }`;
        const weight = data.weight;
        console.log('data', data);
        return (
            <div
                className={classNames({
                    'page score_component_page': true,
                    'page-with-global-tooltip': showChartTip,
                })}
            >
                <Helmet title={data.displayName || locale.score_component} />
                <div className="score_component-header">
                    <div className="content-wrap">
                        <div className="header-content">
                            <div className="header-bread-crumbs">
                                {breadCrumbs.map((i, index) => {
                                    const isCurrent =
                                        index === breadCrumbs.length - 1;
                                    return (
                                        <div
                                            key={i.keyName}
                                            className={classNames({
                                                'bread-crumb-item': true,
                                                'bread-crumb-item-active':
                                                    isCurrent,
                                            })}
                                            onClick={
                                                isCurrent
                                                    ? null
                                                    : () =>
                                                          this.handleClickBreadCrumbs(
                                                              i
                                                          )
                                            }
                                        >
                                            <span className="bread-crumb-title">
                                                {i.displayName}
                                            </span>
                                            <ArrowDown />
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="header-display-name">
                                <button
                                    onClick={() =>
                                        this.handleBack(data.name, backUrl)
                                    }
                                >
                                    <ArrowLeft
                                        color="111111"
                                        style={{ fontSize: 32 }}
                                    />
                                </button>
                                <span title={data.displayName} className="header-display-name-title">
                                    {data.displayName}
                                    {
                                        weight && weight !== 'null' && <>
                                            <SectorRadio
                                                weight={weight}
                                                sectorColor={pillarColors[breadCrumbs[1]?.keyName]}
                                                diameter={mobileView ? 24 : 28}
                                            />
                                             <span className="header-display-name-title-weight">
                                                 {weight}
                                            </span>
                                        </>
                                    }
                                </span>
                            </div>
                            <div className='header-display-standards'>
                                {standards.filter(stand => data[stand]).map(item => <span>{item}</span>)}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={classNames({
                        'score_component-main': true,
                        'score_component-main-show-tip': showChartTip,
                    })}
                >
                    <div className="content-wrap">
                        <div className="section-wrap">
                            <div className="section-header">
                                <div className="section-title">
                                    <span>{data.displayName}</span>
                                    {/* {this.renderTrend(data.diff)} */}
                                </div>
                                <p className="section-subtitle">
                                    {locale.fiscal_year_score_data}
                                </p>
                            </div>
                            <div className="chart-wrap" id="score-chart">
                                {showChartTip && (
                                    <PopoverAntd
                                        placement={
                                            mobileView ? 'top' : 'topLeft'
                                        }
                                        visible
                                        content={
                                            <div className="chart-tip-text">
                                                <p
                                                    dangerouslySetInnerHTML={{
                                                        __html: locale.score_chart_tip,
                                                    }}
                                                />
                                                <Button
                                                    onClick={() =>
                                                        this.setState({
                                                            showChartTip: false,
                                                        })
                                                    }
                                                    label={locale.got_it}
                                                />
                                            </div>
                                        }
                                    >
                                        <div className="section-popover-root" />
                                    </PopoverAntd>
                                )}
                            </div>
                            {data && Array.isArray(data.series) && (
                                <div className="chart-legend">
                                    <Switch
                                        type="checkbox"
                                        options={data.series.map((i) => ({
                                            key: i.id,
                                            name: i.name,
                                            activeColor: i.highlight.color,
                                            disabled: i.validDataCount === 0,
                                        }))}
                                        value={selectedLegend}
                                        onChange={(value) => {
                                            const newState = {
                                                selectedLegend: value,
                                                selectedIndex: [],
                                            };
                                            if (
                                                !this.isToggleSelectAvg(
                                                    selectedLegend,
                                                    value
                                                )
                                            ) {
                                                newState.page = 0;
                                            }
                                            this.setState(newState);
                                        }}
                                    />
                                </div>
                            )}
                            {this.renderScoreProcess()}
                        </div>
                        {typeof data.description === 'string' && (
                            <div className="section-wrap">
                                <div className="section-header">
                                    <span className="section-title">
                                        {locale.description}
                                    </span>
                                    <button
                                        className={classNames({
                                            'section-arrow-btn': true,
                                            'section-arrow-btn-show': showDesc,
                                        })}
                                        onClick={this.toggleShowDesc}
                                    >
                                        <ArrowDown
                                            color="4D4D4D"
                                            style={{ fontSize: 18 }}
                                        />
                                    </button>
                                </div>
                                <p
                                    className={classNames({
                                        'section-desc': true,
                                        'section-desc-hide': !showDesc,
                                    })}
                                >
                                    {data.description}
                                </p>
                            </div>
                        )}
                    </div>
                </div>
                {showChartTip && <div className="empty-tip" />}
            </div>
        );
    }
}

export default withRouter(
    connect(
        ({
            persist: { account, oneTimeNote, locale },
            common: { mobileView },
            router,
            esg,
        }) => ({
            account,
            oneTimeNote,
            router,
            locale,
            mobileView,
            esg,
        })
    )(EsgScoreComponent)
);
