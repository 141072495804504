import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { Popover as PopoverAntd } from 'antd';
import { connect } from 'react-redux';
import './index.scss';
import { Info } from '../../component/Icon';

class Popover extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            info: null,
            showTooltip: false,
            mobileTooltipStyle: null,
        };
        this.iconRef = React.createRef();
        this.tooltipRef = React.createRef();
    }

    componentDidMount() {
        window.addEventListener('click', this.tryHideTooltip);
        window.addEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate(prevProps, prevState) {
        const mobileView = this.checkIfMobileView();
        const { showTooltip } = this.state;
        const { showTooltip: prevShowTooltip } = prevState;
        if (mobileView && !prevShowTooltip && showTooltip) {
            this.setMobileTooltipStyle();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.tryHideTooltip);
        window.removeEventListener('scroll', this.handleScroll);
    }

    checkIfMobileView = () => {
        const { mobileView, isMobileView } = this.props;
        if (typeof isMobileView === 'boolean') {
            return isMobileView;
        } else {
            return mobileView;
        }
    };

    handleScroll = () => {
        const { showTooltip } = this.state;
        if (showTooltip) {
            this.setState({ showTooltip: false });
        }
    };

    tryHideTooltip = (e) => {
        const { showTooltip } = this.state;
        const path = e.path || (e.composedPath && e.composedPath());
        if (path.indexOf(this.iconRef.current) >= 0 && !showTooltip) {
            this.setIconPosition(this.clickIcon);
        }
        if (path.indexOf(this.tooltipRef.current) < 0 && showTooltip) {
            this.setState({ showTooltip: false });
        }
    };

    setIconPosition = (cb) => {
        if (this.iconRef.current) {
            const info = this.iconRef.current.getBoundingClientRect();
            this.setState(
                {
                    info,
                },
                () => {
                    if (typeof cb === 'function') {
                        cb();
                    }
                }
            );
        }
    };

    setMobileTooltipStyle = () => {
        const { info } = this.state;
        const { width } = this.tooltipRef.current.getBoundingClientRect();
        const scrollBarWidth = parseFloat(
            document.documentElement.style.getPropertyValue('--scroll_bar_v') ||
                0
        );
        const clientW = window.innerWidth - scrollBarWidth;
        const left = info.left + (info.width - width) / 2;
        if (left >= 0 && left + width <= clientW) {
            this.setState({
                mobileTooltipStyle: { left },
            });
        } else if (left < 0 && clientW - width > 0) {
            this.setState({ mobileTooltipStyle: { left: 10 } });
        } else if (left + width > clientW && clientW - width > 0) {
            this.setState({ mobileTooltipStyle: { right: 10 } });
        } else {
            this.setState({
                mobileTooltipStyle: {
                    left: (clientW - width) / 2,
                },
            });
        }
    };

    clickIcon = () => {
        const { hideOnMobile } = this.props;
        const mobileView = this.checkIfMobileView();
        const { showTooltip } = this.state;
        if (mobileView && !hideOnMobile) {
            this.setState({ showTooltip: !showTooltip });
        }
    };

    renderMobileTooltipModal = (data) => {
        const { mobilePlacement = 'bottom' } = this.props;
        const {
            info,
            tooltipProps,
            tooltipContentStyle,
            mobileTooltipStyle,
            tooltipChildren,
            verticalGap,
        } = data;
        const polygonStyle = {
            top: info.top + verticalGap,
            left: info.left + (info.width - 12) / 2,
        };
        if (mobilePlacement === 'top') {
            polygonStyle.transform = 'rotateZ(180deg)';
        }
        const childNode = (
            <div className="mobile-tooltip-wrap">
                {info && (
                    <>
                        <p className="tooltip-polygon" style={polygonStyle} />
                        <div
                            ref={this.tooltipRef}
                            className="esgai_tooltip mobile-tooltip"
                            {...tooltipProps}
                            style={{
                                ...tooltipContentStyle,
                                ...mobileTooltipStyle,
                            }}
                        >
                            {tooltipChildren}
                        </div>
                    </>
                )}
            </div>
        );
        return ReactDOM.createPortal(
            childNode,
            document.getElementsByTagName('body')[0]
        );
    };

    renderContent = (tooltipProps, tooltipChildren) => {
        const {
            infoIconSize = 22,
            children,
            hideOnMobile = false,
            mobileMaxWidth,
            width = 305,
            autoWidth = false,
            infoIconStyle = {},
            verticalGap = 32,
            mobilePlacement = 'bottom',
        } = this.props;
        const mobileView = this.checkIfMobileView();
        const { info, showTooltip, mobileTooltipStyle } = this.state;
        const tooltipContentStyle = {
            top: (info ? info.top : 0) + (verticalGap + 8),
            width: autoWidth ? 'auto' : width,
            maxWidth: mobileMaxWidth || (autoWidth ? 'calc(100% - 20px)' : 720),
        };
        if (mobilePlacement === 'top') {
            tooltipContentStyle.transform = 'translateY(calc(-100% - 8px))';
        }
        return (
            <div
                className={classNames({
                    'esgai-popover': true,
                    'esgai-popover-mobile': mobileView,
                })}
            >
                <div className="esgai-popover-icon" ref={this.iconRef}>
                    {children || (
                        <Info
                            color="4D4D4D"
                            style={{ fontSize: infoIconSize, ...infoIconStyle }}
                        />
                    )}
                </div>
                {!hideOnMobile &&
                    mobileView &&
                    showTooltip &&
                    this.renderMobileTooltipModal({
                        info,
                        tooltipProps,
                        tooltipContentStyle,
                        mobileTooltipStyle,
                        tooltipChildren,
                        verticalGap,
                    })}
            </div>
        );
    };

    render() {
        const {
            tooltip = '',
            placement = 'rightTop',
            width = 305,
            autoWidth = false,
            maxWidth,
            isInnerHTML = true,
        } = this.props;
        const mobileView = this.checkIfMobileView();
        let tooltipProps = null;
        let children = null;
        if (isInnerHTML) {
            tooltipProps = {
                dangerouslySetInnerHTML: {
                    __html: tooltip,
                },
            };
        } else {
            children = tooltip;
        }
        return mobileView ? (
            this.renderContent(tooltipProps, children)
        ) : (
            <PopoverAntd
                content={
                    <div
                        style={{
                            width: autoWidth ? 'auto' : width,
                            maxWidth: maxWidth || 'auto',
                        }}
                        className="esgai_tooltip"
                        {...tooltipProps}
                    >
                        {children}
                    </div>
                }
                placement={placement}
            >
                {this.renderContent()}
            </PopoverAntd>
        );
    }
}

export default connect(({ common: { mobileView } }) => ({
    mobileView,
}))(Popover);
