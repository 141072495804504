import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { commonType } from '../../../constant/actionTypes';
import service from '../../../service';
import './index.scss';
import { Newspaper } from '../../../component/Icon';
import NewsWithoutImg from '../../../component/NewsWithoutImg';
import NewsPageTitle from '../../../component/NewsPageTitle';
import NewsEmpty from '../../../component/NewsEmpty';
import { newsUrl } from '../../../constant/endpoint';
import { formatDate, displaySelectedDate, processNewsApi } from '../../../utils';
import Pagination from '../../../component/Pagination';
import NewsFilter from '.././../../component/NewsFilter';
import { NewsContext } from '../index';

class NewsCompany extends React.Component {
    static contextType = NewsContext;

    constructor(props) {
        super(props);
        this.state = {
            news: [],
            totalCount: 0,
            page: 0,
            size: 10,
            period: [],
            paginationCount: 0,
            newsTypes: [],
            nextNewsTypes: [],
            onlyControversial: false,
            nextOnlyControversial: false,
            autoRemoveUrlParams: true,
        };
    }

    componentDidMount() {
        const {
            router: {
                location: {
                    query: { type, startDate },
                },
            },
        } = this.props;
        const { period, onlyControversial, newsTypes } = this.context;

        if (type === 'controversial' && startDate) {
            this.handleGetNotifyNews();
        } else {
            this.setState({
                period, onlyControversial, newsTypes
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            page,
            period,
            paginationCount,
            newsTypes,
            onlyControversial,
            autoRemoveUrlParams,
        } = this.state;
        const {
            page: prevPage,
            period: prevPeriod,
            newsTypes: prevNewsTypes,
            onlyControversial: prevOnlyControversial,
        } = prevState;
        const {
            router: {
                location: {
                    query: { type, startDate },
                },
            },
            history,
        } = this.props;
        const {
            router: {
                location: {
                    query: { type: prevType, startDate: prevStartDate },
                },
            },
        } = prevProps;
        const filterChange =
            period !== prevPeriod ||
            newsTypes !== prevNewsTypes ||
            onlyControversial !== prevOnlyControversial;

        if (filterChange) {
            this.setState({ paginationCount: paginationCount + 1 });
        }
        if (filterChange || page !== prevPage) {
            if (autoRemoveUrlParams && type === 'controversial' && startDate) {
                history.push('/news/company');
            } else if (!autoRemoveUrlParams) {
                this.setState({
                    autoRemoveUrlParams: true,
                });
            }
            this.fetchData();
        }
        if (
            (type !== prevType || startDate !== prevStartDate) &&
            type === 'controversial' &&
            startDate
        ) {
            this.handleGetNotifyNews();
        }
    }

    handleGetNotifyNews = () => {
        const { setPeriod, setOnlyControversial } = this.context;
        const {
            router: {
                location: {
                    query: { startDate },
                },
            },
        } = this.props;
        const start = new Date(Number(startDate));
        const now = new Date();
        const startY = start.getFullYear();
        const startM = start.getMonth();
        const startD = start.getDate();
        const y = now.getFullYear();
        const m = now.getMonth();
        const d = now.getDate();
        setPeriod([
            [startY, startM, startD],
            [y, m, d],
        ]);
        setOnlyControversial(true);
        this.setState({
            period: [
                [startY, startM, startD],
                [y, m, d],
            ],
            onlyControversial: true,
            nextOnlyControversial: true,
            autoRemoveUrlParams: false,
        });
    };

    fetchData = async () => {
        try {
            this.toggleGlobalLoading(true);
            const newsRes = await service.get(
                processNewsApi(this.state, newsUrl.COMPANY_NEWS)
            );
            if (newsRes && Array.isArray(newsRes.data)) {
                this.setState({
                    news: newsRes.data,
                    totalCount: newsRes.totalCount,
                });
            }
        } catch (err) {
            console.log(err);
        }
        this.toggleGlobalLoading(false);
    };

    toggleGlobalLoading = (globalLoading) => {
        this.props.dispatch({
            type: commonType.APPLY_SET_COMMON_STATE,
            payload: {
                globalLoading,
            },
        });
    };

    renderGroupHeader = (date, num) => {
        return (
            <div className="group-header">
                <p className="date-group-date">
                    {date || displaySelectedDate(this.state.period)}
                </p>
                <div className="group-num-wrap">
                    <Newspaper style={{ fontSize: 24 }} color="7A7A7A" />
                    <span className="group-num">{num}</span>
                </div>
            </div>
        );
    };

    renderNewsList = () => {
        const {
            news,
            page,
            size = 10,
            totalCount,
            paginationCount,
        } = this.state;
        const { mobileView } = this.props;
        let groupedNews = [];
        news.forEach((i) => {
            const date = formatDate(i.versionCreated, 'mid', { month: 'long' });
            if (
                groupedNews.length === 0 ||
                groupedNews[groupedNews.length - 1].date !== date
            ) {
                groupedNews.push({
                    date,
                    group: [i],
                });
            } else {
                groupedNews[groupedNews.length - 1].group.push(i);
            }
        });

        return (
            <div className="news-list-wrap">
                <div className="news-list">
                    {groupedNews.length > 0 ? (
                        groupedNews.map((i) => (
                            <div key={i.date} className="date-group">
                                {this.renderGroupHeader(i.date, i.group.length)}
                                {i.group.map((k) => (
                                    <NewsWithoutImg
                                        key={k.storyId}
                                        news={{
                                            title: k.text,
                                            brief: k.abstract,
                                            logo: k.thumbnail,
                                            date: k.versionCreated,
                                            topic: k.topic,
                                            storyId: k.storyId,
                                        }}
                                    />
                                ))}
                            </div>
                        ))
                    ) : (
                        <>
                            {this.renderGroupHeader(null, 0)}
                            <NewsEmpty size={mobileView ? 'tiny' : 'default'} />
                        </>
                    )}
                </div>
                <Pagination
                    count={paginationCount}
                    page={page}
                    size={size}
                    totalCount={totalCount}
                    onChange={(value) => this.setState({ page: value })}
                />
            </div>
        );
    };

    render() {
        const { locale, mobileView } = this.props;
        const {
            period,
            newsTypes,
            onlyControversial,
            nextOnlyControversial,
            nextNewsTypes,
        } = this.state;
        const { setPeriod, setOnlyControversial, setNewsTypes } = this.context;

        return (
            <div className="page news_company_page">
                <Helmet title={locale.company_news} />
                <NewsPageTitle
                    title={locale.company_news}
                    tooltip={locale.company_news_note}
                />
                <div className="news_company-main">
                    {this.renderNewsList()}
                    <NewsFilter
                        period={period}
                        newsTypes={mobileView ? nextNewsTypes : newsTypes}
                        onlyControversial={
                            mobileView
                                ? nextOnlyControversial
                                : onlyControversial
                        }
                        updateParentState={(newState, cb = () => {}) => {
                            const { newsTypes, onlyControversial, period } = newState;

                            newsTypes && setNewsTypes(newsTypes);
                            typeof onlyControversial === 'boolean' && setOnlyControversial(onlyControversial);
                            period && setPeriod(period);

                            this.setState(newState, cb)
                        }}
                        handleShowMobileFilter={(cb) => {    
                            this.setState(
                                {
                                    nextNewsTypes: newsTypes,
                                    nextOnlyControversial: onlyControversial,
                                },
                                cb
                            );
                        }}
                        handleApplyMobileFilter={() => {
                            setNewsTypes(nextNewsTypes);
                            setOnlyControversial(nextOnlyControversial);
                            this.setState({
                                newsTypes: nextNewsTypes,
                                onlyControversial: nextOnlyControversial,
                                page: 0,
                            });
                        }}
                    />
                </div>
            </div>
        );
    }
}

export default withRouter(
    connect(
        ({ persist: { account, locale }, common: { mobileView }, router }) => ({
            account,
            router,
            locale,
            mobileView,
        })
    )(NewsCompany)
);
