import React from 'react';
import { connect } from 'react-redux';
import './index.scss';
import { Eye } from '../../component/Icon';

const Input = (props) => {
    const {
        id,
        label,
        hidePassword,
        value,
        onChange,
        onBlur,
        toggleHidePassword,
        error,
        type = 'text',
        disabled = false,
        maxLength = 60,
        showRequired = false,
    } = props;
    return (
        <div
            className={`esgai_form-input-wrap ${
                type === 'password' ? 'esgai_password-input-wrap' : ''
            }`}
        >
            <label htmlFor={id}>
                {label}
                {showRequired && <span>*</span>}
            </label>
            <input
                maxLength={maxLength}
                className={error ? 'input-error' : ''}
                autoComplete={
                    type === 'password' && hidePassword ? 'new-password' : 'off'
                }
                type={type === 'password' && hidePassword ? 'password' : 'text'}
                style={
                    type === 'password'
                        ? {
                              letterSpacing: hidePassword ? 10 : 1,
                          }
                        : {}
                }
                id={id}
                value={value}
                onChange={onChange}
                onBlur={onBlur || (() => null)}
                disabled={disabled}
            />
            {type === 'password' && (
                <button
                    type="button"
                    className="toggle-switch"
                    onClick={toggleHidePassword}
                >
                    <Eye
                        style={{
                            fontSize: 16,
                            opacity: hidePassword ? 0.5 : 1,
                        }}
                        color={hidePassword ? '7A7A7A' : '233BC9'}
                    />
                </button>
            )}
            <p>{error}</p>
        </div>
    );
};

export default connect(({ common: { globalLoading } }) => ({
    globalLoading,
}))(Input);
