import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { ArrowLeft } from '../../../../component/Icon';
import './index.scss';
import BottomSheet from '../../../../component/BottomSheet';

const ListHeader = (props) => {
    const dropdownRef = useRef(null);
    const {
        title,
        sortOrder = 'desc',
        handleChange,
        isTrash = false,
        locale,
        isEmpty = false,
        mobileView,
    } = props;
    const { sortKey = isTrash ? 'trashedDate' : 'lastUpdate' } = props;
    const [showDropdown, setShowDropdown] = useState(false);

    useEffect(() => {
        if (!mobileView) {
            if (showDropdown) {
                window.addEventListener('click', tryHideDropdown);
            } else {
                window.removeEventListener('click', tryHideDropdown);
            }
        }
        return () => {
            window.removeEventListener('click', tryHideDropdown);
        };
    }, [showDropdown]); // eslint-disable-line

    const tryHideDropdown = (e) => {
        if (mobileView) {
            return;
        }
        const path = e.path || (e.composedPath && e.composedPath());
        if (path.indexOf(dropdownRef.current) < 0 && showDropdown) {
            setShowDropdown(false);
        }
    };

    const sortKeys = [
        {
            key: isTrash ? 'trashedDate' : 'lastUpdate',
            label: isTrash ? locale.date : locale.last_modified,
        },
        {
            key: 'name',
            label: locale.name,
        },
    ];

    const renderOptions = (data) => {
        return (
            <div className="sort-keys-list">
                {sortKeys.map((i) => (
                    <span
                        className={classNames({
                            'sort-key-item option-item': true,
                            'sort-key-item-selected option-item-active':
                                i.key === sortKey,
                        })}
                        key={i.key}
                        onClick={() => {
                            if (data && typeof data.close === 'function') {
                                data.close(() =>
                                    handleChange('SortKey', i.key)
                                );
                            } else {
                                handleChange('SortKey', i.key);
                            }
                        }}
                    >
                        {i.label}
                    </span>
                ))}
            </div>
        );
    };

    return (
        <div className="optimizer_board-list-header">
            {(!mobileView || title) && (
                <span className="header-title">{title}</span>
            )}
            <div className="sort-wrap">
                <button
                    className={classNames({
                        'sort-key-wrap': true,
                        'sort-key-wrap-active': showDropdown,
                    })}
                    ref={dropdownRef}
                    onClick={() => setShowDropdown(!showDropdown)}
                    disabled={isEmpty}
                >
                    <span className="sort-key-label">
                        {isEmpty ? '' : `${locale.sort_by}: `}
                        {(sortKeys.find((i) => i.key === sortKey) || {}).label}
                    </span>
                    {!mobileView && showDropdown && renderOptions()}
                </button>
                <button
                    className={classNames({
                        'sort-order': true,
                        [`sort-order-${sortOrder}`]: true,
                    })}
                    onClick={() =>
                        handleChange(
                            'SortOrder',
                            sortOrder === 'desc' ? 'asc' : 'desc'
                        )
                    }
                    disabled={isEmpty}
                >
                    <ArrowLeft />
                </button>
            </div>
            {mobileView && showDropdown && (
                <BottomSheet
                    title={locale.sort_by}
                    renderContent={renderOptions}
                    close={() => setShowDropdown(false)}
                />
            )}
        </div>
    );
};

export default connect(({ persist: { locale }, common: { mobileView } }) => ({
    locale,
    mobileView,
}))(ListHeader);
