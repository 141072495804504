import React from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import './index.scss';
import service from '../../../../service';
import { optimizerUrl } from '../../../../constant/endpoint';
import { Close, More, User, Exclamation } from '../../../../component/Icon';
import { formatDate } from '../../../../utils';
import Popover from '../../../../component/Popover';

class BoardActivity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showActivity: false,
            users: [],
            count: 0,
            history: {
                data: [],
                totalCount: 0,
            },
            showCurrentHolderTip: false,
        };
        this.timer = null;
        this.sideBoardRef = React.createRef();
        this.sideBoardEntryRef = React.createRef();
    }
    componentDidMount() {
        this.fetchData();
        this.clearTimer();
        this.timer = setInterval(() => {
            this.fetchData();
        }, 1000 * 60);
        window.addEventListener('click', this.tryHideSideBoard);
    }

    componentDidUpdate(prevProps, prevState) {
        const { currentHolder, firebaseUser } = this.props;
        const { currentHolder: prevCurrentHolder } = prevProps;
        const { showActivity } = this.state;
        const { showActivity: prevShowActivity } = prevState;
        if (
            firebaseUser &&
            currentHolder.userId !== prevCurrentHolder.userId &&
            !(
                !prevCurrentHolder.userId &&
                String(currentHolder.userId) === String(firebaseUser.uid)
            )
        ) {
            this.handleShowCurrentHolderNotify();
        }
        if (!prevShowActivity && showActivity) {
            this.handleUnreadActivity();
        }
        if (prevShowActivity && !showActivity) {
            this.updateStateForUnreadActivity();
        }
    }

    componentWillUnmount() {
        this.clearTimer();
        this.handleExit();
        window.removeEventListener('click', this.tryHideSideBoard);
    }

    tryHideSideBoard = (e) => {
        const path = e.path || (e.composedPath && e.composedPath());
        if (
            path.indexOf(this.sideBoardRef.current) < 0 &&
            path.indexOf(this.sideBoardEntryRef.current) < 0 &&
            this.state.showActivity
        ) {
            this.setState({ showActivity: false });
        }
    };

    handleExit = async () => {
        try {
            const {
                board: { id },
            } = this.props;
            await service.delete(optimizerUrl.UPDATE_BOARD_USERS(id));
        } catch (err) {
            console.log(err);
        }
    };

    handleUnreadActivity = async () => {
        const { history } = this.state;
        if (history.data[0].acknowledged) {
            return;
        }
        const revisionHistoryId = history.data[0].id;
        try {
            const {
                board: { id },
            } = this.props;
            await service.put(optimizerUrl.ACK_BOARD_REVERSIONS(id), {
                revisionHistoryId,
            });
        } catch (err) {
            console.log(err);
        }
    };

    updateStateForUnreadActivity = () => {
        const { count, history } = this.state;
        if (count > 0) {
            const newHistoryData = [];
            history.data.forEach((h) => {
                newHistoryData.push({ ...h, acknowledged: true });
            });
            this.setState({
                count: 0,
                history: { ...history, data: newHistoryData },
            });
        }
    };

    fetchData = async () => {
        try {
            const {
                board: { id },
                handleUpdateState,
            } = this.props;
            const { showActivity } = this.state;
            let users = [];
            let count = 0;
            let history = {
                data: [],
                totalCount: 0,
            };
            const newsUsers = await service.put(
                optimizerUrl.UPDATE_BOARD_USERS(id)
            );
            let newState = {};
            if (Array.isArray(newsUsers)) {
                users = newsUsers;
                if (newsUsers.length > 0) {
                    handleUpdateState({ currentHolder: users[0] });
                }
                newState.users = users;
            }
            if (!showActivity) {
                const newCount = await service.get(
                    optimizerUrl.GET_BOARD_REVERSIONS_COUNT(id)
                );
                if (typeof newCount === 'number') {
                    count = newCount;
                }
                const newHistory = await service.get(
                    optimizerUrl.GET_BOARD_REVERSIONS(id)
                );
                if (newHistory && Array.isArray(newHistory.data)) {
                    history = newHistory;
                }
                newState = { ...newState, count, history };
            }
            this.setState(newState);
        } catch (err) {
            console.log(err);
        }
    };

    handleShowCurrentHolderNotify = () => {
        this.setState({ showCurrentHolderTip: true });
    };

    clearTimer = () => {
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
        }
    };

    processHistory = () => {
        const {
            history: { data },
        } = this.state;
        const historyMap = {};
        const dateArr = [];
        data.forEach((h) => {
            const { dateStr, timeStr } = this.processDate(h.lastUpdate);
            if (dateArr[dateArr.length - 1] !== dateStr) {
                dateArr.push(dateStr);
            }
            const newH = { ...h, timeStr };
            if (Array.isArray(historyMap[dateStr])) {
                historyMap[dateStr].push(newH);
            } else {
                historyMap[dateStr] = [newH];
            }
        });
        return { dateArr, historyMap };
    };

    processDate = (timestamp) => {
        const dateArr = formatDate(timestamp, 'full', { hour12: true }).split(
            ', '
        );
        const dateStr = `<b>${dateArr[0]}</b>, ${dateArr[1]}`;
        const timeStr = dateArr[2];
        return { dateStr, timeStr };
    };

    renderSideActivitiesContent = () => {
        const { showActivity } = this.state;
        const { locale } = this.props;
        const { dateArr, historyMap } = this.processHistory();

        return ReactDOM.createPortal(
            <div
                className={classNames({
                    'activity-content-wrap': true,
                    'activity-content-wrap-show': showActivity,
                })}
                ref={this.sideBoardRef}
            >
                <div className="activity-header">
                    <button
                        onClick={() => this.setState({ showActivity: false })}
                    >
                        <Close style={{ fontSize: 24 }} color="#4D4D4D" />
                    </button>
                    <h5>{locale.activity}</h5>
                </div>
                <div className="activity-main esgai_scrollbar">
                    {dateArr.map((d) => (
                        <div key={d} className="activity-group">
                            <h6
                                className="activity-group-title"
                                dangerouslySetInnerHTML={{ __html: d }}
                            />
                            <div className="activity-list">
                                {historyMap[d].map((h) => (
                                    <div key={h.id} className="activity-item">
                                        <div className="activity-avatar">
                                            {h.avatar && h.avatarUrl ? (
                                                <img alt="" src={h.avatarUrl} />
                                            ) : (
                                                <User
                                                    color="B6B8C5"
                                                    style={{ fontSize: 20 }}
                                                />
                                            )}
                                        </div>
                                        <div className="activity-detail">
                                            <p>
                                                <b>{`${h.firstName} ${h.lastName} `}</b>
                                                {locale.edited_file}
                                            </p>
                                            <span>{h.timeStr}</span>
                                        </div>
                                        <span
                                            className={classNames({
                                                'not-read-dot': true,
                                                'not-read-dot-hide':
                                                    h.acknowledged,
                                            })}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>,
            document.getElementsByTagName('body')[0]
        );
    };

    renderActivities = () => {
        const { count } = this.state;
        const { locale } = this.props;
        return (
            <div className="activity-btn-wrap">
                <button
                    ref={this.sideBoardEntryRef}
                    className="activity-btn"
                    onClick={() =>
                        this.setState({
                            showActivity: true,
                        })
                    }
                >
                    {count ? <span>{count > 99 ? '99+' : count}</span> : null}
                    {locale.activity}
                </button>
                {this.renderSideActivitiesContent()}
            </div>
        );
    };

    renderUsers = () => {
        const { users } = this.state;
        const { mobileView } = this.props;
        let displayedUsers = [];
        let hideUsers = [];
        let tooltip = '';
        let wrapWidth = 32;
        if (users.length <= 3) {
            displayedUsers = users;
        } else {
            displayedUsers = users.slice(0, 2);
            hideUsers = users.slice(2);
            const names = hideUsers.map((i) => `${i.firstName} ${i.lastName}`);
            const commonArr = names.slice(0, -1);
            const lastStr = names[names.length - 1];
            const commonStr = commonArr.join(', ');
            tooltip = `${commonStr} and ${lastStr}`;
        }
        if (users.length === 2) {
            wrapWidth = 32 * 2 - 10;
        } else if (users.length > 2) {
            wrapWidth = 32 * 3 - 20;
        }
        return (
            <div className="online-users-wrap" style={{ width: wrapWidth }}>
                {displayedUsers.map((u) => {
                    const hasAvatar = u.avatar && u.avatarUrl;
                    const { firstName, lastName } = u;
                    return (
                        <div
                            className={classNames({
                                'online-user': true,
                                'online-user-without-logo': !hasAvatar,
                            })}
                            key={u.userId}
                            title={`${u.firstName} ${u.lastName}`}
                        >
                            {hasAvatar ? (
                                <img alt="" src={u.avatarUrl} />
                            ) : (
                                <span>{`${firstName.slice(
                                    0,
                                    1
                                )}${lastName.slice(0, 1)}`}</span>
                            )}
                        </div>
                    );
                })}
                {hideUsers.length > 0 && (
                    <Popover
                        tooltip={tooltip}
                        placement="topLeft"
                        autoWidth
                        isMobileView={mobileView}
                        maxWidth={220}
                        mobileMaxWidth={220}
                    >
                        <div className="online-user hide-users">
                            <More />
                        </div>
                    </Popover>
                )}
            </div>
        );
    };

    renderCurrentHolderTip = () => {
        const { currentHolder, firebaseUser, locale } = this.props;
        if (!firebaseUser) {
            return null;
        }
        const isSelf =
            String(currentHolder.userId) === String(firebaseUser.uid);
        return ReactDOM.createPortal(
            <div
                className={classNames({
                    'current-holder-tip': true,
                    'current-holder-tip-self': isSelf,
                })}
            >
                {!isSelf && (
                    <Exclamation color="E65C47" style={{ fontSize: 24 }} />
                )}
                <p
                    dangerouslySetInnerHTML={{
                        __html: isSelf
                            ? locale.you_can_edit_file
                            : locale.current_holder_tip(currentHolder.fullName),
                    }}
                />
                <button
                    onClick={() =>
                        this.setState({ showCurrentHolderTip: false })
                    }
                >
                    <Close color="7A7A7A" style={{ fontSize: 24 }} />
                </button>
            </div>,
            document.getElementsByTagName('body')[0]
        );
    };

    render() {
        const { users, history, showCurrentHolderTip } = this.state;
        if (users.length === 0 && history.data.length === 0) {
            return null;
        }
        return (
            <div className="optimizer_board_detail-activity">
                {history.data.length > 0 && this.renderActivities()}
                {users.length > 0 && this.renderUsers()}
                {showCurrentHolderTip && this.renderCurrentHolderTip()}
            </div>
        );
    }
}

export default withRouter(
    connect(
        ({
            persist: { locale, firebaseUser },
            common: { clientWidth },
            router,
        }) => ({
            locale,
            router,
            mobileView: clientWidth < 1220,
            firebaseUser,
        })
    )(BoardActivity)
);
