import React from 'react';
import { connect } from 'react-redux';
import Popover from '../Popover';
import './index.scss';

const NewsPageTitle = (props) => {
    const { title, tooltip } = props;
    return (
        <div className="news_page-header">
            <div className="content-wrap">
                <h4 className="news-page-title-wrap">
                    <span className="title">{title}</span>
                    <Popover tooltip={tooltip} />
                </h4>
            </div>
        </div>
    );
};

export default connect(({ persist: { locale } }) => ({
    locale,
}))(NewsPageTitle);
