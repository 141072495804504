import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import './index.scss';
import Popover from '../../../../component/Popover';
import { ArrowLeft } from '../../../../component/Icon';

/**
 * This is an abbreviated version of Overview. 
 * Because of changes in demand, the previous Overview is no longer needed.
 * The previous Overview was very complicated, so I couldn't bear to delete it directly, so I added this simplified version.
 */
class SimpleOverview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            scrollY: 0,
        };
        this.pillarLabels = ['E', 'S', 'G'];
    }

    getESGScore = () => {
        const { pillars } = this.props;
        let {
            ESGScore = {},
            EnvironmentPillarScore = {},
            GovernancePillarScore = {},
            SocialPillarScore = {},
        } = pillars || {};
        if (!ESGScore) {
            ESGScore = {};
        }
        if (!EnvironmentPillarScore) {
            EnvironmentPillarScore = {};
        }
        if (!GovernancePillarScore) {
            GovernancePillarScore = {};
        }
        if (!SocialPillarScore) {
            SocialPillarScore = {};
        }
        return {
            ESGScore,
            EnvironmentPillarScore,
            SocialPillarScore,
            GovernancePillarScore,
        };
    };

    getCurrentScoreData = () => {
        const { pillars, country, industry = {} } = this.props;
        const { ESGScore } = this.getESGScore();
        const { industryRank, geoRank } = this.splitRanks(ESGScore.ranks);
        const rankArr = ['industry', 'geographical'];
        const pillarArr = [
            { key: 'environmental', valueKey: 'EnvironmentPillarScore' },
            { key: 'social', valueKey: 'SocialPillarScore' },
            { key: 'governance', valueKey: 'GovernancePillarScore' },
        ];
        return {
            ESGScore,
            pillars,
            country,
            industry,
            industryRank,
            geoRank,
            rankArr,
            pillarArr,
        };
    };

    renderCurrentScoreSimplify = () => {
        const { locale } = this.props;
        const { ESGScore, pillars, pillarArr } = this.getCurrentScoreData();
        return (
            <div className="overview-current-simplify">
                <div className="overall">
                    <span>{locale.overall_esg_score}</span>
                    <b>{`${ESGScore.displayValue || '--'} ${ESGScore.grade || '--'
                        }`}</b>
                </div>
                <div className="pillars">
                    {pillarArr.map((i, index) => {
                        const hasPillarData = pillars && pillars[i.valueKey];
                        return (
                            <div className="pillar" key={i.key}>
                                <span>{this.pillarLabels[index]}</span>
                                <span>
                                    {(hasPillarData &&
                                        pillars[i.valueKey].displayValue) ||
                                        '--'}
                                </span>
                                <span>
                                    {(hasPillarData &&
                                        pillars[i.valueKey].grade) ||
                                        '--'}
                                </span>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    renderCurrentScoreOverview = () => {
        const { locale, oneTimeTutorialStep: step, mobileView } = this.props;
        const {
            ESGScore,
            pillars,
            country,
            industry,
            industryRank,
            geoRank,
            rankArr,
            pillarArr,
        } = this.getCurrentScoreData();
        return (
            <div
                className={classNames({
                    'overview-current': true,
                    'overview-part-with-mask': step && step < 3,
                })}
            >
                <div className="overview-current-top">
                    <div className="overall">
                        <span>{locale.overall_esg_score}</span>
                        <b>{`${ESGScore.displayValue || '--'} ${
                            ESGScore.grade || '--'
                        }`}</b>
                    </div>
                    <div className="ranks">
                        {rankArr.map((i) => {
                            const rank =
                                i === 'industry' ? industryRank : geoRank;
                            return (
                                <div className="rank-group" key={i}>
                                    <h6 className="rank-title">
                                        <span>{locale[`${i}_rank_title`]}</span>
                                        <Popover
                                            width={257}
                                            tooltip={locale[`${i}_rank_note`](
                                                i === 'industry'
                                                    ? industry.title
                                                    : country
                                            )}
                                            infoIconStyle={{ opacity: 0.7 }}
                                            isMobileView={mobileView}
                                        />
                                    </h6>
                                    <p>
                                        <b>{rank.rankValue || '--'}</b>
                                        <span>/{rank.rankTotal || '--'}</span>
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="overview-current-bottom">
                    {pillarArr.map((i) => {
                        const hasPillarData = pillars && pillars[i.valueKey];
                        return (
                            <div
                                className={classNames({
                                    'overview-current-pillar': true,
                                    [`overview-current-pillar-${i.key}`]: true,
                                })}
                                key={i.key}
                            >
                                <p>{locale[i.key]}</p>
                                <p>
                                    <span>
                                        {(hasPillarData &&
                                            pillars[i.valueKey].displayValue) ||
                                            '--'}
                                    </span>
                                    <span>
                                        {(hasPillarData &&
                                            pillars[i.valueKey].grade) ||
                                            '--'}
                                    </span>
                                </p>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    processScore = (scoreObj) => {
        if (scoreObj && scoreObj.displayValue && scoreObj.grade) {
            return {
                score: scoreObj.displayValue,
                grade: scoreObj.grade,
            };
        }
        return {
            score: '--',
            grade: '',
        };
    };

    processRank = (rankObj) => {
        const { rankTotal, rankValue } = rankObj;
        return { rankTotal, rankValue };
    };

    splitRanks = (ranks) => {
        let industryRank = {};
        let geoRank = {};
        if (Array.isArray(ranks)) {
            ranks.forEach((r) => {
                if (r.geographical) {
                    geoRank = r;
                } else {
                    industryRank = r;
                }
            });
        }
        return { industryRank, geoRank };
    };

    getTargetScoreData = () => {
        const { locale } = this.props;
        const {
            ESGScore,
            EnvironmentPillarScore,
            SocialPillarScore,
            GovernancePillarScore,
        } = this.getESGScore();
        const { targetScore: allScore, targetRanks: allRanks } = ESGScore;
        const { industryRank: targetIndustryRank, geoRank: targetGeoRank } =
            this.splitRanks(allRanks);
        const scoreArr = [
            {
                title: locale.esg,
                note: '',
                ...this.processScore(allScore),
            },
            {
                title: locale.industry_rank_title,
                note: locale.industry_rank_note,
                isRank: true,
                ...this.processRank(targetIndustryRank),
            },
            {
                title: locale.geographical_rank_title,
                note: locale.geographical_rank_note,
                isRank: true,
                ...this.processRank(targetGeoRank),
            },
            {
                title: locale.environmental,
                ...this.processScore(EnvironmentPillarScore.targetScore),
            },
            {
                title: locale.social,
                ...this.processScore(SocialPillarScore.targetScore),
            },
            {
                title: locale.governance,
                ...this.processScore(GovernancePillarScore.targetScore),
            },
        ];
        return {
            scoreArr,
        };
    };

    renderTargetScoreOverview = () => {
        const { locale, oneTimeTutorialStep: step } = this.props;
        const { scoreArr } = this.getTargetScoreData();
        return (
            <div
                className={classNames({
                    'overview-target': true,
                    'overview-part-with-mask': step && step > 2,
                })}
            >
                <h5>{locale.target_scores}</h5>
                <div className="overview-target-main">
                    {scoreArr.map((i) => (
                        <div className="target-score-item" key={i.title}>
                            <h6>{i.title}</h6>
                            {i.isRank ? (
                                <div className="score-rank">
                                    <b>{i.rankValue}</b>
                                    <span>/{i.rankTotal}</span>
                                </div>
                            ) : (
                                <div className="score-value">
                                    <b>
                                        {i.score} {i.grade}
                                    </b>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    renderTargetScoreSimplify = () => {
        const { locale, mobileView } = this.props;
        const { scoreArr } = this.getTargetScoreData();
        const { industryRank, geoRank, industry, country } = this.getCurrentScoreData();
        return (
            <div className="overview-target-simplify">
                <h5>{locale.target_scores}</h5>
                <div className="target-simplify-main">
                    <div className="overall">
                        <span>{locale.overall_esg_score}</span>
                        <b>
                            {scoreArr[0].score} {scoreArr[0].grade}
                        </b>
                    </div>
                    <div className="pillars">
                        {scoreArr.slice(3).map((i, index) => (
                            <div className="pillar" key={index}>
                                <span>{this.pillarLabels[index]}</span>
                                <b>
                                    {i.score} {i.grade}
                                </b>
                            </div>
                        ))}
                    </div>
                    <div className="ranks">
                        {scoreArr.slice(1, 3).map((i, index) => {
                            const currentRank =
                                index === 0 ? industryRank : geoRank;
                            const currentRankValue =
                                currentRank.rankValue || '--';
                            return (
                                <div className="rank" key={index}>
                                    <h6>
                                        <span>{i.title}</span>
                                        {
                                            i.note ? <Popover
                                                width={257}
                                                tooltip={i.note(
                                                    index === 0
                                                        ? industry.title
                                                        : country
                                                )}
                                                infoIconStyle={{ opacity: 0.7 }}
                                                isMobileView={mobileView}
                                            /> : ''
                                        }
                                    </h6>
                                    <div className="rank-value">
                                        <b>
                                            {currentRankValue}
                                            <ArrowLeft />
                                            {i.rankValue || currentRank.rankValue || '--'}
                                        </b>
                                        <span>/{i.rankTotal || currentRank.rankTotal || '--'}</span>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { mobileView } = this.props;

        return (
            <div className="optimizer_board_detail-overview">
                {
                    mobileView && (
                        <div className="overview-complete">
                            {this.renderCurrentScoreOverview()}
                            {this.renderTargetScoreOverview()}
                        </div>
                    )
                }
                {!mobileView && (
                    <div className="overview-simplify">
                        {this.renderCurrentScoreSimplify()}
                        {this.renderTargetScoreSimplify()}
                    </div>)}
            </div>
        );
    }
}

export default withRouter(
    connect(({ persist: { locale }, common: { clientWidth } }) => ({
        locale,
        mobileView: clientWidth < 1220,
    }))(SimpleOverview)
);
