import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import NProgress from 'nprogress';
import './index.scss';
import { Loading } from '../Icon';

const GlobalLoading = (props) => {
    const { globalLoading, isProgressBar } = props;
    useEffect(() => {
        if (isProgressBar) {
            NProgress.start();
            return () => {
                NProgress.done();
            };
        }
    }, [isProgressBar]);

    return globalLoading && !isProgressBar ? (
        <div className="page_loading">
            <div className="loading-icon">
                <Loading style={{ fontSize: 40 }} />
            </div>
        </div>
    ) : null;
};

export default connect(({ common: { globalLoading } }) => ({
    globalLoading,
}))(GlobalLoading);
