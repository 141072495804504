import React from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { message } from 'antd';
import firebase from 'firebase/app';
import { pattern } from '../../utils';
import { Key } from '../../component/Icon';
import Input from '../../component/Input';
import Button from '../../component/Button';
import PasswordManage from '../../component/layouts/PasswordManage';
import { persistType } from '../../constant/actionTypes';
import service from '../../service';
import { accountUrl } from '../../constant/endpoint';

class SetupPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                password: '',
                confirmPassword: '',
            },
            errors: {
                password: '',
                confirmPassword: '',
            },
            touched: {
                confirmPassword: false,
            },
            hide: {
                password: true,
                confirmPassword: true,
            },
            loading: false,
            passwordSet: false,
        };
    }

    handleInputChange = (e, field) => {
        const { data, touched } = this.state;
        if (field === 'confirmPassword' && !touched[field]) {
            this.setState({
                touched: { ...touched, [field]: true },
            });
        }
        this.setState(
            {
                data: {
                    ...data,
                    [field]: e.target.value.trim(),
                },
            },
            () => {
                this.validation(field);
            }
        );
    };

    handleSubmit = async () => {
        const {
            data: { password },
            loading,
            passwordSet,
        } = this.state;
        if (!loading && !passwordSet && this.validation()) {
            this.setState({ loading: true });
            const {
                router: {
                    location: {
                        query: { email, oobCode, invitationCode, mode },
                    },
                },
            } = this.props;
            try {
                if (this.isSetupPsw() && !mode) {
                    await firebase
                        .auth()
                        .signInWithEmailAndPassword(email, oobCode);
                    const user = firebase.auth().currentUser;
                    await user.updatePassword(password);
                    await firebase.auth().signOut();
                } else {
                    await firebase
                        .auth()
                        .confirmPasswordReset(oobCode, password);
                }
                if (this.isSetupPsw() && invitationCode) {
                    await service.put(accountUrl.PASSWORD_STATUS, {
                        invitationCode,
                    });
                }
                this.setState({ passwordSet: true }, () => {
                    const { log, dispatch } = this.props;
                    if (log && typeof log === 'string') {
                        dispatch({
                            type: persistType.APPLY_UPDATE_CERTIFICATE,
                            payload: '',
                        });
                    }
                });
            } catch (err) {
                message.error(err.message);
            }
            this.setState({ loading: false });
        }
    };

    validation = (field) => {
        const {
            data: { password, confirmPassword },
            errors,
            touched,
        } = this.state;
        const { locale } = this.props;
        if (field === 'password' || !field) {
            if (!pattern.password.test(password)) {
                errors.password = locale.psw_length_error;
            } else if (errors.password) {
                errors.password = '';
            }
            if (password !== confirmPassword) {
                if (!field || touched.confirmPassword) {
                    errors.confirmPassword = locale.psw_not_match;
                }
            } else if (errors.confirmPassword) {
                errors.confirmPassword = '';
            }
        } else if (field === 'confirmPassword') {
            if (password !== confirmPassword) {
                errors.confirmPassword = locale.psw_not_match;
            } else if (errors.confirmPassword) {
                errors.confirmPassword = '';
            }
        }
        this.setState({ errors });
        return !errors.password && !errors.confirmPassword;
    };

    toggleHide = (field) => {
        const { hide } = this.state;
        hide[field] = !hide[field];
        this.setState({ hide });
    };

    isSetupPsw = () => {
        const {
            router: {
                location: { pathname },
            },
        } = this.props;
        return pathname === '/setup-password';
    };

    getPageText = () => {
        const { locale } = this.props;
        const textMap = {
            setupPsw: {
                pageTitle: locale.setupPSW_page_title,
                formTitle: locale.setupPSW_form_title,
                pswInputLabel: locale.password,
                confirmPswInputLabel: locale.confirm_psw,
                submitBtnLabel: locale.setupPSW_submit_label,
                pswUpdateTitle: locale.setupPSW_psw_created,
                pswUpdateNote: locale.setupPSW_psw_created_note,
            },
            resetPsw: {
                pageTitle: locale.resetPsw_page_title,
                formTitle: locale.resetPsw_page_title,
                formSubtitle: locale.resetPsw_form_subtitle,
                pswInputLabel: locale.new_psw,
                confirmPswInputLabel: locale.confirm_new_psw,
                submitBtnLabel: locale.resetPsw_submit_label,
                pswUpdateTitle: locale.resetPsw_psw_updated,
            },
        };
        const key = this.isSetupPsw() ? 'setupPsw' : 'resetPsw';
        return textMap[key];
    };

    renderForm = () => {
        const {
            data: { password, confirmPassword },
            errors,
            hide,
            loading,
        } = this.state;
        const {
            router: {
                location: {
                    query: { email },
                },
            },
            locale,
        } = this.props;
        const text = this.getPageText();
        return (
            <>
                <h3>{text.formTitle}</h3>
                {text.formSubtitle && (
                    <p className="password_manage-tip">{text.formSubtitle}</p>
                )}
                <div className="password_manage-form">
                    {this.isSetupPsw() && (
                        <Input
                            id="setup_password-email"
                            label={locale.email_address}
                            value={email || ''}
                            disabled
                        />
                    )}
                    <Input
                        id="setup_password-password"
                        label={text.pswInputLabel}
                        hidePassword={hide.password}
                        value={password}
                        onChange={(e) => this.handleInputChange(e, 'password')}
                        toggleHidePassword={() => this.toggleHide('password')}
                        error={errors.password}
                        type="password"
                    />
                    <Input
                        id="setup_password-confirm-password"
                        label={text.confirmPswInputLabel}
                        hidePassword={hide.confirmPassword}
                        value={confirmPassword}
                        onChange={(e) =>
                            this.handleInputChange(e, 'confirmPassword')
                        }
                        toggleHidePassword={() =>
                            this.toggleHide('confirmPassword')
                        }
                        error={errors.confirmPassword}
                        type="password"
                    />
                    <Button
                        loading={loading}
                        style={{ marginTop: 20, width: '100%' }}
                        label={text.submitBtnLabel}
                        onClick={this.handleSubmit}
                        onClickEnter={this.handleSubmit}
                    />
                </div>
            </>
        );
    };

    renderPasswordSet = () => {
        const { history, locale } = this.props;
        const text = this.getPageText();
        return (
            <>
                <h3>{text.pswUpdateTitle}</h3>
                {text.pswUpdateNote && (
                    <p className="password_manage-tip">{text.pswUpdateNote}</p>
                )}
                <div
                    style={
                        !this.isSetupPsw() ? { margin: '62px auto 68px' } : {}
                    }
                    className="password_manage-icon"
                >
                    <Key style={{ fontSize: 98 }} color="9C42CC" />
                </div>
                <Button
                    style={{ width: '100%' }}
                    label={locale.login}
                    onClick={() => history.push('/login')}
                />
            </>
        );
    };

    render() {
        const { passwordSet } = this.state;
        const {
            router: {
                location: {
                    query: { email, oobCode },
                },
            },
        } = this.props;
        const text = this.getPageText();
        return (
            <PasswordManage>
                <Helmet title={text.pageTitle} />
                {!(email && oobCode) && <Redirect to="/login" />}
                {passwordSet ? this.renderPasswordSet() : this.renderForm()}
            </PasswordManage>
        );
    }
}

export default withRouter(
    connect(({ router, persist: { log, locale } }) => ({
        log,
        router,
        locale,
    }))(SetupPassword)
);
