import Immutable from 'seamless-immutable';
import { commonType } from '../../constant/actionTypes';

const initialState = Immutable({
    globalLoading: false,
    mobileView: false,
    mobileSettingsMenuScrollLeft: 0,
    emailSupport: false
});

const common = (state = initialState, action) => {
    switch (action.type) {
        case commonType.APPLY_SET_COMMON_STATE:
            return state.merge({ ...action.payload });
        default:
            return state;
    }
};

export default common;
