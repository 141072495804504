import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import './index.scss';
import { formatDate } from '../../../../utils';
import CompareData from '../CompareData';
import { Business } from '../../../../component/Icon';
import Switch from '../../../../component/Switch';

const IndustrySummary = (props) => {
    const [tab, setTab] = useState('weakest');
    let timer = useRef(null);
    const {
        isIndustryScore,
        locale,
        logo,
        info,
        rows,
        openIndex,
        updateParentState,
        mobileView,
    } = props;
    const { scoreRank = {}, strongest = [], weakest = [] } = info;
    const {
        rankTotal = 0,
        rankValue = 0,
        title,
        dateCreated,
    } = scoreRank || {};
    let activeRank =
        rankTotal === 0
            ? 0
            : Math.round(((rankTotal - rankValue) / rankTotal) * 100);
    const rankPercent = activeRank ? `${activeRank}%` : 0;

    useEffect(() => {
        return () => {
            clearTimer();
        };
    }, []);

    const clearTimer = () => {
        if (timer.current) {
            clearInterval(timer.current);
            timer.current = null;
        }
    };

    const handleHighlight = (rowIndex, item) => {
        const targetRow = document
            .getElementsByClassName('row-level-1')
            [rowIndex / 2].getElementsByClassName('row-level-2')
            [item.categoryIndex].getElementsByClassName('row-level-3')[
            item.metricIndex
        ];
        const bottom = window.innerHeight;
        const { top: targetTop, bottom: targetBottom } =
            targetRow.getBoundingClientRect();
        if (!(targetTop >= 272 && targetBottom <= bottom)) {
            const targetPosition = mobileView ? 320 : 272 + (bottom - 272) / 2;
            const gap = targetPosition - targetTop;
            const page = document.getElementsByClassName('esg_score_page')[0];
            const currentScrollY = page.scrollTop;
            page.scrollTo({
                top: currentScrollY - gap,
                behavior: 'smooth',
            });
        }
        timer.current = setInterval(() => {
            const currentClass = targetRow.getAttribute('class');
            if (currentClass.indexOf('row-level-3-highlight') > -1) {
                targetRow.setAttribute(
                    'class',
                    currentClass.replace('row-level-3-highlight', '')
                );
            } else {
                targetRow.setAttribute(
                    'class',
                    `${currentClass} row-level-3-highlight`
                );
            }
        }, 500);
        setTimeout(() => {
            clearTimer();
            const currentClass = targetRow.getAttribute('class');
            if (currentClass.indexOf('row-level-3-highlight') > -1) {
                targetRow.setAttribute(
                    'class',
                    currentClass.replace('row-level-3-highlight', '')
                );
            }
        }, 2000);
    };

    const handleClickHighlightItem = (item) => {
        if (timer.current) {
            return;
        }
        let rowIndex = -1;
        rows.find((r, index) => {
            if (r.key === item.pillar) {
                rowIndex = index * 2;
                return true;
            }
            return false;
        });
        if (
            !openIndex.find(
                (i) => i[0] === rowIndex && i[1] === item.categoryIndex
            )
        ) {
            updateParentState(
                {
                    openIndex: [...openIndex, [rowIndex, item.categoryIndex]],
                },
                () => {
                    handleHighlight(rowIndex, item);
                }
            );
        } else {
            handleHighlight(rowIndex, item);
        }
    };

    const highlightList = tab === 'weakest' ? weakest : strongest;

    return (
        <div
            className={classNames({
                'industry-summary': true,
                'industry-summary-competitors': !isIndustryScore,
            })}
        >
            <div className="industry-summary-card esgai_scrollbar">
                <h5 className="summary-title">{locale.industry_group_rank}</h5>
                <p className="summary-industry">{title || '--'}</p>
                <div className="summary-rank">
                    <div className="summary-rank-left">
                        <div
                            className="summary-logo-wrap"
                            style={{ left: rankPercent }}
                        >
                            {logo ? (
                                <img alt="" src={logo} />
                            ) : (
                                <Business
                                    color="49A09D"
                                    style={{ fontSize: 14 }}
                                />
                            )}
                        </div>
                        <div
                            className="active-bar"
                            style={{ width: rankPercent }}
                        />
                    </div>
                    <div className="summary-rank-right">
                        <span>{rankValue || '--'}</span> /{rankTotal || '--'}
                    </div>
                </div>
                <p className="summary-last-updated">
                    {locale.last_updated}:{' '}
                    {typeof dateCreated === 'number'
                        ? formatDate(dateCreated, 'mid')
                        : '--'}
                </p>
                <div className="summary-highlights">
                    <h6>{locale.metrics}</h6>
                    <Switch
                        options={[
                            {
                                value: 'weakest',
                                title: locale.weakest,
                            },
                            {
                                value: 'strongest',
                                title: locale.strongest,
                            },
                        ]}
                        valueKey="value"
                        labelKey="title"
                        value={tab}
                        onChange={(value) => setTab(value)}
                        theme="dark"
                    />
                    <div className="summary-highlight-group">
                        {Array.isArray(highlightList) &&
                        highlightList.length > 0 ? (
                            highlightList.map((j) => (
                                <div
                                    className="summary-highlight-item"
                                    key={j.name}
                                    onClick={() => handleClickHighlightItem(j)}
                                >
                                    <span className="highlight-item-name">
                                        {j.displayName}
                                    </span>
                                    <CompareData data={j} />
                                </div>
                            ))
                        ) : (
                            <span className="summary-highlight-item-placeholder">
                                --
                            </span>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default connect(({ persist: { locale }, common: { mobileView } }) => ({
    locale,
    mobileView,
}))(IndustrySummary);
