import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import cns from 'classnames';
import './index.scss';
import { Loading } from '../Icon';

const Button = (props) => {
    const {
        type = 'primary', // primary, secondary, link, error
        label = 'OK',
        loading = false,
        style = {},
        disabled = false,
        onClick,
        onClickEnter,
    } = props;

    useEffect(() => {
        const needListener = typeof onClickEnter === 'function';
        if (needListener) {
            window.addEventListener('keydown', handleKeydown);
        }
        return () => {
            if (needListener) {
                window.removeEventListener('keydown', handleKeydown);
            }
        };
    }, []); // eslint-disable-line

    const handleKeydown = (e) => {
        if (e.keyCode === 13) {
            onClickEnter();
        }
    };

    return (
        <button
            className={cns({
                esgai_button: true,
                [`esgai_button-${type}`]: true,
            })}
            type="button"
            onClick={onClick}
            style={style}
            disabled={disabled || loading}
        >
            {loading && (
                <div className="esgai_button-loading">
                    <Loading
                        style={{ fontSize: 24 }}
                        color={
                            type === 'secondary' || type === 'link'
                                ? '7A7A7A'
                                : 'FFFFFF'
                        }
                    />
                </div>
            )}
            {label}
        </button>
    );
};

export default connect(({ common: { globalLoading } }) => ({
    globalLoading,
}))(Button);
