import React from 'react';
import { connect } from 'react-redux';
import cns from 'classnames';
import './index.scss';
import controversial_icon from '../../assets/image/controversial.svg';
import { formatDate } from '../../utils';
import { newsTopic } from '../../constant/enum';

const NewsWithoutImg = (props) => {
    const processNewsType = (topic) => {
        let isControversy = false;
        let arr = Array.isArray(topic) ? topic : [topic];
        const labels = [];
        arr.forEach((i) => {
            if (i === 'M:1QI') {
                isControversy = true;
            } else if (newsTopic[i] && labels.indexOf(newsTopic[i]) === -1) {
                labels.push(newsTopic[i]);
            }
        });
        return { isControversy, labels };
    };
    const { news, style = {}, mobileView } = props;
    const { key, title, date, brief, topic, storyId, id, company } = news;
    const { labels, isControversy } = processNewsType(topic);
    const [day, year, time] = formatDate(date, 'full').split(',');
    const displayDate = `${day}, ${year} | ${time}`;
    const handleClickNews = () => {
        if (typeof storyId === 'string') {
            window.open(`/news/detail?storyId=${storyId}`);
            return;
        }
        if (typeof id === 'string') {
            window.open(`/news/detail?id=${id}`);
        }
    };
    const hasLabels = Array.isArray(labels) && labels.length > 0;
    return (
        <div
            className={cns({
                esg_news_no_img: true,
                esg_news_can_not_click: !storyId && !id,
            })}
            style={{ ...style, ...(mobileView ? { paddingRight: 20 } : {}) }}
            key={key}
            onClick={handleClickNews}
        >
            {title && <h5 className="news_item-title">{title}</h5>}
            {brief && <p className="news_item-brief">{brief}</p>}
            <p
                className="news_item-date"
                style={{ marginBottom: hasLabels ? 20 : 0 }}
            >
                {displayDate}
            </p>
            {hasLabels && (
                <div className="news_item-labels">
                    {labels.map((i) => (
                        <span
                            className={cns({
                                'news_item-label': true,
                                [`news_item-label-${i.toLowerCase()}`]: true,
                            })}
                            key={i}
                        >
                            {i}
                        </span>
                    ))}
                </div>
            )}
            {isControversy && (
                <div className="controversial-img-wrap">
                    <img src={controversial_icon} alt="" />
                </div>
            )}
            {company && company.name && (
                <span
                    className="news_item-company"
                    style={{
                        backgroundColor: company.highlight
                            ? company.highlight.color
                            : '#233bc9',
                    }}
                    title={company.name}
                >
                    {company.name}
                </span>
            )}
        </div>
    );
};

export default connect(({ common: { globalLoading, mobileView } }) => ({
    globalLoading,
    mobileView,
}))(NewsWithoutImg);
