import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Modal from '../../component/Modal';
import Button from '../../component/Button';
import './index.scss';
import { persistType } from '../../constant/actionTypes';
import service from '../../service';
import { accountUrl } from '../../constant/endpoint';

class SetupProfileReminder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    updateReminderLog = () => {
        const {
            account: { username },
            dispatch,
        } = this.props;
        dispatch({
            type: persistType.APPLY_UPDATE_SETUP_PROFILE_REMINDER_LOG,
            payload: {
                [username]: Date.now(),
            },
        });
    };

    addNow = () => {
        const {
            account: { configureStatus = 0 },
            history,
        } = this.props;
        this.updateReminderLog();
        history.push(
            `/settings/${configureStatus === 1 ? 'partner' : 'competitor'}`
        );
    };

    muteReminder = async () => {
        this.setState({ loading: true });
        try {
            const res = await service.put(accountUrl.MUTE_REMINDER, {
                mute: true,
            });
            this.setState({ loading: false }, () => {
                this.props.dispatch({
                    type: persistType.APPLY_UPDATE_PROFILE,
                    payload: res,
                });
            });
        } catch (err) {
            console.log(err);
            this.setState({ loading: false });
        }
    };

    renderSetupProfileReminder = () => {
        const { loading } = this.state;
        const {
            locale,
            account: { configureStatus = 0 },
        } = this.props;
        const text = [
            {
                title: locale.reminder_1_title,
                paragraph_1: locale.reminder_1_paragraph_1,
                paragraph_2: locale.reminder_1_paragraph_2,
            },
            {
                title: locale.reminder_2_title,
                paragraph_1: locale.reminder_2_paragraph_1,
                paragraph_2: locale.reminder_2_paragraph_2,
            },
            {
                title: locale.reminder_3_title,
                paragraph_1: locale.reminder_3_paragraph_1,
                paragraph_2: locale.reminder_3_paragraph_2,
            },
        ];
        return (
            <div className="setup_profile_reminder">
                <h4>{text[configureStatus].title}</h4>
                <p
                    dangerouslySetInnerHTML={{
                        __html: text[configureStatus].paragraph_1,
                    }}
                />
                <p
                    dangerouslySetInnerHTML={{
                        __html: text[configureStatus].paragraph_2,
                    }}
                />
                <div className="setup_profile_reminder-actions">
                    <Button
                        label={locale.add_now}
                        onClick={this.addNow}
                        onClickEnter={this.addNow}
                    />
                    <Button
                        loading={loading}
                        type="link"
                        label={locale.got_it}
                        onClick={this.muteReminder}
                    />
                </div>
            </div>
        );
    };

    showReminder = () => {
        const {
            account: { username, profileConfigured, configureStatus },
            setupProfileLog,
        } = this.props;
        const now = Date.now();
        const lastReminder = setupProfileLog[username];
        if (
            !profileConfigured ||
            configureStatus === 3 ||
            (lastReminder && now - lastReminder <= 1000 * 60 * 60 * 24)
        ) {
            return false;
        }
        return true;
    };

    render() {
        return this.showReminder() ? (
            <Modal>{this.renderSetupProfileReminder()}</Modal>
        ) : null;
    }
}

export default withRouter(
    connect(({ persist: { account, setupProfileLog, locale }, router }) => ({
        account,
        locale,
        router,
        setupProfileLog,
    }))(SetupProfileReminder)
);
