import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { ArrowDown, Competitor, Plus } from '../Icon';
import './index.scss';

const Selector = (props) => {
    const dropdownRef = useRef(null);
    const {
        valueKey,
        labelKey,
        list,
        selected,
        onChange,
        editable = true,
        renderLogo,
        hideSelf = false,
        showTitleTooltip = false,
        needAddMask = false,
        locale,
        listenToScroll = false,
        renderDropdownPlaceholder,
        renderAddCompetitor,
    } = props;

    const [showDropdown, setShowDropdown] = useState(false);

    useEffect(() => {
        if (showDropdown) {
            window.addEventListener('click', tryHideDropdown);
            if (listenToScroll) {
                window.addEventListener('scroll', handleScrollBySelector);
            }
        } else {
            window.removeEventListener('click', tryHideDropdown);
            if (listenToScroll) {
                window.removeEventListener('scroll', handleScrollBySelector);
            }
        }
        return () => {
            window.removeEventListener('click', tryHideDropdown);
            if (listenToScroll) {
                window.removeEventListener('scroll', handleScrollBySelector);
            }
        };
    }, [showDropdown]); // eslint-disable-line

    const tryHideDropdown = (e) => {
        const path = e.path || (e.composedPath && e.composedPath());
        if (path.indexOf(dropdownRef.current) < 0 && showDropdown) {
            setShowDropdown(false);
        }
    };

    const handleScrollBySelector = () => {
        if (!showDropdown) {
            return;
        }
        const dropdown = document.getElementsByClassName(
            'dropdown_selector-list-wrap'
        )[0];
        const base = document.getElementsByClassName(
            'esg_score-table-legend'
        )[0];
        if (dropdown && base) {
            const dropdownL = dropdown.getBoundingClientRect().left;
            const baseL = base.getBoundingClientRect().left;
            if (dropdownL - baseL <= 488) {
                setShowDropdown(false);
            }
        }
    };

    const handleChange = (item) => {
        onChange(item);
        setShowDropdown(false);
    };

    const selectedItem =
        Array.isArray(list) &&
        list.length > 0 &&
        list.find((i) => i[valueKey] === selected);

    const displayName = selectedItem ? selectedItem[labelKey] : '';

    return (
        <div
            className={classNames({
                dropdown_selector: true,
                'dropdown_selector-show': showDropdown,
                'dropdown_selector-disable': !editable,
            })}
            ref={dropdownRef}
        >
            <div
                className="display-name-wrap"
                onClick={() => {
                    if (editable) {
                        setShowDropdown(!showDropdown);
                    }
                }}
                title={showTitleTooltip ? displayName : ''}
            >
                <div className="name-wrap">
                    {typeof renderLogo === 'function' &&
                        selectedItem &&
                        renderLogo(selectedItem)}
                    <span
                        className={classNames({
                            'display-name': true,
                            'display-name-placeholder': !selectedItem,
                        })}
                    >
                        {selectedItem ? displayName : locale.competitors}
                    </span>
                </div>
                <ArrowDown style={{ fontSize: 16 }} />
                {needAddMask && !selectedItem && !showDropdown && (
                    <div className="add-mask">
                        <Plus />
                    </div>
                )}
            </div>
            {showDropdown && (
                <div className="dropdown_selector-list-wrap">
                    {typeof renderDropdownPlaceholder === 'function' ? (
                        renderDropdownPlaceholder()
                    ) : (
                        <div className="dropdown_selector-list">
                            {typeof renderAddCompetitor === 'function' &&
                                renderAddCompetitor(() =>
                                    setShowDropdown(false)
                                )}
                            {list.map((i, index) => {
                                if (hideSelf && selected === i[valueKey]) {
                                    return null;
                                }
                                return (
                                    <div
                                        key={i[valueKey]}
                                        className={classNames({
                                            'dropdown_selector-item': true,
                                            'dropdown_selector-item-selected':
                                                selected === i[valueKey],
                                        })}
                                        onClick={() => handleChange(i)}
                                    >
                                        {i[valueKey] === 'avg' ? (
                                            <Competitor
                                                style={{
                                                    fontSize: 24,
                                                    marginRight: 10,
                                                }}
                                                color="4D4D4D"
                                            />
                                        ) : typeof renderLogo === 'function' ? (
                                            renderLogo(i)
                                        ) : null}
                                        <span>{i[labelKey]}</span>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default connect(({ persist: { locale } }) => ({
    locale,
}))(Selector);
