import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { message } from 'antd';
import firebase from 'firebase/app';
import classNames from 'classnames';
import { pattern } from '../../utils';
import './index.scss';
import bg_login from '../../assets/image/bg_login.png';
import bg_login_clover from '../../assets/image/bg_login_clover.png';

import logo from '../../assets/image/logo_2022_11.png';
import poweredByImg from '../../assets/image/powered-by.png';
import logo_white from '../../assets/image/esgailogo_white.png';

import earth_grey from '../../assets/image/earth_grey.png';
import Input from '../../component/Input';
import Button from '../../component/Button';
import { persistType } from '../../constant/actionTypes';
import { Check } from '../../component/Icon';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                email: '',
                password: '',
            },
            errors: {
                email: '',
                password: '',
            },
            hide: {
                password: true,
            },
            loading: false,
            remember: false,
        };
    }

    componentDidMount() {
        this.getUserCer();
    }

    handleInputChange = (e, field) => {
        const { data } = this.state;
        this.setState(
            {
                data: {
                    ...data,
                    [field]: e.target.value.trim(),
                },
            },
            () => {
                this.validation(field);
            }
        );
    };

    getUserCer = () => {
        const { log } = this.props;
        if (log && typeof log === 'string') {
            const base64Obj = JSON.parse(window.atob(log));
            const obj = {};
            Object.keys(base64Obj).forEach((key) => {
                obj[window.atob(key)] = window.atob(base64Obj[key]);
            });
            if (obj.em && obj.psw) {
                this.setState({
                    data: {
                        email: obj.em,
                        password: obj.psw,
                    },
                    remember: true,
                });
            }
        }
    };

    handleRememberPassword = () => {
        const {
            remember,
            data: { email, password },
        } = this.state;
        const { log, dispatch } = this.props;
        if (remember) {
            const emailKey = window.btoa('em');
            const emailValue = window.btoa(email);
            const passwordKey = window.btoa('psw');
            const passwordValue = window.btoa(password);
            const obj = {
                [emailKey]: emailValue,
                [passwordKey]: passwordValue,
            };
            const newLog = window.btoa(JSON.stringify(obj));
            dispatch({
                type: persistType.APPLY_UPDATE_CERTIFICATE,
                payload: newLog,
            });
        } else if (log) {
            dispatch({
                type: persistType.APPLY_UPDATE_CERTIFICATE,
                payload: '',
            });
        }
    };

    handleLogin = async () => {
        const {
            data: { email, password },
            errors,
            loading,
        } = this.state;
        const { locale } = this.props;
        if (!loading && this.validation()) {
            this.setState({ loading: true });
            const { dispatch } = this.props;
            try {
                await firebase
                    .auth()
                    .signInWithEmailAndPassword(email, password);
                const { currentUser } = firebase.auth();
                const idToken = await currentUser.getIdToken();
                dispatch({
                    type: persistType.LOGIN,
                    idToken,
                    callback: () => {
                        this.setState({ loading: false });
                    },
                    success: this.handleRememberPassword,
                });
            } catch (err) {
                if (
                    err.code === 'auth/wrong-password' ||
                    err.code === 'auth/user-not-found'
                ) {
                    errors.email = ' ';
                    errors.password = locale.wrong_auth;
                    this.setState({ errors });
                } else {
                    message.error(err.message);
                }
                this.setState({ loading: false });
            }
        }
    };

    validation = (field) => {
        const {
            data: { email, password },
            errors,
        } = this.state;
        const { locale } = this.props;
        if (errors.password === locale.wrong_auth) {
            errors.email = '';
            errors.password = '';
        }
        if (field === 'email' || !field) {
            if (!pattern.email.test(email)) {
                errors.email = locale.invalid_email;
            } else if (errors.email) {
                errors.email = '';
            }
        }
        if (field === 'password' || !field) {
            if (!pattern.required.test(password)) {
                errors.password = locale.required;
            } else if (errors.password) {
                errors.password = '';
            }
        }
        this.setState({ errors });
        return !errors.email && !errors.password;
    };

    toggleHide = (field) => {
        const { hide } = this.state;
        hide[field] = !hide[field];
        this.setState({ hide });
    };

    render() {
        const {
            data: { email, password },
            hide,
            loading,
            errors,
            remember,
        } = this.state;
        const { history, locale } = this.props;
        return (
            <div className="page login_page">
                <Helmet title={locale.login} />
                <div className="login-left">
                    <img src={earth_grey} alt="" className="login-bg-mobile" />
                    <div className="login-left-content">
                        <div className="login-header">
                            <img
                                className="login-logo"
                                src={logo}
                                alt="ESG AI Logo"
                            />
                            <div className="login-logo esgai_devider"></div>
                            <img
                                className="login-logo login-poweredby"
                                src={poweredByImg}
                                alt="ESG AI Logo"
                            />
                            <img
                                className="login-logo-mobile"
                                src={logo_white}
                                alt="ESG AI Logo"
                            />
                            {/* <div className="login-logo-mobile esgai_devider"></div>
                            <img
                                className="login-logo-mobile login-poweredby-mobile"
                                src={poweredByWhiteImg}
                                alt="ESG AI Logo"
                            /> */}
                        </div>
                        <div className="login-header title">
                            <h3 className="login-title">
                                {locale.login_welcome}
                            </h3>
                            {/* <h3>{locale.login_title}</h3> */}
                        </div>
                        <div className="login-form-wrap">
                            <div className="login-form">
                                <Input
                                    id="login-email"
                                    label={locale.email_address}
                                    value={email}
                                    onChange={(e) =>
                                        this.handleInputChange(e, 'email')
                                    }
                                    error={errors.email}
                                />
                                <Input
                                    id="login-password"
                                    label={locale.password}
                                    hidePassword={hide.password}
                                    value={password}
                                    onChange={(e) =>
                                        this.handleInputChange(e, 'password')
                                    }
                                    toggleHidePassword={() =>
                                        this.toggleHide('password')
                                    }
                                    error={errors.password}
                                    type="password"
                                />
                                <div className="login-form-tools">
                                    <div
                                        className={classNames({
                                            'login-remember-wrap': true,
                                            'login-remember-wrap-checked':
                                                remember,
                                        })}
                                        onClick={() => {
                                            this.setState({
                                                remember: !remember,
                                            });
                                        }}
                                    >
                                        <div className="checkbox">
                                            {remember && (
                                                <Check
                                                    color="FFFFFF"
                                                    style={{ fontSize: 12 }}
                                                />
                                            )}
                                        </div>
                                        <span>{locale.login_remember}</span>
                                    </div>
                                    <Button
                                        type="link"
                                        label={locale.login_forgot_psw}
                                        onClick={() =>
                                            history.push('/forgot-password')
                                        }
                                    />
                                </div>
                                <Button
                                    loading={loading}
                                    style={{ marginTop: 28, width: '100%' }}
                                    label={locale.login}
                                    onClick={this.handleLogin}
                                    onClickEnter={this.handleLogin}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="login-right">
                    <img src={bg_login} alt="Login BG Icon" />
                    <h2>{locale.login_slogan}</h2>
                    <img src={bg_login_clover} alt="Login BG Icon" />
                </div>
            </div>
        );
    }
}

export default withRouter(
    connect(({ router, persist: { log, locale } }) => ({
        router,
        log,
        locale,
    }))(Login)
);
